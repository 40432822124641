import React, { useContext, useEffect, useState } from "react";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";
import { useAPI } from "./APIProvider";

const TenantSettingsContext = React.createContext(null);

export const TenantSettingsProvider = ({ children }) => {
  const api = useAPI();
  const [tenantSettings, setTenantSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchTenantSettings = async () => {
    try {
      const data = await api.getTenantSettings();
      setTenantSettings(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTenantSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !tenantSettings) {
    return <ApplicationBootingPage />;
  }

  return (
    <TenantSettingsContext.Provider
      value={{
        config: tenantSettings,
        fetchConfig: fetchTenantSettings,
      }}
    >
      {children}
    </TenantSettingsContext.Provider>
  );
};

export const useTenantSettings = useContext.bind(null, TenantSettingsContext);
