import React from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "@/frontend/lib/utils";
import { Eye, EyeOff } from "lucide-react";
import {
  FormItem,
  FormField,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/frontend/components/ui/form";
import { Input } from "@/frontend/components/ui/input";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function HFTextField({
  label,
  fieldName,
  type,
  icon,
  className,
  ...rest
}) {
  const { control } = useFormContext();
  const [visible, setVisible] = React.useState(false);

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          {label && (
            <FormLabel>
              <LocalTranslatedText text={label} />
            </FormLabel>
          )}
          <FormControl>
            <div className='relative'>
              {icon && (
                <div className='flex absolute inset-y-0 items-center pointer-events-none pl-3 flex-none'>
                  <icon className='text-icon-input' />
                </div>
              )}
              <Input
                {...field}
                onChange={(event) => {
                  if (type === "number") {
                    field.onChange(parseInt(event.target.value));
                  } else {
                    field.onChange(event.target.value);
                  }
                }}
                className={cn(
                  error &&
                    "border-danger focus:shadow-error focus:ring-ring-error",
                  icon && "pl-8 pr-3 py-2.5",
                  className
                )}
                value={
                  type === "number" && field.value === 0 ? "" : field.value
                }
                type={type === "password" && visible ? "text" : type}
                placeholder={rest?.placeholder}
                {...rest}
              />
              {type === "password" && (
                <div className='flex absolute inset-y-0 items-center right-0 pr-4 flex-none'>
                  <button
                    onClick={() => setVisible(!visible)}
                    className='p-0'
                    type='button'
                  >
                    {visible ? (
                      <EyeOff className='text-muted-foreground w-4 h-4' />
                    ) : (
                      <Eye className='text-muted-foreground w-4 h-4' />
                    )}
                  </button>
                </div>
              )}
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
