import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalAgentServicesSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getAgentServicesSchema() {
    if (globalAgentServicesSchema) {
      return globalAgentServicesSchema;
    }
    const response = await axiosWithETAGCache.get(`/agent_service/schema`);
    globalAgentServicesSchema = response.data;
    return response.data;
  },

  async getAgentServices(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };
    const response = await axiosWithETAGCache.get(`/agent_service`, {
      params: queryParams,
    });
    return response.data;
  },

  async getAgentService(agentServiceId) {
    const response = await axiosWithETAGCache.get(
      `/agent_service/${agentServiceId}`
    );
    return response.data;
  },

  async createAgentService(agentService) {
    const response = await axios.post(`/agent_service`, agentService);
    return response.data;
  },

  async updateAgentService(agentService) {
    const response = await axios.put(
      `/agent_service/${agentService._id}`,
      agentService
    );
    return response.data;
  },

  async deleteAgentService(agentServiceId) {
    const response = await axios.delete(`/agent_service/${agentServiceId}`);
    return response.data;
  },
};

export default api;
