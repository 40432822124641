import * as React from "react";
import { useState, useCallback, useMemo, useDeferredValue } from "react";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import SvgIcon from "@mui/material/SvgIcon";
import DialogTitle from "@mui/material/DialogTitle";
import { Dialog, DialogContent } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  allIcons,
  allIconsMap,
  NamedIcon,
  searchIndex,
} from "@/frontend/elements/NamedIcon";

const Form = styled("form")({
  position: "sticky",
});

const Paper = styled(MuiPaper)(({ theme }) => ({
  position: "sticky",
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  width: "100%",
  borderRadius: "12px",
  border: "1px solid",
  borderColor: theme.palette.divider,
  boxShadow: "none",
}));

const Input = styled(InputBase)({
  flex: 1,
});

export default function MuiIconSelector({ fieldName, label, onChange, value }) {
  const [selectedIcon, setSelectedIcon] = useState(value ?? "");
  const [isChangeIconDialogOpen, setIsChangeIconDialogOpen] = useState(false);
  const [theme, setTheme] = useState("Filled");
  const [query, setQuery] = useState("");

  const handleIconClick = useCallback(
    (newIconName) => {
      setSelectedIcon(newIconName);
      setIsChangeIconDialogOpen(false);
      onChange(fieldName, newIconName);
    },
    [fieldName, onChange]
  );

  const icons = useMemo(() => {
    const keys =
      query === "" ? null : searchIndex.search(query, { limit: 3000 });
    return (
      keys === null ? allIcons : keys.map((key) => allIconsMap[key])
    ).filter((icon) => theme === icon.theme);
  }, [query, theme]);

  const deferredIcons = useDeferredValue(icons);
  const isPending = deferredIcons !== icons;

  const handleClose = () => {
    setIsChangeIconDialogOpen(false);
  };

  const handleOpen = () => {
    setIsChangeIconDialogOpen(true);
  };

  return (
    <div>
      <div>
        <span>{label}</span>
        <IconButton onClick={handleOpen}>
          {selectedIcon ? <NamedIcon icon={selectedIcon} /> : <SearchIcon />}
        </IconButton>
      </div>

      <Dialog onClose={handleClose} open={isChangeIconDialogOpen}>
        <DialogTitle>Change Icon</DialogTitle>
        <DialogContent>
          <Grid container sx={{ minHeight: 500 }}>
            <Grid item xs={12} sm={3}>
              <Form>
                <Typography fontWeight={500} sx={{ mb: 1 }}>
                  Filter the style
                </Typography>
                <RadioGroup
                  value={theme}
                  onChange={(event) => setTheme(event.target.value)}
                >
                  {["Filled", "Outlined", "Rounded", "Two tone", "Sharp"].map(
                    (currentTheme) => (
                      <FormControlLabel
                        key={currentTheme}
                        value={currentTheme}
                        control={<Radio size='small' />}
                        label={currentTheme}
                      />
                    )
                  )}
                </RadioGroup>
              </Form>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Paper>
                <IconButton sx={{ padding: "10px" }} aria-label='search'>
                  <SearchIcon />
                </IconButton>
                <Input
                  autoFocus
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder='Search icons…'
                  inputProps={{ "aria-label": "search icons" }}
                  endAdornment={
                    isPending ? (
                      <InputAdornment position='end'>
                        <CircularProgress size={16} sx={{ mr: 2 }} />
                      </InputAdornment>
                    ) : null
                  }
                />
              </Paper>
              <Typography sx={{ mb: 1 }}>
                {`${new Intl.NumberFormat("en-US").format(
                  icons.length
                )} matching results`}
              </Typography>
              <Icons icons={deferredIcons} handleIconClick={handleIconClick} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus={true}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Icons = React.memo(function Icons({ icons, handleIconClick }) {
  return (
    <div>
      {icons.map((icon, i) => (
        <SelectableIcon
          key={icon.importName}
          icon={icon}
          onOpenClick={() => handleIconClick(icon.importName)}
          initiallyVisible={i < 50}
        />
      ))}
    </div>
  );
});

const SelectableIcon = React.memo(function SelectableIcon({
  icon,
  onOpenClick,
  initiallyVisible,
}) {
  const rootRef = React.useRef(null);
  const [isVisible, setIsVisible] = useState(initiallyVisible);

  React.useEffect(() => {
    const margin = 200;
    if (initiallyVisible || isElmVisible(rootRef.current, margin)) {
      setIsVisible(true);
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (isElmVisible(entries[0].target, margin)) {
          setIsVisible(true);
        }
      },
      { rootMargin: `${margin}px 0px` }
    );
    observer.observe(rootRef.current);
    return () => observer.disconnect();
  }, [initiallyVisible]);

  return (
    <StyledIcon ref={rootRef}>
      {isVisible ? (
        <SvgIcon
          component={icon.Component}
          className='svg-icon'
          onClick={onOpenClick}
          title={icon.importName}
        />
      ) : (
        <div className='svg-icon' />
      )}
      <div onClick={(e) => selectNode(e.currentTarget)}>{icon.importName}</div>
    </StyledIcon>
  );
});

const StyledIcon = styled("span")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  color: theme.palette.text.secondary,
  margin: "0 4px",
  "& > div": {
    flexGrow: 1,
    fontSize: ".6rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    width: `calc(35px + ${theme.spacing(2)} * 2 + 2px)`,
  },
  "& .svg-icon": {
    width: 35,
    height: 35,
    boxSizing: "content-box",
    cursor: "pointer",
    color: theme.palette.text.primary,
    border: "1px solid transparent",
    fontSize: 35,
    borderRadius: "12px",
    transition: theme.transitions.create(["background-color", "box-shadow"], {
      duration: theme.transitions.duration.shortest,
    }),
    padding: theme.spacing(2),
    margin: theme.spacing(0.5, 0),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.primary.light,
    },
  },
}));

function isElmVisible(elm, margin = 0) {
  const rect = elm.getBoundingClientRect();
  return rect.bottom >= -margin && rect.top <= window.innerHeight + margin;
}

function selectNode(node) {
  const selection = window.getSelection();
  selection.removeAllRanges();
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.addRange(range);
}
