import CustomDialog from "@/frontend/components/widgets/CustomDialog";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import React from "react";

export default function Success({
  message,
  title,
  open,
  onClose,
  onClick,
  actionBtnText,
}) {
  return (
    <CustomDialog
      title={title}
      open={open}
      onClose={onClose}
      width='xl'
      isCancelBtn={false}
      onActionBtnClick={onClick}
      actionBtnText={actionBtnText}
    >
      <div>
        <p className='bg-background text-base leading-6 text-slate-950 my-10'>
          <LocalTranslatedText text={message ?? ""} />
        </p>
      </div>
    </CustomDialog>
  );
}
