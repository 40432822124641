import React from "react";
import { Button } from "@/frontend/components/ui/button";
import { LoaderCircle } from "lucide-react";

function LoadingButton({ loading, children, ...rest }) {
  return (
    <Button {...rest} disabled={loading}>
      {loading && <LoaderCircle className='animate-spin-slow' />} {children}
    </Button>
  );
}

LoadingButton.displayName = "LoadingButton";

export { LoadingButton };
