import * as React from 'react';
import VoiceSettingSlider from "./VoiceSettingSlider";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

export default function BackgroundAudioVolumeSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Background Audio Volume"
      description="The volume of the background audio that will played behind the voice."
      id="background-audio-volume-slider"
      min={0.0}
      max={0.3}
      step={0.01}
      icon={<VolumeUpIcon />}
      marks={[
        { value: 0.0, label: '0.0' },
        { value: 0.05, label: '0.05' },
        { value: 0.1, label: '0.1' },
        { value: 0.15, label: '0.15' },
        { value: 0.2, label: '0.2' },
        { value: 0.25, label: '0.25' },
        { value: 0.3, label: '0.3' },
      ]}
      onChange={onChange}
      value={value}
    />
  );
}

