import {TextField} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {useChangeFormData, useFormData, useFormOptions} from "../FormRoot";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";
import globalTranslationManager from "../../../../translation/frontend/components/translation_manager";
import IconButton from "@mui/material/IconButton";
import {Cached, Visibility, VisibilityOff} from "@mui/icons-material";

export function ApiKeyInput({
                              label,
                              example,
                              fieldName,
                              formatter,
                              validator,
                              errorHelperText,
                              disabled,
                              size,
                              ...props
                            }) {
  const formOptions = useFormOptions();
  const formData = useFormData();
  const changeFormData = useChangeFormData();

  let initialValue = formData[fieldName];
  if (formatter) {
    initialValue = formatter(initialValue);
  }

  const [showValue, setShowValue] = useState(false); // Track visibility of the input

  const generateApiKey = () => {
    const randomChars = Math.random().toString(36).substring(2, 18); // Generate 16 random alphanumeric characters
    const newApiKey = `xk_${randomChars}`;
    setLocalValue(newApiKey);
    setShowValue(true);
    onChangeFormDataDebounced(fieldName, newApiKey);
  };

  const [localValue, setLocalValue] = useState(initialValue);
  let isValid = true;
  if (validator && localValue) {
    isValid = validator(localValue);
  }

  let disabledLocal = !formOptions.editable || disabled;

  const onChangeFormDataDebounced = useMemo(
      () => _.debounce(changeFormData, 500),
      [changeFormData]
  );

  const handleInputChange = useCallback(
      (event) => {
        if (formOptions.editable) {
          setLocalValue(event.target.value);
          if (
              !validator ||
              !event.target.value ||
              validator(event.target.value)
          ) {
            onChangeFormDataDebounced(fieldName, event.target.value ?? "");
          }
        }
      },
      [validator, formOptions, fieldName, onChangeFormDataDebounced]
  );

  if (props.placeholder) {
    props.placeholder =
        globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
            props.placeholder
        );
  }

  return (
      <div className={"form-input"}>
        <Typography variant={"label"}>
          <LocalTranslatedText language={"en"} text={label}/>
        </Typography>

        <div style={{display: 'flex', alignItems: 'center'}}>
          <TextField
              size={size || "medium"}
              name={fieldName}
              placeholder={example}
              InputProps={{
                type: showValue ? '' : 'password',
                endAdornment: ( // Added endAdornment for icons
                    <IconButton
                        onClick={() => setShowValue(!showValue)}
                        edge="end"
                        aria-label={showValue ? "Hide API Key" : "Show API Key"}
                    >
                      {showValue ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                ),
              }}
              type={showValue ? "text" : "password"} // Toggle between text and password
              value={disabledLocal ? "" : localValue}
              onChange={handleInputChange}
              fullWidth
              disabled={disabledLocal}
              error={!isValid}
              helperText={!isValid ? errorHelperText : null}
              {...props}
          />
          <IconButton
              onClick={generateApiKey}
              title="Generate new API Key" // Added hover text
          >
            <Cached/>
          </IconButton>
        </div>
      </div>
  );
}

export default ApiKeyInput;
