import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function TwilioConfigurationForm() {
    return (
        <FormSection
            title={"Twilio Integration"}
            subtitle={"Configuration for the Twilio API Integration for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"twilio"}>
                <FormInput
                    type={"text"}
                    label={"Account SID"}
                    fieldName={"account_sid"}
                    placeholder={"The account SID used to connect to the Twilio API"}
                />
                <FormInput
                    type={"text"}
                    label={"Auth Token"}
                    fieldName={"auth_token"}
                    placeholder={"The auth token used to connect to the Twilio API"}
                />
                <FormInput
                    type={"text"}
                    label={"API Prefix for Twilio Callbacks"}
                    fieldName={"api_prefix_for_twilio_callbacks"}
                    placeholder={"The prefix that should be used for all Twilio callbacks to the Prospera API"}
                />
                <FormInput
                    type={"text"}
                    label={"Frontend Prefix for Twilio Callbacks"}
                    fieldName={"frontend_prefix_for_twilio_callbacks"}
                    placeholder={"The prefix that should be used for all Twilio callbacks to the Prospera frontend (mainly used for fetching hard-coded audio files like the waiting noises)"}
                />
                <FormInput
                    type={"text"}
                    label={"Streaming Callback URL"}
                    fieldName={"streaming_callback_url"}
                    placeholder={"The URL that should be used for the Twilio Websockets streaming callback. This is notably different then the main API url because it needs to bypass some of the load balancing layers in order for websockets to function correctly."}
                />
                <FormInput
                    type={"text"}
                    label={"WhatsApp Number"}
                    fieldName={"whatsapp_number"}
                    placeholder={"The phone number that is used for the WhatsApp integration."}
                />
                <FormInput
                    type={"text"}
                    label={"WhatsApp User ID"}
                    fieldName={"whatsapp_user_id"}
                    placeholder={"The user ID that will be applied to any incoming WhatsApp messages."}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}