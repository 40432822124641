import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useCallback} from "react";

export default function BackgroundAmbianceSelector({value, onChange}) {
  const handleChange = useCallback((event) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <FormControl>
      <FormLabel id="background-audio-selector-label">Background Ambiance</FormLabel>
      <RadioGroup
        aria-labelledby="background-audio-selector-label"
        value={value}
        name="background-audio-selector-group"
        onChange={handleChange}
      >
        <FormControlLabel value={"office"} control={<Radio />} label="Office" />
        <FormControlLabel value={"hospital"} control={<Radio />} label="Hospital" />
        <FormControlLabel value={"city"} control={<Radio />} label="City" />
        <FormControlLabel value={"nature"} control={<Radio />} label="Nature" />
        <FormControlLabel value={"cafe"} control={<Radio />} label="Cafe" />
      </RadioGroup>
    </FormControl>
  );
}
