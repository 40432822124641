import React, { useEffect, useState } from "react";
import { Calendar } from "@/frontend/components/ui/calendar";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/frontend/components/ui/select-native";

export const CalendarTime = React.forwardRef(
  ({ onChange, value, className }, ref) => {
    const [date, setDate] = useState(new Date());
    const [hour, setHour] = useState("12");
    const [minute, setMinute] = useState("00");
    const [ampm, setAmPm] = useState("AM");

    const getFormattedDateTime = (d, h, m, ap) => {
      if (!d) return "";

      let hours24 = parseInt(h, 10);
      if (ap === "PM" && hours24 !== 12) {
        hours24 += 12;
      } else if (ap === "AM" && hours24 === 12) {
        hours24 = 0;
      }

      const selectedDate = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        hours24,
        parseInt(m, 10),
        0,
        0
      );
      return selectedDate.toISOString();
    };

    const handleDateChange = (newDate) => {
      setDate(newDate);
      if (onChange) onChange(getFormattedDateTime(newDate, hour, minute, ampm));
    };

    const handleHourChange = (newHour) => {
      setHour(newHour);
      if (onChange) onChange(getFormattedDateTime(date, newHour, minute, ampm));
    };

    const handleMinuteChange = (newMinute) => {
      setMinute(newMinute);
      if (onChange) onChange(getFormattedDateTime(date, hour, newMinute, ampm));
    };

    const handleAmPmChange = (newAmPm) => {
      setAmPm(newAmPm);
      if (onChange) onChange(getFormattedDateTime(date, hour, minute, newAmPm));
    };

    useEffect(() => {
      if (value) {
        const parsedDate = new Date(value);
        setDate(parsedDate);

        let hours24 = parsedDate.getHours();
        let minutes = parsedDate.getMinutes().toString().padStart(2, "0");

        let hours12 = hours24 % 12 || 12;
        let ampmValue = hours24 >= 12 ? "PM" : "AM";

        setHour(hours12.toString().padStart(2, "0"));
        setMinute(minutes);
        setAmPm(ampmValue);
      }
    }, [value]);

    return (
      <div className='flex flex-col space-y-4' ref={ref}>
        <Calendar
          initialFocus
          mode='single'
          selected={date}
          onSelect={handleDateChange}
          className={className}
        />

        <div className='flex space-x-2'>
          <Select onValueChange={handleHourChange} value={hour}>
            <SelectTrigger className='w-full'>
              <SelectValue placeholder='Hour' />
            </SelectTrigger>
            <SelectContent className='bg-background'>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((h) => (
                <SelectItem key={h} value={h.toString().padStart(2, "0")}>
                  {h.toString().padStart(2, "0")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select onValueChange={handleMinuteChange} value={minute}>
            <SelectTrigger className='w-full'>
              <SelectValue placeholder='Minute' />
            </SelectTrigger>
            <SelectContent className='bg-background'>
              {Array.from({ length: 4 }, (_, i) =>
                (i * 15).toString().padStart(2, "0")
              ).map((m) => (
                <SelectItem key={m} value={m}>
                  {m}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select onValueChange={handleAmPmChange} value={ampm}>
            <SelectTrigger className='w-full'>
              <SelectValue placeholder='AM/PM' />
            </SelectTrigger>
            <SelectContent className='bg-background'>
              {["AM", "PM"].map((m) => (
                <SelectItem key={m} value={m}>
                  {m}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    );
  }
);

CalendarTime.displayName = "CalendarTime";
