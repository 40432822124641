import React from "react";

export function Notion(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M6.60587 0.529271C3.85141 0.733086 1.5098 0.920526 1.39971 0.942475C1.09102 0.997523 0.810213 1.20691 0.678169 1.4825L0.5625 1.72499L0.573649 7.50427L0.590024 13.2836L0.766315 13.6417C0.865609 13.84 1.53768 14.7657 2.27036 15.7185C3.71937 17.5971 3.82389 17.702 4.37471 17.757C4.53463 17.7734 5.73556 17.7184 7.0358 17.6414C8.34161 17.5588 10.4348 17.432 11.6911 17.3605C17.0569 17.0348 16.7813 17.0623 17.0896 16.7979C17.4753 16.4784 17.4478 17.0017 17.4642 9.97757C17.4753 4.022 17.4697 3.63109 17.3816 3.44922C17.2771 3.24541 17.0896 3.10222 14.6435 1.3832C13.0074 0.237311 12.8917 0.176689 12.1587 0.16554C11.8559 0.15474 9.35476 0.319882 6.60587 0.529271ZM12.798 1.28391C13.0293 1.38843 14.7208 2.56742 14.9686 2.79353C15.0347 2.85973 15.0567 2.91478 15.0236 2.94787C14.9466 3.01407 4.47888 3.63666 4.17612 3.59242C4.0385 3.57047 3.83469 3.4879 3.71902 3.41055C3.23962 3.08549 2.04983 2.11032 2.04983 2.03889C2.04983 1.85145 2.01115 1.85145 6.67764 1.5156C7.57025 1.45497 9.03562 1.34488 9.92823 1.27311C11.8615 1.11877 12.4402 1.12434 12.798 1.28391ZM16.1256 4.29758C16.2026 4.37458 16.2688 4.52335 16.2907 4.66654C16.3071 4.79893 16.3183 7.26701 16.3071 10.1427C16.2907 15.0789 16.2852 15.3875 16.1914 15.5307C16.1364 15.6189 16.0263 15.7126 15.9434 15.7401C15.7124 15.8339 4.74367 16.4564 4.5179 16.3902C4.41338 16.3627 4.26984 16.2746 4.19284 16.1976L4.06045 16.0544L4.04408 10.7326C4.03293 6.99734 4.04408 5.34453 4.08832 5.19576C4.12142 5.08567 4.20399 4.95327 4.27576 4.90937C4.37506 4.84318 5.02517 4.78813 7.26191 4.65609C8.83216 4.56794 11.2616 4.41917 12.6555 4.3366C15.9713 4.13244 15.9604 4.13244 16.1256 4.29758Z'
        fill='black'
      />
      <path
        d='M13.1778 6.12104C12.5827 6.15971 12.0539 6.21476 11.9932 6.24785C11.8114 6.34157 11.7013 6.49592 11.6793 6.67221C11.663 6.85407 11.718 6.87602 12.3127 6.94779L12.5716 6.98089V9.3333C12.5716 10.7325 12.5496 11.6582 12.5221 11.6307C12.4946 11.5976 11.7124 10.4078 10.7812 8.98629C9.85022 7.55959 9.07886 6.38582 9.06771 6.37502C9.05657 6.36387 8.45069 6.39697 7.72358 6.44679C6.83098 6.50741 6.34078 6.56246 6.24148 6.61751C6.08156 6.70008 5.90527 6.99204 5.90527 7.18505C5.90527 7.30072 6.11466 7.36135 6.55539 7.36135H6.78673V14.1266L6.4122 14.2423C6.12024 14.3304 6.02094 14.3855 5.97147 14.4956C5.88332 14.683 5.8889 14.8537 5.97704 14.8537C6.01014 14.8537 6.63274 14.815 7.3487 14.7711C8.7646 14.6886 8.92417 14.6499 9.07294 14.3579C9.11719 14.2698 9.15551 14.1761 9.15551 14.1486C9.15551 14.1266 8.96284 14.0604 8.73116 14.0054C8.49424 13.9503 8.25733 13.8953 8.20786 13.8841C8.11414 13.8566 8.10856 13.6859 8.10856 11.3167V8.7769L9.78333 11.4049C11.5407 14.1594 11.7612 14.4736 12.0312 14.6112C12.3674 14.7875 13.2214 14.6663 13.6509 14.3855L13.7833 14.3029L13.7945 10.5457L13.8109 6.783L14.1028 6.72795C14.4498 6.66176 14.6097 6.50219 14.6097 6.2266C14.6097 6.05031 14.5986 6.03916 14.4334 6.04474C14.3348 6.04927 13.7673 6.08236 13.1778 6.12104Z'
        fill='black'
      />
    </svg>
  );
}
