import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../components/APIProvider";
import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";
import { PageHeader } from "../elements/PageHeader";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { Card, CardContent, CircularProgress } from "@mui/material";
import TenantIntroductoryConfigurationForm from "./TenantIntroductoryConfigurationForm";
import { useTenantSettings } from "../components/TenantSettingsProvider";

export default function TenantIntroductoryConfigurationEditPage() {
  const api = useAPI();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tenantSettings, setTenantSettings] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { config, fetchConfig } = useTenantSettings();

  const handleSave = useCallback(
    async (newValues) => {
      try {
        setIsSubmitting(true);
        await api.saveTenantSettings(newValues);
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 2000);
        fetchConfig();
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        console.error(error);
      }
    },
    [api, fetchConfig]
  );

  useEffect(() => {
    if (config) {
      setTenantSettings(config);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText language={"en"} text='Edit Introductory Text' />
        }
      />
      <div className='tenant-configuration-edit-page'>
        {loading ? (
          <CircularProgress />
        ) : tenantSettings ? (
          <Card className='tenant-configuration-edit-form-card'>
            <CardContent>
              <TenantIntroductoryConfigurationForm
                initialValues={tenantSettings}
                onSubmit={handleSave}
                isSubmitting={isSubmitting}
                saveSuccess={saveSuccess}
              />
            </CardContent>
          </Card>
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
}
