import Paper from "@mui/material/Paper";
import { useCallback } from "react";
import { DocumentTemplateTable } from "./DocumentTemplateTable";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../frontend/components/APIProvider";

export default function DocumentTemplateListPage() {
  const navigate = useNavigate();
  const api = useAPI();

  const handleDocumentTemplateClicked = useCallback(
    (documentTemplate) => {
      navigate(`/document_template/${documentTemplate.id}`);
    },
    [navigate]
  );

  const handleNewDocumentTemplateClicked = useCallback(async () => {
    const newDocumentTemplate = {};

    return api
      .createNewDocumentTemplate(newDocumentTemplate)
      .then((newDocumentTemplateData) => {
        navigate(`/document_template/${newDocumentTemplateData.id}`);
      });
  }, [api, navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Document Templates"}
        subtitle={"View and manage document templates."}
      >
        <WhiteButton onClick={handleNewDocumentTemplateClicked}>
          Add New Document Template
        </WhiteButton>
      </PageHeader>
      <div className='document_template-list-page'>
        <Paper className={"main-page-contents"}>
          <DocumentTemplateTable
            onDocumentTemplateClicked={handleDocumentTemplateClicked}
          />
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
