import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DroppableMenuContainer from "./DroppableMenuContainer";
import MenuItemForm from "./MenuItemForm";
import { IconButton, Collapse, Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function DraggableMenuItem({
  menuItem,
  onMove,
  onRemoveMenuItem,
  setItems,
  allMenuLinks,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: menuItem.id,
    });

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const style = {
    padding: "8px",
    backgroundColor: "white",
    transform: CSS.Translate.toString(transform),
    transition: transition || "transform 0.2s ease",
    cursor: "grab",
  };

  return (
    <div ref={setNodeRef} {...attributes} style={style}>
      <MenuItemForm
        menuItem={menuItem}
        listeners={listeners}
        onRemoveMenuItem={onRemoveMenuItem}
        setItems={setItems}
        allMenuLinks={allMenuLinks}
      />
      {menuItem.children.length > 0 && (
        <Box display='flex' alignItems='center'>
          <Typography variant='caption'>Submenu</Typography>
          <Box
            sx={{ flexGrow: 1, height: "1px", backgroundColor: "#ccc", mx: 2 }}
          />
          <IconButton size='small' onClick={toggleExpand}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      )}
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        <DroppableMenuContainer
          parentId={menuItem.id}
          onMove={onMove}
          onRemoveMenuItem={onRemoveMenuItem}
          setItems={setItems}
          childItems={menuItem.children}
          allMenuLinks={allMenuLinks}
        />
      </Collapse>
    </div>
  );
}

export default React.memo(DraggableMenuItem);
