import React from "react";
import { Button } from "@/frontend/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "@/frontend/components/ui/dialog";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { cn } from "@/frontend/lib/utils";
import { X } from "lucide-react";

const widthClasses = {
  sm: "max-w-sm", // Small
  md: "max-w-md", // Medium (Default)
  lg: "max-w-lg", // Large
  xl: "max-w-xl", // Extra Large
  "2xl": "max-w-2xl", // 2X Large
  "3xl": "max-w-3xl", // 3X Large
  "4xl": "max-w-4xl", // 4X Large
};

export default function CustomDialog({
  title,
  description,
  onClose,
  children,
  open,
  width = "md",
  btnSize = "default",
  actionBtnText = "Save",
  onActionBtnClick,
  isCancelBtn = true,
}) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          widthClasses[width],
          "w-full rounded-md bg-background text-foreground"
        )}
        hideClose
      >
        <DialogHeader>
          <div className='flex justify-between items-center'>
            <DialogTitle className='text-xl font-semibold leading-normal'>
              <LocalTranslatedText text={title} />
            </DialogTitle>
            <DialogClose className='text-black hover:text-slate-500'>
              <X className='w-6 h-6' />
            </DialogClose>
          </div>
          <DialogDescription>
            <LocalTranslatedText text={description ?? ""} />
          </DialogDescription>
          {children}
          <div className='flex justify-between items-center'>
            {isCancelBtn && (
              <Button variant='outline' onClick={onClose} size={btnSize}>
                <LocalTranslatedText text='Cancel' />
              </Button>
            )}
            <Button variant='default' onClick={onActionBtnClick} size={btnSize}>
              <LocalTranslatedText text={actionBtnText} />
            </Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
