import React from "react";

export function GoogleSlides(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='18'
      viewBox='0 0 14 18'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_157479_2215)'>
        <mask
          id='mask0_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_157479_2215)'>
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L10.3352 2.82386L8.31818 0Z'
            fill='#F4B400'
          />
        </g>
        <mask
          id='mask1_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_157479_2215)'>
          <path
            d='M9.32697 8.06812H4.08265C3.74984 8.06812 3.47754 8.34042 3.47754 8.67323V13.9175C3.47754 14.2504 3.74984 14.5227 4.08265 14.5227H9.32697C9.65978 14.5227 9.93209 14.2504 9.93209 13.9175V8.67323C9.93209 8.34042 9.65978 8.06812 9.32697 8.06812ZM9.12527 12.7073H4.28436V9.88346H9.12527V12.7073Z'
            fill='#F1F1F1'
          />
        </g>
        <mask
          id='mask2_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask2_157479_2215)'>
          <path
            d='M8.67188 4.48682L13.1588 8.97272V4.84081L8.67188 4.48682Z'
            fill='url(#paint0_linear_157479_2215)'
          />
        </g>
        <mask
          id='mask3_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask3_157479_2215)'>
          <path
            d='M8.31836 0V3.63068C8.31836 4.29933 8.85994 4.84091 9.52859 4.84091H13.1593L8.31836 0Z'
            fill='#FADA80'
          />
        </g>
        <mask
          id='mask4_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask4_157479_2215)'>
          <path
            d='M8.31836 0V0.100852L13.0584 4.84091H13.1593L8.31836 0Z'
            fill='white'
            fillOpacity='0.1'
          />
        </g>
        <mask
          id='mask5_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask5_157479_2215)'>
          <path
            d='M1.46023 0C0.794602 0 0.25 0.544602 0.25 1.21023V1.31108C0.25 0.645455 0.794602 0.100852 1.46023 0.100852H8.31818V0H1.46023Z'
            fill='white'
            fillOpacity='0.2'
          />
        </g>
        <mask
          id='mask6_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask6_157479_2215)'>
          <path
            d='M11.9489 17.6492H1.46023C0.794602 17.6492 0.25 17.1046 0.25 16.439V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V16.439C13.1591 17.1046 12.6145 17.6492 11.9489 17.6492Z'
            fill='#BF360C'
            fillOpacity='0.2'
          />
        </g>
        <mask
          id='mask7_157479_2215'
          styles={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask7_157479_2215)'>
          <path
            d='M9.52859 4.84084C8.85994 4.84084 8.31836 4.29927 8.31836 3.63062V3.73147C8.31836 4.40012 8.85994 4.94169 9.52859 4.94169H13.1593V4.84084H9.52859Z'
            fill='#BF360C'
            fillOpacity='0.1'
          />
        </g>
        <path
          d='M8.31818 0H1.46023C0.794602 0 0.25 0.544602 0.25 1.21023V16.5398C0.25 17.2054 0.794602 17.75 1.46023 17.75H11.9489C12.6145 17.75 13.1591 17.2054 13.1591 16.5398V4.84091L8.31818 0Z'
          fill='url(#paint1_radial_157479_2215)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_157479_2215'
          x1='233.042'
          y1='43.0033'
          x2='233.042'
          y2='453.14'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BF360C' stopOpacity='0.2' />
          <stop offset='1' stopColor='#BF360C' stopOpacity='0.02' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_157479_2215'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(41.1466 35.0818) scale(2081.57 2081.57)'
        >
          <stop stopColor='white' stopOpacity='0.1' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </radialGradient>
        <clipPath id='clip0_157479_2215'>
          <rect
            width='13.0909'
            height='18'
            fill='white'
            transform='translate(0.25)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
