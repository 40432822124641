import React, {useCallback} from "react";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import "./AnalysisForm.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";


export default function AnalysisForm({
                                         initialAnalysis,
                                         onAnalysisSave,
                                         onDeletedClicked,
                                         saveButtonText,
                                     }) {
    const [analysis, setAnalysis] = React.useState(initialAnalysis);

    const handleAnalysisSaved = useCallback((newAnalysisData) => {
        if (onAnalysisSave) {
            return onAnalysisSave(newAnalysisData);
        }
    }, [onAnalysisSave]);

    const handleAnalysisChange = useCallback((newAnalysisData) => {
        setAnalysis(newAnalysisData);
    }, []);

    return <div className={"analysis-form"}>
        <FormRoot
            initialValue={initialAnalysis}
            saveButtonText={saveButtonText ?? "Save Analysis"}
            onSave={handleAnalysisSaved}
            onChange={handleAnalysisChange}
            extraButtons={<>
                {
                    onDeletedClicked ?
                        <SpinnerButton
                            variant="contained"
                            color="error"
                            className={"delete-button"}
                            onClick={onDeletedClicked}
                        >
                            <LocalTranslatedText language={"en"} text="Delete" />
                        </SpinnerButton>
                        : null
                }
            </>
            }
        >
            <FormSection
              title={"Metadata"}
              subtitle={"This contains the metadata of the analysis."}
            >
                <FormInput
                  type={"text"}
                  label={"Title"}
                  fieldName={"title"}
                />
            </FormSection>

            <FormSection
              title={"Goal Prompt"}
              subtitle={"The goal prompt was the original prompt provided by the user that was used to generate the analysis."}
            >
                <pre>{analysis.goal_prompt}</pre>
            </FormSection>

            <FormSection
                title={"Result"}
                subtitle={"This contains the result of the analysis performed by the model based on the knowledge base."}
            >
                <pre>{analysis.final_result}</pre>
            </FormSection>
        </FormRoot>
    </div>
}