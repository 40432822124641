import * as mui from "@mui/icons-material";
import iconSynonyms from "./form/inputs/IconSynonyms";
import flexsearch from "flexsearch";
import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

const FlexSearchIndex = flexsearch.Index;

export const searchIndex = new FlexSearchIndex({
  tokenize: "full",
});
export const allIconsMap = {};
export const allIcons = Object.keys(mui)
  .sort()
  .map((importName) => {
    let theme = "Filled";
    let name = importName;

    for (const currentTheme of ["Outlined", "Rounded", "TwoTone", "Sharp"]) {
      if (importName.endsWith(currentTheme)) {
        theme = currentTheme === "TwoTone" ? "Two tone" : currentTheme;
        name = importName.slice(0, -currentTheme.length);
        break;
      }
    }
    let searchable = name;
    if (iconSynonyms[searchable]) {
      searchable += ` ${iconSynonyms[searchable]}`;
    }
    searchIndex.add(importName, searchable);

    const icon = {
      importName,
      name,
      theme,
      Component: mui[importName],
    };
    allIconsMap[importName] = icon;
    return icon;
  });

export const NamedIcon = ({ icon, className, onClick }) => {
  const iconData = allIconsMap[icon];

  if (!iconData || !iconData.Component) {
    console.warn(`NamedIcon: Icon "${icon}" not found in @mui/icons-material.`);
    return null;
  }
  if (!iconData) {
    return null;
  }

  return (
    <SvgIcon
      component={iconData.Component}
      className={className}
      tabIndex={-1}
      onClick={onClick}
    />
  );
};
