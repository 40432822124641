import { IconButton, TextField, Tooltip, InputAdornment } from "@mui/material";
import React, { memo } from "react";
import { getIcon } from "../utils/schema-utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import KeyIcon from "@mui/icons-material/Key";
import StarIcon from "@mui/icons-material/Star";
import "./KeyField.scss";

function KeyField({
  currentEditingKey,
  value,
  onKeyChange,
  handleKeyDown,
  onKeyBlur,
  handleRemoveField,
  nodeId,
  toggleSection,
  openSections,
  disabled,
}) {
  // Create the endAdornment with both required asterisk and compound key icon if needed
  const getEndAdornment = () => {
    if (!value.required && !value.compound_key) return null;
    
    return (
      <InputAdornment position="end">
        {value.required && (
          <Tooltip title="Required field" placement="top">
            <StarIcon fontSize="small" style={{ color: 'red', marginRight: '4px' }} />
          </Tooltip>
        )}
        {value.compound_key && (
          <Tooltip title="Part of a compound key" placement="top">
            <KeyIcon fontSize="small" color="primary" />
          </Tooltip>
        )}
      </InputAdornment>
    );
  };

  return (
    <div
      role='button'
      className='field-list-item'
      onClick={() => toggleSection(nodeId)}
      tabIndex={0}
    >
      <div className='field-list-item-key'>
        {getIcon(value?.type, value?.form_input_type)}
        <div className='field-list-item-key-input'>
          <TextField
            size='small'
            label='Key'
            value={currentEditingKey}
            onChange={(e) => onKeyChange(e.target.value)}
            onBlur={onKeyBlur}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            onClick={(e) => e.stopPropagation()}
            InputProps={{
              endAdornment: getEndAdornment()
            }}
          />
        </div>
      </div>
      <div className='field-list-item-actions'>
        <IconButton size='small' className='expand-button'>
          {openSections[nodeId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <IconButton
          onClick={handleRemoveField}
          size='small'
          className='remove-field-button'
          disabled={disabled}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default memo(KeyField);
