import React, { forwardRef } from "react";
import { cn } from "@/frontend/lib/utils";

const Input = forwardRef(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        "flex w-full shadow-input px-3 py-2 px h-11 rounded-sm border focus:ring-ring focus:shadow-input border-slate-300 bg-background text-sm font-medium focus:outline-none focus:ring-1 focus:border-transparent placeholder:text-muted-foreground text-foreground disabled:cursor-not-allowed disabled:opacity-50 transition-all ease-in-out duration-300 delay-100",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

Input.displayName = "Input";

export { Input };
