import "./ChatWithBotWidget.scss";
import React, { useEffect, useCallback } from "react";
import { Card, CardActions, CardContent } from "@mui/material";
import { ConversationHistory } from "../../conversations/frontend/ConversationHistory";
import { ChatMessageInputBox } from "./ChatMessageInputBox";
import { useAPI } from "../../frontend/components/APIProvider";
import { SpinnerIconButton } from "../../frontend/elements/SpinnerIconButton";
import { api as apiDirect } from "../../frontend/components/api";
import Alert from "@mui/material/Alert";
import globalWebChatEventConnectionManager from "./WebChatEventConnectionManager";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadIcon from "@mui/icons-material/Download";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import {randomString} from "@utils/random";

export function ChatWithBotWidget({
  conversation,
  webChatConfiguration,
  showResetButton,
  onResetClicked,
  showInternalEvents,
}) {
  if (showInternalEvents === undefined) {
    showInternalEvents = false;
  }

  let api = useAPI();
  if (!api) {
    api = apiDirect;
  }

  const [events, setEvents] = React.useState([]);
  const [error, setError] = React.useState(null);
  const cardContentRef = React.useRef();

  function scrollToBottom() {
    if (cardContentRef?.current) {
      cardContentRef.current.scrollTop = cardContentRef.current.scrollHeight;
    }
  }

  const addEvent = useCallback((newEventData) => {
    // Ignore the event if it is a user event, since we will track all user created
    // events locally
    if (newEventData.role === "user") {
      return;
    }

    setEvents((events) => {
      // Double check to see if the new event data being added isn't already in the list of events.
      // If so, update it with the latest data. Otherwise append it to the end of the list.
      for (let i = 0; i < events.length; i++) {
        if (events[i]._id === newEventData._id) {
          return [
            ...events.slice(0, i),
            newEventData,
            ...events.slice(i + 1),
          ]
        }
      }

      return [...events, newEventData];
    });
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, []);

  useEffect(() => {
    globalWebChatEventConnectionManager.updateEventHandle(addEvent);
  }, [addEvent]);

  const startListeningForMessages = useCallback(
    (conversationId, conversationToken, mostRecentEventTime) => {
      globalWebChatEventConnectionManager.subscribeToEvents(
        conversationId,
        conversationToken,
        mostRecentEventTime,
        addEvent
      );
    },
    [addEvent]
  );

  useEffect(() => {
    if (conversation?._id) {
      setEvents([]);
      api
        .getRecentEvents(
          conversation._id,
          conversation?.metadata?.conversation_token
        )
        .then((data) => {
          const reversedEvents = data.messages;
          reversedEvents.reverse();
          setEvents(reversedEvents);

          const mostRecentMessage = data.messages[data.messages.length - 1];
          startListeningForMessages(
            conversation._id,
            conversation?.metadata?.conversation_token,
            mostRecentMessage?.created_at ?? new Date(new Date().getTime() - 1000 * 3600 * 24 * 7).toISOString()
          );
          setTimeout(() => {
            scrollToBottom();
          }, 100);
        });
    }
  }, [
    startListeningForMessages,
    conversation?._id,
    conversation?.metadata?.conversation_token,
    api,
  ]);

  React.useEffect(() => {
    return () => {
      globalWebChatEventConnectionManager.disconnect();
    };
  }, []);

  const handleSendMessage = useCallback(
    (message) => {
      const newMessageLocalId = `new-${randomString(8)}`;
      const newEvent = {
        _id: newMessageLocalId,
        role: "user",
        content: message,
        created_at: new Date().toISOString(),
      };

      setEvents((events) => {
        return [...events, newEvent]
      });
      setTimeout(() => {
        scrollToBottom();
      }, 100);

      api
        .newMessage(
          conversation._id,
          message,
          conversation?.metadata?.conversation_token
        )
        .then(
          (response) => {
            const newEvent = response;
            setEvents((events) => {
              return events.map((message) => {
                if (message._id === newMessageLocalId) {
                  return newEvent;
                } else {
                  return message;
                }
              });
            });
          },
          (error) => {
            setError(error);
          }
        );
    },
    [events, conversation, api]
  );

  const handleResetClicked = useCallback(() => {
    if (onResetClicked) {
      return onResetClicked();
    }
  }, [onResetClicked]);

  const handleConversationDownloadClicked = useCallback(() => {
    return api
      .downloadConversationExportPublic(
        conversation._id,
        conversation?.metadata?.conversation_token
      )
      .then((pdfData) => {
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `doc.pdf`;
        a.click();
        URL.revokeObjectURL(url);
      });
  }, [conversation, api]);

  const swallowScrollEvent = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const swallowClickEvent = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const background_color_style = {
    backgroundColor: webChatConfiguration?.widget_background_color,
  };
  const background_watermark_url = webChatConfiguration?.background_watermark_url;
  let background_watermark_style = {};
  if (background_watermark_url) {
    background_watermark_style = {
      // Make the background image tile across the entire chat widget
      backgroundImage: `url(${background_watermark_url})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'repeat',
    };
  }

  return (
    <div
      className='prospera-chat-with-bot-widget'
      onScroll={swallowScrollEvent}
      onClick={swallowClickEvent}
    >
      <Card className='conversation-events-card' sx={background_color_style}>
        {
          background_watermark_url ? (
                <div
                  className={"conversation-background-watermark"}
                  style={background_watermark_style}
                />
            ) : null
        }
        <CardContent ref={cardContentRef}>
          <div className={"conversation-history-box"}>
            <ConversationHistory
              events={events}
              onlyShowMessages={!showInternalEvents}
              conversation={conversation}
              webChatConfiguration={webChatConfiguration}
            />
            {events.length === 0 ? (
              <div className={"prospera-empty-conversation-message"}>
                <LocalTranslatedText
                  text={
                    "Initiate the conversation by sending a message to the bot with the input field at the bottom."
                  }
                />
              </div>
            ) : null}
          </div>
          {showResetButton ? (
            <div className={"prospera-conversation-action-buttons"}>
              <SpinnerIconButton
                onClick={handleResetClicked}
                title={"Start a New Conversation"}
              >
                <RestartAltIcon />
              </SpinnerIconButton>
                <SpinnerIconButton
                  onClick={handleConversationDownloadClicked}
                  title={"Download a Conversation Export"}
                >
                  <DownloadIcon />
                </SpinnerIconButton>
            </div>
          ) : null}
        </CardContent>

        <CardActions className={"card-actions-input-area"}>
          <ChatMessageInputBox onSendMessage={handleSendMessage} />
        </CardActions>

        {error ? (
          <Alert severity='error'>
            <LocalTranslatedText language={"en"} text={error.message} />
          </Alert>
        ) : null}
      </Card>
    </div>
  );
}
