import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import { useNavigate, useParams } from "react-router-dom";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { Button, CircularProgress, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import "./ViewFilledIntakePage.scss";
import CustomAccordion from "../../../frontend/elements/CustomAccordion";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


export default function ViewFilledIntakePage() {
  const api = useAPI();
  const params = useParams();
  const [filledIntake, setFilledIntake] = useState(null);
  const [intake, setIntake] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingIntake, setLoadingIntake] = useState(false);
  const navigate = useNavigate();

  const getFilledIntake = useCallback(async () => {
    setLoading(true);
    const intake = await api.getFilledIntake(params?.filledIntakeId);
    setFilledIntake(intake);
    setLoading(false);
  }, [api, params]);

  const getIntake = useCallback(async () => {
    setLoadingIntake(true);
    const intake = await api.getIntake(filledIntake?.intake_id);
    setLoadingIntake(false);
    setIntake(intake);
  }, [api, filledIntake]);

  const handleExportFilledIntakeClicked = useCallback(
    (e) => {
      e.preventDefault();
      return api.downloadFilledIntakeExport(filledIntake.id).then((pdfData) => {
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `filled-intake-export-${filledIntake.id}.pdf`;
        a.click();
        URL.revokeObjectURL(url);
      });
    },
    [api, filledIntake]
  );

  useEffect(() => {
    if (!filledIntake) {
      getFilledIntake();
    } else {
      getIntake();
    }
  }, [getFilledIntake, filledIntake, getIntake]);

  return (
    <PageWrapperWithMenu>
      <div className={"filled-intake-page"}>
        {loading ? (
          <CircularProgress />
        ) : filledIntake ? (
          <Paper padding="true">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <PageHeader
                title={`Filled Intake on ${
                  loadingIntake ? "loading..." : intake ? intake.title : "N/A"
                }`}
              />
              <Stack direction="row" alignItems="center" gap={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate(`/intakes/${filledIntake.intake_id}`)}
                >
                  <LocalTranslatedText
                    language={"en"}
                    text="View Intake"
                  />
                </Button>
                <SpinnerButton
                  variant="outlined"
                  color="info"
                  onClick={handleExportFilledIntakeClicked}
                >
                  <LocalTranslatedText
                    language={"en"}
                    text="Export PDF"
                  />
                </SpinnerButton>
              </Stack>
            </Stack>
            {filledIntake?.answers?.map((answer, index) => (
              <CustomAccordion
                key={index}
                title={
                  <div className={"question-answer-header"}>
                    {answer.is_completed ? <CheckCircleOutlineIcon/> : <CancelOutlinedIcon/>}
                    {`${index + 1}. ${answer.bot_question}`}
                  </div>
                }
              >
                <p className="answer-text">{answer.user_answer}</p>
                {
                  answer.data_points.length > 0 && (
                    <>
                      <br/>
                      <div className="data-points-header">Data Points</div>
                      <ul>
                        {answer.data_points.map((dataPoint, index) => (
                          <li key={index}>
                            {dataPoint.is_completed ? <CheckCircleOutlineIcon/> : <CancelOutlinedIcon/>}
                            {dataPoint.data_point_name}: {dataPoint.data_point_value}
                          </li>
                        ))}
                      </ul>
                    </>
                  )
                }
              </CustomAccordion>
            ))}
          </Paper>
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
}
