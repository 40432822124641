import React from "react";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import HFTextField from "@/frontend/components/hook-form/hf-text-field";
import HFSelect from "@/frontend/components/hook-form/hf-select";
import { convertToLabelValuePair } from "@/frontend/lib/utils";
import agentVoiceTone from "@/backend/data/agent_voice_tone.json";
import agentPersonalities from "@/backend/data/agent_personalities.json";
import { useFormContext } from "react-hook-form";
import AgentContainer from "../../components/AgentContainer";

export default function AgentIdentity() {
  const { watch } = useFormContext();
  const watchAgentName = watch("agent_configuration.agent_name");
  const watchAgentVisual = watch("agent_configuration.visual_id");

  return (
    <div className='flex flex-col gap-10 justify-center items-center'>
      <h4 className='text-center text-gray-900 text-3xl font-bold'>
        <LocalTranslatedText text="Setup your Agent's Identity" />
      </h4>
      <div className='grid grid-cols-12 gap-8 px-8 mx-auto w-full lg:w-1/2'>
        <AgentContainer
          watchAgentName={watchAgentName}
          watchAgentVisual={watchAgentVisual}
        />
        <div className='col-span-12 lg:col-span-8'>
          <div className='w-full flex flex-col gap-6'>
            <div className='w-full'>
              <HFTextField
                label="Agent's Name"
                fieldName='agent_configuration.agent_name'
                placeholder='Ella'
              />
            </div>
            <div className='w-full'>
              <HFSelect
                label='Personality'
                fieldName='agent_configuration.personality'
                placeholder='Knowledgeable and Trustworthy'
                options={convertToLabelValuePair(
                  agentPersonalities.agent_personalities
                )}
              />
            </div>
            <div className='w-full'>
              <HFSelect
                label='Tone of Voice'
                fieldName='agent_configuration.voice_tone'
                placeholder='Professional and Polished'
                options={convertToLabelValuePair(
                  agentVoiceTone.agent_voice_tone
                )}
              />
            </div>
            <div className='w-full'>
              <HFSelect
                label='Voice'
                fieldName='agent_configuration.voice_type'
                placeholder='Neutral'
                options={[
                  { label: "Female", value: "Female" },
                  { label: "Male", value: "Male" },
                  { label: "Neutral", value: "Neutral" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
