import "./KnowledgeChunkListPage.scss";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import React, { useCallback, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import globalTranslationManager from "../../../translation/frontend/components/translation_manager";
import { KnowledgeChunkTable } from "./KnowledgeChunkTable";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { SpinnerIconButton } from "../../../frontend/elements/SpinnerIconButton";
import { useAPI } from "../../../frontend/components/APIProvider";
import { ensureMinimumPromiseResolveTime } from "../../../frontend/utils/time";

export default function KnowledgeChunkListPage() {
  const [knowledgeChunks, setKnowledgeChunks] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [lastSearchText, setLastSearchText] = useState("");
  const api = useAPI();

  const doSearch = useCallback(
    (searchText) => {
      if (searchText) {
        if (searchText !== lastSearchText) {
          setIsSearching(true);
          setLastSearchText(searchText);
          ensureMinimumPromiseResolveTime(
            api.queryKnowledgeBase(searchText),
            500
          ).then(
            (data) => {
              // Lets reformat the match_scores to be more human readable.
              data.knowledge_chunks.forEach((chunk) => {
                chunk.match_score =
                  Math.round(chunk.match_score * 100000) / 1000;
                chunk.rerank_score =
                  Math.round(chunk.rerank_score * 100000) / 1000;
              });
              setKnowledgeChunks(data.knowledge_chunks);
              setIsSearching(false);
            },
            (err) => {
              setIsSearching(false);
            }
          );
        }
      } else {
        setKnowledgeChunks(null);
        setIsSearching(false);
      }
    },
    [api, lastSearchText]
  );

  const handleSearchInputBlur = useCallback(
    (evt) => {
      doSearch(evt.target.value);
    },
    [doSearch]
  );

  const handleSearchKeyDownEvent = useCallback(
    (evt) => {
      if (evt.key === "Enter") {
        doSearch(evt.target.value);
      }
    },
    [doSearch]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Knowledge"}
        subtitle={"View and manage knowledge chunks."}
      />
      <div className={"knowledge-chunk-list-page"}>
        <div className={"knowledge-chunk-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <FormControl
              variant='outlined'
              fullWidth={true}
              className={"knowledge-base-search-widget"}
            >
              <OutlinedInput
                id='knowledge-query-text'
                type={"text"}
                placeholder={globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
                  "Type in a question to search the knowledge base ..."
                )}
                startAdornment={
                  <InputAdornment position='start'>
                    <SpinnerIconButton
                      title={"Search"}
                      showSpinner={isSearching}
                    >
                      <SearchIcon />
                    </SpinnerIconButton>
                  </InputAdornment>
                }
                onBlur={handleSearchInputBlur}
                onKeyDown={handleSearchKeyDownEvent}
              />
            </FormControl>
            <KnowledgeChunkTable knowledgeChunks={knowledgeChunks} />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
