import * as React from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import VoiceSettingSlider from "./VoiceSettingSlider";


export default function UtteranceEndMsSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Utterance End Detection Milliseconds"
      description="The max number of milliseconds to wait in silence before declaring an utterance end."
      id="utterance-end-ms-slider"
      min={1000}
      max={3000}
      step={100}
      icon={<PauseIcon />}
      marks={[
        { value: 1000, label: '1000' },
        { value: 2000, label: '2000' },
        { value: 3000, label: '3000' }
      ]}
      onChange={onChange}
      value={value}
    />
  );
}
