import React from "react";

export function GoogleAnalytics(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M17.3848 15.7476C17.3915 16.9834 16.3948 17.9897 15.1597 17.9964C15.0679 17.9971 14.9753 17.9919 14.8843 17.9808C13.738 17.8113 12.8983 16.8139 12.9265 15.6558V2.3407C12.8991 1.18113 13.741 0.183718 14.888 0.0156322C16.1149 -0.128018 17.2264 0.750175 17.37 1.97713C17.3804 2.06746 17.3856 2.1578 17.3848 2.24888V15.7476Z'
        fill='#F9AB00'
      />
      <path
        d='M3.35454 13.5409C4.58594 13.5409 5.58409 14.5391 5.58409 15.7705C5.58409 17.0019 4.58594 18 3.35454 18C2.12315 18 1.125 17.0019 1.125 15.7705C1.125 14.5391 2.12315 13.5409 3.35454 13.5409ZM9.22052 6.78419C7.9832 6.85231 7.02356 7.8897 7.05243 9.12851V15.1167C7.05243 16.742 7.76772 17.7283 8.81549 17.9386C10.0247 18.1837 11.2027 17.4025 11.4478 16.1933C11.4782 16.0452 11.493 15.8949 11.4923 15.7438V9.02484C11.4945 7.789 10.4949 6.78567 9.25902 6.78345C9.24644 6.78345 9.23311 6.78345 9.22052 6.78419Z'
        fill='#E37400'
      />
    </svg>
  );
}
