import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/frontend/components/ui/form";
import { cn } from "@/frontend/lib/utils";
import { Select } from "@/frontend/components/ui/select";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function HFSelect({ fieldName, label, options, ...props }) {
  const { control } = useFormContext();

  return (
    <FormField
      name={fieldName}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          {label && (
            <FormLabel>
              <LocalTranslatedText text={label} />
            </FormLabel>
          )}
          <FormControl>
            <Select
              options={options}
              defaultValue={field.value}
              onValueChange={field.onChange}
              className={cn(
                error &&
                  "border-danger focus:shadow-error focus:ring-ring-error"
              )}
              {...props}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
