import React from "react";
import HFTextField from "@/frontend/components/hook-form/hf-text-field";
import HFTextarea from "@/frontend/components/hook-form/hf-textarea";
import CustomDialog from "@/frontend/components/widgets/CustomDialog";

export default function AgentSuggestionForm({ open, onClose, onSubmit }) {
  return (
    <CustomDialog
      title='Suggest an Agent'
      open={open}
      onClose={onClose}
      width='md'
      onActionBtnClick={onSubmit}
      actionBtnText='Submit Suggestion'
    >
      <div className='w-full'>
        <div className='flex flex-col gap-4 w-full my-5'>
          <div className='w-full'>
            <HFTextField
              label='Agent Service Name *'
              fieldName='suggested_agent_service.name'
              placeholder='Receptionist'
            />
          </div>
          <div className='w-full'>
            <HFTextarea
              label='How would you like your Agent to be customized?'
              fieldName='suggested_agent_service.description'
              placeholder='Give a brief description of how this agent service will work . . .'
              rows={4}
            />
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}
