import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./TenantConfigurationEditPage.scss";
import { useAPI } from "../components/APIProvider";
import { PageHeader } from "../elements/PageHeader";
import TenantConfigurationForm from "./TenantConfigurationForm";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export default function TenantConfigurationEditPage(props) {
  const api = useAPI();
  const params = useParams();

  const navigate = useNavigate();
  const [tenantConfiguration, setTenantConfiguration] = useState(null);

  const handleTenantConfigurationSave = useCallback(
    async (newTenantConfiguration) => {
      return await api.saveTenantConfiguration(newTenantConfiguration);
    },
    [api]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api
      .deleteTenantConfiguration(tenantConfiguration.id)
      .then(() => {
        navigate(`/tenant_configuration`);
      });
  }, [navigate, api, tenantConfiguration?.id]);

  React.useEffect(() => {
    if (!tenantConfiguration) {
      api
        .getTenantConfiguration(params?.tenantConfigurationId)
        .then((tenantConfiguration) => {
          setTenantConfiguration(tenantConfiguration);
        });
    }
  }, [
    handleTenantConfigurationSave,
    tenantConfiguration,
    params?.tenantConfigurationId,
    api,
  ]);

  if (!tenantConfiguration) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={
            <LocalTranslatedText
              language={"en"}
              text='Edit Tenant Configuration'
            />
          }
        />
        <div className='tenant-configuration-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText
            language={"en"}
            text='Edit Tenant Configuration'
          />
        }
      />
      <div className='tenant-configuration-edit-page'>
        <Card className='tenant-configuration-edit-form-card'>
          <CardContent>
            <TenantConfigurationForm
              initialTenantConfiguration={tenantConfiguration}
              onTenantConfigurationSave={handleTenantConfigurationSave}
              onDeletedClicked={handleDeleteClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
