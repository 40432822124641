import React, { useEffect, useState } from "react";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import AgentVisualSuggestion from "../../components/AgentVisualSuggestion";
import { useFormContext } from "react-hook-form";
import HFSelect from "@/frontend/components/hook-form/hf-select";
import HFSkinColorPicker from "@/frontend/components/hook-form/hf-skin-color-picker";
import HFEyesColorPicker from "@/frontend/components/hook-form/hf-eyes-color-picker";
import agentHairColors from "@/backend/data/agent_hair_colors.json";
import agentHairLengths from "@/backend/data/agent_hair_lengths.json";
import agentHairStyles from "@/backend/data/agent_hair_styles.json";
import agentClothingStyles from "@/backend/data/agent_clothing_styles.json";
import agentClothingColors from "@/backend/data/agent_clothing_colors.json";
import { LoadingButton } from "@/frontend/components/ui/loading-button";

import { convertToLabelValuePair } from "@/frontend/lib/utils";
import AgentContainer from "../../components/AgentContainer";

export default function AgentVisual() {
  const [agentVisual, setAgentVisual] = useState(null);
  const { setValue, watch } = useFormContext();
  const watchAgentVisual = watch("agent_configuration.visual_id");
  const watchAgentName = watch("agent_configuration.agent_name");

  useEffect(() => {
    if (agentVisual) {
      setValue("agent_configuration.visual_id", agentVisual?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentVisual]);

  return (
    <div className='flex flex-col gap-10 justify-center items-center'>
      <h4 className='text-center text-gray-900 text-3xl font-bold'>
        <LocalTranslatedText text="Setup your Agent's Visual" />
      </h4>
      <div className='grid grid-cols-12 gap-8 px-8 mx-auto w-full lg:w-1/2'>
        <AgentContainer
          agentVisual={agentVisual}
          watchAgentName={watchAgentName}
          watchAgentVisual={watchAgentVisual}
        />
        <div className='col-span-12 lg:col-span-8'>
          <div className='w-full flex flex-col gap-6'>
            <AgentVisualSuggestion
              setAgentVisual={setAgentVisual}
              agentVisual={agentVisual}
              visualId={watchAgentVisual}
            />
            <div className="text-muted-foreground text-base grid grid-cols-[1fr_max-content_1fr] gap-x-4 items-center leading-6 tracking-tight font-medium before:content-[''] after:content-[''] before:block after:block before:h-[1px] after:h-[1px] before:bg-gray-200 after:bg-gray-200">
              <LocalTranslatedText text='Custom Visual' />
            </div>
            <div className='flex flex-col gap-6 md:flex-row md:justify-between md:gap-3 md:items-center w-full'>
              <div className='w-full'>
                <HFSelect
                  label='Hair Colour'
                  fieldName='agent_configuration.hair_color'
                  placeholder='Dark Brown'
                  options={convertToLabelValuePair(
                    agentHairColors.agent_hair_colors
                  )}
                />
              </div>
              <div className='w-full'>
                <HFSelect
                  label='Hair Length'
                  fieldName='agent_configuration.hair_length'
                  placeholder='Medium'
                  options={convertToLabelValuePair(
                    agentHairLengths.agent_hair_lengths
                  )}
                />
              </div>
              <div className='w-full'>
                <HFSelect
                  label='Hair Style'
                  fieldName='agent_configuration.hair_style'
                  placeholder='Straight'
                  options={convertToLabelValuePair(
                    agentHairStyles.agent_hair_styles
                  )}
                />
              </div>
            </div>
            <div className='w-full'>
              <HFSkinColorPicker
                label='Skin'
                fieldName='agent_configuration.skin_color'
              />
            </div>
            <div className='w-full'>
              <HFEyesColorPicker
                label='Eye'
                fieldName='agent_configuration.eyes_color'
              />
            </div>
            <div className='flex flex-col gap-6 md:flex-row md:justify-between md:gap-3 md:items-center w-full'>
              <div className='w-full'>
                <HFSelect
                  label='Clothing Style'
                  fieldName='agent_configuration.clothing_style'
                  placeholder='Formal'
                  options={convertToLabelValuePair(
                    agentClothingStyles.agent_clothing_styles
                  )}
                />
              </div>
              <div className='w-full'>
                <HFSelect
                  label='Clothing Color'
                  fieldName='agent_configuration.clothing_color'
                  placeholder='Beige'
                  options={convertToLabelValuePair(
                    agentClothingColors.agent_clothing_colors
                  )}
                />
              </div>
            </div>
            <span>
              <LoadingButton
                type='button'
                className='bg-black hover:bg-black/70'
              >
                <LocalTranslatedText text='Generate Visual' />
              </LoadingButton>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
