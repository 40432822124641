import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import "./NonInterruptingWordsWidget.scss";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import Box from "@mui/material/Box";



export default function NonInterruptingWordsTagInput({
                                                       value,
                                                       onChange,
                                                     }) {
  return (
    <Box className={"non-interrupting-words-widget"}>
      <Typography id={"non-interrupting-words"} gutterBottom>
        Non Interrupting Words
      </Typography>
      <Typography gutterBottom sx={{fontSize: 12}}>These words will not be counted for interruption purposes. If the user says any of these words, the agent will continue speaking and won't be interrupted. These words will get translated into the target language of the conversation.</Typography>
      <Grid container spacing={2} sx={{alignItems: 'center'}}>
        <Grid item>
          <TextFieldsIcon />
        </Grid>
        <Grid item xs>
          <Autocomplete
            multiple
            freeSolo
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            options={[]}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={option} variant="outlined" label={option} {...getTagProps({index})} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={"Add a word"}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}