import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";
import React, { useEffect, useState } from "react";
import "./HomePage.scss";
import { PageHeader } from "../elements/PageHeader";
import { PageSection } from "../elements/PageSection";
import OnboardingChecklist from "../../onboarding/frontend/OnboardingChecklist";
import { useAPI } from "../components/APIProvider";
import { PageWrapperWithoutMenu } from "../elements/PageWrapperWithoutMenu";
import { useFrontendConfiguration } from "../components/FrontendConfigurationProvider";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTenantSettings } from "../components/TenantSettingsProvider";

export default function HomePage() {
  const config = useFrontendConfiguration();
  const api = useAPI();
  const [checklistData, setChecklistData] = useState(null);
  const [tenantSettings, setTenantSettings] = useState(null);
  const { config: settings } = useTenantSettings();

  useEffect(() => {
    if (!checklistData) {
      api.getOnboardingChecklist().then((checklistData) => {
        setChecklistData(checklistData);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistData]);

  const navigate = useNavigate();

  useEffect(() => {
    if (config.frontend.home_page_redirect) {
      navigate(config.frontend.home_page_redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    if (settings) {
      setTenantSettings(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (config.frontend.home_page_redirect) {
    if (process.env.REACT_APP_HIDE_LEFT_MENU_WHEN_NOT_ADMIN === "true") {
      return <PageWrapperWithoutMenu />;
    } else {
      return <PageWrapperWithMenu />;
    }
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`Home`} />
      <PageSection>
        <Box display='flex' flexDirection='column' gap={4}>
          <Box
            dangerouslySetInnerHTML={{
              __html: tenantSettings?.introductory_text || "",
            }}
          />

          <OnboardingChecklist
            checklistData={checklistData}
            defaultIsExpanded={true}
          />
        </Box>
      </PageSection>
    </PageWrapperWithMenu>
  );
}
