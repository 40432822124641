import "./SmartChainErrorList.scss";
import * as React from 'react';
import {useCallback, useEffect} from "react";
import {useAPI} from "../../../frontend/components/APIProvider";
import {CustomJSONView} from "../../../frontend/elements/CustomJSONView";
import Paper from "@mui/material/Paper";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import moment from "moment";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import LastPageIcon from "@mui/icons-material/LastPage";

export default function SmartChainErrorList({smartChain, selectedStepIndex}) {
    const api = useAPI();
    const [smartChainErrors, setSmartChainErrors] = React.useState(null);
    const [viewModeByError, setViewModeByError] = React.useState({});

    useEffect(() => {
        async function getRecentErrors() {
            const retrievedErrors = await api.getSmartChainRecentErrors(
              null,
              null,
              smartChain.chain_name,
              selectedStepIndex !== null ? smartChain.steps[selectedStepIndex].step_id : null,
              25)
            setSmartChainErrors(retrievedErrors);
        }

        getRecentErrors();
    }, [api, smartChain, selectedStepIndex]);

    const retrySmartChainStep = useCallback(async (smartChainError, smartChainErrorIndex) => {
        if (!selectedStepIndex) {
            alert("Cannot reprocess a whole chain errors for now. Please select an individual step and check its errors.");
            return;
        }

        const step = smartChain.steps[selectedStepIndex];
        const newResult = await api.executeSmartChainStep(step, smartChainError.most_recent_occurrence_context_data);
        const newErrorObject = {
            ...smartChainError,
            reprocessed_result_data: newResult
        }
        const newSmartChainErrors = [...smartChainErrors];
        newSmartChainErrors[smartChainErrorIndex] = newErrorObject;
        setSmartChainErrors(newSmartChainErrors);
    }, [api, smartChain, selectedStepIndex, smartChainErrors]);

    const handleViewModeChange = useCallback((testSampleIndex, newViewMode) => {
        const newViewModeBySample = {...viewModeByError};
        newViewModeBySample[testSampleIndex] = newViewMode;
        setViewModeByError(newViewModeBySample);
    }, [viewModeByError, setViewModeByError]);

    if (!smartChainErrors) {
        return null;
    }

    return (
        <div className={"smart-chain-error-list"}>
            {
                smartChainErrors.map((smartChainError, testErrorIndex) => {
                    return <Paper
                      className={`smart-chain-error-row ${viewModeByError[testErrorIndex] ? viewModeByError[testErrorIndex] : "expand-both"}`}
                      key={smartChainError.id ?? smartChainError._id ?? testErrorIndex}
                    >
                      <div className={"smart-chain-error-header"}>
                        <span className={"sample-date"}>Most Recent Occurrence: {moment.utc(smartChainError.most_recent_occurrence_time).local().format("MMM Do, h:mm:ss a")}</span>
                        <span className={"sample-count"}>Error Count: {smartChainError.count}</span>
                      </div>
                        <div className={"smart-chain-error-body"}>
                        <div className={"smart-chain-error-column smart-chain-error-context"}>
                        <CustomJSONView
                                data={smartChainError.most_recent_occurrence_context_data ?? {}}
                                field_name={"Input"}
                            />
                        </div>
                        <div className={"smart-chain-error-actions"}>
                              <SpinnerButton
                                label={"Expand Left"}
                                onClick={() => handleViewModeChange(testErrorIndex, 'expand-left')}
                                disabled={viewModeByError[testErrorIndex] === "expand-left"}
                              >
                                  <FirstPageIcon/>
                              </SpinnerButton>
                              <SpinnerButton
                                label={"Expand Both"}
                                onClick={() => handleViewModeChange(testErrorIndex, 'expand-both')}
                                disabled={viewModeByError[testErrorIndex] === "expand-both" || !viewModeByError[testErrorIndex]}
                              >
                                  <MultipleStopIcon/>
                              </SpinnerButton>
                              <SpinnerButton
                                label={"Expand Right"}
                                onClick={() => handleViewModeChange(testErrorIndex, 'expand-right')}
                                disabled={viewModeByError[testErrorIndex] === "expand-right"}
                              >
                                  <LastPageIcon/>
                              </SpinnerButton>
                            {
                                selectedStepIndex ?
                                    <SpinnerButton
                                        label={"Reprocess"}
                                        className={"reprocess-button"}
                                        onClick={() => retrySmartChainStep(smartChainError, testErrorIndex)}
                                    >
                                        <PlayCircleIcon/>
                                    </SpinnerButton>
                                    : null
                            }
                        </div>
                        <div className={"smart-chain-error-column smart-chain-error-result"}>
                            <CustomJSONView
                                data={smartChainError.reprocessed_result_data ?? smartChainError ?? {}}
                                field_name={"Result"}
                            />
                        </div>
                      </div>
                    </Paper>
                })
            }
            {
                smartChainErrors.length === 0 ?
                  <Paper className={"smart-chain-error-row"}>
                    <div className={"no-errors"}>All good so far. No recent errors found.</div>
                  </Paper>
                  : null
            }
        </div>
    );
}
