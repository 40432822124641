import React from "react";
import agentEyeColors from "@/backend/data/agent_eyes_colors.json";
import { cn } from "@/frontend/lib/utils";

export function EyesColorPicker({ value, onChange, className }) {
  return (
    <div className="flex flex-wrap gap-2">
      {agentEyeColors.agent_eyes_colors.map((eyeColor) => (
        <button
          key={eyeColor.color}
          className={cn(
            "w-8 h-8 rounded-full border border-slate-300 hover:border-primary transition-all ease-in-out duration-300 delay-100",
            value === eyeColor.color && "border-primary border-2",
            className
          )}
          style={{ backgroundColor: eyeColor.code }}
          onClick={() => onChange(eyeColor.color)}
          type="button"
        ></button>
      ))}
    </div>
  );
}

EyesColorPicker.displayName = "EyesColorPicker";
