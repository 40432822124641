import React from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import { useNavigate } from "react-router-dom";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import { Paper } from "@mui/material";
import IntakeTable from "./IntakeTable";

export default function IntakeListPage() {
  const api = useAPI();
  const navigate = useNavigate();

  const handleNewIntakeClicked = async () => {
    const intake = await api.createNewIntake();
    navigate(`/intakes/${intake._id}`);
  };

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title='Intakes'
        subtitle={"View and manage all your intakes."}
      >
        <WhiteButton onClick={handleNewIntakeClicked}>New Intake</WhiteButton>
      </PageHeader>
      <Paper>
        <IntakeTable />
      </Paper>
    </PageWrapperWithMenu>
  );
}
