import React, { useState, useEffect, useCallback } from "react";
import List from "@mui/material/List";
import { useIsAdmin } from "../components/IsAdminProvider";
import { useTenantSettings } from "../components/TenantSettingsProvider";
import { useLeftMenuState } from "../components/LeftSideMenuStateProvider";
import { IconButton } from "@mui/material";
import MenuOpen from "@mui/icons-material/MenuOpen";
import ChevronRight from "@mui/icons-material/ChevronRight";
import LeftMenuSection from "./LeftMenuSection";
import LeftMenuEntry from "./LeftMenuEntry";
import { useFrontendConfiguration } from "../components/FrontendConfigurationProvider";
import {
  addCustomForms,
  addCustomSchemas,
  applyMenuItemConditions,
  getTableQuery,
  replaceMenuItemTitle,
} from "./utils";
import { useAPI } from "../components/APIProvider";
import "./LeftSideMenu.scss";

let globalCachedMenuItems = [];

export default function LeftSideMenu() {
  const api = useAPI();
  const { isExpanded, setIsExpanded } = useLeftMenuState();
  const isAdmin = useIsAdmin();
  const config = useFrontendConfiguration();
  const [menuData, setMenuData] = useState(globalCachedMenuItems);
  const { config: tenantMenuConfiguration } = useTenantSettings();
  const [openSections, setOpenSections] = useState(() => {
    return JSON.parse(localStorage.getItem("openSections")) || {};
  });

  const toggleSection = (id) => {
    setOpenSections((prev) => {
      const newState = { ...prev, [id]: !prev[id] }; // This UPDATES the state
      localStorage.setItem("openSections", JSON.stringify(newState));
      return newState;
    });
  };

  const handleBillingClick = useCallback(
    (evt) => {
      evt.preventDefault();
      api.getBookingPortalUrl().then((data) => {
        console.log("data", data);
        window.location.href = data.url;
      });
    },
    [api]
  );

  const renderMenu = (menuItems, parentId = null) => {
    return menuItems.map((item) => {
      if (!isAdmin) {
        if (item.is_admin) return null;
      }
      if (item.is_hidden) return null;

      if (item.children.length > 0) {
        return (
          <LeftMenuSection
            key={item.id}
            title={item.title}
            icon={item.icon}
            isOpen={openSections[item.id] || false}
            toggleSection={() => toggleSection(item.id)}
          >
            {renderMenu(item.children, item.id)}
          </LeftMenuSection>
        );
      }

      const handleClick =
        item.link === "/subscription"
          ? handleBillingClick
          : () => parentId && toggleSection(parentId);

      return <LeftMenuEntry key={item.id} item={item} onClick={handleClick} />;
    });
  };

  useEffect(() => {
    if (!tenantMenuConfiguration) return;
    if (menuData.length > 0) return;

    const oldWord = "{outbound_word}";
    const newWord = config?.frontend?.default_outbound_rules_word;

    let updatedMenu = replaceMenuItemTitle(
      tenantMenuConfiguration.menu_items,
      oldWord,
      newWord
    );

    const existingLinks = new Set(updatedMenu.map((item) => item.link));

    api.getUserDetails().then((userDetails) => {
      const tableQuery = getTableQuery(userDetails);
      Promise.all([
        api.getCustomDataSchemas(tableQuery),
        api.getCustomForms(tableQuery),
      ]).then(([customDataSchemas, customForms]) => {
        const filteredSchemas = addCustomSchemas(customDataSchemas).filter(
          (schema) => !existingLinks.has(schema.link)
        );

        const filteredForms = addCustomForms(customForms).filter(
          (form) => !existingLinks.has(form.link)
        );
        updatedMenu = [updatedMenu[0], ...filteredSchemas, ...filteredForms, ...updatedMenu.slice(1)];

        updatedMenu = applyMenuItemConditions(updatedMenu, config, isAdmin);
        setMenuData(updatedMenu);
        globalCachedMenuItems = updatedMenu;
      });
    });
  }, [tenantMenuConfiguration, config, api, isAdmin, menuData]);

  useEffect(() => {
    localStorage.setItem("openSections", JSON.stringify(openSections));
  }, [openSections]);

  return (
    <div className={`left-side-menu ${isExpanded ? "expanded" : ""}`}>
      <div className={"menu-pages-wrapper"} data-testid={"left-side-menu"}>
        <List>{renderMenu(menuData)}</List>
        <div className='collapsible-container'>
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <MenuOpen/> : <ChevronRight/>}
          </IconButton>
        </div>
      </div>
    </div>
  );
}
