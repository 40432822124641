import React from "react";

export function Gmail(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M1.2273 16.021H4.09092V9.06648L0 5.99829V14.7938C0 15.4718 0.549211 16.0211 1.2273 16.0211V16.021Z'
        fill='#4285F4'
      />
      <path
        d='M13.9092 16.021H16.7728C17.4509 16.021 18.0001 15.4718 18.0001 14.7938V5.99829L13.9092 9.06648V16.021Z'
        fill='#34A853'
      />
      <path
        d='M13.9092 3.74815V9.06634L18.0001 5.99815V4.3618C18.0001 2.84509 16.2687 1.97884 15.0546 2.88907L13.9092 3.74815Z'
        fill='#FBBC04'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.09082 9.06648V3.74829L8.9999 7.43014L13.909 3.74829V9.06648L8.9999 12.7483L4.09082 9.06648Z'
        fill='#EA4335'
      />
      <path
        d='M0 4.3618V5.99815L4.09092 9.06634V3.74815L2.94546 2.88907C1.73145 1.97884 0 2.84509 0 4.36173V4.3618Z'
        fill='#C5221F'
      />
    </svg>
  );
}
