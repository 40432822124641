import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useCallback} from "react";

export default function ResponseSpeedOptimizationSelector({value, onChange}) {
  const handleChange = useCallback((event) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <FormControl>
      <FormLabel id="response-speed-optimizations-label">Response Speed Optimizations</FormLabel>
      <RadioGroup
        aria-labelledby="response-speed-optimizations-label"
        value={value}
        name="response-speed-optimizations-group"
        onChange={handleChange}
      >
        <FormControlLabel value={0} control={<Radio />} label="0 - Default Mode (no response speed optimizations, best quality)" />
        <FormControlLabel value={1} control={<Radio />} label="1 - Normal Latency Optimizations (50% of possible response speed improvement, lower quality)" />
        <FormControlLabel value={2} control={<Radio />} label="2 - Strong Latency Optimizations (75% of possible response speed improvement, more monotone)" />
        <FormControlLabel value={3} control={<Radio />} label="3 - Max Latency Optimizations (fastest responses, lowest quality)" />
      </RadioGroup>
    </FormControl>
  );
}
