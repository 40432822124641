import * as React from "react";
import { TableView } from "@/frontend/elements/TableView";
import { useAPI } from "@/frontend/components/APIProvider";

export function CalendarsTable({ onCalendarClicked }) {
  const api = useAPI();

  return (
    <TableView
      schemaAPI={api.getCalendarSchema}
      fetchAPI={api.getCalendars}
      onRowClicked={onCalendarClicked}
    />
  );
}
