import { z } from "zod";

const EmailSchema = z
  .string()
  .min(1, { message: "Email is required" })
  .email({ message: "Wrong Email format" });

const PasswordSchema = z
  .string()
  .min(8, { message: "Ooops! Minimum password characters must be 8" })
  .max(16, {
    message: "Password must be no more than 16 characters long",
  })
  .regex(/[a-z]/, {
    message: "Password must contain at least one lowercase letter",
  })
  .regex(/[A-Z]/, {
    message: "Password must contain at least one uppercase letter",
  })
  .regex(/[0-9]/, { message: "Password must contain at least one number" })
  .regex(/[^a-zA-Z0-9]/, {
    message: "Password must contain at least one special character",
  });

export const SignInSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
});

export const SignUpSchema = z
  .object({
    email: EmailSchema,
    password: PasswordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });
