import {TextField, Tooltip} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";
import globalTranslationManager from "../../../../translation/frontend/components/translation_manager";

function isValidNumber(valueStr) {
  return !isNaN(Number(valueStr));
}

export function NumberInput({
  label,
  example,
  fieldName,
  errorHelperText,
  disabled,
  size,
  required,
  ...props
}) {
  const formOptions = useFormOptions();
  const formData = useFormData();
  const changeFormData = useChangeFormData();

  let initialValue = (formData[fieldName] ?? 0).toString();

  const [localValue, setLocalValue] = useState(initialValue);
  let isValid = isValidNumber(localValue);

  let disabledLocal = !formOptions.editable || disabled;

  const onChangeFormDataDebounced = useMemo(
    () => _.debounce(changeFormData, 500),
    [changeFormData]
  );

  const handleInputChange = useCallback(
    (event) => {
      if (formOptions.editable) {
        setLocalValue(event.target.value);
        if (isValidNumber(event.target.value)) {
          onChangeFormDataDebounced(fieldName, Number(event.target.value ?? 0));
        }
      }
    },
    [formOptions, fieldName, onChangeFormDataDebounced]
  );

  if (props.placeholder) {
    props.placeholder =
      globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
        props.placeholder
      );
  }

  return (
    <div className={"form-input"}>
      <Typography variant={"label"}>
        {required && (
          <Tooltip title="Required field" placement="top">
            <span style={{ color: 'red', fontSize: '1.2em', verticalAlign: 'super', marginRight: '2px' }}>*</span>
          </Tooltip>
        )}
        <LocalTranslatedText language={"en"} text={label} />
      </Typography>
      <TextField
        type={"number"}
        size={size || "medium"}
        name={fieldName}
        placeholder={example}
        value={disabledLocal ? "" : localValue}
        onChange={handleInputChange}
        fullWidth
        disabled={disabledLocal}
        error={!isValid}
        helperText={!isValid ? errorHelperText : null}
        required={required}
        {...props}
      />
    </div>
  );
}

export default NumberInput;
