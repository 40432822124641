import React from "react";

export function GoogleKeep(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M10.8747 4.875L13.1369 5.3025L14.9997 4.875L10.8747 0.75L10.3057 2.70563L10.8747 4.875Z'
        fill='#F29900'
      />
      <path
        d='M10.875 4.875V0.75H4.125C3.50344 0.75 3 1.25344 3 1.875V16.125C3 16.7466 3.50344 17.25 4.125 17.25H13.875C14.4966 17.25 15 16.7466 15 16.125V4.875H10.875Z'
        fill='#FBBC04'
      />
      <path
        d='M10.3125 12.75H7.6875V11.8125H10.3125V12.75ZM10.2984 10.875H7.70156C7.01719 10.4437 6.5625 9.68156 6.5625 8.8125C6.5625 7.46625 7.65375 6.375 9 6.375C10.3463 6.375 11.4375 7.46625 11.4375 8.8125C11.4375 9.68156 10.9828 10.4437 10.2984 10.875Z'
        fill='white'
      />
    </svg>
  );
}
