import React from "react";
import AuthPageWrapper from "@/frontend/wrappers/AuthPageWrapper";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import HFButton from "@/frontend/components/hook-form/hf-button";
import { Polygon } from "@/frontend/icons/Polygon";
import { Briefcase } from "lucide-react";
import { LoadingButton } from "@/frontend/components/ui/loading-button";
import { Robot } from "@/frontend/icons/Robot";

const options = [
  {
    value: "Business",
    label: "AI Bot for My Business",
    description: "Automate tasks with a custom AI assistant.",
    icon: Briefcase,
  },
  {
    value: "Marketplace",
    label: "AI Bot Marketplace",
    description: "Build and sell AI solutions for your industry.",
    icon: Robot,
  },
];

export default function OnboardingWelcome({ isSubmitting }) {
  return (
    <AuthPageWrapper
      title='Join the AI Agent Revolution Today'
      items={[
        "Provide your own logo, branding, and set your own prices.",
        "No AI or technical skills required our expert AI engineers will handle it for you.",
        "Launch your AI Agent in under a month.",
      ]}
    >
      <div className='w-full px-8 md:px-0 md:w-1/2 flex flex-col gap-6'>
        <h4 className='text-3xl text-gray-900 font-bold'>
          <LocalTranslatedText text='What do you want to create?' />
        </h4>
        <div className='flex flex-col gap-6 w-full'>
          <div className='w-full flex flex-col gap-6'>
            <HFButton
              options={options}
              ParentIcon={Polygon}
              fieldName='onboarding_type'
            />
          </div>
          <LoadingButton
            type='submit'
            variant='default'
            size='lg'
            loading={isSubmitting}
          >
            <LocalTranslatedText text='Choose & Next' />
          </LoadingButton>
        </div>
      </div>
    </AuthPageWrapper>
  );
}
