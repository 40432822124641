import "./EmailConfigurationPage.scss";
import React, { useCallback, useRef } from "react";
import { PageHeader } from "../../frontend/elements/PageHeader";
import { PageSection } from "../../frontend/elements/PageSection";
import { PageWrapperWithMenu } from "../../frontend/elements/PageWrapperWithMenu";
import { useAPI } from "../../frontend/components/APIProvider";
import { useEffect } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { useFrontendConfiguration } from "../../frontend/components/FrontendConfigurationProvider";
import { SpinnerButton } from "../../frontend/elements/SpinnerButton";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import { TimeZoneSelector } from "../../frontend/elements/TimeZoneSelector";
import BasicLanguageSelector from "../../voice/frontend/BasicLanguageSelector";

export const EmailConfigurationPage = () => {
  const api = useAPI();
  const [emailConfiguration, setEmailConfiguration] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const frontendConfiguration = useFrontendConfiguration();

  const loadEmailConfiguration = useCallback(() => {
    setIsLoading(true);
    return api.getEmailConfiguration().then(
      (data) => {
        setIsLoading(false);
        setEmailConfiguration(data);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [api]);

  const handleChangeEmailAddressName = useCallback(
    (event) => {
      setEmailConfiguration({
        ...emailConfiguration,
        email_address_name: String(event.target.value).replace(
          /[^a-zA-Z0-9-_]/g,
          ""
        ),
      });
    },
    [emailConfiguration]
  );

  const handleSaveEmailConfiguration = useCallback(() => {
    return api.saveEmailConfiguration(emailConfiguration).then(
      (data) => {
        setEmailConfiguration(data);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [emailConfiguration, api]);

  const handleChangeLanguage = useCallback(
    (languageId) => {
      setEmailConfiguration({
        ...emailConfiguration,
        language_id: languageId,
      });
    },
    [emailConfiguration]
  );

  const handleChangeTimezone = useCallback(
    (timezone) => {
      setEmailConfiguration({
        ...emailConfiguration,
        timezone: timezone,
      });
    },
    [emailConfiguration]
  );

  useEffect(() => {
    if (!emailConfiguration) {
      loadEmailConfiguration();
    }
  }, [emailConfiguration, loadEmailConfiguration]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={`Email Configuration`}
        subtitle={`Configure the email address that your bot uses to communicate with people`}
      />
      <div className='email-configuration-page'>
        {isLoading ? <CircularProgress /> : null}
        {emailConfiguration ? (
          <>
            <PageSection
              title={"Email Link"}
              subheading={
                "This is the final email address that you can give to people. You can also CC the bot on emails to allow " +
                "the bot to take over the conversation."
              }
            >
              <a
                className={"email-address-link"}
                href={`mailto:${emailConfiguration.email_address_name}.${frontendConfiguration.email.tenant_short_code}@${frontendConfiguration?.email?.frontend_email_address_domain}`}
              >
                {`${emailConfiguration.email_address_name}.${frontendConfiguration.email.tenant_short_code}@${frontendConfiguration?.email?.frontend_email_address_domain}`}
              </a>
            </PageSection>
            <PageSection
              title={"Change Email Address"}
              subheading={
                "Here you can personalize the email address your bot uses."
              }
            >
              <div className={"email-address-field-group"}>
                <TextField
                  required
                  id='email-address'
                  label='Bot Email Address'
                  value={emailConfiguration.email_address_name}
                  fullWidth={true}
                  className={"email-address-field"}
                  onChange={handleChangeEmailAddressName}
                />
                <span>
                  .{frontendConfiguration.email.tenant_short_code}@
                  {frontendConfiguration?.email?.frontend_email_address_domain}
                </span>
              </div>

              <SpinnerButton
                variant='contained'
                color='primary'
                onClick={handleSaveEmailConfiguration}
              >
                <LocalTranslatedText
                  language={"en"}
                  text='Update Email Address'
                />
              </SpinnerButton>
            </PageSection>
            <PageSection
              title={"Time zone"}
              subheading={
                "Here you can set the time zone that will be used by default when people email your bot."
              }
            >
              <TimeZoneSelector
                name='timezone'
                value={emailConfiguration?.timezone ?? "America/Toronto"}
                onChange={(evt) => handleChangeTimezone(evt.target.value)}
              />

              <SpinnerButton
                variant='contained'
                color='primary'
                onClick={handleSaveEmailConfiguration}
              >
                <LocalTranslatedText language={"en"} text='Update Time zone' />
              </SpinnerButton>
            </PageSection>
            <PageSection
              title={"Language"}
              subheading={
                "Change the language that is used by the bot by default when you email it."
              }
            >
              <BasicLanguageSelector
                value={emailConfiguration?.language_id ?? "en-US"}
                onChange={handleChangeLanguage}
              />

              <SpinnerButton
                variant='contained'
                color='primary'
                onClick={handleSaveEmailConfiguration}
              >
                <LocalTranslatedText
                  language={"en"}
                  text='Update Default Language'
                />
              </SpinnerButton>
            </PageSection>
          </>
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
};

export default EmailConfigurationPage;
