import React from "react";

export function Slack(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.59961 0C5.60459 0.000733945 4.79931 0.806605 4.80005 1.79963C4.79931 2.79266 5.60533 3.59853 6.60034 3.59927H8.40064V1.80037C8.40137 0.807339 7.59536 0.00146789 6.59961 0C6.60034 0 6.60034 0 6.59961 0M6.59961 4.8H1.8003C0.805281 4.80073 -0.000733404 5.60661 2.0098e-06 6.59963C-0.00146882 7.59266 0.804545 8.39853 1.79956 8.4H6.59961C7.59462 8.39927 8.40064 7.59339 8.3999 6.60037C8.40064 5.60661 7.59462 4.80073 6.59961 4.8Z'
        fill='#36C5F0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9995 6.59963C18.0002 5.60661 17.1942 4.80073 16.1992 4.8C15.2042 4.80073 14.3982 5.60661 14.3989 6.59963V8.4H16.1992C17.1942 8.39927 18.0002 7.59339 17.9995 6.59963ZM13.1995 6.59963V1.79963C13.2002 0.807339 12.3949 0.00146789 11.3999 0C10.4049 0.000733945 9.59888 0.806605 9.59961 1.79963V6.59963C9.59814 7.59266 10.4042 8.39853 11.3992 8.4C12.3942 8.39927 13.2002 7.59339 13.1995 6.59963Z'
        fill='#2EB67D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3999 18.0001C12.3949 17.9994 13.2009 17.1935 13.2002 16.2005C13.2009 15.2074 12.3949 14.4016 11.3999 14.4008H9.59961V16.2005C9.59887 17.1928 10.4049 17.9986 11.3999 18.0001ZM11.3999 13.1994H16.2C17.195 13.1986 18.001 12.3928 18.0002 11.3997C18.0017 10.4067 17.1957 9.60083 16.2007 9.59937H11.4006C10.4056 9.6001 9.59961 10.406 9.60035 11.399C9.59961 12.3928 10.4049 13.1986 11.3999 13.1994Z'
        fill='#ECB22E'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.02848e-07 11.3997C-0.000734911 12.3928 0.805279 13.1986 1.80029 13.1994C2.79531 13.1986 3.60132 12.3928 3.60059 11.3997V9.6001H1.80029C0.805279 9.60083 -0.000734911 10.4067 5.02848e-07 11.3997ZM4.80005 11.3997V16.1997C4.79858 17.1928 5.60459 17.9986 6.59961 18.0001C7.59462 17.9994 8.40064 17.1935 8.3999 16.2005V11.4012C8.40137 10.4082 7.59536 9.6023 6.60034 9.60083C5.60459 9.60083 4.79931 10.4067 4.80005 11.3997C4.80005 11.4005 4.80005 11.3997 4.80005 11.3997Z'
        fill='#E01E5A'
      />
    </svg>
  );
}
