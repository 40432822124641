import React from "react";
import TopNav from "../components/TopNav";
import Back from "../components/Back";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import HFTextField from "@/frontend/components/hook-form/hf-text-field";
import HFSelect from "@/frontend/components/hook-form/hf-select";
import industries from "@/backend/data/industries.json";
import companySizes from "@/backend/data/company_sizes.json";
import annualRevenues from "@/backend/data/annual_revenues.json";
import { convertToLabelValuePair } from "@/frontend/lib/utils";
import Next from "../components/Next";

export default function BasicInfo({
  steps,
  currentStep,
  isSubmitting,
  handlePrev,
}) {
  return (
    <div className='flex flex-col'>
      <TopNav steps={steps} currentStep={currentStep} />
      <Back handlePrev={handlePrev} />
      <div className='flex flex-col gap-10 justify-center items-center'>
        <h4 className='text-center text-gray-900 text-3xl font-bold'>
          <LocalTranslatedText text='Tell us about you' />
        </h4>
        <div className='px-8 mx-auto w-full lg:w-1/3 flex flex-col gap-6'>
          <div className='w-full flex flex-col md:flex-row items-center gap-3 justify-between'>
            <div className='w-full'>
              <HFTextField
                label='First Name *'
                fieldName='first_name'
                placeholder='Enter your first name . . .'
              />
            </div>
            <div className='w-full'>
              <HFTextField
                label='Last Name *'
                fieldName='last_name'
                placeholder='Enter your last name . . .'
              />
            </div>
          </div>
          <div className='w-full'>
            <HFTextField
              label='Company Name *'
              fieldName='company_name'
              placeholder='ACME Inc'
            />
          </div>
          <div className='w-full'>
            <HFSelect
              label='Industry *'
              fieldName='industry'
              placeholder='Select your industry'
              options={convertToLabelValuePair(industries.industries)}
            />
          </div>
          <div className='w-full'>
            <HFSelect
              label='Company Size *'
              fieldName='company_size'
              placeholder='Select your company size'
              options={convertToLabelValuePair(companySizes.company_sizes)}
            />
          </div>
          <div className='w-full'>
            <HFSelect
              label='Annual Revenue (Optional)'
              fieldName='annual_revenue'
              placeholder='Select annual revenue'
              options={convertToLabelValuePair(annualRevenues.annual_revenues)}
            />
          </div>
        </div>
      </div>
      <Next isSubmitting={isSubmitting} />
    </div>
  );
}
