import React from "react";

export function GoogleSheets(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='18'
      viewBox='0 0 14 18'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_157479_2164)'>
        <mask
          id='mask0_157479_2164'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_157479_2164)'>
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L10.7267 3.03929L8.73975 0.257568Z'
            fill='#0F9D58'
          />
        </g>
        <mask
          id='mask1_157479_2164'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_157479_2164)'>
          <path
            d='M3.9707 8.80151V14.5636H10.3289V8.80151H3.9707ZM6.75242 13.7689H4.76548V12.7754H6.75242V13.7689ZM6.75242 12.1793H4.76548V11.1858H6.75242V12.1793ZM6.75242 10.5898H4.76548V9.59629H6.75242V10.5898ZM9.53414 13.7689H7.5472V12.7754H9.53414V13.7689ZM9.53414 12.1793H7.5472V11.1858H9.53414V12.1793ZM9.53414 10.5898H7.5472V9.59629H9.53414V10.5898Z'
            fill='#F1F1F1'
          />
        </g>
        <mask
          id='mask2_157479_2164'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask2_157479_2164)'>
          <path
            d='M9.08887 4.67749L13.5088 9.09645V5.0262L9.08887 4.67749Z'
            fill='url(#paint0_linear_157479_2164)'
          />
        </g>
        <mask
          id='mask3_157479_2164'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask3_157479_2164)'>
          <path
            d='M8.74023 0.257568V3.83406C8.74023 4.49273 9.27373 5.02623 9.9324 5.02623H13.5089L8.74023 0.257568Z'
            fill='#87CEAC'
          />
        </g>
        <mask
          id='mask4_157479_2164'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask4_157479_2164)'>
          <path
            d='M1.98416 0.257568C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V1.54908C0.791992 0.893389 1.32847 0.356915 1.98416 0.356915H8.73975V0.257568H1.98416Z'
            fill='white'
            fillOpacity='0.2'
          />
        </g>
        <mask
          id='mask5_157479_2164'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask5_157479_2164)'>
          <path
            d='M12.3162 17.6433H1.98416C1.32847 17.6433 0.791992 17.1069 0.791992 16.4512V16.5505C0.791992 17.2062 1.32847 17.7427 1.98416 17.7427H12.3162C12.9719 17.7427 13.5084 17.2062 13.5084 16.5505V16.4512C13.5084 17.1069 12.9719 17.6433 12.3162 17.6433Z'
            fill='#263238'
            fillOpacity='0.2'
          />
        </g>
        <mask
          id='mask6_157479_2164'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask6_157479_2164)'>
          <path
            d='M9.9324 5.02615C9.27373 5.02615 8.74023 4.49265 8.74023 3.83398V3.93333C8.74023 4.592 9.27373 5.1255 9.9324 5.1255H13.5089V5.02615H9.9324Z'
            fill='#263238'
            fillOpacity='0.1'
          />
        </g>
        <path
          d='M8.73975 0.257568H1.98416C1.32847 0.257568 0.791992 0.794042 0.791992 1.44973V16.5505C0.791992 17.2062 1.32847 17.7426 1.98416 17.7426H12.3162C12.9719 17.7426 13.5084 17.2062 13.5084 16.5505V5.02623L8.73975 0.257568Z'
          fill='url(#paint1_radial_157479_2164)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_157479_2164'
          x1='230.11'
          y1='42.6191'
          x2='230.11'
          y2='446.635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#263238' stopOpacity='0.2' />
          <stop offset='1' stopColor='#263238' stopOpacity='0.02' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_157479_2164'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(41.0782 34.8158) scale(2050.5 2050.5)'
        >
          <stop stopColor='white' stopOpacity='0.1' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </radialGradient>
        <clipPath id='clip0_157479_2164'>
          <rect
            width='13.1642'
            height='18'
            fill='white'
            transform='translate(0.568359)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
