import React from "react";
import { Calendly } from "@/frontend/icons/Calendly";
import { GoogleCalendar } from "@/frontend/icons/GoogleCalendar";
import { LinkedIn } from "@/frontend/icons/LinkedIn";

const Icons = {
  Calendly: Calendly,
  "Google Calendar": GoogleCalendar,
  LinkedIn: LinkedIn,
};

export default function Tool({ tool }) {
  return (
    <div className='p-4 flex gap-2.5 items-center bg-white border border-slate-300 rounded-sm w-full md:w-[277px]'>
      {React.createElement(Icons[tool], { className: "w-4.5 h-4.5" })}
      <p className='text-base font-semibold'>{tool}</p>
    </div>
  );
}
