import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AnalysisTable } from "./AnalysisTable";
import "./AnalysisListPage.scss";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";

export default function AnalysisListPage() {
  const navigate = useNavigate();

  const handlePerformAnalysisClicked = useCallback(async () => {
    navigate(`/analysis/perform`);
  }, [navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Analysis"}
        subtitle={"View and manage knowledge base analyses."}
      >
        <WhiteButton onClick={handlePerformAnalysisClicked}>
          Perform Analysis
        </WhiteButton>
      </PageHeader>
      <div className={"analysis-list-page"}>
        <div className={"analysis-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <AnalysisTable />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
