import React from "react";

export function MondayCom(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      {...props}
    >
      <path
        d='M2.23912 11.2043C1.84122 11.2052 1.45022 11.1004 1.10606 10.9007C0.761901 10.701 0.476924 10.4135 0.280243 10.0676C0.0865709 9.72416 -0.0100182 9.33449 0.000821664 8.94034C0.0116615 8.54619 0.129522 8.16241 0.341778 7.83012L4.37577 1.49538C4.58224 1.15492 4.87538 0.875371 5.22524 0.685275C5.5751 0.49518 5.96915 0.401353 6.36713 0.41338C6.76482 0.42291 7.15283 0.537929 7.49146 0.746665C7.8301 0.955401 8.10719 1.25036 8.29439 1.60136C8.67044 2.31244 8.62344 3.16795 8.17303 3.83544L4.1416 10.1702C3.93716 10.4888 3.65561 10.7506 3.32304 10.9313C2.99047 11.1121 2.61765 11.206 2.23912 11.2043Z'
        fill='#F62B54'
      />
      <path
        d='M9.15888 11.204C8.34524 11.204 7.59656 10.769 7.20342 10.0699C7.01018 9.72739 6.91381 9.33873 6.92465 8.94561C6.93549 8.55249 7.05313 8.16974 7.26495 7.83839L11.2913 1.51818C11.4947 1.17293 11.7868 0.888402 12.1372 0.694089C12.4877 0.499777 12.8837 0.40276 13.2843 0.413102C14.1048 0.43105 14.8484 0.890002 15.2244 1.60792C15.5979 2.32583 15.5432 3.18647 15.0791 3.85225L11.0537 10.1725C10.8499 10.4897 10.5694 10.7505 10.2383 10.9308C9.90714 11.111 9.53592 11.205 9.15888 11.204Z'
        fill='#FFCC00'
      />
      <path
        d='M15.9235 11.2571C17.0705 11.2571 18.0003 10.3468 18.0003 9.22391C18.0003 8.10099 17.0705 7.19067 15.9235 7.19067C14.7765 7.19067 13.8467 8.10099 13.8467 9.22391C13.8467 10.3468 14.7765 11.2571 15.9235 11.2571Z'
        fill='#00CA72'
      />
    </svg>
  );
}
