import React, { useCallback, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import { Stack, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export function StringListInput({ label, example, fieldName, size, ...props }) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();
  const formOptions = useFormOptions();
  const [localValues, setLocalValues] = useState(formData[fieldName] ?? []);

  const onChangeFormDataDebounced = useMemo(
    () => _.debounce(changeFormData, 500),
    [changeFormData]
  );

  const handleChange = useCallback(
    (event, valueIndex) => {
      const newValues = [...localValues];
      newValues[valueIndex] = event.target.value;
      setLocalValues(newValues);
      onChangeFormDataDebounced(fieldName, newValues);
    },
    [onChangeFormDataDebounced, localValues, fieldName]
  );

  const handleAddClicked = useCallback(() => {
    setLocalValues([...localValues, ""]);
    onChangeFormDataDebounced(fieldName, [...localValues, ""]);
  }, [onChangeFormDataDebounced, localValues, fieldName]);

  const handleDeleteClicked = useCallback(
    (valueIndex) => {
      const newValues = localValues.filter((_, index) => index !== valueIndex);
      setLocalValues(newValues);
      onChangeFormDataDebounced(fieldName, newValues);
    },
    [onChangeFormDataDebounced, localValues, fieldName]
  );

  return (
    <div className={"form-input string-list-input"}>
      {label ? (
        <Typography variant={"label"}>
          <LocalTranslatedText language={"en"} text={label} />
        </Typography>
      ) : null}

      {(localValues ?? []).map((value, valueIndex) => {
        return (
          <Stack direction='row' spacing={1} key={`${fieldName}-${valueIndex}`}>
            <TextField
              key={valueIndex}
              name={fieldName}
              placeholder={example}
              value={value}
              onChange={(evt) => handleChange(evt, valueIndex)}
              disabled={!formOptions.editable}
              size={size || "medium"}
              {...props}
            />
            <IconButton
              aria-label='Delete Item'
              onClick={() => handleDeleteClicked(valueIndex)}
              color={"error"}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      })}
      {formOptions.editable ? (
        <div>
          <Button
            onClick={handleAddClicked}
            aria-label={"Add Item"}
            color='success'
            variant='contained'
            endIcon={<AddCircleIcon />}
          >
            Add
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default StringListInput;
