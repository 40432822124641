import React, { useContext, createContext, useEffect, useState } from "react";

import ApplicationBootingPage from "@/frontend/pages/ApplicationBootingPage";
import { useAPI } from "./APIProvider";
import { useFrontendConfiguration } from "./FrontendConfigurationProvider";
import { useNavigate } from "react-router-dom";

const OnboardingStatusContext = createContext(null);

export const OnboardingStatusProvider = ({ children }) => {
  const api = useAPI();
  const config = useFrontendConfiguration();
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchOnboardingStatus = async () => {
    try {
      const response = await api.getOnboardingStatus();
      setOnboardingStatus(response);
    } catch (e) {
      console.error("Error fetching onboarding status:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (config?.frontend?.enable_onboarding) {
        fetchOnboardingStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onboardingStatus && config?.frontend?.enable_onboarding) {
      if (onboardingStatus.status !== "completed") {
        navigate("/onboarding", { replace: true });
      }
    }
  }, [onboardingStatus, config, navigate]);

  if (loading && !onboardingStatus && config?.frontend?.enable_onboarding) {
    return <ApplicationBootingPage
      description={"Getting your onboarding status ..."}
    />;
  }

  return (
    <OnboardingStatusContext.Provider value={onboardingStatus}>
      {children}
    </OnboardingStatusContext.Provider>
  );
};

export const useOnboardingStatus = () =>
  useContext.bind(null, OnboardingStatusContext);
