import "./NewSmartChainBindingPage.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, {useCallback, useState} from "react";
import {Card, CardContent, Checkbox, FormControlLabel, TextField} from "@mui/material";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useNavigate} from "react-router-dom";
import {useIsAdmin} from "../../../frontend/components/IsAdminProvider";


export default function NewSmartChainBindingPage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const [newBinding, setNewBinding] = useState({});
    const isAdmin = useIsAdmin();

    const handleBindingNameChange = useCallback((evt) => {
        setNewBinding({
            ...newBinding,
            binding_name: evt.target.value,
        });
    }, [newBinding, setNewBinding]);

    const handleChainNameChange = useCallback((evt) => {
        setNewBinding({
            ...newBinding,
            chain_name: evt.target.value,
        });
    }, [newBinding, setNewBinding]);

    const handleApplyToAllClick = useCallback(async (evt) => {
        const userDetails = await api.getUserDetails();
        if (newBinding.user_id) {
            setNewBinding({
                ...newBinding,
                user_id: null,
            });
        } else {
            setNewBinding({
                ...newBinding,
                user_id: userDetails.user_id,
            });
        }
    },[newBinding, setNewBinding, api]);

    const handleCreateNewSmartChainBinding = useCallback(async (result) => {
        await api.createNewSmartChainBinding(newBinding);
        navigate(`/smart_chain_binding`);
    }, [api, navigate, newBinding]);

    return <PageWrapperWithMenu>
        <PageHeader title={"New Smart Chain Binding"}/>
        <div className="new-smart-chain-binding-page">
            <Card className="new-smart-chain-binding-card">
                <CardContent>
                    <TextField
                        label={"Binding Name"}
                        value={newBinding.binding_name}
                        onChange={handleBindingNameChange}
                    />
                    <TextField
                        label={"Chain Name"}
                        value={newBinding.chain_name}
                        onChange={handleChainNameChange}
                    />
                    {
                        isAdmin ? <FormControlLabel
                            control={<Checkbox checked={newBinding.user_id === null} onChange={handleApplyToAllClick} />}
                            label="Apply to all users"
                          /> : null
                    }
                    <SpinnerButton
                        variant="contained"
                        color="primary"
                        onClick={handleCreateNewSmartChainBinding}
                    >
                        <LocalTranslatedText
                            text={"Create"}
                            language={"en"}
                        />
                    </SpinnerButton>
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}