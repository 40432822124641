import React from "react";
import { TextInput } from "./TextInput";

function isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function EmailInput({label, fieldName, size, required}) {
  return (
    <TextInput
      fieldName={fieldName}
      label={label}
      example={"test@mydomainname.com"}
      validator={isValidEmail}
      errorHelperText={"Invalid email address"}
      size={size || "medium"}
      required={required}
    />
  );
}

export default EmailInput;
