import React from "react";
import { MoveLeft } from "lucide-react";
import { Button } from "@/frontend/components/ui/button";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function Back({ handlePrev }) {
  return (
    <div className='px-8 lg:px-[100px] py-[40px]'>
      <Button
        size='default'
        variant='outline'
        type='button'
        className='font-semibold'
        onClick={handlePrev}
      >
        <MoveLeft /> <LocalTranslatedText text='Back' />
      </Button>
    </div>
  );
}
