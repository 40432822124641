import React from "react";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";
import CheckboxesInput from "../../elements/form/inputs/CheckboxesInput";

const globalModules = [
  "Bot",
  "Voice",
  "Web Chat",
  "SMS",
  "Email",
  "WhatsApp",
  "Business Info",
  "Scheduling",
  "Document Generator",
  "Prompt Charts",
  "Knowledge Base",
  "Outbound",
  "Intake",
  "Billing",
];

const globalFieldNames = [
  "bot",
  "voice",
  "web_chat",
  "sms",
  "email",
  "whatsapp",
  "business_info",
  "scheduling",
  "document_generator",
  "prompt_chart",
  "knowledge_base",
  "outbound",
  "intake",
  "billing",
];

export default function ModulesConfigurationForm() {
  return (
    <FormSection
      title={"Modules"}
      subtitle={"Choose which modules you would like enabled in your system"}
      collapsable={false}
    >
      <FormSubFieldRoot field={"modules"}>
        <CheckboxesInput
          type={"checkboxes"}
          options={globalFieldNames}
          fieldName={globalFieldNames}
          optionLabels={globalModules}
        />
      </FormSubFieldRoot>
    </FormSection>
  );
}
