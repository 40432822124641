import React from "react";
import { Button } from "@/frontend/components/ui/button";
import { GoogleColored } from "@/frontend/icons/GoogleColored";

function GoogleAuthButton({ children, ...rest }) {
  return (
    <Button {...rest}>
      <GoogleColored /> {children}
    </Button>
  );
}

GoogleAuthButton.displayName = "GoogleAuthButton";

export { GoogleAuthButton };
