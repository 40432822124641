import React from "react";

export function Github(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M8.74389 0C3.91539 0 0 4.13137 0 9.22783C0 13.305 2.50538 16.764 5.97962 17.9841C6.41659 18.0695 6.57706 17.784 6.57706 17.5402C6.57706 17.3202 6.5689 16.5932 6.5652 15.8222C4.13258 16.3804 3.61928 14.7334 3.61928 14.7334C3.22153 13.6668 2.64844 13.3832 2.64844 13.3832C1.85514 12.8105 2.70824 12.8222 2.70824 12.8222C3.58629 12.8873 4.04864 13.7732 4.04864 13.7732C4.82849 15.1839 6.09415 14.776 6.59311 14.5403C6.67156 13.9438 6.89821 13.5368 7.14824 13.3064C5.20615 13.073 3.16448 12.2817 3.16448 8.7459C3.16448 7.73848 3.50606 6.91524 4.06544 6.26903C3.97464 6.03657 3.67537 5.09805 4.15013 3.82697C4.15013 3.82697 4.88438 3.57895 6.55533 4.77288C7.25275 4.56836 8.00079 4.46588 8.74389 4.4624C9.48698 4.46588 10.2356 4.56836 10.9344 4.77288C12.6033 3.57895 13.3365 3.82697 13.3365 3.82697C13.8125 5.09805 13.5131 6.03657 13.4223 6.26903C13.9829 6.91524 14.3221 7.73841 14.3221 8.7459C14.3221 12.2901 12.2766 13.0706 10.3296 13.299C10.6432 13.5854 10.9226 14.147 10.9226 15.0078C10.9226 16.2425 10.9125 17.2363 10.9125 17.5402C10.9125 17.7858 11.0699 18.0735 11.5132 17.9829C14.9855 16.7613 17.4877 13.3036 17.4877 9.22783C17.4877 4.13137 13.5729 0 8.74389 0Z'
        fill='#161614'
      />
      <path
        d='M3.27521 13.1452C3.25601 13.1911 3.18757 13.2048 3.12537 13.1734C3.06194 13.1433 3.02628 13.0808 3.04685 13.0347C3.06571 12.9875 3.13415 12.9744 3.19744 13.0061C3.26101 13.0361 3.29722 13.0992 3.27521 13.1452ZM3.70532 13.5502C3.66363 13.5911 3.58209 13.5721 3.52675 13.5076C3.46956 13.4433 3.45886 13.3573 3.50117 13.3158C3.54417 13.2751 3.62324 13.2941 3.68057 13.3585C3.73776 13.4236 3.74887 13.5091 3.70525 13.5503M4.00041 14.0684C3.94678 14.1077 3.85914 14.0709 3.80503 13.9888C3.75147 13.9068 3.75148 13.8084 3.8062 13.769C3.86051 13.7295 3.94678 13.765 4.00164 13.8464C4.05513 13.9298 4.05513 14.0283 4.00034 14.0685M4.49937 14.6686C4.45144 14.7243 4.3494 14.7094 4.27465 14.6333C4.19825 14.5589 4.17693 14.4533 4.225 14.3975C4.27348 14.3416 4.37614 14.3573 4.45144 14.4328C4.52735 14.5071 4.55046 14.6135 4.49937 14.6686ZM5.14427 14.8713C5.12322 14.9435 5.02488 14.9763 4.92585 14.9457C4.82696 14.914 4.76223 14.8294 4.78218 14.7563C4.80276 14.6836 4.90151 14.6494 5.00129 14.6822C5.10004 14.7137 5.16484 14.7977 5.14427 14.8713ZM5.87832 14.9572C5.88078 15.0333 5.79678 15.0964 5.69282 15.0978C5.58824 15.1002 5.50368 15.0386 5.50258 14.9637C5.50258 14.8869 5.58467 14.8244 5.68918 14.8225C5.79314 14.8204 5.87832 14.8815 5.87832 14.9572ZM6.59933 14.928C6.61181 15.0022 6.53953 15.0785 6.43633 15.0988C6.33483 15.1183 6.24088 15.0725 6.22792 14.9989C6.2153 14.9228 6.28895 14.8466 6.39024 14.8268C6.49366 14.8079 6.58617 14.8525 6.59933 14.928Z'
        fill='#161614'
      />
    </svg>
  );
}
