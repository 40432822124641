import "./SmartChainStepEditor.scss";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import CodeEditor from "../../../frontend/elements/CodeEditor";
import SmartChainStepTestForm from "./SmartChainStepTestForm";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback, useEffect, useMemo, useRef } from "react";
import SmartChainStepTestSamples from "./SmartChainStepTestSamples";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import { useAPI } from "../../../frontend/components/APIProvider";
import SmartChainErrorList from "./SmartChainErrorList";
import SchemaEditor from "../../../frontend/elements/schema-editor/SchemaEditor";

function TabPanel({ selectedTab, tabName, children, resetWhenTabOpens }) {
  return (
    <div
      role='tabpanel'
      hidden={selectedTab !== tabName}
      id={`full-width-tabpanel-${tabName}`}
      aria-labelledby={`full-width-tab-${tabName}`}
      className={`editor-tab ${selectedTab === tabName ? "active" : "hidden"}`}
    >
      {!resetWhenTabOpens
        ? children
        : selectedTab === tabName
        ? children
        : null}
    </div>
  );
}

export default function SmartChainStepEditor({
  smartChain,
  selectedStepIndex,
  onChangeStep,
  isDarkMode,
  tab,
  setTab,
}) {
  const step = smartChain.steps[selectedStepIndex];
  const api = useAPI();
  const [stepTypes, setStepTypes] = React.useState([]);

  useEffect(() => {
    api.getSmartChainStepTypes().then((types) => {
      setStepTypes(types);
    });
  }, [api]);

  const stepTypeInfo = useMemo(() => {
    return stepTypes.find((type) => type.name === step.step_type);
  }, [step, stepTypes]);

  useEffect(() => {
    if (
      stepTypeInfo &&
      !stepTypeInfo?.uses_content_template &&
      tab === "content"
    ) {
      setTab("options");
    } else if (
      stepTypeInfo &&
      !stepTypeInfo?.uses_options_template &&
      tab === "options"
    ) {
      setTab("content");
    }
  }, [setTab, stepTypeInfo, tab]);

  const contentEditorRef = useRef(null);
  const handleContentEditorDidMount = useCallback((editor, monaco) => {
    contentEditorRef.current = editor;
  }, []);

  const optionsEditorRef = useRef(null);
  const handleOptionsEditorDidMount = useCallback((editor, monaco) => {
    optionsEditorRef.current = editor;
  }, []);

  // const outputSchemaEditorRef = useRef(null);

  // const handleOutputSchemaEditorDidMount = useCallback((editor, monaco) => {
  //   outputSchemaEditorRef.current = editor;
  // }, []);

  const onChangeStepDebounced = useMemo(
    () => _.debounce(onChangeStep, 750),
    [onChangeStep]
  );

  const handleTabChange = useCallback(
    (event, newValue) => {
      setTab(newValue);
    },
    [setTab]
  );

  const handleContentChange = useCallback(
    (newValue) => {
      onChangeStepDebounced({ ...step, content_template: newValue });
    },
    [onChangeStepDebounced, step]
  );

  const handleOptionsChange = useCallback(
    (newValue) => {
      onChangeStepDebounced({ ...step, options_template: newValue });
    },
    [onChangeStepDebounced, step]
  );

  const handleOutputSchemaChange = useCallback(
    (newSchema) => {
      onChangeStepDebounced({ ...step, output_schema: newSchema });
    },
    [onChangeStepDebounced, step]
  );

  return (
    <div className={"smart-chain-step-editor"}>
      <AppBar position='static' color={"transparent"}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='secondary'
          variant='fullWidth'
          aria-label='Smart Chain Step Editor'
        >
          {stepTypeInfo?.uses_content_template ? (
            <Tab label='Content' value={"content"} />
          ) : null}

          {stepTypeInfo?.uses_options_template ? (
            <Tab label='Options' value={"options"} />
          ) : null}
          <Tab label='Output Schema' value={"output_schema"} />
          <Tab label='Test Form' value={"test_form"} />
          <Tab label='Test Samples' value={"test_samples"} />
          <Tab label='Recent Errors' value={"recent_errors"} />
        </Tabs>
      </AppBar>
      <TabPanel selectedTab={tab} tabName={"content"}>
        <CodeEditor
          // height="100%"
          defaultLanguage='handlebars'
          value={step.content_template ?? ""}
          theme={isDarkMode ? "vs-dark" : "vs-light"}
          onMount={handleContentEditorDidMount}
          onChange={(newValue) => handleContentChange(newValue)}
        />
      </TabPanel>
      <TabPanel selectedTab={tab} tabName={"options"}>
        <CodeEditor
          // height="100%"
          defaultLanguage='handlebars'
          defaultValue={step.options_template ?? ""}
          theme={isDarkMode ? "vs-dark" : "vs-light"}
          onMount={handleOptionsEditorDidMount}
          onChange={handleOptionsChange}
        />
      </TabPanel>
      <TabPanel selectedTab={tab} tabName={"output_schema"}>
        <Paper className={"output-schema"}>
          {stepTypeInfo?.allow_editing_output_schema ? (
            <SchemaEditor
              defaultLayout='schema'
              mode='simplified'
              initialSchemaData={step.output_schema}
              onChange={handleOutputSchemaChange}
              title='Output Schema'
            />
          ) : (
            <SchemaEditor
              defaultLayout='schema'
              mode='simplified'
              initialSchemaData={step.step_output_schema}
              disabled={true}
              title='Output Schema'
            />
          )}
        </Paper>
      </TabPanel>
      <TabPanel
        selectedTab={tab}
        tabName={"test_form"}
        resetWhenTabOpens={true}
      >
        <SmartChainStepTestForm
          smartChain={smartChain}
          selectedStepIndex={selectedStepIndex}
          isDarkMode={isDarkMode}
        />
      </TabPanel>
      <TabPanel
        selectedTab={tab}
        tabName={"test_samples"}
        resetWhenTabOpens={true}
      >
        <SmartChainStepTestSamples
          smartChain={smartChain}
          selectedStepIndex={selectedStepIndex}
          isDarkMode={isDarkMode}
        />
      </TabPanel>
      <TabPanel
        selectedTab={tab}
        tabName={"recent_errors"}
        resetWhenTabOpens={true}
      >
        <SmartChainErrorList
          smartChain={smartChain}
          selectedStepIndex={selectedStepIndex}
          isDarkMode={isDarkMode}
        />
      </TabPanel>
    </div>
  );
}
