import { createTheme } from '@mui/material/styles';


/**
 * Note that this material ui theme should stay in sync with the colors set in colors.scss. If
 * you modify one, you should modify the other at the same time.
 */
export const theme = createTheme({
    palette: {
        primary: {
            light: '#151a67',
            main: '#0e1250',
            dark: '#0a0e43',
            contrastText: '#fff',
        },
        secondary: {
            light: '#b37a51',
            main: '#67310a',
            dark: '#4b1400',
            contrastText: '#000',
        },
    },
    components: {
        // Name of the component
        MuiPaper: {
            defaultProps: {
                elevation: 1
            },
        },
    },
});

