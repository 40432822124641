import React from "react";
import { GoogleCalendar } from "@/frontend/icons/GoogleCalendar";
import { GoogleKeep } from "@/frontend/icons/GoogleKeep";
import { GoogleAnalytics } from "@/frontend/icons/GoogleAnalytics";
import { Dropbox } from "@/frontend/icons/Dropbox";
import { Zoom } from "@/frontend/icons/Zoom";
import { Mailchimp } from "@/frontend/icons/Mailchimp";
import { Jira } from "@/frontend/icons/Jira";
import { MicrosoftTeams } from "@/frontend/icons/MicrosoftTeams";
import { Slack } from "@/frontend/icons/Slack";
import { Trello } from "@/frontend/icons/Trello";
import { GoogleDocs } from "@/frontend/icons/GoogleDocs";
import { Zapier } from "@/frontend/icons/Zapier";
import { GoogleSheets } from "@/frontend/icons/GoogleSheets";
import { GoogleSlides } from "@/frontend/icons/GoogleSlides";
import { Calendly } from "@/frontend/icons/Calendly";
import { Github } from "@/frontend/icons/Github";
import { MondayCom } from "@/frontend/icons/MondayCom";
import { Gmail } from "@/frontend/icons/Gmail";
import { Notion } from "@/frontend/icons/Notion";
import { Shopify } from "@/frontend/icons/Shopify";

export function getIcon(tool) {
  const icons = {
    "Google Calendar": <GoogleCalendar />,
    "Google Keep": <GoogleKeep />,
    "Google Analytics": <GoogleAnalytics />,
    Dropbox: <Dropbox />,
    Zoom: <Zoom />,
    Mailchimp: <Mailchimp />,
    Jira: <Jira />,
    "Microsoft Teams": <MicrosoftTeams />,
    Slack: <Slack />,
    Trello: <Trello />,
    "Google Docs": <GoogleDocs />,
    Zapier: <Zapier />,
    "Google Sheets": <GoogleSheets />,
    "Google Slides": <GoogleSlides />,
    Calendly: <Calendly />,
    Github: <Github />,
    "Monday.com": <MondayCom />,
    Gmail: <Gmail />,
    Notion: <Notion />,
    Shopify: <Shopify />,
  };

  return icons[tool] || null;
}
