import React, { useCallback, useEffect, useState } from "react";
import { PageWrapperWithMenu } from "@/frontend/elements/PageWrapperWithMenu";
import { TextField, Typography } from "@mui/material";
import { SpinnerButton } from "@/frontend/elements/SpinnerButton";
import "./SchedulingRulesPage.scss";
import { WeeklyAvailabilityWidget } from "@/frontend/elements/WeeklyAvailabilityWidget";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAPI } from "@/frontend/components/APIProvider";
import { PageHeader } from "@/frontend/elements/PageHeader";
import { PageSection } from "@/frontend/elements/PageSection";
import CircularProgress from "@mui/material/CircularProgress";
import { TimeZoneSelector } from "@/frontend/elements/TimeZoneSelector";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

function BufferTimeWidget({ schedulingRules, handleChange }) {
  return (
    <div className={"event-limit-section"}>
      <h3>
        <LocalTranslatedText language={"en"} text='Buffer Time' />
      </h3>

      <div className='buffer-time-section'>
        <span>
          <LocalTranslatedText language={"en"} text='Before Event' />
        </span>
        <Select
          className='buffer-time-input'
          name='buffer_time_before_minutes'
          value={schedulingRules?.buffer_time_before_minutes ?? 0}
          label={
            <LocalTranslatedText
              language={"en"}
              text='Buffer Time Before Event'
            />
          }
          onChange={handleChange}
        >
          <MenuItem value={0}>
            <LocalTranslatedText language={"en"} text='0 min' />
          </MenuItem>
          <MenuItem value={5}>
            <LocalTranslatedText language={"en"} text='5 min' />
          </MenuItem>
          <MenuItem value={10}>
            <LocalTranslatedText language={"en"} text='10 min' />
          </MenuItem>
          <MenuItem value={15}>
            <LocalTranslatedText language={"en"} text='15 min' />
          </MenuItem>
          <MenuItem value={30}>
            <LocalTranslatedText language={"en"} text='30 min' />
          </MenuItem>
          <MenuItem value={45}>
            <LocalTranslatedText language={"en"} text='45 min' />
          </MenuItem>
          <MenuItem value={60}>
            <LocalTranslatedText language={"en"} text='1 hr' />
          </MenuItem>
          <MenuItem value={90}>
            <LocalTranslatedText language={"en"} text='1 hr 30 min' />
          </MenuItem>
          <MenuItem value={120}>
            <LocalTranslatedText language={"en"} text='2 hr' />
          </MenuItem>
          <MenuItem value={150}>
            <LocalTranslatedText language={"en"} text='2 hr 30 min' />
          </MenuItem>
          <MenuItem value={180}>
            <LocalTranslatedText language={"en"} text='3 hr' />
          </MenuItem>
        </Select>
      </div>

      <div className='buffer-time-section'>
        <span>
          <LocalTranslatedText language={"en"} text='After Event' />
        </span>
        <Select
          className='buffer-time-input'
          name='buffer_time_after_minutes'
          value={schedulingRules?.buffer_time_after_minutes ?? 0}
          label={
            <LocalTranslatedText
              language={"en"}
              text='Buffer Time After Event'
            />
          }
          onChange={handleChange}
        >
          <MenuItem value={0}>
            <LocalTranslatedText language={"en"} text='0 min' />
          </MenuItem>
          <MenuItem value={5}>
            <LocalTranslatedText language={"en"} text='5 min' />
          </MenuItem>
          <MenuItem value={10}>
            <LocalTranslatedText language={"en"} text='10 min' />
          </MenuItem>
          <MenuItem value={15}>
            <LocalTranslatedText language={"en"} text='15 min' />
          </MenuItem>
          <MenuItem value={30}>
            <LocalTranslatedText language={"en"} text='30 min' />
          </MenuItem>
          <MenuItem value={45}>
            <LocalTranslatedText language={"en"} text='45 min' />
          </MenuItem>
          <MenuItem value={60}>
            <LocalTranslatedText language={"en"} text='1 hr' />
          </MenuItem>
          <MenuItem value={90}>
            <LocalTranslatedText language={"en"} text='1 hr 30 min' />
          </MenuItem>
          <MenuItem value={120}>
            <LocalTranslatedText language={"en"} text='2 hr' />
          </MenuItem>
          <MenuItem value={150}>
            <LocalTranslatedText language={"en"} text='2 hr 30 min' />
          </MenuItem>
          <MenuItem value={180}>
            <LocalTranslatedText language={"en"} text='3 hr' />
          </MenuItem>
        </Select>
      </div>
    </div>
  );
}

function MinimumNoticeWidget({ schedulingRules, handleChange }) {
  return (
    <div className={"event-limit-section"}>
      <h3>
        <LocalTranslatedText language={"en"} text='Minimum Notice' />
      </h3>

      <Typography>
        <LocalTranslatedText
          language={"en"}
          text="Invitees can't schedule within ..."
        />
      </Typography>
      <div className='minimum-notice-section'>
        <span>
          <LocalTranslatedText language={"en"} text='Minimum Notice' />
        </span>
        <TextField
          className='minimum-notice-time-units'
          name='minimum_notice_minutes'
          value={schedulingRules?.minimum_notice_minutes ?? 0}
          onChange={handleChange}
          type={"number"}
          min={0}
          max={100}
        />
        <Typography>
          <LocalTranslatedText
            language={"en"}
            text='minutes of the current time'
          />
        </Typography>
      </div>
    </div>
  );
}

function DefaultEventLengthWidget({ schedulingRules, handleChange }) {
  return (
    <div className={"default-event-length-section"}>
      <TextField
        name='default_event_length_minutes'
        value={schedulingRules.default_event_length_minutes}
        onChange={handleChange}
        margin='normal'
        type='number'
        fullWidth
      />
      <span>
        <LocalTranslatedText language={"en"} text='minutes long' />
      </span>
    </div>
  );
}

function StartTimeIncrementWidget({ schedulingRules, handleChange }) {
  return (
    <div className={"event-limit-section"}>
      <h3>
        <LocalTranslatedText language={"en"} text='Start time increment' />
      </h3>

      <Typography>
        <LocalTranslatedText
          language={"en"}
          text='Timeslots are only available in increments of...'
        />
      </Typography>
      <div className='start-time-increment-section'>
        <span>
          <LocalTranslatedText language={"en"} text='Start Increment' />
        </span>
        <TextField
          className='start-time-increment-field'
          name='start_time_increment_minutes'
          value={schedulingRules?.start_time_increment_minutes ?? 0}
          onChange={handleChange}
          type={"number"}
          min={0}
          max={100}
        />
        <Typography>
          <LocalTranslatedText language={"en"} text='minutes' />
        </Typography>
      </div>
    </div>
  );
}

function TimeZoneWidget({ schedulingRules, handleChange }) {
  return (
    <div className={"event-limit-section"}>
      <h3>
        <LocalTranslatedText language={"en"} text='Time Zone' />
      </h3>

      <Typography>
        <LocalTranslatedText
          language={"en"}
          text='Which time zone would you like to use for scheduling...'
        />
      </Typography>
      <div className='time-zone-section'>
        <span>
          <LocalTranslatedText language={"en"} text='Time Zone' />
        </span>
        <TimeZoneSelector
          name='timezone'
          value={schedulingRules?.timezone ?? "America/Toronto"}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

function SaveSchedulingRulesButton({ saveSchedulingRules }) {
  return (
    <div className={"buttons-bar"}>
      <SpinnerButton
        variant='contained'
        color='primary'
        type='submit'
        onClick={saveSchedulingRules}
      >
        <LocalTranslatedText language={"en"} text='Save' />
      </SpinnerButton>
    </div>
  );
}

export const SchedulingRulesPage = () => {
  const api = useAPI();
  const [schedulingRules, setSchedulingRules] = useState(null);

  useEffect(() => {
    api.getSchedulingRules().then((data) => {
      setSchedulingRules(data);
    });
  }, [api]);

  const handleChange = useCallback(
    (e) => {
      setSchedulingRules({
        ...schedulingRules,
        [e.target.name]: e.target.value,
      });
    },
    [schedulingRules]
  );

  const handleAvailableHoursChanged = useCallback(
    (available_hours) => {
      setSchedulingRules({ ...schedulingRules, available_hours });
    },
    [schedulingRules]
  );

  const saveSchedulingRules = useCallback(
    (e) => {
      e.preventDefault();

      return api.saveSchedulingRules(schedulingRules);
    },
    [api, schedulingRules]
  );

  if (!schedulingRules) {
    return (
      <PageWrapperWithMenu>
        <PageHeader title={`Scheduling Rules`} />
        <CircularProgress />
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={`Scheduling Rules`}
        subtitle={`Set the rules for how people can schedule meetings with you.`}
      />
      <div className='scheduling-rules-page'>
        <PageSection
          title={"Date Range"}
          subheading={"Invitees can schedule..."}
        >
          <div className={"max-days-in-future-section"}>
            <TextField
              name='max_days_in_future'
              value={schedulingRules.max_days_in_future}
              onChange={handleChange}
              margin='normal'
              type='number'
              fullWidth
            />
            <span>
              <LocalTranslatedText
                language={"en"}
                text='calendar days into the future'
              />
            </span>
          </div>
          <SaveSchedulingRulesButton
            saveSchedulingRules={saveSchedulingRules}
          />
        </PageSection>

        <PageSection title={"Time Zone"}>
          <TimeZoneWidget
            schedulingRules={schedulingRules}
            handleChange={handleChange}
          />
        </PageSection>
        <PageSection
          title={"Available Hours"}
          subheading={
            "Set the times that people will be able to schedule these types of meetings with you."
          }
        >
          <WeeklyAvailabilityWidget
            value={schedulingRules.available_hours ?? []}
            onChange={handleAvailableHoursChanged}
          />

          <SaveSchedulingRulesButton
            saveSchedulingRules={saveSchedulingRules}
          />
        </PageSection>

        <PageSection
          title={"Event Length"}
          subheading={"Scheduled events should be..."}
        >
          <DefaultEventLengthWidget
            schedulingRules={schedulingRules}
            handleChange={handleChange}
          />

          <SaveSchedulingRulesButton
            saveSchedulingRules={saveSchedulingRules}
          />
        </PageSection>

        <PageSection title={"Event Limits"}>
          <BufferTimeWidget
            schedulingRules={schedulingRules}
            handleChange={handleChange}
          />

          <MinimumNoticeWidget
            schedulingRules={schedulingRules}
            handleChange={handleChange}
          />

          <SaveSchedulingRulesButton
            saveSchedulingRules={saveSchedulingRules}
          />
        </PageSection>

        <PageSection title={"Additional Configuration"}>
          <StartTimeIncrementWidget
            schedulingRules={schedulingRules}
            handleChange={handleChange}
          />

          <SaveSchedulingRulesButton
            saveSchedulingRules={saveSchedulingRules}
          />
        </PageSection>
      </div>
    </PageWrapperWithMenu>
  );
};

export default SchedulingRulesPage;
