import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ErrorBoundary from "@/frontend/components/ErrorBoundary";
import { SnackbarProvider } from "@/frontend/components/SnackbarProvider";
import { APIProvider } from "@/frontend/components/APIProvider";
import { useFrontendConfiguration } from "@/frontend/components/FrontendConfigurationProvider";
import { GoogleMapsProvider } from "@/frontend/components/GoogleMapsProvider";
import { IsAdminProvider } from "@/frontend/components/IsAdminProvider";
import { ErrorReportingWrapper } from "@/frontend/components/ErrorReporting";
import { LeftSideMenuStateProvider } from "@/frontend/components/LeftSideMenuStateProvider";
import { OnboardingStatusProvider } from "@/frontend/components/OnboardingStatusProvider";
import { TenantSettingsProvider } from "@/frontend/components/TenantSettingsProvider";
import { theme } from "@/frontend/theme/theme";
import globalTranslationManager from "@/translation/frontend/components/translation_manager";
import routes from "./routes";
import ApplicationBootingPage from "@/frontend/pages/ApplicationBootingPage";
import { CssBaseline } from "@mui/material";
import "../index.css";

const createRouter = (config) =>
  createBrowserRouter(
    routes.map(({ path, element }) => ({
      path,
      element: (
        <OnboardingStatusProvider>
          {React.createElement(element)}
        </OnboardingStatusProvider>
      ),
    })),
    {
      basename: config?.frontend?.router_basename || "/",
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      },
    }
  );

const composeProviders = (providers, children) =>
  providers.reduceRight(
    (Accumulated, [Provider, props]) => (
      <Provider {...props}>{Accumulated}</Provider>
    ),
    children
  );

function MainFrontendApp() {
  const config = useFrontendConfiguration();
  document.title =
    config.frontend.environment_title || config.frontend.top_bar_title;
  globalTranslationManager.enabled =
    config.frontend.enable_automatic_frontend_translation;

  const needsRedirectToApp =
    window.location.pathname === "/" &&
    config?.frontend?.router_basename !== "/" &&
    config?.frontend?.router_basename;

  if (needsRedirectToApp) {
    // change path to router_basename without triggering a page reload.
    // Crucially this must be done BEFORE the RouterProvider component is ever
    // created for the first time, so it must be done here rather then in a
    // useEffect hook.
    window.history.replaceState(null, "", config?.frontend?.router_basename);
  }

  const router = createRouter(config);

  if (!config || !config.frontend) {
    return <ApplicationBootingPage description={"Loading configuration..."} />;
  }

  return (
    <Auth0Provider
      domain={config.frontend.auth0_domain}
      clientId={config.frontend.auth0_client_id}
      authorizationParams={{
        redirect_uri: new URL(
          config.frontend.router_basename,
          window.location.href
        ).href,
        scope: "read:current_user update:current_user_metadata email profile",
        audience: config.frontend.auth0_audience,
      }}
      cacheLocation='localstorage'
    >
      {composeProviders(
        [
          [ErrorBoundary, {}],
          [LocalizationProvider, { dateAdapter: AdapterMoment }],
          [ThemeProvider, { theme }],
          [SnackbarProvider, {}],
          [LeftSideMenuStateProvider, {}],
          [APIProvider, {}],
          [IsAdminProvider, {}],
          [TenantSettingsProvider, {}],
          [
            config?.frontend?.google_maps_api_key
              ? GoogleMapsProvider
              : React.Fragment,
            {},
          ],
          [ErrorReportingWrapper, {}],
        ],
        <>
          <CssBaseline />
          <RouterProvider router={router} />
        </>
      )}
    </Auth0Provider>
  );
}

export default MainFrontendApp;
