import React from "react";

export function Jira(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      {...props}
    >
      <path
        d='M17.2483 0.539795H8.57422C8.57422 2.63384 10.3291 4.3383 12.4851 4.3383H14.0895V5.8236C14.0895 7.91763 15.8444 9.62214 18.0004 9.62214V1.27028C18.0004 0.856339 17.6745 0.539795 17.2483 0.539795Z'
        fill='#2684FF'
      />
      <path
        d='M12.9612 4.72778H4.28711C4.28711 6.82183 6.04199 8.52627 8.198 8.52627H9.80244V10.036C9.80244 12.13 11.5574 13.8345 13.7134 13.8345V5.45824C13.7134 5.06867 13.3874 4.72778 12.9612 4.72778Z'
        fill='url(#paint0_linear_157479_1982)'
      />
      <path
        d='M8.67416 8.94019H0C0 11.0342 1.75488 12.7387 3.91088 12.7387H5.51535V14.224C5.51535 16.318 7.27021 18.0225 9.4262 18.0225V9.67062C9.4262 9.25669 9.07528 8.94019 8.67416 8.94019Z'
        fill='url(#paint1_linear_157479_1982)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_157479_1982'
          x1='13.5288'
          y1='4.7488'
          x2='9.9516'
          y2='8.5469'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.176' stopColor='#0052CC' />
          <stop offset='1' stopColor='#2684FF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_157479_1982'
          x1='9.48277'
          y1='8.96816'
          x2='5.3397'
          y2='13.1185'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.176' stopColor='#0052CC' />
          <stop offset='1' stopColor='#2684FF' />
        </linearGradient>
      </defs>
    </svg>
  );
}
