import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import {useCallback} from "react";


const Input = styled(MuiInput)`
  width: 80px;
`;

export default function VoiceSettingSlider({title, description, id, min, max, step, marks, icon, onChange, value}) {
  const handleSliderChange = useCallback((event, newValue) => {
    onChange(newValue);
  }, [onChange]);

  const handleInputChange = useCallback((event) => {
    onChange(event.target.value === '' ? 0 : Number(event.target.value));
  }, [onChange]);

  const handleBlur = useCallback(() => {
    if (value < min) {
      onChange(min);
    } else if (value > max) {
      onChange(max);
    }
  }, [value, onChange]);

  return (
    <Box sx={{ width: 500 }}>
    <Typography id={id} gutterBottom>
      {title}
      </Typography>
      <Typography gutterBottom sx={{fontSize: 12}}>{description}</Typography>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item>
          {icon}
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby={id}
            aria-label={title}
            step={step}
            marks={marks}
            min={min}
            max={max}
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            size="medium"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': id,
            }}
            className={"slider-input"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
