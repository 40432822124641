import "./CustomDataObjectForm.scss";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import FormSubFieldRoot from "../../../frontend/elements/form/FormSubFieldRoot";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import FormArraySection from "../../../frontend/elements/form/FormArraySection";
import { useAPI } from "../../../frontend/components/APIProvider";

function RecursiveFormSection({ schema, fieldName, showTitle = true }) {
  if (schema.type === "object") {
    const subFormSection = (
      <FormSection
        title={showTitle ? schema.title || fieldName : null}
        subtitle={showTitle ? schema.description : null}
        noPadding
      >
        {Object.keys(schema.properties).map((fieldName) => {
          const fieldSchema = schema.properties[fieldName];
          return (
            <RecursiveFormSection
              key={fieldName}
              schema={fieldSchema}
              fieldName={fieldName}
            />
          );
        })}
      </FormSection>
    );
    if (fieldName) {
      return (
        <FormSubFieldRoot field={fieldName}>{subFormSection}</FormSubFieldRoot>
      );
    } else {
      return subFormSection;
    }
  } else if (schema.type === "array") {
    return (
      <FormSection
        title={showTitle ? schema.title || fieldName : null}
        subtitle={showTitle ? schema.description ?? "Array of items" : null}
      >
        <FormArraySection
          fieldName={fieldName}
          defaultItemValue={schema.default ?? {}}
          arrayFormItemComponent={({ item, index }) => (
            <>
              <FormSection title={`${schema.title || fieldName} #${index + 1}`}>
                <RecursiveFormSection schema={schema.items} fieldName={null} />
              </FormSection>
            </>
          )}
        />
      </FormSection>
    );
  } else if (schema.type === "string") {
    return (
      <FormInput
        type={schema.form_input_type ?? "text"}
        label={schema.title || fieldName}
        placeholder={schema.placeholder ?? schema.description}
        fieldName={fieldName}
        required={schema.required}
        options={schema.options}
        optionLabels={schema.optionLabels}
        multiline={schema.multiline}
        rows={schema.rows}
        defaultValue={schema.default ?? ""}
      />
    );
  } else if (schema.type === "number") {
    return (
      <FormInput
        type={"number"}
        label={schema.title || fieldName}
        placeholder={schema.placeholder ?? schema.description}
        fieldName={fieldName}
        required={schema.required}
        defaultValue={schema.default ?? ""}
      />
    );
  } else if (schema.type === "boolean") {
    return (
      <FormInput
        type={"checkbox"}
        label={schema.title || fieldName}
        placeholder={schema.placeholder ?? schema.description}
        fieldName={fieldName}
        required={schema.required}
        defaultValue={schema.default ?? false}
      />
    );
  } else {
    return null;
  }
}

function validateObject(schema, data, path = "") {
  const errors = [];
  
  if (schema.type === "object" && schema.properties) {
    Object.keys(schema.properties).forEach(fieldName => {
      const fieldSchema = schema.properties[fieldName];
      const fieldPath = path ? `${path}.${fieldName}` : fieldName;
      const fieldValue = data && data[fieldName];
      
      if (fieldSchema.required && (fieldValue === undefined || fieldValue === null || fieldValue === "")) {
        errors.push(`${fieldSchema.title || fieldName} is required`);
      }
      
      if (fieldSchema.type === "object" && fieldValue) {
        errors.push(...validateObject(fieldSchema, fieldValue, fieldPath));
      } else if (fieldSchema.type === "array" && Array.isArray(fieldValue)) {
        fieldValue.forEach((item, index) => {
          errors.push(...validateObject(fieldSchema.items, item, `${fieldPath}[${index}]`));
        });
      }
    });
  }
  
  return errors;
}

export default function CustomDataObjectForm({
  customDataSchema,
  initialCustomDataObject,
  onCustomDataObjectSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const api = useAPI();
  const [customDataObject, setCustomDataObject] = React.useState(
    initialCustomDataObject || {}
  );
  const [validationErrors, setValidationErrors] = useState([]);
  const errorsRef = useRef(null);

  // Add effect to scroll to errors when they appear
  useEffect(() => {
    if (validationErrors.length > 0 && errorsRef.current) {
      errorsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [validationErrors]);

  const handleCustomDataObjectSaved = useCallback(
    (newCustomDataObjectData) => {
      // Validate the form data before submission
      const errors = validateObject(customDataSchema.json_schema, newCustomDataObjectData.data);
      
      if (errors.length > 0) {
        setValidationErrors(errors);
        return false; // Prevent form submission
      }
      
      setValidationErrors([]); // Clear errors on successful validation
      
      if (onCustomDataObjectSave) {
        return onCustomDataObjectSave(newCustomDataObjectData);
      }
    },
    [onCustomDataObjectSave, customDataSchema]
  );

  const handleCustomDataObjectChange = useCallback(
    (newCustomDataObjectData) => {
      setCustomDataObject(newCustomDataObjectData);
      // Clear validation errors when the form changes
      setValidationErrors([]);
    },
    []
  );

  const handleExecuteCustomActionClicked = useCallback(async (actionName) => {
    await api.executeCustomAction(customDataObject.id, actionName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"custom-data-object-form"}>
      {validationErrors.length > 0 && (
        <div className="validation-errors" ref={errorsRef}>
          <ul>
            {validationErrors.map((error, index) => (
              <li key={index} style={{ color: 'red' }}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <FormRoot
        initialValue={initialCustomDataObject}
        saveButtonText={saveButtonText ?? `Save ${customDataSchema.title}`}
        onSave={handleCustomDataObjectSaved}
        onChange={handleCustomDataObjectChange}
        extraButtons={
          <>
            {(customDataSchema.custom_actions ?? []).map((action) => (
              <SpinnerButton
                key={action.action_name}
                variant='contained'
                color='primary'
                className={"execute-action-button"}
                onClick={handleExecuteCustomActionClicked.bind(
                  null,
                  action.action_name
                )}
              >
                <LocalTranslatedText language={"en"} text={action.title} />
              </SpinnerButton>
            ))}
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                <LocalTranslatedText language={"en"} text='Delete' />
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <RecursiveFormSection
          schema={customDataSchema.json_schema}
          fieldName={"data"}
          showTitle={false}
        />
      </FormRoot>
    </div>
  );
}
