import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../components/APIProvider";
import { TenantConfigurationTable } from "./TenantConfigurationTable";
import "./TenantConfigurationListPage.scss";
import { PageHeader } from "../elements/PageHeader";
import { WhiteButton } from "../elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";

export default function TenantConfigurationListPage() {
  const api = useAPI();
  const navigate = useNavigate();

  const handleCreateNewTenantClicked = useCallback(async () => {
    navigate(`/tenant_configuration/new`);
  }, [navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Tenant Configurations"}
        subtitle={"Add and manage your tenants here"}
      >
        <WhiteButton onClick={handleCreateNewTenantClicked}>
          Create New Tenant
        </WhiteButton>
      </PageHeader>
      <div className={"tenant-configuration-list-page"}>
        <div className={"tenant-configuration-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <TenantConfigurationTable />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
