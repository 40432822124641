import React from "react";

export function GoogleColored(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_156850_651)'>
        <path
          d='M18.117 9.95242C18.117 9.20992 18.0609 8.66992 17.926 8.10742H9.48828V11.4487H14.4431C14.342 12.2812 13.8027 13.5299 12.6005 14.3737L12.5892 14.4862L15.252 16.5562L15.4318 16.5787C17.1396 15.0037 18.117 12.6862 18.117 9.95242Z'
          fill='#4285F4'
        />
        <path
          d='M9.48835 18.7501C11.9152 18.7501 13.9488 17.9513 15.4431 16.5676L12.6118 14.3626C11.8478 14.8913 10.8366 15.2626 9.49958 15.2626C7.11769 15.2626 5.10656 13.6876 4.3875 11.5276L4.28638 11.5388L1.50001 13.6876L1.46631 13.7888C2.93814 16.7251 5.97168 18.7501 9.48835 18.7501Z'
          fill='#34A853'
        />
        <path
          d='M4.37619 11.5273C4.18519 10.9648 4.07284 10.3685 4.07284 9.74976C4.07284 9.13101 4.18519 8.53476 4.36496 7.97226V7.84851L1.55612 5.66602L1.46624 5.71102C0.848296 6.92601 0.5 8.29851 0.5 9.74976C0.5 11.201 0.848296 12.5735 1.455 13.7885L4.37619 11.5273Z'
          fill='#FBBC05'
        />
        <path
          d='M9.48835 4.22625C11.1737 4.22625 12.3197 4.9575 12.9601 5.565L15.4993 3.09C13.9375 1.63875 11.9152 0.75 9.48835 0.75C5.97169 0.75 2.93814 2.775 1.45508 5.71125L4.36503 7.9725C5.09533 5.80125 7.10646 4.22625 9.48835 4.22625Z'
          fill='#EB4335'
        />
      </g>
      <defs>
        <clipPath id='clip0_156850_651'>
          <rect x='0.5' y='0.75' width='18' height='18' rx='9' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
