import React from "react";

export function Files(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      {...props}
    >
      <path
        d='M23.75 3H13.4C12.8 3 12.2 3.3 11.75 3.75C11.3 4.2 11 4.8 11 5.4V24.6C11 25.2 11.3 25.8 11.75 26.25C12.2 26.7 12.8 27 13.4 27H28.1C28.7 27 29.3 26.7 29.75 26.25C30.2 25.8 30.5 25.2 30.5 24.6V9.75L23.75 3Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 11.4004V30.6004C5 31.2004 5.3 31.8004 5.75 32.2504C6.2 32.7004 6.8 33.0004 7.4 33.0004H22.1'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23 3V10.5H30.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
