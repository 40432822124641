import "./AIConfigurationPage.scss";
import Paper from "@mui/material/Paper";
import React, { useCallback, useEffect, useState } from "react";
import { PageWrapperWithMenu } from "@/frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "@/frontend/elements/PageHeader";
import { SpinnerButton } from "@/frontend/elements/SpinnerButton";
import { TextField, Typography } from "@mui/material";
import { useAPI } from "@/frontend/components/APIProvider";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { useFrontendConfiguration } from "@/frontend/components/FrontendConfigurationProvider";
import { useSnackbar } from "@/frontend/components/SnackbarProvider";

export const AIConfigurationPage = () => {
  const config = useFrontendConfiguration();
  const api = useAPI();
  const { showMessage } = useSnackbar();
  const [formData, setFormData] = useState({
    llm_prompt_tweak: "",
  });
  const [loading, setLoading] = useState(false);

  // Load AI configuration on component mount
  useEffect(() => {
    api.getAIConfiguration().then((data) => {
      setFormData({
        llm_prompt_tweak: data.llm_prompt_tweak || "",
      });
    });
  }, [api]);

  const handleChange = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );

  const saveAIConfiguration = useCallback(
    (e) => {
      e.preventDefault();
      return api.saveAIConfiguration(formData);
    },
    [api, formData]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"AI Configuration"}
        subtitle={"Customize the AI behavior by adding specific instructions."}
      />
      <div className='ai-configuration-form'>
        <Paper className='ai-configuration-card'>
          <TextField
            name='llm_prompt_tweak'
            label='LLM Prompt Tweak'
            value={formData.llm_prompt_tweak}
            onChange={handleChange}
            margin='normal'
            fullWidth
            multiline
            rows={12}
          />
          <Typography
            variant='caption'
            color='textSecondary'
            className='instruction-text'
          >
            Use this field to customize the AI behavior by adding specific
            instructions. The text entered here will be appended to the LLM's
            system prompt, affecting how it responds to user queries. Be careful
            with your changes as they directly impact AI interactions.
          </Typography>
          <div className={"buttons-bar"}>
            <SpinnerButton
              variant='contained'
              color='primary'
              type='submit'
              loading={loading ? true : undefined}
              onClick={saveAIConfiguration}
            >
              <LocalTranslatedText language={"en"} text='Save' />
            </SpinnerButton>
          </div>
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
};

export default AIConfigurationPage;
