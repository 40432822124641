import React from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "@/frontend/lib/utils";
import {
  FormItem,
  FormField,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/frontend/components/ui/form";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { CalendarTime } from "@/frontend/components/ui/calendar-time";

export default function HFCalendarTime({
  label,
  fieldName,
  className,
  ...rest
}) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          {label && (
            <FormLabel>
              <LocalTranslatedText text={label} />
            </FormLabel>
          )}
          <FormControl>
            <div className='w-full h-full flex items-center justify-center'>
              <CalendarTime
                {...field}
                onChange={(date) => field.onChange(date)}
                value={field.value}
                className={cn(
                  "rounded-sm border border-slate-300 w-auto max-w-[350px] h-auto",
                  error &&
                    "border-danger focus:shadow-error focus:ring-ring-error",
                  className
                )}
                {...rest}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
