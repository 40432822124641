import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import AbcIcon from "@mui/icons-material/Abc";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import NumbersIcon from "@mui/icons-material/Numbers";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DataObjectIcon from "@mui/icons-material/DataObject";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import {RadioButtonCheckedOutlined} from "@mui/icons-material";

export const getDefaultObject = (mode = "simplified", isChildNode = false) => ({
  type: "object",
  title: "",
  description: "",
  properties: {},
  ...(mode === "full" &&
    isChildNode && {
      multiline: false,
      rows: 1,
      show_in_table_view: false,
      table_view_order: 0,
      placeholder: "",
      form_input_type: "text", // text, number, email, phone, address, url, full_name, company_name, checkboxes, datetime, currency, radio, dropdown, string_list, json
      required: false,
      compound_key: false,
      options: [],
      optionLabels: [],
      default: "",
    }),
});

export const schemaTypes = ["object", "array", "string", "number", "boolean"];

export const formInputTypes = [
  "text",
  "number",
  "email",
  "phone",
  "address",
  "checkbox",
  "url",
  "full_name",
  "company_name",
  "checkboxes",
  "datetime",
  "currency",
  "radio",
  "dropdown",
  "string_list",
  "json",
];

export const isNotEmptyObject = (obj) =>
  obj && typeof obj === "object" && Object.keys(obj).length > 0;

export const getIcon = (type, formInputType) => {
  switch (type) {
    case "object":
      return <DeveloperModeIcon/>;
    case "array":
      return <FormatListBulletedIcon />;
    case "string":
      switch (formInputType) {
        case "datetime":
          return <CalendarMonthIcon />;
        case "email":
          return <AlternateEmailIcon />;
        case "address":
          return <EmailIcon />;
        case "phone":
          return <PhoneIcon />;
        case "currency":
          return <AttachMoneyIcon />;
        case "checkboxes":
          return <CheckBoxOutlinedIcon />;
        case "dropdown":
          return <ArrowDropDownCircleIcon />;
        case "json":
          return <DataObjectIcon />;
        case "radio":
          return <RadioButtonCheckedOutlined />;
        default:
          return <AbcIcon />;
      }
    case "number":
      switch (formInputType) {
        case "currency":
          return <AttachMoneyIcon />;
        default:
          return <NumbersIcon />;
      }
    case "boolean":
      return <CheckBoxOutlinedIcon />;
    default:
      return <HighlightOffOutlinedIcon />;
  }
};
