import React, { useCallback, useEffect, useState } from "react";
import { PageWrapperWithMenu } from "../../frontend/elements/PageWrapperWithMenu";
import {
  useAPI,
  useIsAuthenticated,
} from "../../frontend/components/APIProvider";
import "./ChatWithBotPage.scss";
import Alert from "@mui/material/Alert";
import { ChatWithBotWidget } from "./ChatWithBotWidget";
import globalTranslationManager from "../../translation/frontend/components/translation_manager";
import { useParams } from "react-router-dom";

export default function ChatWithBotPage() {
  const api = useAPI();
  const params = useParams();
  const isAuthenticated = useIsAuthenticated();
  const [conversation, setConversation] = useState(null);
  const [webChatConfig, setWebChatConfig] = useState(null);
  const [error, setError] = useState(null);
  const intakeToken = params?.intakeToken || "";

  const resetConversation = useCallback(async () => {
    try {
      const config = await api.getWebChatConfiguration();
      setWebChatConfig(config);
      const conversationPayload = {
        client_token: config.client_token,
        communication_channel: "web_chat",
        initiate_conversation: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language_id: globalTranslationManager.getLocalLanguage(),
      };
      const conversation = intakeToken
        ? await api.startIntakeChatConversation({
            intake_token: intakeToken,
            ...conversationPayload,
          })
        : await api.startWebChatConversation(conversationPayload);
      setError(null);
      setConversation(conversation);
      localStorage.setItem("web_chat_conversation_id", conversation._id);
    } catch (err) {
      setError(err);
    }
  }, [api, intakeToken]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!conversation) {
        // Check if there is a conversation id in local storage. If so, try to load that conversation
        const conversationId = localStorage.getItem("web_chat_conversation_id");
        if (conversationId) {
          api.getConversation_noToastOnError(conversationId).then(
            (conversation) => {
              setConversation(conversation);
              setError(null);
            },
            (error) => {
              resetConversation();
            }
          );
        } else {
          resetConversation();
        }
      }
    }
  }, [isAuthenticated, conversation, api, resetConversation]);

  useEffect(() => {
    if (isAuthenticated) {
      api.getWebChatConfiguration().then((config) => {
        setWebChatConfig(config);
      });
    }
  }, [api, isAuthenticated]);

  return (
    <PageWrapperWithMenu>
      <div className={"prospera-chat-with-bot-page"}>
        <ChatWithBotWidget
          conversation={conversation}
          webChatConfiguration={webChatConfig}
          showResetButton={true}
          onResetClicked={resetConversation}
          showInternalEvents={true}
        />
        {error ? <Alert severity='error'>{error.message}</Alert> : null}
      </div>
    </PageWrapperWithMenu>
  );
}
