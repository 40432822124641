import React, { useEffect, useState } from "react";
import { useAPI } from "@/frontend/components/APIProvider";
import { useFormContext } from "react-hook-form";
import TopNav from "../../components/TopNav";
import Back from "../../components/Back";
import Next from "../../components/Next";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { Button } from "@/frontend/components/ui/button";
import RobotSpeak from "@/frontend/assets/img/robot-speak-1.webp";
import { LoaderCircle } from "lucide-react";
import { Badge } from "@/frontend/components/ui/badge";
import List from "../../components/List";
import Tool from "../../components/Tool";
import AgentServiceButton from "../../components/AgentServiceButton";
import DemoForm from "../../components/DemoForm";
import Success from "../../components/Success";
import { ScrollArea, ScrollBar } from "@/frontend/components/ui/scroll-area";
import AgentSuggestionForm from "../../components/AgentSuggestionForm";

const customization = [
  "Fully Customized AI to adjust workflows and automations",
  "Seamless Integration with your existing internal tools",
  "Industry-Specific Optimization with custom training data",
  "Higher Efficiency & Accuracy to reduces manual work",
];

export default function BusinessServiceSelection({
  steps,
  currentStep,
  isSubmitting,
  handlePrev,
}) {
  const api = useAPI();
  const { setValue, watch, getValues } = useFormContext();
  const watchAgentService = watch("agent_service");
  const [loadAgentServices, setLoadAgentServices] = useState(true);
  const [agentServices, setAgentServices] = useState([]);
  const [agentService, setAgentService] = useState(null);
  const [openDemoModal, setOpenDemoModal] = useState(false);
  const [bookingDemo, setBookingDemo] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [openAgentSuggestionModal, setOpenAgentSuggestionModal] =
    useState(false);
  const [suggestedSuccess, setSuggestedSuccess] = useState(false);
  const [suggestingAgent, setSuggestingAgent] = useState(false);

  const fetchAgentServices = async () => {
    try {
      const response = await api.getAgentServices({});
      setAgentServices(response);
    } catch (e) {
      console.error("Error fetching agent services:", e);
    } finally {
      setLoadAgentServices(false);
    }
  };

  const onBookDemo = async () => {
    setBookingDemo(true);
    const data = getValues();
    const payload = { ...data, request_demo: true };
    try {
      await api.saveOnboardingStatus(payload);
      setBookingSuccess(true);
      setOpenDemoModal(false);
    } catch (e) {
      setBookingSuccess(false);
      console.error("Error saving demo request:", e);
    } finally {
      setBookingDemo(false);
    }
  };

  const onSuggestAgent = async () => {
    setSuggestingAgent(true);
    const data = getValues();
    if (data?.suggested_agent_service?.name === "") {
      setOpenAgentSuggestionModal(false);
      return;
    }
    try {
      await api.saveOnboardingStatus(data);
      setOpenAgentSuggestionModal(false);
      setSuggestedSuccess(true);
    } catch (e) {
      setSuggestedSuccess(true);
      console.error("Error saving agent suggestion:", e);
    } finally {
      setSuggestingAgent(false);
    }
  };

  useEffect(() => {
    if (agentServices.length > 0) {
      const service = agentServices.find(
        (service) => service?.service_name === watchAgentService
      );
      if (service) {
        setAgentService(service);
      } else {
        setValue("agent_service", agentServices[0]?.service_name);
        setAgentService(agentServices[0]);
      }
    }
  }, [agentServices, setValue, watchAgentService]);

  useEffect(() => {
    fetchAgentServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='flex flex-col'>
        <TopNav steps={steps} currentStep={currentStep} />
        <Back handlePrev={handlePrev} />

        <div className='flex flex-col gap-10 justify-center items-center'>
          <h4 className='text-center text-gray-900 text-3xl font-bold'>
            <LocalTranslatedText text='Choose your first AI Agent' />
          </h4>
          <div className='grid grid-cols-12 gap-8 px-8 mx-auto w-full lg:w-1/2'>
            <div className='col-span-12 lg:col-span-3'>
              <ScrollArea className='lg:h-1/2 w-full'>
                {loadAgentServices ? (
                  <LoaderCircle className='animate-spin-slow' />
                ) : agentServices.length > 0 ? (
                  agentServices.map((service, idx) => (
                    <AgentServiceButton
                      key={idx}
                      service={service}
                      setAgentService={setAgentService}
                      setValue={setValue}
                      watchAgentService={watchAgentService}
                    />
                  ))
                ) : null}
                <ScrollBar orientation='vertical' />
              </ScrollArea>
              <Button
                className='mt-4 w-full border-slate-950'
                variant='outline'
                type='button'
                onClick={() => setOpenAgentSuggestionModal(true)}
              >
                <LocalTranslatedText text='Suggest an Agent' />
              </Button>
            </div>
            <div className='col-span-12 lg:col-span-9 border border-slate-300 rounded-md p-8 bg-indigo-50 flex flex-col gap-6'>
              <div className='flex gap-5 items-start'>
                <img
                  src={RobotSpeak}
                  alt='Robot Speak'
                  height={50}
                  width={50}
                  loading='lazy'
                />
                <div className='flex flex-col gap-3'>
                  <div className='flex flex-col gap-1'>
                    <div className='flex gap-3 items-center'>
                      <h4 className='text-black text-xl font-semibold'>
                        <LocalTranslatedText
                          text={agentService?.service_name}
                        />
                      </h4>
                      <Badge className='bg-indigo-100 text-primary hover:bg-indigo-100 text-xs'>
                        {agentService?.service_type}
                      </Badge>
                    </div>
                    <p className='text-slate-500 text-base font-normal leading-6'>
                      <LocalTranslatedText
                        text={agentService?.service_description}
                      />
                    </p>
                  </div>
                  <p className='flex items-center gap-4 text-base'>
                    <span className='font-semibold text-xl'>
                      <span className='text-black line-through'>
                        ${agentService?.price_per_month}/mo
                      </span>{" "}
                      <span className='text-rose-600'>$0</span>
                    </span>
                    <span className='text-rose-600'>
                      <LocalTranslatedText
                        text={`First ${agentService?.days_free_trial} days for free`}
                      />
                    </span>
                  </p>
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <h4 className='text-base font-semibold leading-6'>
                  <LocalTranslatedText text='Features' />
                </h4>
                <div className='flex flex-col gap-3 p-5 rounded-sm border border-slate-300 bg-white'>
                  {agentService?.service_features?.map((feature, index) => (
                    <List key={index} text={feature} />
                  ))}
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <h4 className='text-base font-semibold leading-6'>
                  <LocalTranslatedText text='Tools used' />
                </h4>
                <div className='flex items-center gap-3 flex-wrap'>
                  {agentService?.tools_used?.map((tool, index) => (
                    <Tool key={index} tool={tool} />
                  ))}
                </div>
              </div>
              <div className='flex flex-col gap-3 p-5 rounded-sm border border-slate-300 bg-white'>
                <h4 className='text-base font-semibold leading-6'>
                  <LocalTranslatedText text='Would you like to hire our specialist for customization?' />
                </h4>
                <p className='text-base leading-6 text-slate-500'>
                  <LocalTranslatedText text="ProsperaLab's Add-ons Package ensures that each AI agent is not just an out-of-the-box solution but a tailored tool that aligns perfectly with your company's unique needs." />
                </p>
                <div className='flex flex-col gap-3'>
                  {customization.map((custom, index) => (
                    <List key={index} text={custom} />
                  ))}
                </div>
                <span>
                  <Button
                    className='mt-5 bg-black hover:bg-black/80'
                    type='button'
                    onClick={() => setOpenDemoModal(true)}
                  >
                    <LocalTranslatedText text='Book a demo' />
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <Next isSubmitting={isSubmitting} />
      </div>
      {openDemoModal && (
        <DemoForm
          isSubmitting={bookingDemo}
          onClose={() => setOpenDemoModal(false)}
          onSubmit={onBookDemo}
          open={openDemoModal}
        />
      )}
      {bookingSuccess && (
        <Success
          title='Demo Request Success'
          message="Your demo request has been sent! Our team will review your details, and you'll receive a confirmation soon. Get ready to explore how AI can transform your business!"
          actionBtnText='Continue Onboarding'
          onClick={() => setBookingSuccess(false)}
          onClose={() => setBookingSuccess(false)}
          open={bookingSuccess}
        />
      )}
      {openAgentSuggestionModal && (
        <AgentSuggestionForm
          isSubmitting={suggestingAgent}
          open={openAgentSuggestionModal}
          onClose={() => setOpenAgentSuggestionModal(false)}
          onSubmit={onSuggestAgent}
        />
      )}
      {suggestedSuccess && (
        <Success
          message="Your agent suggestion has been saved! Our team will review your details, and you'll receive a confirmation soon. Get ready to explore how AI can transform your business!"
          title='Agent Suggestion Success'
          actionBtnText='Continue Onboarding'
          onClick={() => setSuggestedSuccess(false)}
          onClose={() => setSuggestedSuccess(false)}
          open={suggestedSuccess}
        />
      )}
    </>
  );
}
