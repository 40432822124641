import React, { useCallback } from "react";
import { useChangeFormData, useFormData } from "./FormRoot";
import Button from "@mui/material/Button";
import { randomString } from "../../utils/random";
import FormArrayItemRoot from "./FormArrayItemRoot";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import "./FormArraySection.scss";

export default function FormArraySection({
  arrayFormItemComponent,
  fieldName,
  defaultItemValue,
}) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();

  function createDefaultItem() {
    return {
      ...defaultItemValue,
      id: randomString(16),
    };
  }

  const handleAddArrayItem = useCallback(() => {
    const newItems = [...(formData[fieldName] ?? []), createDefaultItem()];

    changeFormData(fieldName, newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, changeFormData, fieldName]);

  const handleDeleteItemClick = useCallback(
    (index) => {
      const newItems = [...(formData[fieldName] ?? [])];
      newItems.splice(index, 1);

      changeFormData(fieldName, newItems);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData, changeFormData]
  );

  return (
    <div className='form-array-section'>
      {(formData[fieldName] ?? []).map((item, index) => {
        return (
          <FormArrayItemRoot
            key={item.id ?? index}
            editable={true}
            field={fieldName}
            itemIndex={index ?? 0}
            defaultItemValue={createDefaultItem()}
          >
            <div className='array-item'>
              {arrayFormItemComponent({
                item,
                index,
              })}
              <div className='delete-button'>
                <IconButton
                  onClick={handleDeleteItemClick.bind(index)}
                  color={"error"}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </FormArrayItemRoot>
        );
      })}
      <div className='add-item-button'>
        <Button onClick={handleAddArrayItem} variant={"outlined"}>
          Add Item
        </Button>
      </div>
    </div>
  );
}
