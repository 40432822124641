import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CustomDataObjectForm from "./CustomDataObjectForm";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function NewCustomDataObjectPage(props) {
  const api = useAPI();
  const navigate = useNavigate();
  const params = useParams();
  const [customDataSchema, setCustomDataSchema] = React.useState(null);

  useEffect(() => {
    api.getCustomDataSchema(params.customDataSchemaId).then((data) => {
      setCustomDataSchema(data);
    });
  }, [params.customDataSchemaId, api]);

  const handleCreateCustomDataObject = useCallback(
    async (customDataObjectData) => {
      customDataObjectData.custom_data_schema_id = customDataSchema.id;

      return await api
        .createNewCustomDataObject(customDataObjectData)
        .then((newObject) => {
          navigate(`/c/${params.customDataSchemaId}/${newObject.id}`);
        });
    },
    [api, navigate, customDataSchema, params.customDataSchemaId]
  );

  if (!customDataSchema) {
    return (
      <PageWrapperWithMenu>
        <PageHeader title={`New ${customDataSchema?.title ?? ''}`} />
        <div className='custom-data-object-edit-page'>
          <Card className='custom-data-object-edit-form-card'>
            <CardContent>
              <CircularProgress />
            </CardContent>
          </Card>
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`New ${customDataSchema?.title ?? ''}`} />
      <div className='custom-data-object-edit-page'>
        <Card className='custom-data-object-edit-form-card'>
          <CardContent>
            <CustomDataObjectForm
              customDataSchema={customDataSchema}
              initialCustomDataObject={{}}
              onCustomDataObjectSave={handleCreateCustomDataObject}
              saveButtonText={`Create ${customDataSchema?.title}`}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
