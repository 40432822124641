import React, {useCallback, useMemo, useState} from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DataObjectIcon from "@mui/icons-material/DataObject";
import AbcIcon from "@mui/icons-material/Abc";
import NumbersIcon from "@mui/icons-material/Numbers";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import "./CustomJSONView.scss";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
import SourceIcon from "@mui/icons-material/Source";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import CodeEditor from "./CodeEditor";

function RecursiveJSONView({
                             data,
                             level = 0,
                             field_name,
                             disable_collapse,
                             root,
                             openToLevel,
                           }) {
  field_name = field_name ?? "";
  root = root ?? "";

  if (disable_collapse === undefined) {
    disable_collapse = true;
  }

  let icon = null;
  let defaultOpen = true;
  if (data === null) {
    icon = (
      <ListItemIcon>
        <HighlightOffOutlinedIcon/>
      </ListItemIcon>
    );
  } else if (typeof data === "string") {
    icon = (
      <ListItemIcon>
        <AbcIcon/>
      </ListItemIcon>
    );
    defaultOpen = data.length < 512;
  } else if (typeof data === "boolean") {
    icon = (
      <ListItemIcon>
        <CheckBoxOutlinedIcon/>
      </ListItemIcon>
    );
  } else if (typeof data === "number") {
    icon = (
      <ListItemIcon>
        <NumbersIcon/>
      </ListItemIcon>
    );
  } else if (Array.isArray(data)) {
    icon = (
      <ListItemIcon>
        <FormatListBulletedIcon/>
      </ListItemIcon>
    );
    defaultOpen = level < openToLevel + 1;
  } else if (typeof data === "object") {
    icon = (
      <ListItemIcon>
        <DataObjectIcon/>
      </ListItemIcon>
    );
    defaultOpen = level < openToLevel;
  }

  const [open, setOpen] = useState(defaultOpen);
  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  if (typeof field_name === "number") {
    icon = (
      <ListItemAvatar>
        <Avatar sx={{width: 24, height: 24}}>{field_name}</Avatar>
      </ListItemAvatar>
    );
  }

  if (typeof data === "string") {
    return (
      <>
        {!disable_collapse ? (
          data.indexOf("\n") === -1 && data.length < 50 ? (
            <>
              <ListItem>
                {icon}
                <ListItemText
                  primary={
                    <span className={"field-value-group"}>
                      <span className={"field-value-name"}>{field_name}</span>
                      <span className={"field-value-operator"}>=</span>
                      <span className={"field-value-value"}>{data}</span>
                      <span className={"field-value-icon"}> </span>
                    </span>
                  }
                />
              </ListItem>
            </>
          ) : (
            <>
              <ListItemButton onClick={handleClick}>
                {icon}
                <ListItemText primary={
                  <span className={"field-value-expanding-text"}>
                    <span className={"field-value-name"}>{field_name}</span>
                    <span className={"field-value-operator"}>=</span>
                    <span className={"field-value-value"}>...</span>
                    <span className={"field-value-icon"}>
                      {open ? <ExpandLess/> : <ExpandMore/>}
                    </span>
                  </span>
                }/>
              </ListItemButton>

              <Collapse in={open} timeout='auto' unmountOnExit>
                <ListItemButton onClick={handleClick} disableGutters={true}>
                  <pre>{data}</pre>
                </ListItemButton>
              </Collapse>
            </>
          )
        ) : (
          <ListItem>
            {icon}
            <pre>{data}</pre>
          </ListItem>
        )}
      </>
    );
  } else if (typeof data === "number") {
    return (
      <ListItem>
        {icon}
        <ListItemText
          primary={
            <span className={"field-value-group"}>
              <span className={"field-value-name"}>{field_name}</span>
              <span className={"field-value-operator"}>=</span>
              <span className={"field-value-value"}>{data}</span>
              <span className={"field-value-icon"}> </span>
            </span>
          }
        />
      </ListItem>
    );
  } else if (data === null) {
    return (
      <ListItem>
        {icon}
        <ListItemText
          primary={
            <span className={"field-value-group"}>
              <span className={"field-value-name"}>{field_name}</span>
              <span className={"field-value-operator"}>=</span>
              <span className={"field-value-value"}>null</span>
              <span className={"field-value-icon"}></span>
            </span>
          }
        />
      </ListItem>
    );
  } else if (data === true) {
    return (
      <ListItem>
        {icon}
        <ListItemText
          primary={
            <span className={"field-value-group"}>
              <span className={"field-value-name"}>{field_name}</span>
              <span className={"field-value-operator"}>=</span>
              <span className={"field-value-value"}>true</span>
              <span className={"field-value-icon"}></span>
            </span>
          }
        />
      </ListItem>
    );
  } else if (data === false) {
    return (
      <ListItem>
        {icon}
        <ListItemText
          primary={
            <span className={"field-value-group"}>
              <span className={"field-value-name"}>{field_name}</span>
              <span className={"field-value-operator"}>=</span>
              <span className={"field-value-value"}>false</span>
              <span className={"field-value-icon"}></span>
            </span>
          }
        />
      </ListItem>
    );
  } else if (Array.isArray(data)) {

    const listItemText = <span className={"field-value-group"}>
      <span className={"field-value-name"}>{field_name}</span>
      <span className={"field-value-operator"}></span>
      <span className={"field-value-value"}></span>
      <span className={"field-value-icon"}>
        {!disable_collapse ? (
          open ? <ExpandLess/> : <ExpandMore/>
        ) : null
        }
      </span>
    </span>;

    return (
      <>
        {!disable_collapse ? (
          <ListItemButton onClick={handleClick}>
            {icon}
            <ListItemText primary={listItemText}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
          </ListItemButton>
        ) : (
          <ListItem>
            {icon}
            <ListItemText primary={listItemText}/>
          </ListItem>
        )}
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {data.map((value, keyIndex) => {
              return (
                <RecursiveJSONView
                  key={keyIndex}
                  data={value}
                  level={level + 1}
                  field_name={Number(keyIndex)}
                  openToLevel={openToLevel}
                  disable_collapse={false}
                  root={
                    level > 1
                      ? `${root}.${field_name}[${keyIndex}]`
                      : `${field_name}[${keyIndex}]`
                  }
                />
              );
            })}
            {data.length === 0 ? (
              <ListItem>
                <ListItemText primary={"empty list []"} inset/>
              </ListItem>
            ) : null}
          </List>
        </Collapse>
      </>
    );
  } else if (typeof data === "object" && data !== null) {
    const allKeys = Object.keys(data);
    let fieldKeys = [];
    let arrayKeys = [];
    let objectKeys = [];
    allKeys.forEach((key) => {
      if (typeof data[key] === "object" && data[key] !== null) {
        objectKeys.push(key);
      } else if (Array.isArray(data[key])) {
        arrayKeys.push(key);
      } else {
        fieldKeys.push(key);
      }
    });

    const listItemText = <span className={"field-value-group"}>
      <span className={"field-value-name"}>{field_name}</span>
      <span className={"field-value-operator"}></span>
      <span className={"field-value-value"}></span>
      <span className={"field-value-icon"}>
        {!disable_collapse ? (
          open ? <ExpandLess/> : <ExpandMore/>
        ) : null
        }
      </span>
    </span>;

    return (
      <List dense={true} className={"custom-json-view-object-root"}>
        {!disable_collapse ? (
          <ListItemButton onClick={handleClick}>
            {icon}
            <ListItemText primary={listItemText}/>
          </ListItemButton>
        ) : (
          <ListItem>
            {icon}
            <ListItemText primary={listItemText}/>
          </ListItem>
        )}
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' dense={true}>
            {fieldKeys.map((key) => {
              return (
                <RecursiveJSONView
                  key={key}
                  data={data[key]}
                  level={level + 1}
                  field_name={key}
                  disable_collapse={false}
                  root={level > 1 ? `${root}.${field_name}` : field_name}
                  openToLevel={openToLevel}
                />
              );
            })}
            {arrayKeys.concat(objectKeys).map((key) => (
              <RecursiveJSONView
                key={key}
                data={data[key]}
                level={level + 1}
                field_name={key}
                disable_collapse={false}
                root={level > 1 ? `${root}.${field_name}` : field_name}
                openToLevel={openToLevel}
              />
            ))}
            {Object.keys(data).length === 0 ? (
              <ListItem>
                <ListItemText primary={"empty object {}"} inset/>
              </ListItem>
            ) : null}
          </List>
        </Collapse>
      </List>
    );
  } else {
    return <ListItemText primary={`Unrecognized type ${data}`}/>;
  }
}

function ViewToggleButton({isTextView, onClick}) {
  return (
    <ToggleButton value='check' onChange={onClick} color={"primary"}>
      {!isTextView ? <DeveloperModeIcon/> : <SourceIcon/>}
    </ToggleButton>
  );
}

export function CustomJSONView({
                                 data,
                                 field_name,
                                 disable_collapse,
                                 root,
                                 openToLevel = 1,
                                 isDarkMode,
                               }) {
  const [isTextView, setIsTextView] = React.useState(false);

  const handleClick = useCallback(() => {
    setIsTextView(!isTextView);
  }, [isTextView]);

  const dataString = useMemo(() => {
    return JSON.stringify(data, null, 2);
  }, [data]);

  return (
    <div className={"custom-json-view"}>
      {isTextView ? (
        <CodeEditor
          height={"70vh"}
          defaultLanguage='json'
          value={dataString}
          readOnly={true}
          theme={isDarkMode ? "vs-dark" : "vs-light"}
        />
      ) : (
        <RecursiveJSONView
          data={data}
          level={0}
          field_name={field_name}
          disable_collapse={disable_collapse}
          root={root}
          openToLevel={openToLevel}
        />
      )}
      <ViewToggleButton isTextView={isTextView} onClick={handleClick}/>
    </div>
  );
}

export default CustomJSONView;
