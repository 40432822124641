import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import { useNavigate, useParams } from "react-router-dom";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { Button, CircularProgress, Stack } from "@mui/material";
import EditIntakeForm from "./components/EditIntakeForm";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";

export default function IntakeEditorPage() {
  const api = useAPI();
  const params = useParams();
  const [intake, setIntake] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleUpdateIntake = async (intake) => {
    return await api.updateIntake(intake);
  };

  const handleDeleteClicked = async () => {
    return await api.deleteIntake(intake.id).then(() => {
      navigate(`/intakes`);
    });
  };

  const getIntake = useCallback(async () => {
    setLoading(true);
    const intake = await api.getIntake(params?.intakeId);
    setIntake(intake);
    setLoading(false);
  }, [api, params]);

  useEffect(() => {
    if (!intake) {
      getIntake();
    }
  }, [getIntake, intake]);

  return (
    <PageWrapperWithMenu>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        mb={2}
      >
        <PageHeader title='Edit Intake' />

        <Stack direction='row' spacing={2}>
          <SpinnerButton
            variant='contained'
            color='error'
            className={"delete-button"}
            onClick={handleDeleteClicked}
          >
            Delete
          </SpinnerButton>
          {intake && intake?.questions?.length ? (
            <>
              <Button
                variant='contained'
                onClick={() =>
                  navigate(`/intakes/config/${intake.intake_token}`)
                }
              >
                Intake chat config
              </Button>
              <Button
                variant='contained'
                onClick={() => navigate(`/intakes/chat/${intake.intake_token}`)}
              >
                Chat with your agent
              </Button>
            </>
          ) : null}
        </Stack>
      </Stack>
      {loading ? (
        <CircularProgress />
      ) : intake ? (
        <EditIntakeForm
          intake={intake}
          handleUpdateIntake={handleUpdateIntake}
        />
      ) : null}
    </PageWrapperWithMenu>
  );
}
