import React from "react";

export function Calendly(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_156850_26'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='19'
        height='18'
      >
        <path
          d='M9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_156850_26)'>
        <path
          d='M0.0498047 -0.450195H18.9498V18.4498H0.0498047V-0.450195Z'
          fill='white'
        />
        <path
          d='M12.6461 11.1829C12.1326 11.6462 11.4814 12.2223 10.3042 12.2223H9.59037C8.73878 12.2223 7.96233 11.9092 7.4113 11.3582C6.8728 10.8072 6.57224 10.0557 6.57224 9.24173V8.27743C6.57224 7.46341 6.8728 6.712 7.4113 6.16097C7.96233 5.59742 8.73878 5.29686 9.59037 5.29686H10.2917C11.4689 5.29686 12.1201 5.87294 12.6336 6.3363C13.1721 6.81219 13.6354 7.22546 14.8752 7.22546C15.0631 7.22546 15.2509 7.21294 15.4388 7.17537V7.16284C15.3637 6.97499 15.276 6.79967 15.1758 6.62434L14.3493 5.18415C13.5853 3.8692 12.1827 3.05518 10.6549 3.05518H9.0143C7.48644 3.05518 6.08383 3.8692 5.3199 5.18415L4.49336 6.62434C3.72943 7.9393 3.72943 9.56734 4.49336 10.8823L5.3199 12.3225C6.08383 13.6374 7.48644 14.4515 9.0143 14.4515H10.6674C12.1952 14.4515 13.5979 13.6374 14.3618 12.3225L15.1883 10.8823C15.2885 10.707 15.3762 10.5316 15.4513 10.3438V10.3313C15.2635 10.3062 15.0756 10.2812 14.8878 10.2812C13.648 10.2937 13.1846 10.707 12.6461 11.1829Z'
          fill='#006BFF'
        />
        <path
          d='M10.3046 6.16113H9.59072C8.30081 6.16113 7.44922 7.08786 7.44922 8.26506V9.22936C7.44922 10.4191 8.30081 11.3333 9.59072 11.3333H10.292C12.1831 11.3333 12.0328 9.40469 14.8881 9.40469C15.1636 9.40469 15.4266 9.42974 15.6896 9.47983C15.7773 8.99142 15.7773 8.49048 15.6896 8.00207C15.4266 8.05217 15.1511 8.07721 14.8881 8.07721C12.0328 8.08973 12.1831 6.16113 10.3046 6.16113Z'
          fill='#006BFF'
        />
        <path
          d='M17.3301 10.1938C16.8417 9.8431 16.2907 9.59263 15.6895 9.49244V9.50496C15.6394 9.793 15.5643 10.0685 15.4516 10.3315C15.94 10.4066 16.4034 10.5945 16.8041 10.8951V10.9076C15.6144 14.7648 11.5318 16.9188 7.67456 15.7291C3.81736 14.5394 1.66334 10.4567 2.85306 6.59954C4.04278 2.74234 8.12541 0.588314 11.9826 1.77804C14.2869 2.49187 16.0903 4.29524 16.8041 6.59954V6.61206C16.4034 6.9001 15.94 7.08795 15.4516 7.16309C15.5643 7.42608 15.6394 7.71412 15.6895 7.98964V8.00216C16.2781 7.88945 16.8417 7.6515 17.3301 7.30085C17.7935 6.95019 17.7058 6.56197 17.6307 6.33655C16.3032 2.01598 11.7196 -0.401032 7.39905 0.926447C3.07848 2.25393 0.661468 6.83748 1.98895 11.158C3.31643 15.4786 7.89999 17.8956 12.2206 16.5682C14.8004 15.7667 16.8292 13.7504 17.6307 11.158C17.7058 10.9326 17.806 10.5444 17.3301 10.1938Z'
          fill='#006BFF'
        />
        <path
          d='M15.4518 7.16318C15.264 7.18822 15.0761 7.21327 14.8883 7.21327C13.6485 7.21327 13.1851 6.8 12.6466 6.32411C12.1206 5.86074 11.4819 5.28467 10.3047 5.28467H9.59089C8.7393 5.28467 7.96285 5.59775 7.41182 6.14878C6.87331 6.69981 6.57275 7.45121 6.57275 8.26523V9.22953C6.57275 10.0436 6.87331 10.795 7.41182 11.346C7.96285 11.9095 8.7393 12.2101 9.59089 12.2101H10.2922C11.4694 12.2101 12.1206 11.634 12.6341 11.1707C13.1726 10.6948 13.6359 10.2815 14.8758 10.2815C15.0636 10.2815 15.2515 10.294 15.4393 10.3316C15.552 10.0686 15.6272 9.78057 15.6773 9.50505V9.49253C15.4143 9.44243 15.1387 9.41739 14.8758 9.41739C12.0204 9.41739 12.1707 11.346 10.2797 11.346H9.59089C8.30098 11.346 7.44939 10.4193 7.44939 9.22953V8.26523C7.44939 7.07551 8.30098 6.1613 9.59089 6.1613H10.2922C12.1832 6.1613 12.0329 8.08991 14.8883 8.08991C15.1638 8.08991 15.4268 8.06486 15.6898 8.01476V8.00224C15.6397 7.71421 15.5645 7.43869 15.4518 7.16318Z'
          fill='#0AE8F0'
        />
        <path
          d='M15.4518 7.16318C15.264 7.18822 15.0761 7.21327 14.8883 7.21327C13.6485 7.21327 13.1851 6.8 12.6466 6.32411C12.1206 5.86074 11.4819 5.28467 10.3047 5.28467H9.59089C8.7393 5.28467 7.96285 5.59775 7.41182 6.14878C6.87331 6.69981 6.57275 7.45121 6.57275 8.26523V9.22953C6.57275 10.0436 6.87331 10.795 7.41182 11.346C7.96285 11.9095 8.7393 12.2101 9.59089 12.2101H10.2922C11.4694 12.2101 12.1206 11.634 12.6341 11.1707C13.1726 10.6948 13.6359 10.2815 14.8758 10.2815C15.0636 10.2815 15.2515 10.294 15.4393 10.3316C15.552 10.0686 15.6272 9.78057 15.6773 9.50505V9.49253C15.4143 9.44243 15.1387 9.41739 14.8758 9.41739C12.0204 9.41739 12.1707 11.346 10.2797 11.346H9.59089C8.30098 11.346 7.44939 10.4193 7.44939 9.22953V8.26523C7.44939 7.07551 8.30098 6.1613 9.59089 6.1613H10.2922C12.1832 6.1613 12.0329 8.08991 14.8883 8.08991C15.1638 8.08991 15.4268 8.06486 15.6898 8.01476V8.00224C15.6397 7.71421 15.5645 7.43869 15.4518 7.16318Z'
          fill='#0AE8F0'
        />
      </g>
    </svg>
  );
}
