import * as React from 'react';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import VoiceSettingSlider from "./VoiceSettingSlider";


export default function MaxTimeAfterUtteranceEndMs({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Max Time After Utterance End Milliseconds"
      description="The maximum number of milliseconds to wait after the end of an utterance before processing the response"
      id="max-time-after-utterance-end-ms-slider"
      min={0}
      max={2000}
      step={50}
      icon={<PauseCircleOutlineIcon />}
      marks={[
        { value: 0, label: '0' },
        { value: 500, label: '500' },
        { value: 1000, label: '1000' },
        { value: 1500, label: '1500' },
        { value: 2000, label: '2000' }
      ]}
      onChange={onChange}
      value={value}
    />
  );
}
