import React from "react";
import AuthPageWrapper from "@/frontend/wrappers/AuthPageWrapper";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SignInSchema } from "@/frontend/zod-schema/auth";
import FormProvider from "@/frontend/components/hook-form/form-provider";
import HFTextField from "@/frontend/components/hook-form/hf-text-field";
import { LoadingButton } from "@/frontend/components/ui/loading-button";
import Or from "@/frontend/auth/Or";
import { GoogleAuthButton } from "@/frontend/components/ui/google-auth-button";
import { Button } from "@/frontend/components/ui/button";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: zodResolver(SignInSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    // TODO: Implement login
  });

  return (
    <AuthPageWrapper
      title='Join the AI Agent Revolution Today'
      items={[
        "Provide your own logo, branding, and set your own prices.",
        "No AI or technical skills required our expert AI engineers will handle it for you.",
        "Launch your AI Agent in under a month.",
      ]}
    >
      <div className='w-full px-8 md:px-0 md:w-1/2 flex flex-col gap-6'>
        <h4 className='text-3xl text-gray-900 font-bold'>
          <LocalTranslatedText text='Sign in to your account' />
        </h4>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <div className='flex flex-col gap-6 w-full'>
            <div className='w-full'>
              <HFTextField
                type='email'
                fieldName='email'
                label={<LocalTranslatedText text='Email Address*' />}
                placeholder='Your email ...'
              />
            </div>
            <div className='w-full'>
              <HFTextField
                type='password'
                fieldName='password'
                label={<LocalTranslatedText text='Password*' />}
                placeholder='Your password ...'
              />
            </div>
            <LoadingButton
              type='submit'
              variant='default'
              size='lg'
              loading={false}
            >
              <LocalTranslatedText text='Sign In' />
            </LoadingButton>
          </div>
        </FormProvider>
        <Or className='mt-4 mb-2' />
        <GoogleAuthButton
          type='button'
          variant='outline'
          size='lg'
          className='border-slate-950'
        >
          <LocalTranslatedText text='Sign in with Google' />
        </GoogleAuthButton>
        <p className='font-medium text-base text-muted-foreground leading-6'>
          <LocalTranslatedText text="Don't have an account?" />{" "}
          <Button
            variant='link'
            size='default'
            className='text-base p-0'
            onClick={() => navigate("/auth/sign-up")}
          >
            <LocalTranslatedText text='Sign Up' />
          </Button>
        </p>
      </div>
    </AuthPageWrapper>
  );
}
