import React, { memo } from "react";
import { schemaTypes, formInputTypes } from "../utils/schema-utils";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CommaSeparatedInput from "../../form/inputs/CommaSeparatedInput";

function RenderPrimitiveFields({
  value,
  fullPath,
  mode,
  handleFieldUpdate,
  handleCompoundKeyUpdate,
  canChangeType,
  disabled,
}) {
  const isOptions =
    value.form_input_type === "dropdown" ||
    value.form_input_type === "radio" ||
    value.form_input_type === "checkboxes";

  return (
    <Grid container spacing={2} sx={{ mb: 1, pb: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.24)' }}>
      {canChangeType && (
          <>
            <Grid item xs={3}>
              <FormControl fullWidth size='small'>
                <InputLabel>Type</InputLabel>
                <Select
                  label='Type'
                  value={value.type || ""}
                  onChange={(e) =>
                    handleFieldUpdate(fullPath, "type", e.target.value)
                  }
                  size='small'
                  disabled={disabled}
                >
                  {schemaTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size='small'>
                <InputLabel>Form Input Type</InputLabel>
                <Select
                  label='Form Input Type'
                  value={value.form_input_type || ""}
                  onChange={(e) =>
                    handleFieldUpdate(fullPath, "form_input_type", e.target.value)
                  }
                  size='small'
                  disabled={disabled}
                >
                  {formInputTypes.map((type) => {
                    const label = type.replace("_", " ");
                    return (
                      <MenuItem value={type} key={type}>
                        {label.charAt(0).toUpperCase() + label.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </>
      )}
      <Grid item xs={5}>
        <TextField
          fullWidth
          label='Title'
          size='small'
          value={value.title || ""}
          onChange={(e) => handleFieldUpdate(fullPath, "title", e.target.value)}
          disabled={disabled}
        />
      </Grid>

      {value.type === "string" && value.form_input_type === "text" && (
            <>
              <Grid item xs={3}/>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  label='Multiline'
                  control={
                    <Checkbox
                      size='small'
                      checked={Boolean(value.multiline)}
                      onChange={(e) =>
                        handleFieldUpdate(
                          fullPath,
                          "multiline",
                          e.target.checked
                        )
                      }
                      disabled={disabled}
                    />
                  }
                  sx={{ marginRight: 1 }}
                />
              {value.multiline && (
                  <TextField
                    label='Rows'
                    type='number'
                    size='small'
                    value={value.rows ?? 1}
                    onChange={(e) => {
                      const parsed = parseInt(e.target.value, 10);
                      handleFieldUpdate(fullPath, "rows", parsed || 1);
                    }}
                    disabled={disabled}
                    inputProps={{ min: 1 }}
                    sx={{ width: '80px' }}
                  />
              )}
              </Grid>
            </>
          )}

      {/* New row */}
      <Grid item xs={12} sx={{ height: '0px', paddingTop: '0px!important' }}></Grid>

      <Grid item xs={3}>
        <TextField
          fullWidth
          label='Description'
          size='small'
          value={value.description || ""}
          onChange={(e) =>
            handleFieldUpdate(fullPath, "description", e.target.value)
          }
          multiline
          rows={3}
          disabled={disabled}
        />
      </Grid>

      {mode === "full" && (
        <>
          <Grid item xs={3}>
            <FormControlLabel
              label='Required'
              control={
                <Checkbox
                  size='small'
                  checked={Boolean(value.compound_key) || Boolean(value.required)}
                  onChange={(e) =>
                    handleFieldUpdate(fullPath, "required", e.target.checked)
                  }
                  disabled={disabled || Boolean(value.compound_key)}
                />
              }
            /><br/>
            <FormControlLabel
              label='Compound Key'
              control={
                <Checkbox
                  size='small'
                  checked={Boolean(value.compound_key)}
                  onChange={(e) => {
                    handleCompoundKeyUpdate(fullPath, e.target.checked);
                  }}
                  disabled={disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              fullWidth
              label='Default Value'
              size='small'
              value={value.default || ""}
              onChange={(e) =>
                handleFieldUpdate(fullPath, "default", e.target.value)
              }
              disabled={disabled}
            /><br/><br/>
            <TextField
              fullWidth
              label='Placeholder'
              size='small'
              value={value.placeholder || ""}
              onChange={(e) =>
                handleFieldUpdate(fullPath, "placeholder", e.target.value)
              }
              disabled={disabled}
            />
          </Grid>

          {/* New row */}
          <Grid item xs={12} sx={{ height: '0px', paddingTop: '0px!important' }}></Grid>

          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
          {value.type !== "object" && (
                <FormControlLabel
                  label='Show in Table'
                  control={
                    <Checkbox
                      size='small'
                      checked={Boolean(value.show_in_table_view)}
                      onChange={(e) =>
                        handleFieldUpdate(
                          fullPath,
                          "show_in_table_view",
                          e.target.checked
                        )
                      }
                      disabled={disabled}
                    />
                  }
                />
          )}
          {value.show_in_table_view && (
              <TextField
                label='Table Order'
                type='number'
                size='small'
                value={value.table_view_order ?? 0}
                onChange={(e) => {
                  const parsed = parseInt(e.target.value, 10);
                  handleFieldUpdate(fullPath, "table_view_order", parsed || 0);
                }}
                disabled={disabled}
                inputProps={{ min: 0 }}
                sx={{ width: '120px', ml: 2 }}
              />
          )}
          </Grid>
          {isOptions && (
            <>
              <Grid item xs={4}>
                <CommaSeparatedInput
                  size='small'
                  onChange={(values) =>
                    handleFieldUpdate(fullPath, "options", values)
                  }
                  values={value.options || []}
                  placeholder='Enter options'
                  label='Options'
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <CommaSeparatedInput
                  size='small'
                  onChange={(values) =>
                    handleFieldUpdate(fullPath, "optionLabels", values)
                  }
                  values={value.optionLabels || []}
                  placeholder='Enter options labels'
                  label='Options Labels'
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}

export default memo(RenderPrimitiveFields);
