import * as React from 'react';
import {useChangeFormData, useFormData} from "../FormRoot";
import {useCallback} from "react";
import TableElementLookupSelector from "../../TableElementLookupSelector";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export default function TableElementLookupSelectorInput({
                                                            id,
                                                            label,
                                                            fieldName,
                                                            idFieldName,
                                                            searchFieldName,
                                                            displayFieldName,
                                                            getSuggestions,
                                                            getSingleItem,
                                                            multiple,
                                                        }) {
    const formData = useFormData();
    const changeFormData = useChangeFormData();

    const handleChange = useCallback((newValue) => {
        changeFormData(fieldName, newValue);
    }, [fieldName, changeFormData, multiple]);

    return (
      <div className={"form-input"}>
        <Typography variant={"label"}>
          <LocalTranslatedText language={"en"} text={label} />
        </Typography>

        <TableElementLookupSelector
            value={formData[fieldName]}
            onChange={handleChange}
            id={id}
            idFieldName={idFieldName}
            searchFieldName={searchFieldName}
            displayFieldName={displayFieldName}
            getSuggestions={getSuggestions}
            getSingleItem={getSingleItem}
            multiple={multiple}
        />
      </div>
    );
}