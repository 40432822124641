import React from "react";
import TopNav from "../../components/TopNav";
import Back from "../../components/Back";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import HFTextField from "@/frontend/components/hook-form/hf-text-field";
import Next from "../../components/Next";
import HFUpload from "@/frontend/components/hook-form/hf-upload";
import { useFormContext } from "react-hook-form";
import HFCheckbox from "@/frontend/components/hook-form/hf-checkbox";

export default function EssentialInfo({
  steps,
  currentStep,
  isSubmitting,
  handlePrev,
}) {
  const { watch } = useFormContext();
  const document = watch("document");
  const isDocumentURL = watch("is_document_url");

  return (
    <div className='flex flex-col'>
      <TopNav steps={steps} currentStep={currentStep} />
      <Back handlePrev={handlePrev} />
      <div className='flex flex-col gap-10 justify-center items-center'>
        <h4 className='text-center text-gray-900 text-3xl font-bold'>
          <LocalTranslatedText text='Fill your essential information' />
        </h4>
        <div className='px-8 mx-auto w-full lg:w-1/3 flex flex-col gap-6'>
          <div className='w-full'>
            <HFTextField
              label='Your Website *'
              fieldName='company_website_url'
              placeholder='http://acme.com'
            />
          </div>
          <div className='w-full'>
            <HFCheckbox
              fieldName='is_document_url'
              label={
                isDocumentURL ? "Upload File Instead" : "Enter File URL Instead"
              }
              center
            />
          </div>
          {isDocumentURL ? (
            <div className='w-full'>
              <HFTextField
                label='File URL'
                fieldName='document_url'
                placeholder='http://acme.com/document.pdf'
              />
            </div>
          ) : (
            <div className='w-full flex flex-col gap-2'>
              <HFUpload
                uploadFileFunc='uploadOnboardingStatusDocument'
                acceptedFileTypes={[
                  "application/pdf",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ]}
                multiple={false}
                fieldName='document'
                label='Product/Service Information (Optional)'
              />
              {watch("document") && (
                <p className='text-sm text-muted-foreground font-medium'>
                  {document?.file_name}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <Next isSubmitting={isSubmitting} />
    </div>
  );
}
