import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../../../frontend/components/APIProvider";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import "./KnowledgeChunkViewPage.scss";
import KnowledgeChunkViewer from "./KnowledgeChunkViewer";


export default function KnowledgeChunkViewPage(props) {
    const api = useAPI();
    const params = useParams();
    const [knowledgeChunk, setKnowledgeChunk] = useState(null);

    React.useEffect(() => {
        if (!knowledgeChunk) {
            api.getKnowledgeChunk(params?.knowledgeChunkId).then((knowledgeChunk) => {
                setKnowledgeChunk(knowledgeChunk);
            });
        }
    }, [knowledgeChunk, params?.knowledgeChunkId, api]);

    if (!knowledgeChunk) {
        return <PageWrapperWithMenu>
            <PageHeader title={`View Knowledge Chunk`}/>
            <div className="view-knowledge-chunk-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`View Knowledge Chunk`}/>
        <div className="view-knowledge-chunk-page">
            <Card className="view-knowledge-chunk-form-card">
                <CardContent>
                    <KnowledgeChunkViewer
                        knowledgeChunk={knowledgeChunk}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}
