import { PageWrapperWithMenu } from "../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationsTable } from "./ConversationsTable";
import Paper from "@mui/material/Paper";
import "./ConversationsListPage.scss";
import { PageHeader } from "../../frontend/elements/PageHeader";

export default function ConversationsListPage() {
  const navigate = useNavigate();

  const handleConversationClicked = useCallback(
    (conversation) => {
      navigate(`/conversations/${conversation.id}`);
    },
    [navigate]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Conversations"}
        subtitle={"View all conversations made with the agents."}
      />
      <div className='conversation-list-page'>
        <Paper className={"main-page-contents"}>
          <ConversationsTable
            onConversationClicked={handleConversationClicked}
          />
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
