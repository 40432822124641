import Paper from "@mui/material/Paper";
import { useCallback } from "react";
import { GeneratedDocumentTable } from "./GeneratedDocumentTable";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../frontend/components/APIProvider";

export default function GeneratedDocumentListPage() {
  const navigate = useNavigate();
  const api = useAPI();

  const handleGeneratedDocumentClicked = useCallback(
    (documentTemplate) => {
      navigate(`/generated_document/${documentTemplate.id}`);
    },
    [navigate]
  );

  const handleNewGeneratedDocumentClicked = useCallback(async () => {
    const newGeneratedDocument = {};

    return api
      .createNewGeneratedDocument(newGeneratedDocument)
      .then((newGeneratedDocumentData) => {
        navigate(`/generated_document/${newGeneratedDocumentData.id}`);
      });
  }, [api, navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Generated Document"}
        subtitle={"View and manage generated documents."}
      >
        <WhiteButton onClick={handleNewGeneratedDocumentClicked}>
          Add New Generated Document
        </WhiteButton>
      </PageHeader>
      <div className='generated-document-list-page'>
        <Paper className={"main-page-contents"}>
          <GeneratedDocumentTable
            onGeneratedDocumentClicked={handleGeneratedDocumentClicked}
          />
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
