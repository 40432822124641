import React, { useCallback, useState } from "react";
import "./SchemaEditor.scss";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SchemaLayout from "./SchemaLayout";
import CodeLayout from "./CodeLayout";
import { getDefaultObject, isNotEmptyObject } from "./utils/schema-utils";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import { produce } from "immer";
import classnames from "classnames";

export default function SchemaEditor({
  mode,
  title,
  defaultLayout,
  initialSchemaData,
  onChange,
  disabled,
  options,
}) {
  const [layout, setLayout] = useState(defaultLayout);
  const [schema, setSchema] = useState(() => {
    if (initialSchemaData && isNotEmptyObject(initialSchemaData)) {
      return {
        ...getDefaultObject(mode),
        ...initialSchemaData,
      };
    } else {
      return getDefaultObject(mode);
    }
  });

  const handleSchemaMetadataChange = useCallback(
    (field, value) => {
      setSchema((currentSchema) => {
        const updatedSchema = produce(currentSchema, (draft) => {
          draft[field] = value;
        });
        onChange && onChange(updatedSchema);
        return updatedSchema;
      });
    },
    [onChange]
  );

  const handleAddField = useCallback(
    (parentPath) => {
      setSchema((currentSchema) => {
        const updatedSchema = produce(currentSchema, (draft) => {
          let pointer = draft;
          parentPath.forEach((key) => {
            pointer = pointer[key];
          });

          const newKey = `key-${Object.keys(pointer).length}`;
          pointer[newKey] = {
            ...getDefaultObject(mode, true),
            type: "string",
          };
        });
        onChange && onChange(updatedSchema);
        return updatedSchema;
      });
    },
    [mode, onChange]
  );

  const handleRemoveField = useCallback(
    (parentPath, key) => {
      setSchema((currentSchema) => {
        const updatedSchema = produce(currentSchema, (draft) => {
          let pointer = draft;
          parentPath.forEach((key) => {
            pointer = pointer[key];
          });
          delete pointer[key];
        });
        onChange && onChange(updatedSchema);
        return updatedSchema;
      });
    },
    [onChange]
  );

  const handleFieldUpdate = useCallback(
    (fullPath, field, value) => {
      setSchema((currentSchema) => {
        const updatedSchema = produce(currentSchema, (draft) => {
          let pointer = draft;
          fullPath.slice(0, -1).forEach((key) => {
            pointer = pointer[key];
          });
          const lastKey = fullPath[fullPath.length - 1];
          pointer[lastKey][field] = value;

          if (field === "type") {
            if (value === "object") {
              pointer[lastKey].properties = {};
              delete pointer[lastKey].items;
            } else if (value === "array") {
              pointer[lastKey].items = { type: "string" };
              delete pointer[lastKey].properties;
            } else {
              delete pointer[lastKey].properties;
              delete pointer[lastKey].items;
            }
          }
        });
        onChange && onChange(updatedSchema);
        return updatedSchema;
      });
    },
    [onChange]
  );

  const handleKeyRename = useCallback(
    (path, oldKey, newKey) => {
      if (!newKey || newKey === oldKey) return;
      setSchema((currentSchema) => {
        const updatedSchema = produce(currentSchema, (draft) => {
          let pointer = draft;
          path.forEach((key) => {
            pointer = pointer[key];
          });
          pointer[newKey] = pointer[oldKey];
          delete pointer[oldKey];
        });
        onChange && onChange(updatedSchema);
        return updatedSchema;
      });
    },
    [onChange]
  );

  const handleCompoundKeyUpdate = useCallback(
    (fullPath, isChecked) => {
      setSchema((currentSchema) => {
        const updatedSchema = produce(currentSchema, (draft) => {
          let pointer = draft;
          fullPath.slice(0, -1).forEach((key) => {
            pointer = pointer[key];
          });
          const lastKey = fullPath[fullPath.length - 1];
          
          pointer[lastKey].compound_key = isChecked;
          
          if (isChecked) {
            pointer[lastKey].required = true;
          }
        });
        onChange && onChange(updatedSchema);
        return updatedSchema;
      });
    },
    [onChange]
  );

  return (
    <div
      className={classnames("schema-editor-container", {
        "no-box-shadow": !options?.boxShadow,
        "no-padding": !options?.padding,
        "no-margin": !options?.margin,
      })}
    >
      <div className='schema-editor-toggler'>
        <Tooltip
          title={
            layout === "schema"
              ? "Switch to Code Editor"
              : "Switch to Schema Editor"
          }
          placement='left'
        >
          <IconButton
            className='toggle-button'
            onClick={() => setLayout(layout === "schema" ? "code" : "schema")}
          >
            {layout === "schema" ? <CodeIcon /> : <ListAltIcon />}
          </IconButton>
        </Tooltip>
      </div>
      <div className='editor-area'>
        <h4 className='title'>
          <LocalTranslatedText
            language={"en"}
            text={title || "Schema Editor"}
          />
        </h4>
        {layout === "schema" ? (
          <div className='schema-editor'>
            <SchemaLayout
              mode={mode}
              schema={schema}
              handleSchemaMetadataChange={handleSchemaMetadataChange}
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              handleFieldUpdate={handleFieldUpdate}
              handleKeyRename={handleKeyRename}
              handleCompoundKeyUpdate={handleCompoundKeyUpdate}
              disabled={disabled}
              options={options}
            />
          </div>
        ) : (
          <CodeLayout
            schema={schema}
            setSchema={setSchema}
            readOnly={disabled}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
}

SchemaEditor.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.oneOf(["simplified", "full"]).isRequired,
  defaultLayout: PropTypes.oneOf(["schema", "code"]).isRequired,
  initialSchemaData: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    padding: PropTypes.bool,
    margin: PropTypes.bool,
    boxShadow: PropTypes.bool,
    metadataComponent: PropTypes.bool,
  }),
};
