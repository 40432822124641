import React from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "@/frontend/lib/utils";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/frontend/components/ui/form";

export default function HFButton({ fieldName, options, ParentIcon, ...rest }) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          <FormControl>
            <div className="flex flex-col gap-6 w-full">
              {options.map((option) => (
                <button
                  {...field}
                  className={cn(
                    "group w-full text-left px-7 py-6 border border-slate-300 hover:border-primary bg-background hover:bg-indigo-50 transition-all ease-in-out duration-300 delay-100 rounded-xs shadow-button",
                    error && "border-danger",
                    field.value === option.value &&
                      "border-primary bg-indigo-50"
                  )}
                  key={option.value}
                  type="button"
                  onClick={() => field.onChange(option.value)}
                  {...rest}
                >
                  <div className="flex gap-7 items-start">
                    <div className="w-[52px] h-[52px] relative">
                      <ParentIcon
                        className={cn(
                          "text-primary w-full h-full shrink-0 transition-colors duration-300 ease-in-out delay-100 group-hover:fill-primary",
                          field.value === option.value && "fill-primary"
                        )}
                      />
                      <div className="absolute inset-0 top-1 flex items-center justify-center">
                        <option.icon
                          className={cn(
                            "w-6 h-6 text-primary shrink-0 transition-colors duration-300 group-hover:text-white",
                            field.value === option.value && "text-white"
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <h4 className="text-black text-lg leading-normal tracking-[0.09px] font-semibold">
                        {option.label}
                      </h4>
                      {option?.description && (
                        <p className="tracking-[0.08px] leading-6 text-muted-foreground text-base">
                          {option.description}
                        </p>
                      )}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
