import React, { useCallback, useState } from "react";
import {
  extractLinks,
  getParentId,
  moveItemInNestedArray,
  reorderItemsInNestedArray,
} from "./utils";
import { rectIntersection, DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import DraggableMenuItem from "./DraggableMenuItem";
import { Box, Button, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { randomString } from "../utils/random";

export default function NestedMenuDragAndDrop({
  initialValues,
  onSubmitClick,
  isSubmitting,
  saveSuccess,
}) {
  const [items, setItems] = useState(initialValues?.menu_items || []);

  const allMenuLinks = extractLinks(initialValues?.menu_items || []);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (!over || active.id === over.id) return;

      setItems((prevItems) => {
        const activeParentId = getParentId(prevItems, active.id);
        const overParentId = getParentId(prevItems, over.id);
        if (activeParentId === overParentId) {
          // Sort within the same level
          return reorderItemsInNestedArray(prevItems, active.id, over.id);
        } else {
          // Move item to another parent
          return moveItemInNestedArray(prevItems, active.id, over.id);
        }
      });
    },
    [setItems]
  );

  const handleSortEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (!over || active.id === over.id) return;

      setItems((prev) => reorderItemsInNestedArray(prev, active.id, over.id));
    },
    [setItems]
  );

  const onAddMenuItem = useCallback(() => {
    const newMenuItem = {
      id: randomString(16),
      title: "Enter Menu Name",
      link: "",
      icon: "",
      is_hidden: false,
      children: [],
      is_admin: false,
    };
    setItems((prev) => [...prev, newMenuItem]);
  }, [setItems]);

  const onRemoveMenuItem = useCallback(
    (id) => {
      setItems((prev) => prev.filter((item) => item.id !== id));
    },
    [setItems]
  );

  const handleSave = useCallback(() => {
    const payload = {
      ...initialValues,
      menu_items: items,
    };
    onSubmitClick(payload);
  }, [items, initialValues, onSubmitClick]);

  return (
    <Box display='flex' flexDirection='column'>
      <DndContext
        onDragEnd={handleDragEnd}
        collisionDetection={rectIntersection}
      >
        <SortableContext items={items.map((item) => item.id)}>
          {items.map((item) => (
            <DraggableMenuItem
              key={item.id}
              menuItem={item}
              onMove={handleSortEnd}
              onRemoveMenuItem={() => onRemoveMenuItem(item.id)}
              setItems={setItems}
              allMenuLinks={allMenuLinks}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        mt={4}
      >
        <Button
          type='button'
          variant='outlined'
          color='success'
          onClick={onAddMenuItem}
          startIcon={<AddIcon />}
        >
          Add Menu Item
        </Button>
        <Button
          type='button'
          variant='contained'
          color='primary'
          disabled={isSubmitting}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={20} />
            ) : saveSuccess ? (
              <CheckCircleIcon />
            ) : (
              <SaveIcon />
            )
          }
          onClick={handleSave}
        >
          {saveSuccess ? "Saved!" : "Save Configuration"}
        </Button>
      </Box>
    </Box>
  );
}
