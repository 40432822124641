import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, IconButton, Toolbar } from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  Code,
  StrikethroughS,
  Link,
  Image,
  FormatIndentIncrease,
  FormatIndentDecrease,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify,
} from "@mui/icons-material";
import "./RichEditor.scss";

const CustomToolbar = () => {
  return (
    <Toolbar
      sx={{
        borderColor: "divider",
        mb: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        maxWidth: "100%",
        overflowX: "auto",
        backgroundColor: "#f5f5f5",
        padding: "4px",
        borderRadius: "4px",
      }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {[
          { className: "ql-bold", icon: <FormatBold /> },
          { className: "ql-italic", icon: <FormatItalic /> },
          { className: "ql-underline", icon: <FormatUnderlined /> },
          { className: "ql-strike", icon: <StrikethroughS /> },
          {
            className: "ql-list",
            value: "bullet",
            icon: <FormatListBulleted />,
          },
          {
            className: "ql-list",
            value: "ordered",
            icon: <FormatListNumbered />,
          },
          { className: "ql-blockquote", icon: <FormatQuote /> },
          { className: "ql-code-block", icon: <Code /> },
          { className: "ql-link", icon: <Link /> },
          { className: "ql-image", icon: <Image /> },
          {
            className: "ql-indent",
            value: "+1",
            icon: <FormatIndentIncrease />,
          },
          {
            className: "ql-indent",
            value: "-1",
            icon: <FormatIndentDecrease />,
          },
          { className: "ql-align", value: "", icon: <FormatAlignLeft /> },
          {
            className: "ql-align",
            value: "center",
            icon: <FormatAlignCenter />,
          },
          { className: "ql-align", value: "right", icon: <FormatAlignRight /> },
          {
            className: "ql-align",
            value: "justify",
            icon: <FormatAlignJustify />,
          },
        ].map(({ className, value, icon }, index) => (
          <IconButton
            key={index}
            className={className}
            value={value}
            sx={{
              "&:hover": {
                backgroundColor: "#ddd",
                "& svg": {
                  color: "#f8bc7d", // Change icon color on hover
                },
              },
            }}
          >
            {icon}
          </IconButton>
        ))}
      </Box>
    </Toolbar>
  );
};

const RichEditor = ({ value, onChange, id, placeholder, rows = 5 }) => {
  const [mount, setMount] = useState(false);

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
  };

  useEffect(() => {
    setMount(true);
  }, []);

  return (
    <Box
      sx={{
        border: "1px solid #D1D9E0",
        borderRadius: "8px",
        padding: "4px",
        backgroundColor: "#fff",
      }}
    >
      <div id={id}>
        <CustomToolbar />
      </div>
      {mount && (
        <ReactQuill
          theme='snow'
          value={value}
          onChange={onChange}
          modules={modules}
          sx={{ border: "none" }}
          style={{ minHeight: `${rows * 20}px` }}
          placeholder={placeholder ?? " Start here . . ."}
        />
      )}
    </Box>
  );
};

export default RichEditor;
