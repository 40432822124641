import "./CalendarForm.scss";
import React, {useCallback} from 'react';
import {SpinnerButton} from "@/frontend/elements/SpinnerButton";
import {Card, TextField} from "@mui/material";
import {GoogleCalendarIntegrationWidget} from "../third_party_calendar/GoogleCalendarIntegrationWidget";
import {MicrosoftOutlookIntegrationWidget} from "../third_party_calendar/MicrosoftOutlookIntegrationWidget";
import {useFrontendConfiguration} from "@/frontend/components/FrontendConfigurationProvider";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export const CalendarForm = ({calendar, onChange, onSaveClicked, showDeleteButton, onDeleteClicked}) => {
    const handleChange = useCallback((e) => {
        const newCalendar = {
            ...calendar,
            [e.target.name]: e.target.value
        };

        onChange(newCalendar);
    }, [calendar, onChange]);
    const enabledFeatures = useFrontendConfiguration();


    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        return onSaveClicked();
    }, [onSaveClicked]);

    const handleDeleteClicked = useCallback((e) => {
        e.preventDefault();
        return onDeleteClicked();
    }, [onDeleteClicked]);

    return (
        <form className={"calendar-form"}>
            <TextField
                name="person_name"
                label={<LocalTranslatedText language={"en"} text="Persons Name" />}
                value={calendar.person_name}
                onChange={handleChange}
                margin="normal"
                fullWidth
            />
            {
                calendar?._id ?
                    <div className={"third-party-calendar-widgets"}>
                        <Card variant="outlined">
                            <h2><LocalTranslatedText language={"en"} text="Google Calendar Integration"/></h2>
                            <GoogleCalendarIntegrationWidget
                                calendar={calendar}
                            />
                        </Card>
                        <Card variant="outlined">
                            <h2><LocalTranslatedText language={"en"} text="Microsoft Outlook Integration"/></h2>
                            <MicrosoftOutlookIntegrationWidget
                                calendar={calendar}
                            />
                        </Card>
                    </div>
                    : null
            }
            <div className={"calendar-buttons-bar"}>
                <SpinnerButton variant="contained" color="primary" type="submit" onClick={handleSubmit}>
                    <LocalTranslatedText language={"en"} text="Save"/>
                </SpinnerButton>
                {
                    calendar?._id && showDeleteButton && (
                        <SpinnerButton variant="contained" color="error" type="button" onClick={handleDeleteClicked}>
                            <LocalTranslatedText language={"en"} text="Delete"/>
                        </SpinnerButton>
                    )
                }
            </div>
        </form>
    );
};
