import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import * as React from 'react';
import VoiceSettingSlider from "./VoiceSettingSlider";


export default function MinimumWordsForInterruptSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Miniumum Words for Interrupt"
      description="The minium number of words that the user must speak in order to interrupt the agent and prevent if from speaking."
      id="minimum-words-for-interrupt-slider"
      min={1}
      max={25}
      step={1}
      icon={<DoDisturbIcon />}
      marks={[
        { value: 0, label: '0' },
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 15, label: '15' },
        { value: 20, label: '20' },
        { value: 25, label: '25' }
      ]}
      onChange={onChange}
      value={value}
    />
  );
}
