import * as React from 'react';
import {TableView} from "../elements/TableView";
import {useAPI} from "../components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import CopyTenantPopup from "./CopyTenantPopup";

export function TenantConfigurationTable() {
  const api = useAPI();
  const navigate = useNavigate();
  const [copyTenantOpen, setCopyTenantOpen] = React.useState(false);
  const [selectedTenantForCopy, setSelectedTenantForCopy] = React.useState(null);

  const handleRowClicked = useCallback((tenantConfiguration) => {
    navigate(`/tenant_configuration/${tenantConfiguration.id}`);
  }, [navigate]);

  const handleCopyClicked = useCallback((tenant) => {
    // First lets fetch the full tenant object with all of its data
    return api.getTenantConfiguration(tenant.id).then((tenant) => {
      setSelectedTenantForCopy(tenant);
      setCopyTenantOpen(true);
    });
  }, [api]);


  return (
    <>
      <TableView
        schemaAPI={api.getTenantConfigurationSchema}
        fetchAPI={api.getTenantConfigurations}
        onRowClicked={handleRowClicked}
        onCopyClicked={handleCopyClicked}
      />
      <CopyTenantPopup
        open={copyTenantOpen}
        setOpen={setCopyTenantOpen}
        copyTenant={selectedTenantForCopy}
      />
    </>
  );
}
