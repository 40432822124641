import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { CheckMark } from "@/frontend/icons/CheckMark";
import React from "react";

export default function List({ text }) {
  return (
    <div className='flex items-center gap-2'>
      <span className='w-8 h-8 rounded-full bg-indigo-100 flex justify-center items-center shrink-0'>
        <CheckMark className='text-primary' />
      </span>
      <p className='text-base font-semibold'>
        <LocalTranslatedText text={text} />
      </p>
    </div>
  );
}
