import React, {useState} from "react";
import AccountMenu from "./AccountMenu";
import EnvironmentTitleMenu from "./EnvironmentTitleMenu";
import {useFrontendConfiguration} from "../components/FrontendConfigurationProvider";
import {IconButton, Tooltip} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import "./TopBar.scss";
import CustomDrawer from "../components/widgets/CustomDrawer";
import ChatWithAgentPopupWidget from "../../web_chat/frontend/ChatWithAgentPopupWidget";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import {useIsAuthenticated} from "@components/APIProvider";

export function TopBar({injectedTopBarItems}) {
  const config = useFrontendConfiguration();
  const isAuthenticated = useIsAuthenticated();
  const [openChatDrawer, setOpenChatDrawer] = useState(false);

  let logoAreaClass = "logo-area";

  if (config?.frontend?.enable_environment_menu) {
    logoAreaClass += " environment-menu-enabled";
  }

  return (
    <>
      <div className={"top-bar"} data-testid={"top-bar"}>
        {config ? (
          <>
            <div className={logoAreaClass}>
              {config?.frontend?.logo_url !== "" ? (
                <div className={"logo-image"}>
                  <img
                    src={config?.frontend?.logo_url}
                    alt={config?.frontend?.top_bar_title}
                    className={"logo-image"}
                  />
                </div>
              ) : null}
              {config?.frontend?.logo_url === "" ? (
                config?.frontend?.enable_environment_menu ? (
                  <EnvironmentTitleMenu/>
                ) : (
                  <span className={"logo-text"}>
                    {config?.frontend?.top_bar_title}
                  </span>
                )
              ) : null}
            </div>

            {injectedTopBarItems}

            <div className='right-container'>
              {config && config?.frontend && config?.modules?.web_chat && isAuthenticated ? (
                <Tooltip
                  title={<LocalTranslatedText text='Chat with your Agent'/>}
                >
                  <IconButton
                    variant='contained'
                    size='small'
                    color="secondary"
                    className='chat-button'
                    onClick={() => setOpenChatDrawer(true)}
                  >
                    <ChatIcon/>
                  </IconButton>
                </Tooltip>
              ) : null}
              <AccountMenu/>
            </div>
          </>
        ) : null}
      </div>
      {config && config?.frontend && config?.modules?.web_chat && isAuthenticated ? (
        <CustomDrawer
          open={openChatDrawer}
          onClose={() => setOpenChatDrawer(false)}
          title='Chat with your Agent'
        >
          <ChatWithAgentPopupWidget/>
        </CustomDrawer>
      ) : null
      }
    </>
  );
}

export default TopBar;
