import "./SmartChainStepTestForm.scss";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import Paper from "@mui/material/Paper";
import { CustomJSONView } from "../../../frontend/elements/CustomJSONView";
import { CircularProgress } from "@mui/material";
import { recursivelyModifySchemaForFrontend } from "./schema_utils";
import { JSONSchemaForm } from "../../../frontend/elements/JSONSchemaForm";
import TableElementLookupSelector from "../../../frontend/elements/TableElementLookupSelector";

export default function SmartChainStepTestForm({
  smartChain,
  selectedStepIndex,
  isDarkMode,
}) {
  const api = useAPI();
  const [schema, setSchema] = React.useState(null);
  const [executionResult, setExecutionResult] = React.useState(null);
  const [isExecuting, setIsExecuting] = React.useState(false);
  const [conversationId, setConversationId] = React.useState(null);
  const step = smartChain.steps[selectedStepIndex];

  useEffect(() => {
    async function getSchema() {
      // Refresh the whole smart chain, then find this one step in the refreshed result
      const newSmartChain = await api.refreshSmartChain(smartChain);
      const newStep = newSmartChain.steps.find(
        (s) => s.step_id === step.step_id
      );
      const newSchema = newStep.actual_input_schema;
      newSchema.title = "Prompt Input";
      recursivelyModifySchemaForFrontend(newSchema);
      setSchema(newStep.actual_input_schema);
    }

    getSchema();
  }, [step, api, smartChain]);

  const handleChange = useCallback((formData) => {}, []);

  const handleChangeConversationId = useCallback((newConversationId) => {
    setConversationId(newConversationId);
  }, []);

  const handleSubmit = useCallback(
    async ({ formData }) => {
      try {
        setIsExecuting(true);

        let newFormData = {
          ...formData,
        };

        if (conversationId) {
          newFormData["conversation_id"] = conversationId;
        }

        const response = await api.executeSmartChainStep(step, newFormData);
        setExecutionResult(response);
      } finally {
        setIsExecuting(false);
      }
    },
    [step, api, conversationId]
  );

  const handleError = useCallback((errors) => {
    console.log("Form errors: ", errors);
  }, []);

  if (!schema) {
    return null;
  }

  return (
    <div className={"smart-chain-step-tester"}>
      <div className={"input-column"}>
        <Paper>
          <h5 className={"conversation-id-title"}>Conversation</h5>
          <hr />
          <br />
          <TableElementLookupSelector
            label={"Conversation (by most recent event time)"}
            getSuggestions={api.getConversations}
            getSingleItem={api.getConversation}
            idFieldName={"id"}
            searchFieldName={"most_recent_event_time"}
            displayFieldName={"most_recent_event_time"}
            value={conversationId}
            onChange={handleChangeConversationId}
            multiple={false}
          />
          <br />

          <JSONSchemaForm
            schema={schema}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onError={handleError}
            submitText={"Run"}
          />
        </Paper>
      </div>
      <div className={"output-column"}>
        <Paper>
          {isExecuting ? (
            <CircularProgress />
          ) : executionResult ? (
            <div className={"execution-result"}>
              <CustomJSONView data={executionResult} field_name={"Output"} />
            </div>
          ) : null}
        </Paper>
      </div>
    </div>
  );
}
