import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./AnalysisViewPage.scss";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import AnalysisForm from "./AnalysisForm";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function AnalysisViewPage(props) {
  const api = useAPI();
  const params = useParams();

  const navigate = useNavigate();
  const [analysis, setAnalysis] = useState(null);

  const handleAnalysisSave = useCallback(
    async (newAnalysis) => {
      return await api.saveAnalysis(newAnalysis).then((savedAnalysis) => {
        navigate('/analysis');
        return savedAnalysis;
      });
    },
    [api, navigate]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api.deleteAnalysis(analysis.id).then(() => {
      navigate(`/analysis`);
    });
  }, [navigate, api, analysis?.id]);

  React.useEffect(() => {
    if (!analysis) {
      api
        .getAnalysis(params?.analysisId)
        .then((analysis) => {
          setAnalysis(analysis);
        });
    }
  }, [
    handleAnalysisSave,
    analysis,
    params?.analysisId,
    api,
  ]);

  if (!analysis) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={
            <LocalTranslatedText language={"en"} text='Edit Analysis' />
          }
        />
        <div className='analysis-view-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText language={"en"} text='View Analysis' />
        }
      />
      <div className='analysis-view-page'>
        <Card className='analysis-edit-form-card'>
          <CardContent>
            <AnalysisForm
              initialAnalysis={analysis}
              onAnalysisSave={handleAnalysisSave}
              onDeletedClicked={handleDeleteClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
