import React, { useEffect, useState } from "react";
import TopNav from "../../components/TopNav";
import Back from "../../components/Back";
import Next from "../../components/Next";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { agentVisuals } from "../../components/AgentVisualSuggestion";
import AgentContainer from "../../components/AgentContainer";
import AgentChat from "../../components/AgentChat";

export default function BusinessPreview({ steps, currentStep, isSubmitting }) {
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const getAgentVisual = getValues("agent_configuration.visual_id");
  const getAgentName = getValues("agent_configuration.agent_name");
  const getAgentService = getValues("agent_service");
  const [agentVisual, setAgentVisual] = useState(null);

  const handleBack = () => {
    navigate(`/onboarding/${steps[currentStep - 1]}/visual`);
  };

  useEffect(() => {
    if (getAgentVisual) {
      const agentVisual = agentVisuals.find(
        (agent) => agent.id === getAgentVisual
      );
      setAgentVisual(agentVisual);
    }
  }, [getAgentVisual]);

  return (
    <div className='flex flex-col'>
      <TopNav steps={steps} currentStep={currentStep} />
      <Back handlePrev={handleBack} />

      <div className='flex flex-col gap-10 justify-center items-center'>
        <h4 className='text-center text-gray-900 text-3xl font-bold'>
          <LocalTranslatedText
            text={`Try out ${getAgentName}, your first AI ${getAgentService} agent`}
          />
        </h4>
        <div className='grid grid-cols-12 gap-8 px-8 mx-auto w-full lg:w-1/2'>
          <AgentContainer
            agentVisual={agentVisual}
            watchAgentName={getAgentName}
            watchAgentVisual={getAgentVisual}
          />
          <div className='col-span-12 lg:col-span-8'>
            <AgentChat agentName={getAgentName} agentVisual={agentVisual} />
          </div>
        </div>
      </div>

      <Next isSubmitting={isSubmitting} text='Get Started' />
    </div>
  );
}
