import React, { useState, useCallback, useMemo, useEffect } from "react";
import { PageWrapperWithMenu } from "../../frontend/elements/PageWrapperWithMenu";
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import "./VoiceConfigurationPage.scss";
import { PhoneNumberPurchaseFlow } from "./PhoneNumberPurchaseFlow";
import { PhoneNumberConfigurationMethodSelector } from "./PhoneNumberConfigurationMethodSelector";
import { PhoneNumberPortFlow } from "./PhoneNumberPortFlow";
import { useAPI } from "../../frontend/components/APIProvider";
import { PageHeader } from "../../frontend/elements/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import { SpinnerButton } from "../../frontend/elements/SpinnerButton";
import { formatPhoneNumberHumanReadable } from "../../frontend/utils/phone";
import { VoiceSelector } from "./VoiceSelector";
import { PageSection } from "../../frontend/elements/PageSection";
import _ from "lodash";
import WaitingNoiseSelector from "./WaitingNoiseSelector";
import { BasicLanguageSelector } from "./BasicLanguageSelector";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import CountrySelectorForAvailablePhoneNumbers from "./CountrySelectorForAvailablePhoneNumbers";
import countryData from "../data/supported_countries_for_phone_numbers.json";
import Box from "@mui/material/Box";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import StabilitySlider from "./components/StabilitySlider";
import StyleSlider from "./components/StyleSlider";
import SimilarityBoostSlider from "./components/SimilarityBoostSlider";
import Checkbox from "@mui/material/Checkbox";
import ResponseSpeedOptimizationSelector from "./components/ResponseSpeedOptimizationSelector";
import UtteranceEndMsSlider from "./components/UtteranceEndMsSlider";
import EndpointingMsSlider from "./components/EndpointMsSlider";
import MaxTimeAfterUtteranceEndMs from "./components/MaxTimeAfterUtteranceEndMs";
import MaxTimeAfterFinalSpeechMs from "./components/MaxTimeAfterFinalSpeechMs";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import SpeedSlider from "./components/SpeedSlider";
import Typography from "@mui/material/Typography";
import MinimumWordsForInterruptSlider from "@/voice/frontend/components/MinimumWordsForInterruptSlider";
import NonInterruptingWordsTagInput from "@/voice/frontend/components/NonInterruptingWordsWidget";
import BackgroundAudioVolumeSlider from "@/voice/frontend/components/BackgroundAudioVolumeSlider";
import BackgroundAmbianceSelector from "@/voice/frontend/components/BackgroundAmbianceSelector";
import BackgroundFadeOutTimeSlider from "@/voice/frontend/components/BackgroundAudioFadeOutTime";
import BackgroundFadeInTimeSlider from "@/voice/frontend/components/BackgroundAudioFadeInTime";

function InitialGreetingEditor({
  voiceConfiguration,
  onInitialGreetingChanged,
  onHangUpMessageChanged,
  onTransferMessageChanged,
  onSaveClicked,
}) {
  const [initialGreeting, setInitialGreeting] = useState(
    voiceConfiguration.initial_greeting
  );
  const [hangUpMessage, setHangUpMessage] = useState(
    voiceConfiguration.hang_up_message
  );
  const [transferMessage, setTransferMessage] = useState(
    voiceConfiguration.transfer_message
  );

  const onInitialGreetingChangedDebounced = useMemo(
    () => _.debounce(onInitialGreetingChanged, 500),
    [onInitialGreetingChanged]
  );

  const onHangUpMessageChangedDebounced = useMemo(
    () => _.debounce(onHangUpMessageChanged, 500),
    [onHangUpMessageChanged]
  );

  const onTransferMessageChangedDebounced = useMemo(
    () => _.debounce(onTransferMessageChanged, 500),
    [onTransferMessageChanged]
  );

  const handleInitialGreetingChange = useCallback(
    (evt) => {
      setInitialGreeting(evt.target.value);
      onInitialGreetingChangedDebounced(evt.target.value);
    },
    [onInitialGreetingChangedDebounced]
  );

  const handleHangUpMessageChange = useCallback(
    (evt) => {
      setHangUpMessage(evt.target.value);
      onHangUpMessageChangedDebounced(evt.target.value);
    },
    [onHangUpMessageChangedDebounced]
  );

  const handleTransferMessageChange = useCallback(
    (evt) => {
      setTransferMessage(evt.target.value);
      onTransferMessageChangedDebounced(evt.target.value);
    },
    [onTransferMessageChangedDebounced]
  );

  return (
    <PageSection
      title={"Greeting and Goodbye"}
      subheading={
        "Please type in what you want the bot to say at the start and end of phone calls"
      }
    >
      <TextField
        label={<LocalTranslatedText language={"en"} text='Initial Greeting' />}
        name={"initial_greeting"}
        fullWidth
        multiline
        rows={4}
        value={initialGreeting}
        onChange={handleInitialGreetingChange}
      />

      <br />

      <TextField
        label={<LocalTranslatedText language={"en"} text='Good Bye Message' />}
        name={"hang_up_message"}
        fullWidth
        multiline
        rows={4}
        value={hangUpMessage}
        onChange={handleHangUpMessageChange}
      />
      <TextField
        label={
          <LocalTranslatedText
            language={"en"}
            text='Transfer to Human Message'
          />
        }
        name={"transfer_message"}
        fullWidth
        multiline
        rows={4}
        value={transferMessage}
        onChange={handleTransferMessageChange}
      />

      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText language={"en"} text='Save Greeting & Goodbye' />
      </SpinnerButton>
    </PageSection>
  );
}

function HumanTransferPhoneNumberEditor({
  voiceConfiguration,
  onHumanTransferPhoneNumberChanged,
  onSaveClicked,
}) {
  let parsedNumber;
  let defaultNationalNumber = "";
  let defaultCountryLetterCode = "US";
  if (voiceConfiguration?.human_transfer_phone_number) {
    try {
      parsedNumber = parsePhoneNumberWithError(
        String(voiceConfiguration.human_transfer_phone_number ?? ""),
        "US"
      );
      defaultNationalNumber = parsedNumber.nationalNumber;
      defaultCountryLetterCode = parsedNumber.country;
    } catch (e) {
      console.error("Error parsing phone number", e);
    }
  }
  const [humanTransferNumber, setHumanTransferNumber] = useState(
    defaultNationalNumber
  );
  const [countryLetterCode, setCountryLetterCode] = useState(
    defaultCountryLetterCode
  );

  const onHumanTransferPhoneNumberChangedDebounced = useMemo(
    () => _.debounce(onHumanTransferPhoneNumberChanged, 500),
    [onHumanTransferPhoneNumberChanged]
  );

  /**
   * Get the phone country code from the country code, ie 'US' -> 1
   * @param countryLetterCode
   * @returns {*|null}
   */
  const getPhoneCodeFromCountryLetterCode = (countryLetterCode) => {
    const country = countryData.countries.find(
      (country) => country.code === countryLetterCode
    );
    return country ? country.phone_country_code : null;
  };

  useEffect(() => {
    if (humanTransferNumber && humanTransferNumber.startsWith("+")) {
      const code = humanTransferNumber.match(/^\+(\d+)/)[1];
      setCountryLetterCode(code);
      setHumanTransferNumber(humanTransferNumber.slice(code.length + 1));
    }
  }, [humanTransferNumber]);

  const handleCountryCodeChange = useCallback(
    (newCountryLetterCode) => {
      const numberCode =
        getPhoneCodeFromCountryLetterCode(newCountryLetterCode);
      setCountryLetterCode(newCountryLetterCode);
      const fullNumber = `+${numberCode}${humanTransferNumber}`;
      onHumanTransferPhoneNumberChangedDebounced(fullNumber);
    },
    [humanTransferNumber, onHumanTransferPhoneNumberChangedDebounced]
  );

  const handleNumberChange = useCallback(
    (newLocalNumber) => {
      const newNumber = newLocalNumber.target.value;
      setHumanTransferNumber(newNumber);
      const countryNumberCode =
        getPhoneCodeFromCountryLetterCode(countryLetterCode);
      const fullNumber = `+${countryNumberCode}${newNumber}`;
      onHumanTransferPhoneNumberChangedDebounced(fullNumber);
    },
    [countryLetterCode, onHumanTransferPhoneNumberChangedDebounced]
  );

  return (
    <PageSection
      title={"Human Transfer"}
      subheading={
        "Please provide the phone number you want called when a caller asks to speak with a human."
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row", // This makes children align horizontally
          gap: 2, // Optional: adds space between the divs
        }}
      >
        <CountrySelectorForAvailablePhoneNumbers
          onChange={handleCountryCodeChange}
          value={countryLetterCode}
          allowNone={false}
        />
        <TextField
          className={"human-transfer-phone-textfield"}
          label={
            <LocalTranslatedText
              language={"en"}
              text='Human Transfer Phone Number'
            />
          }
          name={"human_transfer_phone_number"}
          type='text'
          inputMode='numeric'
          pattern='[0-9]{*}'
          value={humanTransferNumber}
          onChange={handleNumberChange}
        />
      </Box>
      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText
          language={"en"}
          text='Save Human Transfer Number'
        />
      </SpinnerButton>
    </PageSection>
  );
}

function WaitingNoiseEditor({
  voiceConfiguration,
  onWaitingNoiseChange,
  onSaveClicked,
}) {
  const handleValueChange = useCallback(
    (newValue) => {
      onWaitingNoiseChange(newValue);
    },
    [onWaitingNoiseChange]
  );

  return (
    <PageSection
      title={"Waiting Noise"}
      subheading={
        "Please select which noise you would like to play while the bot is thinking"
      }
    >
      <WaitingNoiseSelector
        value={voiceConfiguration?.waiting_noise}
        onChange={handleValueChange}
      />

      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText language={"en"} text='Save Waiting Noise' />
      </SpinnerButton>
    </PageSection>
  );
}

function LanguageEditor({
  voiceConfiguration,
  onLanguageChange,
  onSaveClicked,
}) {
  const handleValueChange = useCallback(
    (newValue) => {
      onLanguageChange(newValue);
    },
    [onLanguageChange]
  );

  return (
    <PageSection
      title={"Language"}
      subheading={
        "Please select the default language that you want the bot to use when it's called"
      }
    >
      <BasicLanguageSelector
        value={voiceConfiguration?.language_id ?? "en-US"}
        onChange={handleValueChange}
      />

      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText language={"en"} text='Save Language' />
      </SpinnerButton>
    </PageSection>
  );
}

function VoiceGenerationSettings({
  voiceConfiguration,
  onVoiceConfigurationChanged,
  onSaveClicked,
}) {
  const handleStabilityChange = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        voice_generation_settings: {
          ...voiceConfiguration.voice_generation_settings,
          stability: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleSpeedChange = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        voice_generation_settings: {
          ...voiceConfiguration.voice_generation_settings,
          speed: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleStyleChange = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        voice_generation_settings: {
          ...voiceConfiguration.voice_generation_settings,
          style: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleSimilarityBoostChange = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        voice_generation_settings: {
          ...voiceConfiguration.voice_generation_settings,
          similarity_boost: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleUseSpeakerBoostChange = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        voice_generation_settings: {
          ...voiceConfiguration.voice_generation_settings,
          use_speaker_boost: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleOptimizeStreamingLatencyChange = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        voice_generation_settings: {
          ...voiceConfiguration.voice_generation_settings,
          optimize_streaming_latency: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleQualityResponseClicked = useCallback(() => {
    onVoiceConfigurationChanged({
      voice_generation_settings: {
        ...voiceConfiguration.voice_generation_settings,
        speed: 1.1,
        stability: 0.3,
        style: 0.5,
        similarity_boost: 0.7,
        use_speaker_boost: true,
        optimize_streaming_latency: 0,
      },
    });
  }, [voiceConfiguration, onVoiceConfigurationChanged]);

  const handleFastResponseClicked = useCallback(() => {
    onVoiceConfigurationChanged({
      voice_generation_settings: {
        ...voiceConfiguration.voice_generation_settings,
        speed: 1.2,
        stability: 0.4,
        style: 0,
        similarity_boost: 0.6,
        use_speaker_boost: false,
        optimize_streaming_latency: 2,
      },
    });
  }, [voiceConfiguration, onVoiceConfigurationChanged]);

  const handleReliableResponseClicked = useCallback(() => {
    onVoiceConfigurationChanged({
      voice_generation_settings: {
        ...voiceConfiguration.voice_generation_settings,
        speed: 1.0,
        stability: 0.9,
        style: 0,
        similarity_boost: 0.4,
        use_speaker_boost: false,
        optimize_streaming_latency: 1,
      },
    });
  }, [voiceConfiguration, onVoiceConfigurationChanged]);

  return (
    <PageSection
      title={"Voice Settings"}
      subheading={"Advanced settings to customize your bots voice"}
    >
      <ButtonGroup
        variant='contained'
        aria-label='Preset Voice Generation Settings'
      >
        <Button onClick={handleFastResponseClicked}>Fast Response</Button>
        <Button onClick={handleQualityResponseClicked} color={"success"}>
          Quality Voice
        </Button>
        <Button onClick={handleReliableResponseClicked}>
          Reliable & Balanced
        </Button>
      </ButtonGroup>

      <SpeedSlider
        value={voiceConfiguration?.voice_generation_settings?.speed ?? 1.0}
        onChange={handleSpeedChange}
      />
      <StabilitySlider
        value={voiceConfiguration?.voice_generation_settings?.stability ?? 0}
        onChange={handleStabilityChange}
      />
      <StyleSlider
        value={voiceConfiguration?.voice_generation_settings?.style ?? 0}
        onChange={handleStyleChange}
      />
      <SimilarityBoostSlider
        value={
          voiceConfiguration?.voice_generation_settings?.similarity_boost ?? 0
        }
        onChange={handleSimilarityBoostChange}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                voiceConfiguration?.voice_generation_settings
                  ?.use_speaker_boost ?? false
              }
              onChange={(e) => handleUseSpeakerBoostChange(e.target.checked)}
            />
          }
          label='Use Speaker Boost? (makes voice more similar to reference, but reduces response speed)'
        />
      </FormGroup>

      <ResponseSpeedOptimizationSelector
        value={
          voiceConfiguration?.voice_generation_settings
            ?.optimize_streaming_latency ?? 0
        }
        onChange={handleOptimizeStreamingLatencyChange}
      />
      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText language={"en"} text='Save Voice Settings' />
      </SpinnerButton>
    </PageSection>
  );
}

function SpeechRecognitionSettings({
  voiceConfiguration,
  onVoiceConfigurationChanged,
  onSaveClicked,
}) {
  const handleUtteranceEndMSChanged = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        speech_recognition_settings: {
          ...voiceConfiguration.speech_recognition_settings,
          utterance_end_ms: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleEndpointingMSChanged = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        speech_recognition_settings: {
          ...voiceConfiguration.speech_recognition_settings,
          endpointing_ms: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleMaxTimeAfterUtteranceEndChanged = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        speech_recognition_settings: {
          ...voiceConfiguration.speech_recognition_settings,
          max_time_after_utterance_end: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleMaxTimeAfterFinalSpeechChanged = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        speech_recognition_settings: {
          ...voiceConfiguration.speech_recognition_settings,
          max_time_after_speech: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleFastResponseClicked = useCallback(() => {
    onVoiceConfigurationChanged({
      speech_recognition_settings: {
        ...voiceConfiguration.speech_recognition_settings,
        utterance_end_ms: 1000,
        endpointing_ms: 500,
        max_time_after_utterance_end: 250,
        max_time_after_speech: 1500,
      },
    });
  }, [onVoiceConfigurationChanged]);

  const handleReliableResponseClicked = useCallback(() => {
    onVoiceConfigurationChanged({
      speech_recognition_settings: {
        ...voiceConfiguration.speech_recognition_settings,
        utterance_end_ms: 1250,
        endpointing_ms: 650,
        max_time_after_utterance_end: 500,
        max_time_after_speech: 2000,
      },
    });
  }, [onVoiceConfigurationChanged]);

  const handleMinimumWordsForInterruptSliderChanged = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        speech_recognition_settings: {
          ...voiceConfiguration.speech_recognition_settings,
          minimum_words_for_interrupt: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleEnableInterruptionChanged = useCallback(
    (evt) => {
      const newValue = evt.target.checked;
      onVoiceConfigurationChanged({
        speech_recognition_settings: {
          ...voiceConfiguration.speech_recognition_settings,
          enable_interruption: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleChangeNonInterruptingWords = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        speech_recognition_settings: {
          ...voiceConfiguration.speech_recognition_settings,
          non_interrupting_words: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  return (
    <PageSection
      title={"Speech Recognition Settings"}
      subheading={"Settings to customize the speed at which your bot responds."}
    >
      <ButtonGroup
        variant='contained'
        aria-label='Preset Speech Recognition Settings'
      >
        <Button onClick={handleFastResponseClicked} color={"warning"}>
          Fast Response
        </Button>
        <Button onClick={handleReliableResponseClicked} color={"success"}>
          Reliable Response
        </Button>
      </ButtonGroup>

      <UtteranceEndMsSlider
        value={
          voiceConfiguration?.speech_recognition_settings?.utterance_end_ms ?? 0
        }
        onChange={handleUtteranceEndMSChanged}
      />
      <EndpointingMsSlider
        value={
          voiceConfiguration?.speech_recognition_settings?.endpointing_ms ?? 0
        }
        onChange={handleEndpointingMSChanged}
      />
      <MaxTimeAfterUtteranceEndMs
        value={
          voiceConfiguration?.speech_recognition_settings
            ?.max_time_after_utterance_end ?? 0
        }
        onChange={handleMaxTimeAfterUtteranceEndChanged}
      />
      <MaxTimeAfterFinalSpeechMs
        value={
          voiceConfiguration?.speech_recognition_settings
            ?.max_time_after_speech ?? 0
        }
        onChange={handleMaxTimeAfterFinalSpeechChanged}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                voiceConfiguration?.speech_recognition_settings
                  ?.enable_interruption ?? false
              }
              onChange={handleEnableInterruptionChanged}
            />
          }
          label='Enable Interruption? (Allow users to interrupt the bot while its speaking)'
        />
      </FormGroup>
      {voiceConfiguration?.speech_recognition_settings?.enable_interruption ? (
        <>
          <MinimumWordsForInterruptSlider
            value={
              voiceConfiguration?.speech_recognition_settings
                ?.minimum_words_for_interrupt ?? 0
            }
            onChange={handleMinimumWordsForInterruptSliderChanged}
          />
          <NonInterruptingWordsTagInput
            value={
              voiceConfiguration?.speech_recognition_settings
                ?.non_interrupting_words ?? []
            }
            onChange={handleChangeNonInterruptingWords}
          />
        </>
      ) : null}
      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText
          language={"en"}
          text='Save Speech Recognition Settings'
        />
      </SpinnerButton>
    </PageSection>
  );
}

function BackgroundAudioSettings({
  voiceConfiguration,
  onVoiceConfigurationChanged,
  onSaveClicked,
}) {
  const handleChangeEnableBackgroundAudio = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        background_audio_settings: {
          ...voiceConfiguration.background_audio_settings,
          enable_background_audio: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleBackgroundAudioVolume = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        background_audio_settings: {
          ...voiceConfiguration.background_audio_settings,
          background_audio_volume: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleBackgroundAudioFadeInTime = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        background_audio_settings: {
          ...voiceConfiguration.background_audio_settings,
          background_audio_fade_in_time_seconds: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleBackgroundAudioFadeOutTime = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        background_audio_settings: {
          ...voiceConfiguration.background_audio_settings,
          background_audio_fade_out_time_seconds: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  const handleBackgroundAudioChanged = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        background_audio_settings: {
          ...voiceConfiguration.background_audio_settings,
          background_audio_name: newValue,
        },
      });
    },
    [voiceConfiguration, onVoiceConfigurationChanged]
  );

  return (
    <PageSection
      title={"Background Audio Settings"}
      subheading={"Put background audio behind your bots generated voice"}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                voiceConfiguration?.background_audio_settings
                  ?.enable_background_audio ?? false
              }
              onChange={(e) =>
                handleChangeEnableBackgroundAudio(e.target.checked)
              }
            />
          }
          label='Enable Background Audio behind Voice [experimental]'
        />
      </FormGroup>
      {voiceConfiguration?.background_audio_settings
        ?.enable_background_audio ? (
        <>
          <BackgroundAudioVolumeSlider
            value={
              voiceConfiguration?.background_audio_settings
                ?.background_audio_volume ?? 0.1
            }
            onChange={handleBackgroundAudioVolume}
          />
          <BackgroundFadeInTimeSlider
            value={
              voiceConfiguration?.background_audio_settings
                ?.background_audio_fade_in_time_seconds ?? 7.0
            }
            onChange={handleBackgroundAudioFadeInTime}
          />
          <BackgroundFadeOutTimeSlider
            value={
              voiceConfiguration?.background_audio_settings
                ?.background_audio_fade_out_time_seconds ?? 7.0
            }
            onChange={handleBackgroundAudioFadeOutTime}
          />
          <BackgroundAmbianceSelector
            value={
              voiceConfiguration?.background_audio_settings
                ?.background_audio_name ?? "office"
            }
            onChange={handleBackgroundAudioChanged}
          />
        </>
      ) : null}
      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText language={"en"} text='Save Voice Settings' />
      </SpinnerButton>
    </PageSection>
  );
}

function VoiceEngineSettings({
  voiceConfiguration,
  onVoiceConfigurationChanged,
  onSaveClicked,
}) {
  const handleChangePrecacheLLMRequests = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        enable_precache_llm_requests: newValue,
      });
    },
    [onVoiceConfigurationChanged]
  );

  const handleChangeLLMResponseTimeHedging = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        enable_llm_response_time_hedging: newValue,
      });
    },
    [onVoiceConfigurationChanged]
  );

  const handleChangeResponseSentenceSplitting = useCallback(
    (newValue) => {
      onVoiceConfigurationChanged({
        enable_response_sentence_splitting: newValue,
      });
    },
    [onVoiceConfigurationChanged]
  );

  return (
    <PageSection
      title={"Voice Engine Settings"}
      subheading={
        "Settings to customize the core voice engine and its various behaviors."
      }
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={voiceConfiguration?.enable_precache_llm_requests ?? true}
              onChange={(e) =>
                handleChangePrecacheLLMRequests(e.target.checked)
              }
            />
          }
          label={
            <>
              <Typography>Enable precache LLM requests?</Typography>
              <Typography gutterBottom sx={{ fontSize: 12 }}>
                Whether to enable making LLM requests as soon as audio comes in,
                instead of waiting for a complete sentence to finish. This can
                save response times by around 1-2 seconds in certain edge case
                situations, but at the expense of increased costs due to
                occasional wasted LLM requests.
              </Typography>
            </>
          }
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                voiceConfiguration?.enable_llm_response_time_hedging ?? true
              }
              onChange={(e) =>
                handleChangeLLMResponseTimeHedging(e.target.checked)
              }
            />
          }
          label={
            <>
              <Typography>Enable LLM response time hedging?</Typography>
              <Typography gutterBottom sx={{ fontSize: 12 }}>
                Whether or not to enable duplication of LLM requests to improve
                response times. When this is enabled, LLM requests for voice
                calls will get made twice, and the first response to return will
                be used. This can improve response times in the range of 500ms
                on average and 2-3 seconds in extreme cases. But this comes at
                the expense of increased LLM costs due to the extra duplicated
                LLM requests. Note! When used in combination with the "Enable
                partial sentence streaming?" option, it can lead to systematic
                bias on your agents behavior towards short opening sentences
                (and could alter your bots behavior in other subtle ways).
              </Typography>
            </>
          }
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                voiceConfiguration?.enable_response_sentence_splitting ?? true
              }
              onChange={(e) =>
                handleChangeResponseSentenceSplitting(e.target.checked)
              }
            />
          }
          label={
            <>
              <Typography>Enable partial sentence streaming?</Typography>
              <Typography gutterBottom sx={{ fontSize: 12 }}>
                This can improve the response time of the bot between 200 to
                800ms allowing the bot to start speaking before the entire
                response has been generated from the LLM, but can reduce the
                quality and consistency of the generated voice audio. Note! When
                used in combination with the "Enable LLM response time hedging?"
                option, it can lead to systematic bias on your agents behavior
                towards short opening sentences (and could alter your bots
                behavior in other subtle ways).
              </Typography>
            </>
          }
        />
      </FormGroup>
      <SpinnerButton
        color={"primary"}
        variant={"contained"}
        onClick={onSaveClicked}
      >
        <LocalTranslatedText
          language={"en"}
          text='Save Voice Engine Settings'
        />
      </SpinnerButton>
    </PageSection>
  );
}

export const VoiceConfigurationPage = () => {
  const api = useAPI();
  const [voiceConfiguration, setVoiceConfiguration] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadVoiceConfiguration = useCallback(() => {
    setIsLoading(true);
    api.getVoiceConfiguration().then(
      (data) => {
        setIsLoading(false);
        setVoiceConfiguration(data);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [api]);

  useEffect(() => {
    loadVoiceConfiguration();
  }, [loadVoiceConfiguration]);

  const handleConfigurationModeChanged = useCallback(
    (purchaseMethod) => {
      setVoiceConfiguration({
        ...voiceConfiguration,
        phone_number_configuration_mode: purchaseMethod,
      });
    },
    [voiceConfiguration]
  );

  const handleResetConfigurationClicked = useCallback(() => {
    return api
      .saveVoiceConfiguration({
        phone_number_configuration_mode: null,
        bot_number: null,
      })
      .then(() => {
        setVoiceConfiguration({
          ...voiceConfiguration,
          phone_number_configuration_mode: null,
          bot_number: null,
          status: "not_configured",
        });
      });
  }, [api, voiceConfiguration]);

  const handleVoiceConfigChange = useCallback(
    (changes) => {
      setVoiceConfiguration({
        ...voiceConfiguration,
        ...changes,
      });
    },
    [voiceConfiguration]
  );

  const handleSaveVoiceConfigurationClicked = useCallback(() => {
    return api.saveVoiceConfiguration(voiceConfiguration);
  }, [api, voiceConfiguration]);

  const handleVoiceIdChanged = useCallback(
    (voiceId) => {
      handleVoiceConfigChange({ voice_id: voiceId });
    },
    [handleVoiceConfigChange]
  );

  const handleInitialGreetingChanged = useCallback(
    (newInitialGreeting) => {
      handleVoiceConfigChange({ initial_greeting: newInitialGreeting });
    },
    [handleVoiceConfigChange]
  );

  const handleHangUpMessageChanged = useCallback(
    (newHangUpMessage) => {
      handleVoiceConfigChange({ hang_up_message: newHangUpMessage });
    },
    [handleVoiceConfigChange]
  );

  const handleTransferMessageChanged = useCallback(
    (newTransferMessage) => {
      handleVoiceConfigChange({ transfer_message: newTransferMessage });
    },
    [handleVoiceConfigChange]
  );

  const handleHumanTransferPhoneNumberChanged = useCallback(
    (newNumber) => {
      handleVoiceConfigChange({ human_transfer_phone_number: newNumber });
    },
    [handleVoiceConfigChange]
  );

  const handleWaitingNoiseChange = useCallback(
    (newWaitingNoise) => {
      handleVoiceConfigChange({ waiting_noise: newWaitingNoise });
    },
    [handleVoiceConfigChange]
  );

  const handleLanguageChange = useCallback(
    (newLanguageId) => {
      handleVoiceConfigChange({ language_id: newLanguageId });
    },
    [handleVoiceConfigChange]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={`Voice Configuration`}
        subtitle={`Configure your voice bot here`}
      />
      <div className='voice-configuration-page'>
        <PageSection
          title={"Phone Number"}
          subheading={"You can configure the phone number for your bot here"}
        >
          {isLoading ? <CircularProgress /> : null}
          {voiceConfiguration?.status === "not_configured" ? (
            <div>
              <PhoneNumberConfigurationMethodSelector
                onConfigurationModeSelected={handleConfigurationModeChanged}
              />

              {voiceConfiguration?.phone_number_configuration_mode ===
              "purchase" ? (
                <PhoneNumberPurchaseFlow
                  onVoiceConfigurationChanged={handleVoiceConfigChange}
                />
              ) : null}
              {voiceConfiguration?.phone_number_configuration_mode ===
              "port" ? (
                <PhoneNumberPortFlow
                  onVoiceConfigurationChanged={handleVoiceConfigChange}
                />
              ) : null}
            </div>
          ) : null}
          {voiceConfiguration?.status === "ready" ? (
            <div>
              <p>
                <LocalTranslatedText language={"en"} text={"Status: "} />
                <strong>
                  <LocalTranslatedText language={"en"} text='Ready' />
                </strong>
              </p>

              <p>
                <LocalTranslatedText
                  language={"en"}
                  text={
                    "Your account is currently configured to use the phone number"
                  }
                />
                {formatPhoneNumberHumanReadable(voiceConfiguration?.bot_number)}
                .
              </p>
              <br />

              <SpinnerButton
                color={"primary"}
                variant={"contained"}
                onClick={handleResetConfigurationClicked}
              >
                <LocalTranslatedText
                  language={"en"}
                  text='Reset Phone Number Configuration'
                />
              </SpinnerButton>
            </div>
          ) : null}
          {voiceConfiguration?.status === "port_processing" ? (
            <div>
              <p>
                <LocalTranslatedText language={"en"} text='Status: ' />
                <strong>
                  <LocalTranslatedText language={"en"} text='Port Processing' />
                </strong>
              </p>

              <p>
                <LocalTranslatedText language={"en"} text='Phone Number: ' />
                {formatPhoneNumberHumanReadable(voiceConfiguration?.bot_number)}
              </p>

              <p>
                <LocalTranslatedText
                  language={"en"}
                  text='Your phone number porting request is currently being processed. Our team will reach out to you directly to complete the process.'
                />
              </p>

              <SpinnerButton
                color={"primary"}
                variant={"contained"}
                onClick={handleResetConfigurationClicked}
              >
                <LocalTranslatedText
                  language={"en"}
                  text='Cancel Phone Number Port Request'
                />
              </SpinnerButton>
            </div>
          ) : null}
        </PageSection>

        {voiceConfiguration?.status === "ready" ? (
          <InitialGreetingEditor
            voiceConfiguration={voiceConfiguration}
            onInitialGreetingChanged={handleInitialGreetingChanged}
            onHangUpMessageChanged={handleHangUpMessageChanged}
            onTransferMessageChanged={handleTransferMessageChanged}
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}

        {voiceConfiguration?.status === "ready" ? (
          <HumanTransferPhoneNumberEditor
            voiceConfiguration={voiceConfiguration}
            onHumanTransferPhoneNumberChanged={
              handleHumanTransferPhoneNumberChanged
            }
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}

        {voiceConfiguration?.status === "ready" ? (
          <LanguageEditor
            voiceConfiguration={voiceConfiguration}
            onLanguageChange={handleLanguageChange}
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}

        {voiceConfiguration?.status === "ready" ? (
          <WaitingNoiseEditor
            voiceConfiguration={voiceConfiguration}
            onWaitingNoiseChange={handleWaitingNoiseChange}
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}

        {voiceConfiguration?.status === "ready" ? (
          <PageSection
            title={"Voice"}
            subheading={"Please select the voice you would like the bot to use"}
          >
            <VoiceSelector
              value={voiceConfiguration.voice_id}
              onChange={handleVoiceIdChanged}
            />

            <SpinnerButton
              color={"primary"}
              variant={"contained"}
              onClick={handleSaveVoiceConfigurationClicked}
            >
              <LocalTranslatedText
                language={"en"}
                text='Save Voice Selection'
              />
            </SpinnerButton>
          </PageSection>
        ) : null}

        {voiceConfiguration?.status === "ready" ? (
          <VoiceGenerationSettings
            voiceConfiguration={voiceConfiguration}
            onVoiceConfigurationChanged={handleVoiceConfigChange}
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}
        {voiceConfiguration?.status === "ready" ? (
          <SpeechRecognitionSettings
            voiceConfiguration={voiceConfiguration}
            onVoiceConfigurationChanged={handleVoiceConfigChange}
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}

        {voiceConfiguration?.status === "ready" ? (
          <BackgroundAudioSettings
            voiceConfiguration={voiceConfiguration}
            onVoiceConfigurationChanged={handleVoiceConfigChange}
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}

        {voiceConfiguration?.status === "ready" ? (
          <VoiceEngineSettings
            voiceConfiguration={voiceConfiguration}
            onVoiceConfigurationChanged={handleVoiceConfigChange}
            onSaveClicked={handleSaveVoiceConfigurationClicked}
          />
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
};

export default VoiceConfigurationPage;
