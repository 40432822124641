import { PageWrapperWithMenu } from "../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../frontend/components/APIProvider";
import Paper from "@mui/material/Paper";
import { PageHeader } from "../../frontend/elements/PageHeader";
import "./RunSyntheticUserPage.scss";
import { SyntheticUserForm } from "./SyntheticUserForm";
import globalTranslationManager from "../../translation/frontend/components/translation_manager";

const defaultSyntheticUserPrompt =
  globalTranslationManager.getTranslationIfAvailableAndEnabledSync(`Your name is Sally and you are talking to a receptionist over the phone to book an appointment. Your goal is to book the appointment next week on Monday at 1 pm. You must not give up until you have booked the appointment.

Your name is: Sally Smith
Your email is: test@test.bradleyarsenault.me
Your phone number is: 555-261-1234`);

export default function RunSyntheticUserPage() {
  const api = useAPI();

  let prompt = localStorage.getItem("synthetic-user-prompt");
  if (!prompt) {
    prompt = defaultSyntheticUserPrompt;
  }

  const [syntheticUserData, setSyntheticUserData] = useState({
    prompt: prompt,
    temperature: 1.0,
    max_remaining_responses: 5,
    start_role: "user",
  });

  const navigate = useNavigate();

  const handleRunUserClicked = useCallback(async () => {
    const conversation = await api.createNewSyntheticUser(syntheticUserData);
    navigate(`/conversations/${conversation.id}`);
  }, [api, navigate, syntheticUserData]);

  const handleSyntheticUserDataChange = useCallback(
    (newSyntheticUserData) => {
      setSyntheticUserData(newSyntheticUserData);
      localStorage.setItem(
        "synthetic-user-prompt",
        newSyntheticUserData.prompt
      );
    },
    [setSyntheticUserData]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Run Synthetic User"}
        subtitle={"Run a synthetic user to test the system"}
      />
      <div className='run-synthetic-user-page'>
        <Paper className={"main-page-contents"}>
          <SyntheticUserForm
            syntheticUser={syntheticUserData}
            onChange={handleSyntheticUserDataChange}
            onSaveClicked={handleRunUserClicked}
          />
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
