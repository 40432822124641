const FORMAT_PDF = ["pdf"];
const FORMAT_TEXT = ["txt"];
const FORMAT_PHOTOSHOP = ["psd"];
const FORMAT_WORD = ["doc", "docx"];
const FORMAT_EXCEL = ["xls", "xlsx"];
const FORMAT_ZIP = ["zip", "rar", "iso"];
const FORMAT_ILLUSTRATOR = ["ai", "esp"];
const FORMAT_POWERPOINT = ["ppt", "pptx"];
const FORMAT_AUDIO = ["wav", "aif", "mp3", "aac"];
const FORMAT_IMG = ["jpg", "jpeg", "gif", "bmp", "png", "svg"];
const FORMAT_VIDEO = ["m4v", "avi", "mpg", "mp4", "webm"];

export function fileNameByUrl(fileUrl) {
  return fileUrl.split("/").pop();
}

export function fileTypeByUrl(fileUrl = "") {
  return (fileUrl && fileUrl.split(".").pop()) || "";
}

export function fileData(file) {
  if (typeof file === "string") {
    return {
      key: file,
      preview: file,
      name: fileNameByUrl(file),
      type: fileTypeByUrl(file),
    };
  }

  return {
    key: file.preview,
    name: file.name,
    size: file.size,
    path: file.path,
    type: file.type,
    preview: file.preview,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
  };
}

export function fData(inputValue) {
  if (!inputValue) return "";

  if (inputValue === 0) return "0 Bytes";

  const units = ["bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

  const decimal = 2;

  const baseValue = 1024;

  const number = Number(inputValue);

  const index = Math.floor(Math.log(number) / Math.log(baseValue));

  const fm = `${parseFloat((number / baseValue ** index).toFixed(decimal))} ${
    units[index]
  }`;

  return fm;
}

export function fileFormat(fileUrl) {
  let format;

  switch (fileUrl?.includes(fileTypeByUrl(fileUrl))) {
    case FORMAT_TEXT.includes(fileTypeByUrl(fileUrl)):
      format = "txt";
      break;
    case FORMAT_ZIP.includes(fileTypeByUrl(fileUrl)):
      format = "zip";
      break;
    case FORMAT_AUDIO.includes(fileTypeByUrl(fileUrl)):
      format = "audio";
      break;
    case FORMAT_IMG.includes(fileTypeByUrl(fileUrl)):
      format = "image";
      break;
    case FORMAT_VIDEO.includes(fileTypeByUrl(fileUrl)):
      format = "video";
      break;
    case FORMAT_WORD.includes(fileTypeByUrl(fileUrl)):
      format = "word";
      break;
    case FORMAT_EXCEL.includes(fileTypeByUrl(fileUrl)):
      format = "excel";
      break;
    case FORMAT_POWERPOINT.includes(fileTypeByUrl(fileUrl)):
      format = "powerpoint";
      break;
    case FORMAT_PDF.includes(fileTypeByUrl(fileUrl)):
      format = "pdf";
      break;
    case FORMAT_PHOTOSHOP.includes(fileTypeByUrl(fileUrl)):
      format = "photoshop";
      break;
    case FORMAT_ILLUSTRATOR.includes(fileTypeByUrl(fileUrl)):
      format = "illustrator";
      break;
    default:
      format = fileTypeByUrl(fileUrl);
  }

  return format;
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      resolve(event.target.result);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}
