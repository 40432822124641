import "./DocumentTemplatePageSettingsTab.scss";
import "./DocumentTemplateSettingsTab.scss";
import "@grapesjs/studio-sdk/style";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, {useCallback, useEffect} from "react";
import Typography from '@mui/material/Typography';
import _ from "lodash";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {useAPI} from "../../../frontend/components/APIProvider";


export default function DocumentTemplatePageSettingsTab({documentTemplateId}) {
  const [documentTemplate, setDocumentTemplate] = React.useState(null);
  const [expandedPageIndex, setExpandedPageIndex] = React.useState(null);
  const api = useAPI();

  const saveDocumentTemplate = useCallback(async (newTemplateData) => {
    await api.saveDocumentTemplate(documentTemplateId, newTemplateData);
  }, [api]);

  const saveDocumentTemplateDebounced = useCallback(_.debounce(saveDocumentTemplate, 1000), [saveDocumentTemplate]);

  useEffect(() => {
    api.getDocumentTemplate(documentTemplateId).then((documentTemplate) => {
      setDocumentTemplate(documentTemplate);
    });
  }, []);

  useEffect(() => {
    api.getDocumentTemplate(documentTemplateId).then((documentTemplate) => {
      setDocumentTemplate(documentTemplate);
    });
  }, [api, documentTemplateId]);

  const handleChangeShouldRepeat = useCallback(async (pageIndex, newValue) => {
    const newDocumentTemplateData = {
      ...documentTemplate,
      pages: documentTemplate.pages.map((page, index) => {
        if (index === pageIndex) {
          return {
            ...page,
            should_repeat: newValue
          };
        } else {
          return page;
        }
      })
    };

    setDocumentTemplate(newDocumentTemplateData);
    saveDocumentTemplateDebounced(newDocumentTemplateData);
  }, [documentTemplate, saveDocumentTemplateDebounced]);

  const handleChangeRepeatVariableBinding = useCallback(async (pageIndex, newValue) => {
    const newDocumentTemplateData = {
      ...documentTemplate,
      pages: documentTemplate.pages.map((page, index) => {
        if (index === pageIndex) {
          return {
            ...page,
            repeat_variable_binding: newValue
          };
        } else {
          return page;
        }
      })
    };

    setDocumentTemplate(newDocumentTemplateData);
    saveDocumentTemplateDebounced(newDocumentTemplateData);
  }, [documentTemplate, saveDocumentTemplateDebounced]);

  if (!documentTemplate) {
    return null;
  }

  return (
    <div className='document-template-page-settings-tab'>
      <PageHeader
        title={
          <LocalTranslatedText language={"en"} text='Page Settings'/>
        }
      />
      <div className={"page-list-wrapper"}>
        {
          documentTemplate.pages.map((page, pageIndex) => {
            return (
              <Accordion
                expanded={expandedPageIndex === pageIndex}
                onChange={() => setExpandedPageIndex(expandedPageIndex === pageIndex ? -1 : pageIndex)}
                key={pageIndex}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls={`page-${pageIndex}-content`}
                  id={`page-${pageIndex}-header`}
                >
                  <Typography component="span">{pageIndex + 1} - {page.title}</Typography>
                </AccordionSummary>
                <AccordionDetails id={`page-${pageIndex}-content`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={page.should_repeat}
                        onChange={(e) => handleChangeShouldRepeat(pageIndex, e.target.checked)}
                      />
                    }
                    label="Should Page Repeat?"
                  />
                  <br/>
                  {
                    page.should_repeat ?
                      <TextField
                        placeholder={"Array variable binding name to use for template values"}
                        defaultValue={page.repeat_variable_binding}
                        onChange={(e) => handleChangeRepeatVariableBinding(pageIndex, e.target.value)}
                        fullWidth={true}
                      /> : null
                  }
                </AccordionDetails>
              </Accordion>
            );
          })
        }
      </div>
    </div>
  );
}