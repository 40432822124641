import * as React from 'react';
import VoiceSettingSlider from "./VoiceSettingSlider";
import PaletteIcon from "@mui/icons-material/Palette";


export default function StyleSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Style"
      description="The extent to which the the style should be exagerated compared to the reference voices. Higher is more exaggerated, lower is more of an 'average' voice. Setting it to 0.0 disables style exaggeration and improves response speed of the voice."
      id="style-slider"
      min={0}
      max={1.0}
      step={0.05}
      icon={<PaletteIcon />}
      marks={[
        { value: 0, label: '0' },
        { value: 0.25, label: '0.25' },
        { value: 0.5, label: '0.5' },
        { value: 0.75, label: '0.75' },
        { value: 1, label: '1' },
      ]}
      onChange={onChange}
      value={value}
    />
  );
}
