import React from "react";

export function Shopify(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M14.9199 3.53473C14.9075 3.44473 14.8286 3.39492 14.7635 3.38944C14.6985 3.38401 13.3221 3.28199 13.3221 3.28199C13.3221 3.28199 12.3662 2.33299 12.2612 2.22794C12.1563 2.12297 11.9512 2.1549 11.8716 2.17832C11.8599 2.18177 11.6628 2.24262 11.3367 2.34353C11.0174 1.42466 10.4538 0.580267 9.46242 0.580267C9.43505 0.580267 9.40688 0.581376 9.37871 0.582979C9.09675 0.210096 8.74748 0.0480957 8.44579 0.0480957C6.13631 0.0480957 5.03294 2.93518 4.687 4.40231C3.78959 4.68038 3.15207 4.87807 3.07063 4.90366C2.56972 5.06079 2.55387 5.07657 2.4881 5.54857C2.4386 5.90592 1.12793 16.042 1.12793 16.042L11.341 17.9555L16.8747 16.7584C16.8747 16.7584 14.9321 3.62473 14.9199 3.53473ZM10.7722 2.5181L9.90805 2.78557C9.90835 2.72467 9.90866 2.66475 9.90866 2.59923C9.90866 2.02816 9.82939 1.56836 9.70222 1.20386C10.2131 1.26797 10.5533 1.84921 10.7722 2.5181ZM9.06852 1.3171C9.21055 1.67297 9.30289 2.18368 9.30289 2.87286C9.30289 2.90812 9.30258 2.94036 9.30227 2.97297C8.74026 3.14705 8.12956 3.33605 7.5175 3.52567C7.86116 2.19934 8.50534 1.55874 9.06852 1.3171ZM8.38236 0.667554C8.48204 0.667554 8.58246 0.701397 8.67856 0.767541C7.9384 1.11583 7.14505 1.99302 6.81001 3.74475L5.39923 4.18168C5.79166 2.84555 6.72353 0.667554 8.38236 0.667554Z'
        fill='#95BF46'
      />
      <path
        d='M14.7638 3.3894C14.6988 3.38397 13.3224 3.28195 13.3224 3.28195C13.3224 3.28195 12.3665 2.33294 12.2616 2.2279C12.2223 2.18882 12.1694 2.16879 12.114 2.16016L11.3418 17.9553L16.8751 16.7583C16.8751 16.7583 14.9324 3.62469 14.9202 3.53469C14.9078 3.44469 14.829 3.39488 14.7638 3.3894Z'
        fill='#5E8E3E'
      />
      <path
        d='M9.46305 6.4471L8.78071 8.47678C8.78071 8.47678 8.18289 8.15772 7.45007 8.15772C6.37574 8.15772 6.32168 8.83191 6.32168 9.0018C6.32168 9.9288 8.73812 10.284 8.73812 12.4553C8.73812 14.1637 7.6546 15.2637 6.19364 15.2637C4.44049 15.2637 3.54395 14.1726 3.54395 14.1726L4.01336 12.6217C4.01336 12.6217 4.93494 13.4129 5.71258 13.4129C6.22071 13.4129 6.4274 13.0128 6.4274 12.7205C6.4274 11.5113 4.44493 11.4573 4.44493 9.47036C4.44493 7.79802 5.64526 6.17969 8.06823 6.17969C9.00183 6.17969 9.46305 6.4471 9.46305 6.4471Z'
        fill='white'
      />
    </svg>
  );
}
