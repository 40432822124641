import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/frontend/components/ui/form";
import { Upload } from "@/frontend/components/ui/upload";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function HFUpload({ fieldName, label, ...props }) {
  const { control, setValue } = useFormContext();

  return (
    <FormField
      name={fieldName}
      control={control}
      render={({ field }) => (
        <FormItem>
          {label && (
            <FormLabel>
              <LocalTranslatedText text={label} />
            </FormLabel>
          )}
          <FormControl>
            <Upload
              {...props}
              onSetUploadedFiles={setValue}
              fieldName={fieldName}
              value={field.value}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
