import * as React from 'react';
import BalanceIcon from '@mui/icons-material/Balance';
import VoiceSettingSlider from "./VoiceSettingSlider";


export default function StabilitySlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Stability"
      description="The stability of the voice generation model. Higher is more monotone, lower is more expressive and varied."
      id="style-slider"
      min={0}
      max={1.0}
      step={0.05}
      icon={<BalanceIcon />}
      marks={[
        { value: 0, label: '0' },
        { value: 0.25, label: '0.25' },
        { value: 0.5, label: '0.5' },
        { value: 0.75, label: '0.75' },
        { value: 1, label: '1' },
      ]}
      onChange={onChange}
      value={value}
    />
  );
}
