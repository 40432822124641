import React, { forwardRef } from "react";
import { cn } from "@/frontend/lib/utils";

const Textarea = forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        "flex w-full px-3 py-2 shadow-input rounded-sm border border-input bg-background text-sm font-medium text-foreground focus:ring-ring focus:shadow-input placeholder:text-muted-foreground focus-visible:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50 transition-all ease-in-out duration-300 delay-100",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = "Textarea";

export { Textarea };
