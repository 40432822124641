import React, { useEffect, useRef } from "react";
import { FormProvider as Form, useWatch } from "react-hook-form";
import { useDebounce } from "../../../hooks/use-debounce";

export default function FormProvider({
  methods,
  onSubmit,
  children,
  onAutoSave,
}) {
  const formData = useWatch({ control: methods.control });
  const debouncedFormData = useDebounce(formData, 1000);

  // Ref to store the previous form data
  const prevFormData = useRef(formData);

  useEffect(() => {
    if (onAutoSave) {
      if (
        JSON.stringify(debouncedFormData) !==
        JSON.stringify(prevFormData?.current)
      ) {
        onAutoSave(debouncedFormData);
        prevFormData.current = debouncedFormData;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFormData, onAutoSave]);

  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}
