import React from "react";

export function Zapier(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M11.2499 9.00394C11.2502 9.65297 11.1332 10.2967 10.9043 10.9041C10.2968 11.1328 9.65288 11.2501 9.00366 11.2502H8.99606C8.32753 11.2494 7.68698 11.1272 7.09594 10.9043C6.86698 10.2969 6.74978 9.65308 6.75 9.00394V8.99606C6.74969 8.34715 6.86664 7.70353 7.09523 7.09622C7.70277 6.86701 8.3468 6.74971 8.99613 6.75H9.00373C9.65299 6.74969 10.2969 6.86696 10.9044 7.09615C11.1333 7.7034 11.2503 8.34705 11.25 8.99599V9.00387L11.2499 9.00394ZM17.875 7.50002H12.6215L16.3361 3.7852C16.0445 3.37564 15.7193 2.99113 15.3638 2.63566V2.63538C15.0083 2.2803 14.6238 1.95537 14.2145 1.66395L10.4997 5.37877V0.125297C10.0056 0.0421825 9.50551 0.000272594 9.0045 0L8.99522 0C8.48559 0.00028125 7.98645 0.0435937 7.50002 0.125297V5.37877L3.7852 1.66395C3.3758 1.95529 2.99156 2.28045 2.63651 2.63602L2.63454 2.63742C2.27964 2.99244 1.95487 3.37636 1.66359 3.7852L5.3787 7.50002H0.125297C0.125297 7.50002 0 8.48672 0 8.99691V9.00309C0 9.51328 0.0432422 10.0133 0.125297 10.5H5.37877L1.66366 14.2148C2.24808 15.0349 2.96508 15.7519 3.7852 16.3363L7.50002 12.6212V17.875C7.99355 17.9577 8.49306 17.9995 8.99346 18H9.00619C9.50659 17.9995 10.0061 17.9577 10.4996 17.875V12.6212L14.2148 16.3363C14.624 16.0448 15.0084 15.7198 15.3638 15.3646L15.3646 15.3638C15.7196 15.0083 16.0446 14.624 16.3361 14.2148L12.621 10.5H17.875C17.9568 10.0141 17.9994 9.5156 18 9.00654V8.99346C17.9994 8.4844 17.9568 7.98588 17.875 7.50002Z'
        fill='#FF4A00'
      />
    </svg>
  );
}
