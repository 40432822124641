import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function AnalysisTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleAnalysisRowClicked = useCallback((analysis) => {
        navigate(`/analysis/${analysis.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getAnalysisSchema}
            fetchAPI={api.getAnalysisList}
            onRowClicked={handleAnalysisRowClicked}
        />
    );
}
