import React from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "@/frontend/lib/utils";
import {
  FormItem,
  FormField,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/frontend/components/ui/form";
import { SkinColorPicker } from "@/frontend/components/ui/skin-color-picker";

export default function HFSkinColorPicker({ fieldName, label, ...rest }) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <FormControl>
            <SkinColorPicker
              value={field.value}
              onChange={field.onChange}
              className={cn(
                error &&
                  "border-danger focus:shadow-error focus:ring-ring-error"
              )}
              {...rest}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
