import React, {useCallback, useEffect, useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from "@mui/material/ListItemText";
import {useAPI} from "../../frontend/components/APIProvider";
import "./AgentActionlList.scss";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "../../frontend/components/SnackbarProvider";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";

/**
 * from pydantic import BaseModel
 *
 *
 * class AvailableAction(BaseModel):
 *     """
 *     This is a data class that represents a single action that can be performed by the bot_
 *     These are provided by the various bot_core modules.
 *     """
 *
 *     module_id: str
 *
 *     action_id: str
 *
 *     description: str
 *
 *     # Parameters in a JSON-schema structured document.
 *     # Validation is not enforced. Fields should be provided
 *     # with a "description" value to describe the field for
 *     # best results.
 *     parameters: dict
 */
export function AgentActionListItem({actionInfo, actionIndex, isFavorite, onFavoriteClicked, onActionClicked}) {
  const [localIsFavorite, setLocalIsFavorite] = useState(isFavorite);

  const handleFavoriteClicked = useCallback((actionInfo, actionIndex) => {
    setLocalIsFavorite(!localIsFavorite);
    onFavoriteClicked(actionInfo, actionIndex);
  }, [localIsFavorite, onFavoriteClicked]);

  const actionInfoFormatted = `Module ID: ${actionInfo.module_id}\nAction ID: ${actionInfo.action_id}\nDescription: ${actionInfo.description}\nParameters:\n${JSON.stringify(actionInfo.parameters, null, 2)}`;

  return <Tooltip
    title={actionInfoFormatted}
    placement="top-start"
    classes={{tooltip: "available-action-additional-details-tooltip"}}
  >
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="favorite"
          onClick={() => handleFavoriteClicked(actionInfo, actionIndex)}
        >
          {
            localIsFavorite ? <FavoriteIcon/> : <FavoriteBorderIcon/>
          }
        </IconButton>
      }
    >
      <ListItemButton
        onClick={() => onActionClicked(actionInfo, actionIndex)}
      >
        <ListItemText
          primary={actionInfo.action_id}
          secondary={actionInfo.description}
        />
      </ListItemButton>
    </ListItem>
  </Tooltip>
}

export default function AgentActionList() {
  const [favoriteActionIds, setFavoriteActionIds] = useState(localStorage.getItem('favoriteActions') ? JSON.parse(localStorage.getItem('favoriteActions')) : []);
  const [favoriteActions, setFavoriteActions] = useState([]);
  const [allActions, setAllActions] = useState([]);
  const [groupedActions, setGroupedActions] = useState({});
  const [open, setOpen] = useState({});
  const snackbar = useSnackbar();
  const api = useAPI();

  const handleFavoriteClicked = useCallback((actionInfo, actionIndex) => {
    let newFavoriteActions = [];
    if (favoriteActionIds.includes(actionInfo.action_id)) {
      newFavoriteActions = favoriteActionIds.filter((name) => name !== actionInfo.action_id);
    } else {
      newFavoriteActions = [...favoriteActionIds, actionInfo.action_id];
    }
    setFavoriteActionIds(newFavoriteActions);
    localStorage.setItem('favoriteActions', JSON.stringify(newFavoriteActions));
  }, [favoriteActionIds]);

  useEffect(() => {
    api.getAvailableActions().then((responseData) => {
      // First group by module_id
      const groupedByModuleId = _.groupBy(responseData.available_actions, 'module_id');
      setGroupedActions(groupedByModuleId);
      setAllActions(responseData.available_actions);
    });
  }, [api]);

  useEffect(() => {
      const favoriteActionsList = [];
      // First find the favorite actions within the list of agent actions
      favoriteActionIds.forEach((favoriteActionName) => {
        const foundAction = allActions.find((action) => action.action_id === favoriteActionName);
        if (foundAction) {
          favoriteActionsList.push(foundAction);
        }
      });
      // Sort the favorite actions list by name
      favoriteActionsList.sort((a, b) => a.name.localeCompare(b.action_id));
      setFavoriteActions(favoriteActionsList);
  }, [allActions, favoriteActionIds]);

  const handleActionClicked = useCallback((actionInfo, actionIndex) => {
    // Save the action name into the clipboard
    navigator.clipboard.writeText(actionInfo.action_id);
    snackbar.toast({
      message: `Action ID ${actionInfo.action_id} copied to clipboard`,
      severity: "info",
      autoHideMs: 5000,
    });
  }, [snackbar]);

  if (allActions.length === 0) {
    return <div className={"agent-action-list loading"}>
      <CircularProgress/>
    </div>
  }

  return <div className={"agent-action-list"}>
    <div className={"agent-action-list-inner"}>
      <List sx={{width: '100%', bgcolor: 'background.paper'}} dense={true}>
        <ListItem className={'title-row'}>
          <ListItemText primary={"List of Built-In Available Actions for Agent"}/>
        </ListItem>
        {
          favoriteActions.map((actionInfo, actionIndex) => {
            return <AgentActionListItem
              key={`${actionInfo.action_id}-${actionIndex}`}
              actionInfo={actionInfo}
              actionIndex={actionIndex}
              isFavorite={true}
              onFavoriteClicked={handleFavoriteClicked}
              onActionClicked={handleActionClicked}
            />
          })
        }

        {
          Object.keys(groupedActions).map((moduleId, moduleIndex) => {
            return <List
              key={moduleId}
              sx={{width: '100%', bgcolor: 'background.paper'}}
              disablePadding
              dense={true}
            >
              <ListItemButton onClick={() => setOpen({...open, [moduleId]: !open[moduleId]})}>
                <ListItemText primary={moduleId}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
              </ListItemButton>
              <Collapse in={open[moduleId]} timeout="auto" unmountOnExit>
                {
                  groupedActions[moduleId].map((actionInfo, actionIndex) => {
                    return <AgentActionListItem
                      key={`${actionInfo.action_id}-${actionIndex}`}
                      actionInfo={actionInfo}
                      actionIndex={actionIndex}
                      isFavorite={false}
                      onFavoriteClicked={handleFavoriteClicked}
                      onActionClicked={handleActionClicked}
                    />
                  })
                }
              </Collapse>
            </List>;
          })
        }

      </List>
    </div>
  </div>
}
