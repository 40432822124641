import React from "react";

export function Trello(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8632 0H2.13973C0.959132 0 0.00162586 0.956831 1.72492e-05 2.13819V15.8503C-0.00226571 16.4196 0.222149 16.9664 0.623669 17.3698C1.02519 17.7732 1.57074 18 2.13973 18H15.8632C16.4316 17.9992 16.9765 17.7721 17.3773 17.3688C17.7782 16.9655 18.0023 16.4191 18 15.8503V2.13819C17.9984 0.957957 17.0426 0.00158953 15.8632 0ZM7.7629 12.9647C7.76214 13.155 7.68566 13.3372 7.55038 13.4709C7.4151 13.6047 7.23215 13.679 7.04198 13.6775H4.04295C3.65071 13.6759 3.33357 13.3572 3.33357 12.9647V4.03399C3.33357 3.64148 3.65071 3.32285 4.04295 3.32126H7.04198C7.4347 3.32284 7.75267 3.64101 7.75425 4.03399L7.7629 12.9647ZM14.6837 8.86438C14.6837 9.05492 14.6075 9.23752 14.4721 9.37144C14.3366 9.50535 14.1532 9.57942 13.9628 9.57716H10.9638C10.5711 9.57553 10.2531 9.25735 10.2515 8.86438V4.03399C10.2531 3.64101 10.5711 3.32284 10.9638 3.32126H13.9628C14.3551 3.32285 14.6722 3.64148 14.6722 4.03399L14.6837 8.86438Z'
        fill='url(#paint0_linear_346_8449)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_346_8449'
          x1='2810.7'
          y1='1152'
          x2='2810.7'
          y2='0'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0052CC' />
          <stop offset='1' stopColor='#2684FF' />
        </linearGradient>
      </defs>
    </svg>
  );
}
