import React from "react";

export function LinkedIn(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      {...props}
    >
      <path
        d='M0.5 1.32037C0.5 0.61151 1.09583 0.0361328 1.83031 0.0361328H17.1697C17.9045 0.0361328 18.5 0.61151 18.5 1.32037V16.6801C18.5 17.3892 17.9045 17.9641 17.1697 17.9641H1.83031C1.0959 17.9641 0.5 17.3892 0.5 16.6803V1.32016V1.32037Z'
        fill='#006699'
      />
      <path
        d='M5.96997 15.0397V6.96843H3.27644V15.0397H5.97025H5.96997ZM4.62377 5.86663C5.56286 5.86663 6.14751 5.24685 6.14751 4.47231C6.12993 3.68012 5.56286 3.07764 4.64163 3.07764C3.71976 3.07764 3.11768 3.68012 3.11768 4.47224C3.11768 5.24678 3.70211 5.86656 4.60612 5.86656H4.62356L4.62377 5.86663ZM7.46088 15.0397H10.1542V10.5328C10.1542 10.2919 10.1718 10.0504 10.2429 9.8783C10.4376 9.39614 10.8807 8.89702 11.625 8.89702C12.5994 8.89702 12.9894 9.63711 12.9894 10.7222V15.0397H15.6827V10.4119C15.6827 7.93283 14.354 6.77921 12.5819 6.77921C11.1291 6.77921 10.491 7.588 10.1365 8.13887H10.1544V6.96871H7.46102C7.49618 7.72589 7.46081 15.04 7.46081 15.04L7.46088 15.0397Z'
        fill='white'
      />
    </svg>
  );
}
