import React from "react";
import { cn } from "@/frontend/lib/utils";

export default function Or({ className }) {
  return (
    <div
      className={cn(
        "text-muted-foreground text-sm grid grid-cols-[1fr_max-content_1fr] gap-x-4 items-center leading-6 tracking-tight font-medium before:content-[''] after:content-[''] before:block after:block before:h-[1px] after:h-[1px] before:bg-gray-200 after:bg-gray-200",
        className
      )}
    >
      OR
    </div>
  );
}
