import React from "react";

export function MicrosoftTeams(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      {...props}
    >
      <path
        d='M12.6637 6.92212H17.3123C17.7537 6.92212 18.1087 7.27439 18.1087 7.7124V11.9161C18.1087 13.5205 16.7991 14.8154 15.1871 14.8154H15.1727C13.5559 14.8154 12.2511 13.5157 12.2511 11.9161V7.33627C12.2462 7.10776 12.4333 6.92212 12.6637 6.92212Z'
        fill='#5059C9'
      />
      <path
        d='M15.8053 6.09354C16.8466 6.09354 17.6908 5.25581 17.6908 4.22251C17.6908 3.1892 16.8466 2.35156 15.8053 2.35156C14.764 2.35156 13.9199 3.1892 13.9199 4.22251C13.9199 5.25581 14.764 6.09354 15.8053 6.09354Z'
        fill='#5059C9'
      />
      <path
        d='M9.94282 6.08886C11.4451 6.08886 12.663 4.88028 12.663 3.38952C12.663 1.89868 11.4451 0.690186 9.94282 0.690186C8.44051 0.690186 7.22266 1.89868 7.22266 3.38952C7.22266 4.88028 8.44051 6.08886 9.94282 6.08886Z'
        fill='#7B83EB'
      />
      <path
        d='M13.5748 6.92212H5.89885C5.46709 6.93163 5.12167 7.28874 5.13126 7.7219V12.5159C5.0689 15.101 7.13179 17.2482 9.73679 17.3101C12.3418 17.2482 14.4047 15.101 14.3423 12.5159V7.7219C14.3519 7.28874 14.0065 6.93163 13.5748 6.92212Z'
        fill='#7B83EB'
      />
      <path
        opacity='0.1'
        d='M10.1539 6.92212V13.6395C10.1539 13.949 9.96207 14.2251 9.67902 14.3394C9.58784 14.3775 9.48716 14.3965 9.39114 14.3965H5.50046C5.44769 14.2584 5.39492 14.1203 5.35175 13.9823C5.20302 13.5062 5.13106 13.0111 5.13106 12.5112V7.7219C5.12146 7.29349 5.46209 6.93638 5.89865 6.92212H10.1539Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M9.73665 6.92212V14.0537C9.73665 14.1537 9.71746 14.2489 9.67909 14.3394C9.55913 14.625 9.28093 14.8106 8.97386 14.8106H5.70204C5.63008 14.6726 5.56291 14.5345 5.50534 14.3965C5.44777 14.2584 5.3998 14.1203 5.35662 13.9823C5.2079 13.5062 5.13594 13.0111 5.13594 12.5112V7.7219C5.12635 7.29349 5.46696 6.93638 5.90353 6.92212H9.73665Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M9.73658 6.92212V13.2253C9.73183 13.6442 9.39114 13.9822 8.96903 13.9871H5.35175C5.20302 13.511 5.13106 13.0158 5.13106 12.5159V7.7219C5.12146 7.29349 5.46209 6.93638 5.89865 6.92212H9.73658Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M9.31431 6.92212V13.2253C9.30956 13.6442 8.96896 13.9822 8.54677 13.9871H5.34685C5.19813 13.511 5.12617 13.0158 5.12617 12.5159V7.7219C5.11658 7.29349 5.45719 6.93638 5.89376 6.92212H9.31431Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M10.1542 4.77487V6.08405C10.0822 6.0888 10.0151 6.09356 9.94309 6.09356C9.87118 6.09356 9.80394 6.0888 9.73203 6.08405C9.59289 6.07454 9.44899 6.05069 9.3146 6.01742C8.46549 5.81743 7.76507 5.22714 7.42924 4.43202C7.37168 4.29875 7.3285 4.16073 7.29492 4.01787H9.38184C9.80878 4.01312 10.1542 4.35588 10.1542 4.77487Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M9.73735 5.18913V6.08416C9.5982 6.07465 9.45431 6.0508 9.32 6.01752C8.47081 5.81753 7.77039 5.22725 7.43457 4.43213H8.96972C9.39191 4.43213 9.73251 4.77014 9.73735 5.18913Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M9.73735 5.18913V6.08416C9.5982 6.07465 9.45431 6.0508 9.32 6.01752C8.47081 5.81753 7.77039 5.22725 7.43457 4.43213H8.96972C9.39191 4.43213 9.73251 4.77014 9.73735 5.18913Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M9.31508 5.18913V6.01752C8.46593 5.81753 7.7655 5.22725 7.42969 4.43213H8.54745C8.97448 4.43213 9.31508 4.77014 9.31508 5.18913Z'
        fill='black'
      />
      <path
        d='M0.875986 4.42725H8.55187C8.97406 4.42725 9.31951 4.77001 9.31951 5.18892V12.8061C9.31951 13.225 8.97406 13.5678 8.55187 13.5678H0.875986C0.453814 13.5678 0.108398 13.225 0.108398 12.8061V5.18892C0.108398 4.77001 0.449016 4.42725 0.875986 4.42725Z'
        fill='url(#paint0_linear_157479_2022)'
      />
      <path
        d='M6.72898 7.32627H5.1938V11.4729H4.21992V7.32627H2.69434V6.52173H6.73377V7.32627H6.72898Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_157479_2022'
          x1='1.70633'
          y1='3.83411'
          x2='7.64782'
          y2='14.2039'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5A62C3' />
          <stop offset='0.5' stopColor='#4D55BD' />
          <stop offset='1' stopColor='#3940AB' />
        </linearGradient>
      </defs>
    </svg>
  );
}
