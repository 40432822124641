import React, { useCallback, useMemo, useRef } from "react";
import { PageWrapperWithMenu } from "../../frontend/elements/PageWrapperWithMenu";
import { useEffect } from "react";
import "./WebChatConfigurationPage.scss";
import { useAPI } from "../../frontend/components/APIProvider";
import { PageHeader } from "../../frontend/elements/PageHeader";
import { PageSection } from "../../frontend/elements/PageSection";
import {
  CircularProgress,
  Button,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import AvatarSelector from "./AvatarSelector";
import { useParams } from "react-router-dom";
import { useFrontendConfiguration } from "../../frontend/components/FrontendConfigurationProvider";
import { toBase64 } from "../../frontend/utils/file-helper";
import { useSnackbar } from "../../frontend/components/SnackbarProvider";
import UploadIcon from "@mui/icons-material/Upload";
import { ConversationAvatar } from "../../conversations/frontend/ConversationAvatar";
import TextField from "@mui/material/TextField";
import { SpinnerButton } from "@/frontend/elements/SpinnerButton";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

function generateCodeSnippedForClientToken(clientToken, frontendConfig) {
  const frontendUrlBase = frontendConfig.frontend.plugin_root.replace(
    /\/$/,
    ""
  );
  const pluginSourceUrl = `${frontendUrlBase}/static/js/plugin.js`;
  let clientTokenCode = `<!-- Start of plugin code -->\n`;
  clientTokenCode += `<script>\n`;
  clientTokenCode += `    (function() {\n`;
  clientTokenCode += `        var w = window;\n`;
  clientTokenCode += `        var d = document;\n`;
  clientTokenCode += `        var s = d.createElement('script');\n`;
  clientTokenCode += `        s.src = '${pluginSourceUrl}';\n`;
  clientTokenCode += `        s.id = 'plugin-${clientToken}';\n`;
  clientTokenCode += `        s.onload = function() {\n`;
  clientTokenCode += `            w.WebChat.init({\n`;
  clientTokenCode += `                clientToken: '${clientToken}'\n`;
  clientTokenCode += `            });\n`;
  clientTokenCode += `        };\n`;
  clientTokenCode += `        d.body.appendChild(s);\n`;
  clientTokenCode += `    })();\n`;
  clientTokenCode += `</script>\n`;
  clientTokenCode += `<!-- End of plugin code -->\n`;
  return clientTokenCode;
}

const generateCodeSnippedForIntakeToken = (intakeToken, frontendConfig) => {
  const frontendUrlBase = frontendConfig.frontend.plugin_root.replace(
    /\/$/,
    ""
  );
  const pluginSourceUrl = `${frontendUrlBase}/static/js/plugin.js`;
  let intakeTokenCode = `<!-- Start of plugin code -->\n`;
  intakeTokenCode += `<script>\n`;
  intakeTokenCode += `    (function() {\n`;
  intakeTokenCode += `        var w = window;\n`;
  intakeTokenCode += `        var d = document;\n`;
  intakeTokenCode += `        var s = d.createElement('script');\n`;
  intakeTokenCode += `        s.src = '${pluginSourceUrl}';\n`;
  intakeTokenCode += `        s.id = 'plugin-${intakeToken}';\n`;
  intakeTokenCode += `        s.onload = function() {\n`;
  intakeTokenCode += `            w.IntakeChat.init({\n`;
  intakeTokenCode += `                intakeToken: '${intakeToken}'\n`;
  intakeTokenCode += `            });\n`;
  intakeTokenCode += `        };\n`;
  intakeTokenCode += `        d.body.appendChild(s);\n`;
  intakeTokenCode += `    })();\n`;
  intakeTokenCode += `</script>\n`;
  intakeTokenCode += `<!-- End of plugin code -->\n`;
  return intakeTokenCode;
};

export const WebChatConfigurationPage = () => {
  const api = useAPI();
  const [webChatConfiguration, setWebChatConfiguration] = React.useState(null);
  const frontendConfiguration = useFrontendConfiguration();
  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadingAvatar, setUploadingAvatar] = React.useState(false);
  const [uploadingBackgroundWatermark, setUploadingBackgroundWatermark] =
    React.useState(false);
  const codeSnippetRef = useRef(null);
  const avatarFileInputRef = useRef(null);
  const backgroundWatermarkFileInputRef = useRef(null);
  const params = useParams();
  const intakeToken = params?.intakeToken || "";
  const snackbar = useSnackbar();

  const loadWebChatConfiguration = useCallback(() => {
    setIsLoading(true);
    api.getWebChatConfiguration().then(
      (data) => {
        setIsLoading(false);
        setWebChatConfiguration(data);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [api]);

  useEffect(() => {
    if (!webChatConfiguration) {
      loadWebChatConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webChatConfiguration]);

  const codeSnippet = useMemo(() => {
    if (webChatConfiguration) {
      return intakeToken
        ? generateCodeSnippedForIntakeToken(intakeToken, frontendConfiguration)
        : generateCodeSnippedForClientToken(
            webChatConfiguration.client_token,
            frontendConfiguration
          );
    }
    return null;
  }, [webChatConfiguration, intakeToken]);

  const botLandingPageURL = useMemo(() => {
    if (webChatConfiguration) {
      const frontendUrlBase =
        frontendConfiguration.frontend.plugin_root.replace(/\/$/, "");
      const botLandingURL = intakeToken
        ? `${frontendUrlBase}/full-screen/intake/${intakeToken}`
        : `${frontendUrlBase}/full-screen/${webChatConfiguration.client_token}`;
      return botLandingURL;
    }
    return null;
  }, [webChatConfiguration, intakeToken]);

  const handleChangedBotAvatar = useCallback(
    (avatar_url) => {
      const newWebChatConfiguration = {
        ...webChatConfiguration,
        avatar_url: avatar_url,
      };
      setWebChatConfiguration(newWebChatConfiguration);
      api.saveWebChatConfiguration(newWebChatConfiguration);
    },
    [api, webChatConfiguration]
  );

  const handleUploadAvatarClick = useCallback(() => {
    avatarFileInputRef.current.click();
  }, []);

  const handleUploadBackgroundWatermarkClick = useCallback(() => {
    backgroundWatermarkFileInputRef.current.click();
  }, []);

  const handleImageUpload = useCallback(
    async (e, imageFieldName) => {
      const file = e.target.files[0];
      if (file) {
        try {
          const base64Image = await toBase64(file);
          const base64String = base64Image.split(",")[1];
          const payload = {
            file: base64String,
          };
          const response = await api.uploadImage(payload);
          if (response && response._id) {
            // Update configuration with the new image URL
            const newWebChatConfiguration = {
              ...webChatConfiguration,
              [imageFieldName]: response.image_url,
            };
            setWebChatConfiguration(newWebChatConfiguration);
            api.saveWebChatConfiguration(newWebChatConfiguration);

            snackbar.toast({
              message: "Image uploaded successfully",
              severity: "success",
              autoHideMs: 3000,
            });
          }
        } catch (error) {
          setUploadingAvatar(false);
          setUploadingBackgroundWatermark(false);
          snackbar.toast({
            message: error?.response?.data?.message || "Error uploading image",
            severity: "error",
            autoHideMs: 5000,
          });
        } finally {
          e.target.value = null;
        }
      }
    },
    [api, handleChangedBotAvatar, snackbar]
  );

  const handleAvatarUpload = useCallback(
    async (e) => {
      setUploadingAvatar(true);
      await handleImageUpload(e, "avatar_url");
      setUploadingAvatar(false);
    },
    [handleImageUpload]
  );

  const handleBackgroundWatermarkUpload = useCallback(
    async (e) => {
      setUploadingBackgroundWatermark(true);
      await handleImageUpload(e, "background_watermark_url");
      setUploadingBackgroundWatermark(false);
    },
    [handleImageUpload]
  );

  const handleDeleteBackgroundWatermarkClicked = useCallback(() => {
    const newWebChatConfiguration = {
      ...webChatConfiguration,
      background_watermark_url: null,
    };
    setWebChatConfiguration(newWebChatConfiguration);
    api.saveWebChatConfiguration(newWebChatConfiguration);
  }, [api, webChatConfiguration]);

  const handleChangeTextField = useCallback(
    (fieldName, evt) => {
      const newWebChatConfiguration = {
        ...webChatConfiguration,
        [fieldName]: evt.target.value,
      };
      setWebChatConfiguration(newWebChatConfiguration);
    },
    [webChatConfiguration]
  );

  const handleChangeShowChatButtonCallout = useCallback(
    (evt) => {
      const newWebChatConfiguration = {
        ...webChatConfiguration,
        show_chat_button_callout: evt.target.checked,
      };
      setWebChatConfiguration(newWebChatConfiguration);
    },
    [webChatConfiguration]
  );

  const handleSaveClicked = useCallback(() => {
    return api.saveWebChatConfiguration(webChatConfiguration);
  }, [api, webChatConfiguration]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={`Web Chat Configuration`}
        subtitle={`Configure the appearance and behavior of your chat-bot.`}
      />
      <div className='web-chat-configuration-page'>
        {isLoading ? <CircularProgress /> : null}
        {webChatConfiguration ? (
          <>
            <PageSection
              title={"Web Chat Code Snippet"}
              subheading={
                "Please put this code snippet into your website to enable your chat-bot."
              }
            >
              <pre className='code-snippet' ref={codeSnippetRef}>
                {codeSnippet}
              </pre>
            </PageSection>
            <PageSection
              title={"Bot Landing Page URL"}
              subheading={
                "You can share this link with other people, and they will be able to chat directly with your bot!"
              }
            >
              <pre className='code-snippet' ref={codeSnippetRef}>
                {botLandingPageURL}
              </pre>
            </PageSection>
            <PageSection
              title={"Avatar"}
              subheading={
                "Change the icon used for your bot when it appears in the chat window."
              }
            >
              <h2>Current Avatar</h2>
              <ConversationAvatar
                avatarUrl={webChatConfiguration.avatar_url}
                role={"assistant"}
                moduleId={"user_interactor"}
              />
              <br />
              <h2>Change Avatar</h2>
              <AvatarSelector
                value={webChatConfiguration.avatar_url}
                onChange={handleChangedBotAvatar}
              />
              <div style={{ marginBottom: "20px" }}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<UploadIcon />}
                  onClick={handleUploadAvatarClick}
                  disabled={uploadingAvatar}
                  style={{ marginRight: "10px" }}
                >
                  {uploadingAvatar ? "Uploading..." : "Upload Custom Avatar"}
                  &nbsp; &nbsp;
                  {uploadingAvatar ? <CircularProgress size={20} /> : null}
                </Button>
                <input
                  type='file'
                  ref={avatarFileInputRef}
                  onChange={handleAvatarUpload}
                  accept='image/*'
                  style={{ display: "none" }}
                />
                <span style={{ fontSize: "0.875rem", color: "#666" }}>
                  Upload a custom image to use as your bot's avatar
                </span>
              </div>
            </PageSection>
            <PageSection
              title={"Styles & Colors"}
              subheading={
                "These settings allow you to edit the visual appearance of the chat window."
              }
            >
              <h2>Background Watermark Image</h2>

              {webChatConfiguration.background_watermark_url ? (
                <img
                  className={"background-watermark-preview"}
                  src={webChatConfiguration.background_watermark_url}
                  alt={"Background Watermark"}
                  style={{ maxWidth: "100%", marginBottom: "20px" }}
                />
              ) : null}

              <div style={{ marginBottom: "20px" }}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<UploadIcon />}
                  onClick={handleUploadBackgroundWatermarkClick}
                  disabled={uploadingBackgroundWatermark}
                  style={{ marginRight: "10px" }}
                >
                  {uploadingBackgroundWatermark
                    ? "Uploading..."
                    : "Upload Background Watermark"}
                  &nbsp; &nbsp;
                  {uploadingBackgroundWatermark ? (
                    <CircularProgress size={20} />
                  ) : null}
                </Button>
                <input
                  type='file'
                  ref={backgroundWatermarkFileInputRef}
                  onChange={handleBackgroundWatermarkUpload}
                  accept='image/*'
                  style={{ display: "none" }}
                />
                <span style={{ fontSize: "0.875rem", color: "#666" }}>
                  Upload a custom image to be used as a watermark in the
                  background of the chat window.
                </span>
              </div>
              {webChatConfiguration.background_watermark_url ? (
                <div>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={handleDeleteBackgroundWatermarkClicked}
                  >
                    Delete Background Watermark
                  </Button>
                </div>
              ) : null}

              <h2>Colors</h2>

              <TextField
                label='Widget Background Color'
                value={webChatConfiguration.widget_background_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "widget_background_color"
                )}
              />

              <TextField
                label='User Message Background Color'
                value={webChatConfiguration.user_message_background_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "user_message_background_color"
                )}
              />

              <TextField
                label='User Message Font Color'
                value={webChatConfiguration.user_message_font_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "user_message_font_color"
                )}
              />

              <TextField
                label='Assistant Message Background Color'
                value={webChatConfiguration.assistant_message_background_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "assistant_message_background_color"
                )}
              />

              <TextField
                label='Assistant Message Font Color'
                value={webChatConfiguration.assistant_message_font_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "assistant_message_font_color"
                )}
              />

              <TextField
                label='Tool Message Background Color'
                value={webChatConfiguration.tool_message_background_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "tool_message_background_color"
                )}
              />

              <TextField
                label='Tool Message Font Color'
                value={webChatConfiguration.tool_message_font_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "tool_message_font_color"
                )}
              />

              <TextField
                label='Role Name Font Color'
                value={webChatConfiguration.role_name_font_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "role_name_font_color"
                )}
              />

              <TextField
                label='Role Icon Color'
                value={webChatConfiguration.role_icon_color}
                onChange={handleChangeTextField.bind(this, "role_icon_color")}
              />

              <TextField
                label='Event Date Font Color'
                value={webChatConfiguration.event_date_font_color}
                onChange={handleChangeTextField.bind(
                  this,
                  "event_date_font_color"
                )}
              />

              <TextField
                label='Chat with Bot Button Color'
                value={webChatConfiguration.chat_button_color}
                onChange={handleChangeTextField.bind(this, "chat_button_color")}
              />

              <h2>Callout</h2>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        webChatConfiguration?.show_chat_button_callout ?? true
                      }
                      onChange={handleChangeShowChatButtonCallout}
                    />
                  }
                  label={
                    <>
                      <Typography>Enable web-chat callout?</Typography>
                      <Typography gutterBottom sx={{ fontSize: 12 }}>
                        Shows a customizable tooltip on the user interface above
                        the chat button to draw attention from the user.
                      </Typography>
                    </>
                  }
                />
              </FormGroup>

              <TextField
                label='Chat Button Callout Text'
                value={webChatConfiguration.chat_button_callout_text}
                onChange={handleChangeTextField.bind(
                  this,
                  "chat_button_callout_text"
                )}
              />

              <SpinnerButton
                variant='contained'
                color='primary'
                onClick={handleSaveClicked}
              >
                Save
              </SpinnerButton>
            </PageSection>
          </>
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
};

export default WebChatConfigurationPage;
