import React from "react";

export function Polygon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='48'
      viewBox='0 0 50 48'
      fill='none'
      className={props.className}
      {...props}
    >
      <path
        d='M1.09543 18.2329L25 0.865247L48.9046 18.2329L39.7738 46.3344H10.2262L1.09543 18.2329Z'
        stroke='currentColor'
        strokeWidth='1.4'
      />
    </svg>
  );
}
