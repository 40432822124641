import React from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TopNav from "../../components/TopNav";
import Back from "../../components/Back";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import Next from "../../components/Next";
import { Button } from "@/frontend/components/ui/button";
import dayjs from "dayjs";

export default function MarketplacePreview({
  steps,
  currentStep,
  isSubmitting,
  handlePrev,
}) {
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const values = getValues();

  const onNavigate = (path) => {
    navigate(`/onboarding/${path}`);
  };

  return (
    <div className="flex flex-col">
      <TopNav steps={steps} currentStep={currentStep} />
      <Back handlePrev={handlePrev} />
      <div className="flex flex-col gap-10 justify-center items-center">
        <h4 className="text-center text-gray-900 text-3xl font-bold">
          <LocalTranslatedText text="Preview Details" />
        </h4>
        <div className="w-full lg:w-1/2 px-8 mx-auto flex flex-col gap-5">
          <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center py-5 border-b border-slate-300">
            <div className="flex flex-col gap-2 flex-1">
              <h4 className="leading-6 font-semibold text-base">
                <LocalTranslatedText text="Basic Information" />
              </h4>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="Name:" />
                </span>{" "}
                <span>{`${values?.first_name} ${values?.last_name}`}</span>
              </p>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="Company Name:" />
                </span>{" "}
                <span>{values?.company_name}</span>
              </p>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="Industry:" />
                </span>{" "}
                <span>{values?.industry}</span>
              </p>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="Company Size:" />
                </span>{" "}
                <span>{values?.company_size}</span>
              </p>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="Revenue:" />
                </span>{" "}
                <span>{values?.annual_revenue} (USD)</span>
              </p>
            </div>
            <Button
              variant="outline"
              className="border-slate-950"
              type="button"
              onClick={() => onNavigate("basic-information")}
            >
              Edit
            </Button>
          </div>
          <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center py-5 border-b border-slate-300">
            <div className="flex flex-col gap-2 flex-1">
              <h4 className="leading-6 font-semibold text-base">
                <LocalTranslatedText text="Service Selections" />
              </h4>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="AI Agents:" />
                </span>{" "}
                <span>{values?.agent_marketplace?.join(", ")}</span>
              </p>
            </div>
            <Button
              variant="outline"
              className="border-slate-950"
              type="button"
              onClick={() => onNavigate("service-selection/services")}
            >
              Edit
            </Button>
          </div>
          <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center py-5 border-b border-slate-300">
            <div className="flex flex-col gap-2 flex-1">
              <h4 className="leading-6 font-semibold text-base">
                <LocalTranslatedText text="Workflow Tools" />
              </h4>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="Tools:" />
                </span>{" "}
                <span>{values?.workflow_tools?.join(", ")}</span>
              </p>
            </div>
            <Button
              variant="outline"
              className="border-slate-950"
              type="button"
              onClick={() => onNavigate("service-selection/tools")}
            >
              Edit
            </Button>
          </div>
          <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center py-5">
            <div className="flex flex-col gap-2 flex-1">
              <h4 className="leading-6 font-semibold text-base">
                <LocalTranslatedText text="Marketplace Setup" />
              </h4>
              <p className="text-base leading-6 text-muted-foreground">
                <span className="font-medium">
                  <LocalTranslatedText text="Specialist:" />
                </span>{" "}
                <span>{`${
                  values?.marketplace_specialist?.name
                }, zoom on ${dayjs(
                  values?.marketplace_specialist
                    ?.setup_datetime_in_user_timezone
                ).format("MM/DD/YYYY HH:mmA")}`}</span>
              </p>
            </div>
            <Button
              variant="outline"
              className="border-slate-950"
              type="button"
              onClick={() => onNavigate("marketplace-setup")}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
      <Next isSubmitting={isSubmitting} text="Get Started" />
    </div>
  );
}
