import React from "react";
import bgImage from "@/frontend/assets/img/auth-wrapper-bg.png";
import { CheckMark } from "@/frontend/icons/CheckMark";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function AuthPageWrapper({ title, items, children }) {
  return (
    <div className='relative flex min-h-screen bg-background'>
      <div
        className='w-1/2 hidden lg:flex flex-col justify-center items-center relative h-screen bg-cover bg-center bg-no-repeat bg-primary'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className='absolute inset-0 top-0 left-0 bg-gradient-to-br from-indigo-600 to-[#14B8A6] opacity-80'></div>
        <div className='w-[451px] z-10 flex flex-col gap-[54px]'>
          <h1 className='text-5xl text-white font-semibold tracking-[0.24px] leading-normal'>
            <LocalTranslatedText text={title} />
          </h1>
          <div className='flex flex-col gap-8'>
            {items?.map((item, index) => (
              <div className='flex items-baseline gap-5' key={index}>
                <div className='w-[26px] h-[26px] rounded-sm bg-white flex justify-center items-center shrink-0'>
                  <CheckMark className='text-primary' />
                </div>
                <p className='text-white text-2xl font-medium tracking-[0.12px] leading-normal'>
                  <LocalTranslatedText text={item} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='w-full lg:w-1/2 h-screen flex flex-col justify-center items-center'>
        {children}
      </div>
    </div>
  );
}
