import { Button, Grid } from "@mui/material";
import React from "react";
import RHFTextField from "../../../../frontend/elements/form/hook-form/RHFTextField";
import "./EditIntakeForm.scss";
import RHFCheckboxField from "../../../../frontend/elements/form/hook-form/RHFCheckboxField";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomAccordion from "../../../../frontend/elements/CustomAccordion";
import AddIcon from "@mui/icons-material/Add";
import RHFTagsInputField from "@/frontend/elements/form/hook-form/RHFTagsInputField";

export default function QuestionsContainer({
  questionIndex,
  removeQuestionField,
  isDisabled,
  onInserQuestionField,
  questionsFields,
}) {
  return (
    <div className='card'>
      <CustomAccordion
        key={questionIndex}
        title={`Question ${questionIndex + 1}`}
        defaultExpanded={true}
      >
        <div className='detail-box'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField
                name={`questions.${questionIndex}.question_text`}
                placeholder='Enter the question...'
                label='Question'
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                name={`questions.${questionIndex}.description`}
                placeholder='Provide additional information and context about the question for the AI here...'
                label='Description'
                multiline={true}
                minRows={2}
                autoSize={true}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTagsInputField
                name={`questions.${questionIndex}.required_data_points`}
                placeholder='Provide a list of all the data points that must be collected before this question is considered answered.'
                label='Required Data Points'
              />
            </Grid>
            <Grid item xs={6}>
              <RHFCheckboxField
                name={`questions.${questionIndex}.is_required`}
                label='Check if this question is required'
              />
            </Grid>
            <Grid item xs={12}>
              {questionsFields.length > 1 &&
              questionIndex !== questionsFields.length - 1 ? (
                <Button
                  type='button'
                  onClick={() => onInserQuestionField(questionIndex)}
                  variant='outlined'
                  color='info'
                  startIcon={<AddIcon />}
                  sx={{ mt: 2 }}
                >
                  Add a question under question {questionIndex + 1}
                </Button>
              ) : null}

              <Button
                type='button'
                onClick={() => removeQuestionField(questionIndex)}
                variant='outlined'
                color='error'
                startIcon={<DeleteIcon />}
                sx={{ mt: 2, ml: 2 }}
                disabled={isDisabled}
              >
                Remove Question
              </Button>
            </Grid>
          </Grid>
        </div>
      </CustomAccordion>
    </div>
  );
}
