import { useAPI } from "@/frontend/components/APIProvider";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import TopNav from "../../components/TopNav";
import Back from "../../components/Back";
import Next from "../../components/Next";
import MarketplaceServices from "./MarketplaceServices";
import MarketplaceTools from "./MarketplaceTools";

export default function MarketplaceServiceSelection({
  steps,
  currentStep,
  handlePrev,
  fetchOnboardingStatus,
  onboardingStatus,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getValues } = useFormContext();
  const api = useAPI();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkServiceRoute = location.pathname.includes("services");
  const checkToolsRoute = location.pathname.includes("tools");

  const handleNext = async () => {
    const values = getValues();
    try {
      setIsSubmitting(true);
      await api.saveOnboardingStatus(values);
      setIsSubmitting(false);
      fetchOnboardingStatus();
      navigate(`/onboarding/${steps[currentStep]}/tools`);
    } catch (e) {
      setIsSubmitting(false);
      console.error("Error saving onboarding status:", e);
    }
  };

  const handleBack = () => {
    navigate(`/onboarding/${steps[currentStep]}/services`);
  };

  const renderButton = () => {
    if (checkToolsRoute) {
      return <Next isSubmitting={isSubmitting} />;
    } else if (checkServiceRoute) {
      return (
        <Next isSubmitting={isSubmitting} onClick={handleNext} type='button' />
      );
    }
  };

  return (
    <div className='flex flex-col'>
      <TopNav steps={steps} currentStep={currentStep} />
      <Back handlePrev={checkToolsRoute ? handleBack : handlePrev} />
      <Routes>
        <Route index path='' element={<Navigate to='services' />} />
        <Route
          path='services/*'
          element={
            <MarketplaceServices
              onboardingStatus={onboardingStatus}
              fetchOnboardingStatus={fetchOnboardingStatus}
            />
          }
        />
        <Route
          path='tools/*'
          element={
            <MarketplaceTools
              onboardingStatus={onboardingStatus}
              fetchOnboardingStatus={fetchOnboardingStatus}
            />
          }
        />
      </Routes>

      {renderButton()}
    </div>
  );
}
