import pages from "./pages";

const importPage = (pageKey) => {
  if (!pages[pageKey]) {
    throw new Error(`Invalid import: ${pageKey}`);
  }
  return pages[pageKey];
};

const routes = [
  { path: "/", element: importPage("HomePage") },
  { path: "/health", element: importPage("SystemHealthPage") },
  { path: "/health_check", element: importPage("SystemHealthPage") },
  { path: "/system_health", element: importPage("SystemHealthPage") },
  { path: "/system", element: importPage("AboutSystemPage") },
  { path: "/about", element: importPage("AboutSystemPage") },
  {
    path: "/tenant_configuration",
    element: importPage("TenantConfigurationsListPage"),
  },
  {
    path: "/tenant_configuration/new",
    element: importPage("NewTenantConfigurationsPage"),
  },
  {
    path: "/tenant_configuration/:tenantConfigurationId",
    element: importPage("TenantConfigurationsEditPage"),
  },
  { path: "/conversations", element: importPage("ConversationsListPage") },
  {
    path: "/conversations/:conversationId",
    element: importPage("ConversationViewPage"),
  },
  { path: "/business_info", element: importPage("BusinessInfoPage") },
  { path: "/ai_configuration", element: importPage("AIConfigurationPage") },
  { path: "/scheduling_rules", element: importPage("SchedulingRulesPage") },
  { path: "/chat", element: importPage("ChatWithBotPage") },
  {
    path: "/voice_configuration",
    element: importPage("VoiceConfigurationPage"),
  },
  {
    path: "/wa_2chat_configuration",
    element: importPage("WA2ChatConfigurationPage"),
  },
  { path: "/bookings/new", element: importPage("NewBookingPage") },
  { path: "/bookings/:bookingId", element: importPage("EditBookingPage") },
  { path: "/bookings", element: importPage("BookingsListPage") },
  { path: "/synthetic_user", element: importPage("RunSyntheticUserPage") },
  { path: "/calendars/new", element: importPage("NewCalendarPage") },
  { path: "/calendars/:calendarId", element: importPage("EditCalendarPage") },
  { path: "/calendars", element: importPage("CalendarListPage") },
  {
    path: "/web_chat_configuration",
    element: importPage("WebChatConfigurationPage"),
  },
  { path: "/privacy_policy", element: importPage("PrivacyPolicyPage") },
  {
    path: "/terms_and_conditions",
    element: importPage("TermsAndConditionsPage"),
  },
  { path: "/subscription", element: importPage("SubscriptionPage") },
  {
    path: "/imported_document/:documentId",
    element: importPage("ImportedDocumentEditPage"),
  },
  {
    path: "/imported_documents/new",
    element: importPage("NewImportedDocumentPage"),
  },
  {
    path: "/imported_documents/upload",
    element: importPage("BulkDocumentUploadPage"),
  },
  {
    path: "/imported_documents",
    element: importPage("ImportedDocumentListPage"),
  },
  { path: "/knowledge", element: importPage("KnowledgeChunkListPage") },
  {
    path: "/knowledge/:knowledgeChunkId",
    element: importPage("KnowledgeChunkViewPage"),
  },
  {
    path: "/default_outbound_rules",
    element: importPage("DefaultOutboundRulesPage"),
  },
  {
    path: "/document_template",
    element: importPage("DocumentTemplateListPage"),
  },
  {
    path: "/document_template/:documentTemplateId",
    element: importPage("DocumentTemplateEditPage"),
  },
  {
    path: "/generated_document",
    element: importPage("GeneratedDocumentListPage"),
  },
  {
    path: "/generated_document/:generatedDocumentId",
    element: importPage("GeneratedDocumentEditPage"),
  },
  {
    path: "/imported_webpages",
    element: importPage("ImportedWebpageListPage"),
  },
  {
    path: "/imported_webpages/import",
    element: importPage("ImportWebpagePage"),
  },
  {
    path: "/imported_webpages/:importedWebpageId",
    element: importPage("ImportedWebpageEditPage"),
  },
  { path: "/intake/start", element: importPage("IntakeStartPage") },
  { path: "/intakes", element: importPage("IntakeListPage") },
  { path: "/intakes/:intakeId/*", element: importPage("IntakeEditorPage") },
  {
    path: "/intakes/config/:intakeToken/*",
    element: importPage("WebChatConfigurationPage"),
  },
  {
    path: "/intakes/chat/:intakeToken/*",
    element: importPage("ChatWithBotPage"),
  },
  { path: "/filled_intakes", element: importPage("FilledIntakeListPage") },
  {
    path: "/filled_intakes/:filledIntakeId",
    element: importPage("ViewFilledIntakePage"),
  },
  {
    path: "/default_intake_configuration",
    element: importPage("DefaultIntakeConfigurationPage"),
  },
  { path: "/smart_chains", element: importPage("SmartChainListPage") },
  {
    path: "/smart_chains/:smartChainId/*",
    element: importPage("SmartChainEditorPage"),
  },
  {
    path: "/smart_chain_binding/new",
    element: importPage("NewSmartChainBindingPage"),
  },
  {
    path: "/smart_chain_binding",
    element: importPage("SmartChainBindingListPage"),
  },
  {
    path: "/custom_data_schema",
    element: importPage("CustomDataSchemaListPage"),
  },
  {
    path: "/custom_data_schema/new",
    element: importPage("NewCustomDataSchemaPage"),
  },
  {
    path: "/custom_data_schema/:customDataSchemaId",
    element: importPage("CustomDataSchemaEditPage"),
  },
  { path: "/custom_form", element: importPage("CustomFormListPage") },
  { path: "/custom_form/new", element: importPage("NewCustomFormPage") },
  {
    path: "/custom_form/:customFormId",
    element: importPage("CustomFormEditPage"),
  },
  {
    path: "/c/:customDataSchemaId",
    element: importPage("CustomDataObjectListPage"),
  },
  {
    path: "/c/:customDataSchemaId/upload",
    element: importPage("CustomDataObjectUploadPage"),
  },
  {
    path: "/c/:customDataSchemaId/new",
    element: importPage("NewCustomDataObjectPage"),
  },
  {
    path: "/c/:customDataSchemaId/:customDataObjectId",
    element: importPage("CustomDataObjectEditPage"),
  },
  { path: "/cf/:customFormId", element: importPage("UseCustomFormPage") },
  { path: "/impersonate", element: importPage("ImpersonatePage") },
  { path: "/auth/login", element: importPage("LoginPage") },
  { path: "/auth/sign-up", element: importPage("SignupPage") },
  {
    path: "/onboarding/*",
    element: importPage("OnboardingFlowPage"),
  },
  {
    path: "/email_configuration",
    element: importPage("EmailConfigurationPage"),
  },
  { path: "/analysis/perform", element: importPage("PerformAnalysisPage") },
  { path: "/analysis", element: importPage("AnalysisListPage") },
  { path: "/analysis/:analysisId", element: importPage("AnalysisViewPage") },
  {
    path: "/tenant_introductory_settings",
    element: importPage("TenantIntroductoryConfigurationEditPage"),
  },
  {
    path: "/tenant_menu_settings",
    element: importPage("TenantMenuConfigurationEditPage"),
  },
];

export default routes;
