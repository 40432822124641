import { useDroppable } from "@dnd-kit/core";
import React, { useCallback } from "react";
import DraggableMenuItem from "./DraggableMenuItem";
import { SortableContext } from "@dnd-kit/sortable";
import { randomString } from "../utils/random";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function DroppableMenuContainer({
  parentId,
  onMove,
  setItems,
  childItems,
  allMenuLinks,
}) {
  const { setNodeRef } = useDroppable({
    id: parentId,
  });

  const handleAddChildMenuItem = useCallback(() => {
    const newMenuItem = {
      id: randomString(16),
      title: "Enter Child Menu Name",
      link: "",
      icon: "",
      is_hidden: false,
      children: [],
      is_admin: false,
    };

    const addChildToParent = (items) => {
      return items.map((item) => {
        if (item.id === parentId) {
          // If the item is the parent, add the child
          return { ...item, children: [...item.children, newMenuItem] };
        } else if (item.children.length > 0) {
          // Recursively search in children
          return { ...item, children: addChildToParent(item.children) };
        }
        return item;
      });
    };

    setItems((prevItems) => addChildToParent(prevItems));
  }, [setItems, parentId]);

  const handleRemoveChildMenuItem = (id) => {
    const removeChildById = (items) => {
      return items
        .map((item) => {
          if (item.id === id) {
            return null; // Mark this item for removal
          } else if (item.children.length > 0) {
            return { ...item, children: removeChildById(item.children) };
          }
          return item;
        })
        .filter(Boolean); // Remove null values
    };

    setItems((prevItems) => removeChildById(prevItems));
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <SortableContext items={childItems.map((child) => child.id)}>
        <div
          ref={setNodeRef}
          style={{ paddingLeft: "10px", borderLeft: "1px dashed #666666" }}
        >
          {childItems.map((child) => (
            <DraggableMenuItem
              key={child.id}
              menuItem={child}
              onMove={onMove}
              onRemoveMenuItem={() => handleRemoveChildMenuItem(child.id)}
              setItems={setItems}
              allMenuLinks={allMenuLinks}
            />
          ))}
        </div>
        <span>
          <Button
            variant='outlined'
            color='warning'
            onClick={handleAddChildMenuItem}
            startIcon={<AddIcon />}
          >
            Add Child Menu Item
          </Button>
        </span>
      </SortableContext>
    </Box>
  );
}

export default React.memo(DroppableMenuContainer);
