import { randomString } from "../utils/random";

export function replaceMenuItemTitle(menuItems, oldWord, newWord) {
  return menuItems.map((item) => {
    const updatedItem = { ...item };
    if (updatedItem.title.includes(oldWord)) {
      updatedItem.title = updatedItem.title.replace(oldWord, newWord);
    }

    if (updatedItem.children && updatedItem.children.length > 0) {
      updatedItem.children = replaceMenuItemTitle(
        updatedItem.children,
        oldWord,
        newWord
      );
    }

    return updatedItem;
  });
}

export function applyMenuItemConditions(menuItems, config, isAdmin) {
  return menuItems.map((item) => {
    const updatedItem = { ...item };

    if (updatedItem.title === "Conversations" && !config?.modules?.bot) {
      updatedItem.is_hidden = true;
    }

    if (updatedItem.title === "Intake" && !config?.modules?.intake) {
      updatedItem.is_hidden = true;
    }

    if (updatedItem.title === "Scheduling" && !config?.modules?.scheduling) {
      updatedItem.is_hidden = true;
    }

    if (updatedItem.title === "Web Chat" && !config?.modules?.web_chat) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title === "Knowledge Base" &&
      !config?.modules?.knowledge_base
    ) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title === "Document Generation" &&
      !config?.modules?.document_generator
    ) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title === "Business Info" &&
      !config?.modules?.business_info
    ) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title === "Voice Configuration" &&
      !config?.modules?.voice
    ) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title === "2Chat Configuration" &&
      !config?.modules?.whatsapp
    ) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title === "Email Configuration" &&
      !config?.modules?.email
    ) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title === "Billing & Subscription" &&
      !config?.modules?.billing
    ) {
      updatedItem.is_hidden = true;
    }

    if (
      updatedItem.title ===
        `${config?.frontend?.default_outbound_rules_word} Rules` &&
      !config?.modules?.outbound
    ) {
      updatedItem.is_hidden = true;
    }

    if (updatedItem.title === "Tenants" && !config?.modules?.tenant) {
      updatedItem.is_hidden = true;
    }

    if (updatedItem.title === "Synthetic User" && !config?.modules?.bot) {
      updatedItem.is_hidden = true;
    }

    if (updatedItem.title === "Impersonate" && !isAdmin) {
      updatedItem.is_hidden = true;
    }

    // Recursively apply conditions to children
    if (updatedItem.children && updatedItem.children.length > 0) {
      updatedItem.children = applyMenuItemConditions(
        updatedItem.children,
        config
      );
    }

    return updatedItem;
  });
}

export function addCustomSchemas(customDataSchemas) {
  if (!Array.isArray(customDataSchemas) || customDataSchemas.length === 0) {
    return [];
  }

  return customDataSchemas.map((schema) => ({
    id: randomString(16),
    title: schema.title,
    icon: schema.left_menu_icon,
    link: `/c/${schema.machine_name}`,
    is_hidden: false,
    is_admin: false,
    children: [],
  }));
}

export function addCustomForms(customForms) {
  if (!Array.isArray(customForms) || customForms.length === 0) {
    return [];
  }

  return customForms.map((form) => ({
    id: randomString(16),
    title: form.title,
    icon: form.left_menu_icon,
    link: `/cf/${form.machine_name}`,
    is_hidden: false,
    is_admin: false,
    children: [],
  }));
}

export function getTableQuery(userDetails) {
  return {
    startRow: 0,
    endRow: 1000,
    filterModel: {
      show_in_left_menu: {
        filterType: "set",
        values: [true],
      },
      user_id: {
        filterType: "set",
        values: [userDetails.user_id, null, ""],
      },
    },
    sortModel: [],
    fields: [
      "id",
      "title",
      "machine_name",
      "show_in_left_menu",
      "left_menu_icon",
    ],
  };
}
