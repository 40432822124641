import React, { useEffect } from "react";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import Agent1 from "@/frontend/assets/img/avatar_1.svg";
import Agent2 from "@/frontend/assets/img/avatar_2.svg";
import Agent3 from "@/frontend/assets/img/avatar_3.svg";
import Agent4 from "@/frontend/assets/img/avatar_4.svg";
import Agent5 from "@/frontend/assets/img/avatar_5.svg";
import Agent6 from "@/frontend/assets/img/avatar_6.svg";
import { cn } from "@/frontend/lib/utils";

export const agentVisuals = [
  {
    id: "39aec1bc-9286-42c6-ba4f-3d4a054dfe84",
    content: Agent1,
  },
  {
    id: "d16fe89d-2e1e-4d85-a0ca-115e56e31832",
    content: Agent2,
  },
  {
    id: "75d86303-1bf8-4554-8b4c-acdbc2092f77",
    content: Agent3,
  },
  {
    id: "d39b7320-f9e1-413b-898a-c85c564abfce",
    content: Agent4,
  },
  {
    id: "bf06ec5a-f282-43da-861d-f9e7012227b5",
    content: Agent5,
  },
  {
    id: "e8930588-67df-40a3-bbd8-1915a2ba7afd",
    content: Agent6,
  },
];

export default function AgentVisualSuggestion({
  agentVisual,
  setAgentVisual,
  visualId,
}) {
  useEffect(() => {
    if (visualId) {
      const selectedAgent = agentVisuals.find((agent) => agent.id === visualId);
      setAgentVisual(selectedAgent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualId]);

  return (
    <div className='flex flex-col gap-2 w-full'>
      <h4 className='text-base text-gray-900 leading-normal'>
        <LocalTranslatedText text='Visual Suggestion' />
      </h4>
      <div className='flex items-center gap-2'>
        {agentVisuals.map((agent) => (
          <button
            key={agent.id}
            className={cn(
              "flex w-16 h-16 border-2 justify-center items-center rounded-sm hover:border-primary transition-all ease-in-out duration-300 delay-100 flex-wrap",
              agentVisual?.id === agent.id && "border-primary"
            )}
            type='button'
            onClick={() => setAgentVisual(agent)}
          >
            <img
              src={agent.content}
              alt='Agent'
              className='w-full h-full rounded-sm object-cover'
            />
          </button>
        ))}
      </div>
    </div>
  );
}
