import "./ImportWebpagePage.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, {useCallback, useState} from "react";
import {Card, CardContent, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useNavigate} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";


export default function ImportWebpagePage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const [urlToImport, setUrlToImport] = useState(null);
    const [importMethod, setImportMethod] = useState("readability");

    const handleUrlChange = useCallback((evt) => {
        setUrlToImport(evt.target.value);
    }, [setUrlToImport]);

    const handleImportMethodChange = useCallback((evt) => {
        setImportMethod(evt.target.value);
    }, [setImportMethod]);

    const handleImportWebpageClicked = useCallback(async (result) => {
        const importedPage = await api.importWebpage(urlToImport, importMethod);
        navigate(`/imported_webpages/${importedPage.id}`);
    }, [api, navigate, urlToImport, importMethod]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Import Webpage"}/>
        <div className="import-webpage-page">
            <Card className="import-webpage-card">
                <CardContent>
                    <TextField
                        label={"URL"}
                        value={urlToImport}
                        onChange={handleUrlChange}
                        placeholder={"https://prosperalabs.ai/"}
                    />

                    <FormControl>
                      <FormLabel id="webpage-importer-label">Webpage Importer</FormLabel>
                      <RadioGroup
                        aria-labelledby="webpage-importer-label"
                        defaultValue="readability"
                        name="webpage-importer-group"
                        value={importMethod}
                        onChange={handleImportMethodChange}
                      >
                        <FormControlLabel value="markitdown" control={<Radio />} label="MarkItDown - good for most sites" />
                        <FormControlLabel value="readability" control={<Radio />} label="Readability - good for blogs and articles" />
                        <FormControlLabel value="visual_layout" control={<Radio />} label="Visual Layout - preserves visual layout" />
                        <FormControlLabel value="all_text" control={<Radio />} label="All Text - good for backup for most sites" />
                      </RadioGroup>
                    </FormControl>

                    <SpinnerButton
                        variant="contained"
                        color="primary"
                        onClick={handleImportWebpageClicked}
                    >
                        <LocalTranslatedText
                            text={"Import"}
                            language={"en"}
                        />
                    </SpinnerButton>
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}