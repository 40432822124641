import "./SmartChainStepTestSamples.scss";
import * as React from 'react';
import {useCallback, useEffect} from "react";
import {useAPI} from "../../../frontend/components/APIProvider";
import {CustomJSONView} from "../../../frontend/elements/CustomJSONView";
import Paper from "@mui/material/Paper";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from "moment";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import {useIsAdmin} from "../../../frontend/components/IsAdminProvider";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';


function OrderModeSelector({value, onChange}) {
  return (
    <FormControl>
      <FormLabel id="order-mode-selector-label">Order Mode</FormLabel>
      <RadioGroup
        aria-labelledby="order-mode-selector-label"
        value={value}
        name="order-mode-selector"
        onChange={onChange}
      >
        <FormControlLabel value="recent" control={<Radio/>} label="Absolute Most Recent"/>
        <FormControlLabel value="random" control={<Radio/>} label="Random Recent Assortment"/>
      </RadioGroup>
    </FormControl>
  );
}

export default function SmartChainStepTestSamples({smartChain, selectedStepIndex}) {
  const api = useAPI();
  const [testSamples, setTestSamples] = React.useState(null);
  const [orderMode, setOrderMode] = React.useState("recent");
  const [viewModeBySample, setViewModeBySample] = React.useState({});
  const isAdmin = useIsAdmin();

  useEffect(() => {
    async function getTestSamples() {
      const step_id = selectedStepIndex === null ? null : smartChain.steps[selectedStepIndex].step_id;
      const newSamples = await api.getSmartChainSampleContexts(smartChain.chain_name, step_id, orderMode, 10)
      setTestSamples(newSamples);
    }

    getTestSamples();
  }, [api, smartChain, selectedStepIndex, orderMode]);

  const handleOrderModeChange = useCallback((event) => {
    setOrderMode(event.target.value);
  }, [setOrderMode]);

  const reprocessSample = useCallback(async (testSample, testSampleIndex) => {
    let result_data = null;
    if (selectedStepIndex === null) {
      result_data = await api.executeSmartChain(smartChain, testSample.context_data);
    } else {
      result_data = await api.executeSmartChainStep(smartChain.steps[selectedStepIndex], testSample.context_data);
    }
    const newTestSample = {
      ...testSample,
      result_data: result_data
    }
    const newTestSamples = [...testSamples];
    newTestSamples[testSampleIndex] = newTestSample;
    setTestSamples(newTestSamples);
  }, [api, smartChain, selectedStepIndex, testSamples]);

  const handleViewModeChange = useCallback((testSampleIndex, newViewMode) => {
    const newViewModeBySample = {...viewModeBySample};
    newViewModeBySample[testSampleIndex] = newViewMode;
    setViewModeBySample(newViewModeBySample);
  }, [viewModeBySample, setViewModeBySample]);

  if (!testSamples) {
    return null;
  }

  return (
    <div className={"smart-chain-test-samples"}>
      <Paper className={"search-options-row"}>
        <OrderModeSelector
          value={orderMode}
          onChange={handleOrderModeChange}
        />
      </Paper>

      {
        testSamples.map((testSample, testSampleIndex) => {
          return <Paper
            className={`test-sample-row ${viewModeBySample[testSampleIndex] ? viewModeBySample[testSampleIndex] : "expand-both"}`}
            key={testSample.id ?? testSample._id ?? testSampleIndex}>
            <div className={"test-sample-header"}>
              <span
                className={"sample-date"}>{moment.utc(testSample.updated_at).local().format("MMM Do, h:mm:ss a")}</span>
              {
                isAdmin ?
                  <span className={"sample-user-id"}>{testSample.user_id}</span>
                  : null
              }
            </div>
            <div className={"test-sample-body"}>
              <div className={"test-sample-column test-sample-context"}>
                <CustomJSONView
                  data={testSample.context_data ?? {}}
                  field_name={"Input"}
                />
              </div>
              <div className={`test-sample-actions`}>
                <SpinnerButton
                  label={"Expand Left"}
                  onClick={() => handleViewModeChange(testSampleIndex, 'expand-left')}
                  disabled={viewModeBySample[testSampleIndex] === "expand-left"}
                >
                  <FirstPageIcon/>
                </SpinnerButton>
                <SpinnerButton
                  label={"Expand Both"}
                  onClick={() => handleViewModeChange(testSampleIndex, 'expand-both')}
                  disabled={viewModeBySample[testSampleIndex] === "expand-both" || !viewModeBySample[testSampleIndex]}
                >
                  <MultipleStopIcon/>
                </SpinnerButton>
                <SpinnerButton
                  label={"Expand Right"}
                  onClick={() => handleViewModeChange(testSampleIndex, 'expand-right')}
                  disabled={viewModeBySample[testSampleIndex] === "expand-right"}
                >
                  <LastPageIcon/>
                </SpinnerButton>
                <SpinnerButton
                  className={"reprocess-button"}
                  label={"Reprocess"}
                  onClick={() => reprocessSample(testSample, testSampleIndex)}
                >
                  <PlayCircleIcon/>
                </SpinnerButton>
              </div>
              <div className={"test-sample-column test-sample-result"}>
                <CustomJSONView
                  data={testSample.result_data ?? {}}
                  field_name={"Result"}
                />
              </div>
            </div>
          </Paper>
        })
      }
    </div>
  );
}
