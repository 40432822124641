import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";

export function CustomDataObjectTable({customSchemaId}) {
    const api = useAPI();
    const navigate = useNavigate();

    const [customDataSchema, setCustomDataSchema] = React.useState(null);
    const handleCustomDataObjectRowClicked = useCallback((customDataObject) => {
        navigate(`/c/${customSchemaId}/${customDataObject.id}`);
    }, [navigate, customSchemaId]);

    const handleDeleteClicked = useCallback(async (customDataObject) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            return api.deleteCustomDataObject(customDataObject.id);
        }
        return Promise.reject("Delete cancelled");
    }, [api]);

    useEffect(() => {
        if (customDataSchema === null) {
            api.getCustomDataSchema(customSchemaId).then((data) => {
                setCustomDataSchema(data);
            });
        }
    }, [api, customSchemaId]);

    const getCustomDataSchema = useCallback(async () => {
        if (customDataSchema) {
            let newProperties = modifySchemaForTableView(customDataSchema.json_schema);
            return {
                ...customDataSchema.json_schema,
                properties: newProperties
            };
        }
    }, [customDataSchema]);

    const fetchCustomDataObjects = useCallback(async (tableQuery) => {
        tableQuery = {...tableQuery};

        if (!tableQuery.fields) {
            tableQuery.fields = [];
        }

        tableQuery.fields = ['data'];

        tableQuery.sortModel = tableQuery.sortModel.map((sort) => {
          if (sort.colId !== "user_id") {
            return {
              colId: `data.${sort.colId}`,
              sort: sort.sort
            }
          } else {
            return sort;
          }
        })

        const newFilterModel = {
            "custom_data_schema_id": {
                "filterType": "text",
                "type": "equals",
                "filter": customDataSchema.id
            }
        };

        Object.keys(tableQuery.filterModel).forEach((key) => {
            if (key !== "user_id") {
              newFilterModel[`data.${key}`] = tableQuery.filterModel[key];
            } else {
              newFilterModel[`user_id`] = tableQuery.filterModel['user_id'];
            }
        })

        tableQuery.filterModel = newFilterModel;

        const results = await api.getCustomDataObjects(tableQuery);

        // Grab the 'data' field within each object.
        const transformed = results.map((result) => ({...result, ...result.data}));
        return transformed;
    }, [customDataSchema]);

    if (!customDataSchema) {
        return null;
    }

    return (
        <TableView
            schemaAPI={getCustomDataSchema}
            fetchAPI={fetchCustomDataObjects}
            onRowClicked={handleCustomDataObjectRowClicked}
            onDeleteClicked={handleDeleteClicked}
        />
    );
}


const modifySchemaForTableView = (schema, prefix = '') => {
    if (schema.type === "object") {
        let properties = {};
        Object.entries(schema.properties).forEach(([key, value]) => {
            if (value.type === "object") {
                // Recursively flatten nested objects
                Object.assign(properties, modifySchemaForTableView(value, `${prefix}${key}.`));
            } else {
                // Add non-object properties with the current prefix
                properties[`${prefix}${key}`] = value;
            }
        });
        return properties;
    } else {
        // Handle case where schema itself is not an object type
        return { [prefix.slice(0, -1)]: schema };
    }
}