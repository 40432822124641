import "./PerformAnalysisPage.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, {useCallback, useState} from "react";
import {Card, CardContent, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useNavigate} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Alert from "@mui/material/Alert";


export default function PerformAnalysisPage(props) {
  const api = useAPI();
  const navigate = useNavigate();
  const [goalPrompt, setGoalPrompt] = useState(null);
  const [iterations, setIterations] = useState(3);
  const [error, setError] = useState(null);

  const handleUrlChange = useCallback((evt) => {
    setGoalPrompt(evt.target.value);
    setError(null); // Clear error when input changes
  }, [setGoalPrompt]);

  const handleIterationsChange = useCallback((evt) => {
    setIterations(evt.target.value);
  }, [setIterations]);

  const handlePerformAnalysisClicked = useCallback(async (result) => {
    if (!goalPrompt || goalPrompt.trim() === '') {
      setError("The Goal Analysis text is required in order to perform this action");
      return;
    }
    
    const analysis = await api.performAnalysis(goalPrompt, iterations);
    navigate(`/analysis/${analysis.id}`);
  }, [api, navigate, goalPrompt, iterations]);

  return <PageWrapperWithMenu>
    <PageHeader title={"Perform Analysis"}/>
    <div className="perform-analysis-page">
      <Card className="perform-analysis-card">
        <CardContent>
          <TextField
            label={"Goal Prompt"}
            value={goalPrompt}
            onChange={handleUrlChange}
            placeholder={
              "Your goal prompt should contain instructions on what question " +
              "you want answered or what analysis you want to perform based on " +
              "the information in the knowledge base."
          }
            multiline={true}
            fullWidth={true}
            rows={25}
            error={!!error}
            helperText={error ? <LocalTranslatedText language={"en"} text={error} /> : null}
          />

          <FormControl>
            <FormLabel id="iteration-count-label">Iterations</FormLabel>
            <RadioGroup
              aria-labelledby="iteration-count-label"
              name="iteration-count-group"
              value={iterations}
              onChange={handleIterationsChange}
            >
              <FormControlLabel value={1} control={<Radio />} label="1 iteration- Rapid Analysis" />
              <FormControlLabel value={3} control={<Radio />} label="3 iterations - Light Analysis" />
              <FormControlLabel value={5} control={<Radio />} label="5 iterations - Normal Analysis" />
              <FormControlLabel value={10} control={<Radio />} label="10 iterations - Deep Analysis" />
            </RadioGroup>
          </FormControl>

          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              <LocalTranslatedText language={"en"} text={error} />
            </Alert>
          )}

          <SpinnerButton
            variant="contained"
            color="primary"
            onClick={handlePerformAnalysisClicked}
          >
            <LocalTranslatedText
              text={"Perform Analysis"}
              language={"en"}
            />
          </SpinnerButton>
        </CardContent>
      </Card>
    </div>

  </PageWrapperWithMenu>
}