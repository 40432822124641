import React from "react";

export function GoogleDocs(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='18'
      viewBox='0 0 14 18'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_157479_2071)'>
        <mask
          id='mask0_157479_2071'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_157479_2071)'>
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L10.8216 2.86364L8.78794 0Z'
            fill='#4285F4'
          />
        </g>
        <mask
          id='mask1_157479_2071'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_157479_2071)'>
          <path
            d='M9.14453 4.55005L13.6684 9.09914V4.90903L9.14453 4.55005Z'
            fill='url(#paint0_linear_157479_2071)'
          />
        </g>
        <mask
          id='mask2_157479_2071'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask2_157479_2071)'>
          <path
            d='M3.90723 13.0909H10.4149V12.2727H3.90723V13.0909ZM3.90723 14.7273H8.788V13.9091H3.90723V14.7273ZM3.90723 9V9.81818H10.4149V9H3.90723ZM3.90723 11.4545H10.4149V10.6364H3.90723V11.4545Z'
            fill='#F1F1F1'
          />
        </g>
        <mask
          id='mask3_157479_2071'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask3_157479_2071)'>
          <path
            d='M8.78809 0V3.68182C8.78809 4.35989 9.33412 4.90909 10.0083 4.90909H13.6689L8.78809 0Z'
            fill='#A1C2FA'
          />
        </g>
        <mask
          id='mask4_157479_2071'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask4_157479_2071)'>
          <path
            d='M1.87351 0C1.20241 0 0.65332 0.552273 0.65332 1.22727V1.32955C0.65332 0.654546 1.20241 0.102273 1.87351 0.102273H8.78794V0H1.87351Z'
            fill='white'
            fillOpacity='0.2'
          />
        </g>
        <mask
          id='mask5_157479_2071'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask5_157479_2071)'>
          <path
            d='M12.4485 17.8977H1.87351C1.20241 17.8977 0.65332 17.3454 0.65332 16.6704V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V16.6704C13.6687 17.3454 13.1196 17.8977 12.4485 17.8977Z'
            fill='#1A237E'
            fillOpacity='0.2'
          />
        </g>
        <mask
          id='mask6_157479_2071'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='14'
          height='18'
        >
          <path
            d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask6_157479_2071)'>
          <path
            d='M10.0083 4.90916C9.33412 4.90916 8.78809 4.35995 8.78809 3.68188V3.78416C8.78809 4.46223 9.33412 5.01143 10.0083 5.01143H13.6689V4.90916H10.0083Z'
            fill='#1A237E'
            fillOpacity='0.1'
          />
        </g>
        <path
          d='M8.78794 0H1.87351C1.20241 0 0.65332 0.552273 0.65332 1.22727V16.7727C0.65332 17.4477 1.20241 18 1.87351 18H12.4485C13.1196 18 13.6687 17.4477 13.6687 16.7727V4.90909L8.78794 0Z'
          fill='url(#paint1_radial_157479_2071)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_157479_2071'
          x1='235.362'
          y1='43.609'
          x2='235.362'
          y2='459.523'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1A237E' stopOpacity='0.2' />
          <stop offset='1' stopColor='#1A237E' stopOpacity='0.02' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_157479_2071'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(41.8867 35.3707) scale(2098.71 2098.71)'
        >
          <stop stopColor='white' stopOpacity='0.1' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </radialGradient>
        <clipPath id='clip0_157479_2071'>
          <rect
            width='13.0154'
            height='18'
            fill='white'
            transform='translate(0.65332)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
