import * as React from 'react';
import {TableView} from "@/frontend/elements/TableView";
import {useAPI} from "@/frontend/components/APIProvider";

export function BookingsTable({onBookingClicked}) {
    const api = useAPI();

    return (
        <TableView
            schemaAPI={api.getBookingSchema}
            fetchAPI={api.getBookings}
            onRowClicked={onBookingClicked}
        />
    );
}
