import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Progress } from "@/frontend/components/ui/progress";
import { Files } from "@/frontend/icons/Files";
import { useAPI } from "@/frontend/components/APIProvider";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export function Upload({
  multiple = true,
  acceptedFileTypes = ["image/jpeg", "image/png", "application/pdf"],
  maxSize = 2 * 1024 * 1024,
  uploadFileFunc,
  onSetUploadedFiles,
  fieldName,
  value,
}) {
  const [uploadProgress, setUploadProgress] = useState({});
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const api = useAPI();

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setRejectedFiles(
        rejectedFiles.map((file) => ({
          name: file.file.name,
          errors: file.errors.map((err) => err.message),
        }))
      );
    } else {
      setRejectedFiles([]);
    }

    acceptedFiles.forEach((file) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: 0,
      }));
      uploadFile(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFile = async (file) => {
    try {
      const data = await api[uploadFileFunc](file, setUploadProgress);

      if (multiple) {
        onSetUploadedFiles(fieldName, [...value, data]);
      } else {
        onSetUploadedFiles(fieldName, data);
      }
    } catch (e) {
      console.error("Error uploading file:", e);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: acceptedFileTypes.reduce((acc, type) => {
      acc[type] = [];
      return acc;
    }, {}),
    maxSize,
  });

  return (
    <div className='w-full p-6 rounded-sm border-2 border-dashed border-gray-300 flex flex-col justify-center items-center'>
      <div {...getRootProps()} className='p-4 text-center cursor-pointer'>
        <input {...getInputProps()} />
        <Files className='w-9 h-9 mx-auto text-primary' />
        <p className='text-gray-600 text-sm leading-5 mt-2 mb-1'>
          <LocalTranslatedText
            text={`Drag & drop ${
              multiple ? "files" : "a file"
            } here, or click to select`}
          />{" "}
          {multiple ? "files" : "a file"}
        </p>
        <p className='text-xs text-gray-500 mb-1'>
          <LocalTranslatedText text='Accepted file types' />:{" "}
          {acceptedFileTypes.join(", ")}
        </p>
        <p className='text-xs text-gray-500'>
          <LocalTranslatedText text='Max file size' />: {maxSize / 1024 / 1024}
          MB
        </p>
      </div>
      <div className='mt-4 w-full'>
        {Object.keys(uploadProgress).map((fileName) => (
          <div key={fileName} className='mb-2'>
            <p className='text-xs font-medium mb-2'>{fileName}</p>
            <Progress value={uploadProgress[fileName] || 0} className='h-1' />
          </div>
        ))}
      </div>
      {rejectedFiles.length > 0 && (
        <div className='w-full mt-4 p-2 bg-red-100 border border-danger text-danger rounded'>
          <h4 className='text-base font-medium'>
            <LocalTranslatedText text='Rejected Files' />
          </h4>
          {rejectedFiles.map((file, index) => (
            <div key={index}>
              <p className='text-sm'>{file.name}</p>
              <ul className='list-disc list-inside'>
                {file.errors.map((err, idx) => (
                  <li key={idx} className='text-xs'>
                    <LocalTranslatedText text={err} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Upload.displayName = "Upload";
