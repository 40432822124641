import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import "./SmartChainOwnerMenu.scss";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export default function SmartChainOwnerMenu({
  smartChain,
  setSmartChain,
}) {
  const api = useAPI();

  const handleApplyToAllClick = useCallback(async () => {
    const userDetails = await api.getUserDetails();

    console.log(userDetails);

    if (smartChain.user_id) {
      setSmartChain({
        ...smartChain,
        user_id: null,
      });
    } else {
      setSmartChain({
        ...smartChain,
        user_id: userDetails.user_id,
      });
    }
  }, [smartChain, setSmartChain]);

  return (
    <>
      <div className='smart-chain-owner-menu'>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={!smartChain.user_id} onChange={handleApplyToAllClick} />}
            label="Apply to all users"
          />
        </FormGroup>
      </div>
    </>
  );
}
