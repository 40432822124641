import {api} from "../../frontend/components/api";


/**
 * This class is responsible for managing the connection to the web chat event service,
 * in order to receive an uninterrupted continuous flow of events from the server.
 */
class WebChatEventConnectionManager {
    static eventStreamerCloseReconnectTimeMs = 250;
    static startEventStreamerErrorReconnectTimeMs = 5000;
    static maxEventStreamerErrorReconnectTimeMs = 60000;
    static maxReconnectAttempts = 100;
    static pollingPastMessagesFetchingAdjustmentMs = 60000;

    constructor() {
        this.state = "disconnected";
        this.conversationId = null;
        this.eventStreamer = null;
        this.eventHandler = null;
        this.reconnectHandle = null;
        this.eventStreamerErrorReconnectTimeMs = WebChatEventConnectionManager.startEventStreamerErrorReconnectTimeMs;
        this.currentReconnectAttemptCount = 0;
    }

    updateEventHandle(eventHandler) {
        this.eventHandler = eventHandler;
    }

    subscribeToEvents(conversationId, conversationToken, mostRecentEventTime, eventHandler) {
        this.eventHandler = eventHandler;

        if (mostRecentEventTime) {
            // parse, subtract 60 seconds and reformat. This helps ensure
            // that we fetch recent messages or changes to messages
            // that may have been missed due to reconnections.
            let localMostRecentEventTimeParsed = new Date(mostRecentEventTime);
            localMostRecentEventTimeParsed = new Date(localMostRecentEventTimeParsed.getTime() - WebChatEventConnectionManager.pollingPastMessagesFetchingAdjustmentMs);
            mostRecentEventTime = localMostRecentEventTimeParsed.toISOString();
        }

        if (conversationId !== this.conversationId) {
            this.conversationId = conversationId;
            this.eventStreamerErrorReconnectTimeMs = WebChatEventConnectionManager.startEventStreamerErrorReconnectTimeMs;
            this.currentReconnectAttemptCount = 0;
            this.recreateEventStreamer(conversationId, conversationToken, mostRecentEventTime);
        }
    }

    disconnect() {
        if (this.eventStreamer) {
            this.eventStreamer.close();
            this.eventStreamer = null;
            this.conversationId = null;
            this.reconnectHandle = null;
        }
    }


    recreateEventStreamer(conversationId, conversationToken, mostRecentEventTime) {
        if (this.eventStreamer) {
            this.eventStreamer.close();
            this.eventStreamer = null;
        }

        let localMostRecentEventTime = mostRecentEventTime;

        let localEventStreamer = api.streamChatEvents(conversationId, conversationToken, mostRecentEventTime);

        this.eventStreamer = localEventStreamer;
        this.eventStreamer.addEventListener("conversation_event", (e) => {
            const messageData = JSON.parse(e.data);

            // parse, subtract 60 seconds and reformat. This helps ensure
            // that we fetch recent messages or changes to messages
            // that may have been missed due to reconnections.
            const localMostRecentEventTimeParsed = new Date(new Date(messageData.created_at).getTime() - WebChatEventConnectionManager.pollingPastMessagesFetchingAdjustmentMs);
            localMostRecentEventTime = localMostRecentEventTimeParsed.toISOString();

            if (messageData.conversation_id === this.conversationId) {
                if (this.eventHandler) {
                    this.eventHandler(messageData);
                }
            }
        });

        this.eventStreamer.addEventListener("error", (e) => {
            if (this.currentReconnectAttemptCount >= WebChatEventConnectionManager.maxReconnectAttempts) {
                this.disconnect();
                return;
            } else {
                this.currentReconnectAttemptCount += 1;
            }

            this.reconnectAfterWait(localEventStreamer, conversationId, conversationToken, localMostRecentEventTime, this.eventStreamerErrorReconnectTimeMs);

            this.eventStreamerErrorReconnectTimeMs = Math.min(
                this.eventStreamerErrorReconnectTimeMs * 1.5,
                WebChatEventConnectionManager.maxEventStreamerErrorReconnectTimeMs
            )
        });

        this.eventStreamer.addEventListener("close", (e) => {
            this.reconnectAfterWait(
              localEventStreamer,
              conversationId,
              conversationToken,
              localMostRecentEventTime,
              WebChatEventConnectionManager.eventStreamerCloseReconnectTimeMs
            );
        });
    }

    reconnectAfterWait(localEventStreamer, conversationId, conversationToken, localMostRecentEventTime, waitTimeMS) {
        if (this.reconnectHandle) {
            clearTimeout(this.reconnectHandle);
            this.reconnectHandle = null;
        }

        this.reconnectHandle = setTimeout(() => {
            this.reconnectHandle = null;
            if (this.eventStreamer === localEventStreamer && this.conversationId === conversationId) {
                this.recreateEventStreamer(conversationId, conversationToken, localMostRecentEventTime);
            }
        }, waitTimeMS);
    }
}

export const globalWebChatEventConnectionManager = new WebChatEventConnectionManager();
export default globalWebChatEventConnectionManager;
