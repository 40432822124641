import axios from "axios";
import { createContext } from "react";
import { axiosETAGCache } from "axios-etag-cache";
import agentKernelApi from "../../agent_kernel/frontend/apis";
import billingApi from "../../billing/frontend/apis";
import businessInfoApi from "@/business_info/frontend/apis";
import aiConfigurationApi from "@/ai_configuration/frontend/apis";
import conversationsApi from "../../conversations/frontend/apis";
import customDataApi from "../../custom_data/frontend/apis";
import outboundsApi from "../../outbound/frontend/apis";
import knowledgeBaseApi from "../../knowledge_base/frontend/apis";
import fileUploadAPI from "../../file_uploads/frontend/apis";
import llmModelsApi from "../../llm_model_providers/frontend/apis";
import onboardingApi from "../../onboarding/frontend/apis";
import smartChainApis from "../../smart_chains/frontend/apis";
import schedulingApi from "@/scheduling/frontend/apis";
import syntheticUserApi from "@/synthetic_user/frontend/apis";
import tenantApi from "../tenant_configuration/apis";
import translationApi from "../../translation/frontend/apis";
import voiceApi from "../../voice/frontend/apis";
import wa2ChatApi from "../../wa_2chat/frontend/apis";
import webApi from "../../web_chat/frontend/apis";
import imageUploadApi from "../../image_uploads/frontend/apis";
import documentGeneratorApi from "../../document_generator/frontend/apis";
import axiosRetry from "axios-retry";
import intakeApi from "../../intake/frontend/apis";
import agentServiceApi from "../agent_services/apis";
import emailChannelApi from "../../email_channel/frontend/apis";
import tenantSettingsAPi from "../tenant_settings/api";
import { randomString } from "@utils/random";

export const apiAccessTokenContext = createContext(null);

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

let cachedUserDetails = null;

export const api = {
  async home() {
    const response = await axiosWithETAGCache.get(`/`);
    return response.data;
  },

  async frontendConfiguration() {
    const response = await axiosWithETAGCache.get(`/frontend_configuration`, {
      headers: {
        // Explicitly remove the token from the header. This is to ensure that the
        // frontend_configuration endpoint gets cached by the intermediary CDN
        // and helps in reducing the load on the backend.
        "WWW-Authenticate": undefined,
      },
    });
    return response.data;
  },

  async healthCheck() {
    // This may seem weird, but the we want calls to the health check endpoint to bypass the normal
    // error handling mechanisms that the system installs into the axios default. So instead we use
    // native javascript API here.
    const response = await fetch(axios.defaults.baseURL + "health_check");
    return response.json();
  },

  async isAdmin() {
    const response = await axiosWithETAGCache.get(`/is-admin`);
    return response.data;
  },

  async getUserDetails() {
    if (cachedUserDetails) {
      return cachedUserDetails;
    }

    const response = await axiosWithETAGCache.get(`/user/me`);
    cachedUserDetails = response.data;
    return response.data;
  },
  ...agentKernelApi,
  ...billingApi,
  ...businessInfoApi,
  ...aiConfigurationApi,
  ...conversationsApi,
  ...customDataApi,
  ...outboundsApi,
  ...knowledgeBaseApi,
  ...fileUploadAPI,
  ...llmModelsApi,
  ...onboardingApi,
  ...smartChainApis,
  ...schedulingApi,
  ...syntheticUserApi,
  ...tenantApi,
  ...translationApi,
  ...voiceApi,
  ...wa2ChatApi,
  ...webApi,
  ...imageUploadApi,
  ...documentGeneratorApi,
  ...intakeApi,
  ...agentServiceApi,
  ...emailChannelApi,
  ...tenantSettingsAPi,
  resetCachedUserDetails() {
    cachedUserDetails = null;
  },
};

const SAFE_HTTP_METHODS = ["get", "head", "options"];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat([
  "put",
  "delete",
  "patch",
]);

export function isIdempotentRequestError(error) {
  if (!error.config?.method) {
    // Cannot determine if the request can be retried
    return false;
  }
  return (
    axiosRetry.isRetryableError(error) &&
    IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1
  );
}

// Setup retries on API calls with backoff
axiosRetry(axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    // We apply the default condition, except if the error is from the health check endpoint,
    // which has a normal behavior of returning the a bad status code if the health check fails,
    if (error.config.url === "/health_check") {
      return false;
    }

    return axiosRetry.isNetworkError(error) || isIdempotentRequestError(error);
  },
});

// TODO: FIX ME
if (process.env.REACT_APP_INITIAL_BACKEND_API_URL_ROOT) {
  axios.defaults.baseURL = process.env.REACT_APP_INITIAL_BACKEND_API_URL_ROOT;
} else {
  // Just assume the API url is the same as the page the code is loaded in.
  axios.defaults.baseURL = `https://${window.location.hostname}/api/`;
}

axios.defaults.headers["X-Client-Session-Id"] = randomString(32);
if (document.currentScript?.src) {
  const scriptFileName = document.currentScript.src.split("/").pop();
  axios.defaults.headers["X-Client-Version"] = scriptFileName;
}
axios.defaults.headers["X-Client-Tenant-Host"] = window.location.hostname;

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // Auth0.logout();
      return Promise.reject(error);
    }

    if (process.env.REACT_APP_DEBUG === "true") {
      console.log(error.toString());
    } else {
      // Force reload the page. maybe this will help.
      // window.location.reload();
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export const axiosInstance = axios;
