import "./SyntheticUserForm.scss";
import React, {useCallback} from 'react';
import {SpinnerButton} from "../../frontend/elements/SpinnerButton";
import {FormControlLabel, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {TemperatureSlider} from "./TemperatureSlider";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export const SyntheticUserForm = ({syntheticUser, onChange, onSaveClicked}) => {
    const handleChange = useCallback((e) => {
        const newSyntheticUser = {
            ...syntheticUser,
            [e.target.name]: e.target.value
        };

        onChange(newSyntheticUser);
    }, [syntheticUser, onChange]);

    const handleChangedTemperature = useCallback((newTemperature) => {
        const newSyntheticUser = {
            ...syntheticUser,
            temperature: newTemperature
        };

        onChange(newSyntheticUser);
    }, [syntheticUser, onChange]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        return onSaveClicked();
    }, [onSaveClicked]);

    return (
        <form className={"synthetic-user-form"}>
            <TextField
                name="prompt"
                label={<LocalTranslatedText language={"en"} text="Prompt for Synthetic User" />}
                value={syntheticUser.prompt}
                onChange={handleChange}
                margin="normal"
                fullWidth
                multiline={true}
                rows={8}
            />
            <TemperatureSlider
                value={syntheticUser.temperature}
                onChange={handleChangedTemperature}
            />
            <TextField
                name="max_remaining_responses"
                label={<LocalTranslatedText language={"en"} text="Maxiumum number of responses for Synthetic User" />}
                value={syntheticUser.max_remaining_responses}
                onChange={handleChange}
                margin="normal"
                fullWidth
                type={"number"}
            />
            <FormControl>
              <FormLabel id="start-role-button-label">
                  <LocalTranslatedText language={"en"} text="Who Starts the Conversation?"/>
              </FormLabel>
              <RadioGroup
                aria-labelledby="start-role-button-label"
                name="start_role"
                value={syntheticUser.start_role}
                onChange={handleChange}
              >
                <FormControlLabel value="user" control={<Radio />} label={<LocalTranslatedText language={"en"} text="Synthetic User" />}/>
                <FormControlLabel value="assistant" control={<Radio />} label={<LocalTranslatedText language={"en"} text="Bot / Assistant" />}/>
              </RadioGroup>
            </FormControl>
            <div className={"booking-buttons-bar"}>
                <SpinnerButton variant="contained" color="primary" type="submit" onClick={handleSubmit}>
                    <LocalTranslatedText language={"en"} text="Generate Synthetic Conversation" />
                </SpinnerButton>
            </div>
        </form>
    );
};
