import "./BusinessInfoPage.scss";
import Paper from "@mui/material/Paper";
import React, { useCallback, useEffect, useState } from "react";
import { PageWrapperWithMenu } from "@/frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "@/frontend/elements/PageHeader";
import { SpinnerButton } from "@/frontend/elements/SpinnerButton";
import { TextField } from "@mui/material";
import { useAPI } from "@/frontend/components/APIProvider";
import { formatPhoneNumberHumanReadable } from "@/frontend/utils/phone";
import { isUrlValid } from "@/frontend/utils/url";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { useFrontendConfiguration } from "@/frontend/components/FrontendConfigurationProvider";

export const BusinessInfoPage = () => {
  const config = useFrontendConfiguration();
  const api = useAPI();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    website_url: "",
    address: "",
    product_information: "",
  });
  const [isWebsiteUrlValid, setIsWebsiteUrlValid] = useState(false);
  const [fillInManually, setFillInManually] = useState(false);
  const [websiteUrlForAutofill, setWebsiteUrlForAutofill] = useState("");

  useEffect(() => {
    api.getBusinessInfo().then((data) => {
      data.phone_number = formatPhoneNumberHumanReadable(data.phone_number);
      setFormData(data);
      setIsWebsiteUrlValid(isUrlValid(data.website_url));
    });
  }, [api]);

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "website_url") {
        setIsWebsiteUrlValid(isUrlValid(e.target.value));
        setWebsiteUrlForAutofill(e.target.value);
      }

      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );

  const saveBusinessInformation = useCallback(
    (e) => {
      e.preventDefault();
      return api.saveBusinessInfo(formData);
    },
    [api, formData]
  );

  const handleChangeWebsite = useCallback(
    (e) => {
      setWebsiteUrlForAutofill(e.target.value);
      setIsWebsiteUrlValid(isUrlValid(e.target.value));
    },
    [setWebsiteUrlForAutofill, setIsWebsiteUrlValid]
  );

  const loadBusinessInfoFromWebsite = useCallback(
    (e) => {
      e.preventDefault();
      let websiteUrl = websiteUrlForAutofill || formData.website_url;
      if (!websiteUrl.startsWith("http")) {
        websiteUrl = "https://" + websiteUrl;
      }
      return api.getBusinessInfoFromWebsite(websiteUrl).then((data) => {
        // Copy the fields into our local object
        for (let key in data) {
          if (key !== "_id" && key !== "id") {
            if (data.hasOwnProperty(key) && formData.hasOwnProperty(key)) {
              formData[key] = data[key];
            }
          }
        }

        data.phone_number = formatPhoneNumberHumanReadable(data.phone_number);

        setFormData(data);
        return api.saveBusinessInfo(data);
      });
    },
    [websiteUrlForAutofill, api, formData]
  );

  const fillInManuallyButtonClicked = useCallback(
    (e) => {
      e.preventDefault();
      setFillInManually(true);
    },
    [setFillInManually]
  );

  const areAllFieldsEmpty =
    !formData.website_url &&
    !formData.name &&
    !formData.email &&
    !formData.phone_number &&
    !formData.address &&
    !formData.product_information;

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Business Profile"}
        subtitle={"Setup your business profile"}
      />
      <div className='business-info-form'>
        <Paper className='business-info-card'>
          {areAllFieldsEmpty &&
          !fillInManually &&
          config.frontend.show_detailed_business_information_fields ? (
            <div className='empty-state'>
              <h2>
                <LocalTranslatedText
                  language={"en"}
                  text='Setup your business profile'
                />
              </h2>

              <div className={"business-info-options"}>
                <div className={"business-info-option-box"}>
                  <h4>
                    <LocalTranslatedText
                      language={"en"}
                      text='Option 1: Fill in from your Website'
                    />
                  </h4>
                  <span>
                    <LocalTranslatedText
                      language={"en"}
                      text='Punch in your website URL to automatically fill in the details of your business'
                    />
                  </span>

                  <TextField
                    name='website_url'
                    label='Website URL'
                    value={websiteUrlForAutofill}
                    onChange={handleChangeWebsite}
                    margin='normal'
                    fullWidth
                    placeholder={"https://www.example.com"}
                  />
                  <SpinnerButton
                    variant='contained'
                    color='primary'
                    onClick={loadBusinessInfoFromWebsite}
                    disabled={!isWebsiteUrlValid}
                  >
                    <LocalTranslatedText
                      language={"en"}
                      text='Fill in Details From Your Website'
                    />
                  </SpinnerButton>
                </div>

                <div className={"business-info-option-box"}>
                  <h4>
                    <LocalTranslatedText
                      language={"en"}
                      text='Option 2: Fill in manually'
                    />
                  </h4>

                  <SpinnerButton
                    variant='contained'
                    color='primary'
                    onClick={fillInManuallyButtonClicked}
                  >
                    <LocalTranslatedText
                      language={"en"}
                      text='Fill in Manually'
                    />
                  </SpinnerButton>
                </div>
              </div>
            </div>
          ) : null}
          {!areAllFieldsEmpty ||
          fillInManually ||
          !config.frontend.show_detailed_business_information_fields ? (
            <form>
              {config.frontend.show_detailed_business_information_fields ? (
                <div className={"website-url-row"}>
                  <TextField
                    name='website_url'
                    label='Website'
                    value={formData.website_url}
                    onChange={handleChange}
                    margin='normal'
                    fullWidth
                  />
                  <SpinnerButton
                    variant='contained'
                    color='primary'
                    onClick={loadBusinessInfoFromWebsite}
                    disabled={!isWebsiteUrlValid}
                  >
                    <LocalTranslatedText
                      language={"en"}
                      text='Fill in Details From Your Website'
                    />
                  </SpinnerButton>
                </div>
              ) : null}
              <TextField
                name='name'
                label='Name'
                value={formData.name}
                onChange={handleChange}
                margin='normal'
                fullWidth
              />
              <TextField
                name='address'
                label='Address'
                value={formData.address}
                onChange={handleChange}
                margin='normal'
                fullWidth
              />
              <TextField
                name='email'
                label='Email'
                value={formData.email}
                onChange={handleChange}
                margin='normal'
                fullWidth
              />
              <TextField
                name='phone_number'
                label='Phone Number'
                value={formData.phone_number}
                onChange={handleChange}
                margin='normal'
                fullWidth
              />
              {config.frontend.show_detailed_business_information_fields ? (
                <>
                  <TextField
                    name='product_information'
                    label='Product Information'
                    value={formData.product_information}
                    onChange={handleChange}
                    margin='normal'
                    fullWidth
                    multiline
                    rows={24}
                  />
                </>
              ) : null}
              <div className={"buttons-bar"}>
                <SpinnerButton
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={saveBusinessInformation}
                >
                  <LocalTranslatedText language={"en"} text='Save' />
                </SpinnerButton>
              </div>
            </form>
          ) : null}
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
};

export default BusinessInfoPage;
