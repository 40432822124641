import React from "react";
import agentSkinColors from "@/backend/data/agent_skin_colors.json";
import { cn } from "@/frontend/lib/utils";

export function SkinColorPicker({ value, onChange, className }) {
  return (
    <div className="flex flex-wrap gap-2">
      {agentSkinColors.agent_skin_colors.map((skinColor) => (
        <button
          key={skinColor.color}
          className={cn(
            "w-8 h-8 rounded-full border border-slate-300 hover:border-primary transition-all ease-in-out duration-300 delay-100",
            value === skinColor.color && "border-primary border-2",
            className
          )}
          style={{ backgroundColor: skinColor.code }}
          onClick={() => onChange(skinColor.color)}
          type="button"
        ></button>
      ))}
    </div>
  );
}

SkinColorPicker.displayName = "SkinColorPicker";
