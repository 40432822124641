import { z } from "zod";
import { randomString } from "../../frontend/utils/random";

const QuestionSchema = z.object({
  id: z.string().optional(),
  question_text: z.string().optional(),
  description: z.string().optional(),
  required_data_points: z.array(z.string()).optional(),
  is_required: z.boolean(),
});

export const IntakeSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  bot_personality: z.string().optional(),
  introduction: z.string().optional(),
  questions: z.array(QuestionSchema),
});

export const dataPointsInitialValues = {
  id: randomString(16),
  data_point: "",
  description: "",
};

export const questionInitialValues = {
  id: randomString(16),
  question_text: "",
  data_points: [dataPointsInitialValues],
  is_required: false,
};
