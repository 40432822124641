import "./SmartChainWholeChainTestForm.scss";
import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {useAPI} from "../../../frontend/components/APIProvider";
import Paper from '@mui/material/Paper';
import {CustomJSONView} from "../../../frontend/elements/CustomJSONView";
import {CircularProgress} from "@mui/material";
import {recursivelyModifySchemaForFrontend} from "./schema_utils";
import {JSONSchemaForm} from "../../../frontend/elements/JSONSchemaForm";


export default function SmartChainWholeChainTestForm({smartChain, isDarkMode}) {
  const api = useAPI();
  const [schema, setSchema] = React.useState(null);
  const [executionResult, setExecutionResult] = React.useState(null);
  const [isExecuting, setIsExecuting] = React.useState(false);

  useEffect(() => {
    async function getSchema() {
      // Refresh the whole smart chain, then find this one step in the refreshed result
      const newSmartChain = await api.refreshSmartChain(smartChain);
      const newSchema = newSmartChain.actual_input_schema;
      newSchema.title = "Chain Input";
      recursivelyModifySchemaForFrontend(newSchema);
      setSchema(newSchema);
    }
    getSchema();
  }, [api, smartChain]);

  const handleChange = useCallback((formData) => {

  }, []);

  const handleSubmit = useCallback(async ({formData}) => {
    try {
      setIsExecuting(true);
      const response = await api.executeSmartChain(smartChain, formData);
      setExecutionResult(response);
    } finally {
      setIsExecuting(false);
    }
  }, [api, smartChain]);

  const handleError = useCallback((errors) => {
    console.log("Form errors: ", errors);
  }, []);


  if (!schema) {
    return null;
  }

  return (
    <div className={"smart-chain-whole-chain-test-form"}>
      <div className={"input-column"}>
        <Paper>
          <JSONSchemaForm
            schema={schema}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onError={handleError}
            submitText={"Run"}
          />
        </Paper>
      </div>
      <div className={"output-column"}>
        <Paper>
          {
            isExecuting ?
              <CircularProgress/>
              : executionResult
                ? (
                  <div className={"execution-result"}>
                    <CustomJSONView
                      data={executionResult}
                      field_name={"Output"}
                    />
                  </div>
                )
                : null
          }
        </Paper>
      </div>
    </div>
  );
}
