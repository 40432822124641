import * as React from 'react';
import PausePresentationRoundedIcon from '@mui/icons-material/PausePresentationRounded';
import VoiceSettingSlider from "./VoiceSettingSlider";


export default function EndpointingMsSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Endpointing Milliseconds"
      description="The number of milliseconds to wait for endpointing."
      id="endpointing-ms-slider"
      min={0}
      max={1000}
      step={25}
      icon={<PausePresentationRoundedIcon />}
      marks={[
        { value: 0, label: '0' },
        { value: 250, label: '250' },
        { value: 500, label: '500' },
        { value: 750, label: '750' },
        { value: 1000, label: '1000' },
      ]}
      onChange={onChange}
      value={value}
    />
  );
}
