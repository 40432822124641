import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

const axiosWithETAGCache = axiosETAGCache(axios);

const api = {
  async getTenantSettings() {
    const response = await axiosWithETAGCache.get(`/tenant_settings`);

    return response.data;
  },

  async saveTenantSettings(tenantSettings) {
    const response = await axios.put(
      `/tenant_settings/${tenantSettings.id}`,
      tenantSettings
    );
    return response.data;
  },
};
export default api;
