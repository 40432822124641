import * as React from "react";
import { TableView } from "../../../frontend/elements/TableView";
import { useAPI } from "../../../frontend/components/APIProvider";
import { useCallback } from "react";
// import { useNavigate } from "react-router-dom";

export function SmartChainBindingTable() {
  const api = useAPI();
  //   const navigate = useNavigate();

  //   const handleSmartChainBindingRowClicked = useCallback(
  //     (smartChainBinding) => {
  //       navigate(`/smart_chain_binding/${smartChainBinding.id}`);
  //     },
  //     [navigate]
  //   );

  const handleDeleteSmartChainBinding = useCallback(
    async (smartChainBinding) => {
      console.log(smartChainBinding);
      await api.deleteSmartChainBinding(smartChainBinding.id);
    },
    [api]
  );

  const allowDeleteOnRow = useCallback((smartChainBinding) => {
    return smartChainBinding.data_source !== "built_in";
  }, []);

  return (
    <TableView
      schemaAPI={api.getSmartChainBindingSchema}
      fetchAPI={api.getSmartChainBindings}
      updateAPI={api.saveSmartChainBinding}
      editable={true}
      // onRowClicked={handleSmartChainBindingRowClicked}
      onDeleteClicked={handleDeleteSmartChainBinding}
      allowDeleteOnRow={allowDeleteOnRow}
    />
  );
}
