import * as React from 'react';
import BalanceIcon from '@mui/icons-material/Balance';
import VoiceSettingSlider from "./VoiceSettingSlider";
import SpeedIcon from '@mui/icons-material/Speed';


export default function SpeedSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Speed"
      description="The speed of that the voice speaks at. A value of 1.0 is normal speed, lower is slower, and higher is faster."
      id="style-slider"
      min={0.7}
      max={1.2}
      step={0.05}
      icon={<SpeedIcon />}
      marks={[
        { value: 0.7, label: '0.7' },
        { value: 0.8, label: '0.8' },
        { value: 0.9, label: '0.9' },
        { value: 1.0, label: '1.0' },
        { value: 1.1, label: '1.1' },
        { value: 1.2, label: '1.2' },


      ]}
      onChange={onChange}
      value={value}
    />
  );
}
