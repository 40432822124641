import React from "react";
import { LoadingButton } from "@/frontend/components/ui/loading-button";
import { MoveRight } from "lucide-react";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function Next({ isSubmitting, text, type, onClick }) {
  return (
    <div className='px-8 lg:px-[100px] py-[40px] flex justify-end gap-4 items-center'>
      <LoadingButton
        size='default'
        variant='default'
        type={type ?? "submit"}
        className='font-semibold rounded-sm'
        loading={isSubmitting}
        onClick={onClick}
      >
        <LocalTranslatedText text={text ?? "Save and Next"} /> <MoveRight />
      </LoadingButton>
    </div>
  );
}
