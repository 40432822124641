import { useRef, useEffect } from "react";
import { useDebounce } from "@/frontend/hooks/use-debounce";
import { useWatch, FormProvider as Form } from "react-hook-form";

export default function FormProvider({
  children,
  onSubmit,
  methods,
  onAutoSubmit,
}) {
  const formData = useWatch({ control: methods.control });
  const debouncedFormData = useDebounce(formData, 1000);

  const prevFormData = useRef(formData);

  useEffect(() => {
    if (onAutoSubmit) {
      if (
        JSON.stringify(debouncedFormData) !==
        JSON.stringify(prevFormData.current)
      ) {
        onAutoSubmit(debouncedFormData);
        prevFormData.current = debouncedFormData;
      }
    }
  }, [debouncedFormData, onAutoSubmit]);

  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}
