import React from "react";
import { cn } from "@/frontend/lib/utils";
import { Loader2 } from "lucide-react";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function PageLoader() {
  const spinnerClass = cn(
    "absolute right-0 top-0 bottom-0 left-0 w-full h-full flex items-center justify-center"
  );

  return (
    <div className={spinnerClass}>
      <div className='flex flex-col justify-center items-center gap-6'>
        <Loader2 className='animate-spin-slow text-primary w-11 h-11' />
        <p className='text-base text-muted-foreground font-medium'>
          <LocalTranslatedText text='Loading...please wait' />
        </p>
      </div>
    </div>
  );
}
