import React, { useState, useEffect, useCallback } from "react";
import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";
import { Card, CardContent } from "@mui/material";
import "./TenantMenuConfigurationEditPage.scss";
import { useAPI } from "../components/APIProvider";
import { PageHeader } from "../elements/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import NestedMenuDragAndDrop from "./NestedMenuDragAndDrop";
import { useTenantSettings } from "../components/TenantSettingsProvider";

export default function TenantMenuConfigurationEditPage() {
  const api = useAPI();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tenantMenuConfiguration, setTenantMenuConfiguration] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { config, fetchConfig } = useTenantSettings();

  const handleSave = useCallback(
    async (newValues) => {
      try {
        setIsSubmitting(true);
        await api.saveTenantSettings(newValues);
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 2000);
        setIsSubmitting(false);
        fetchConfig();
      } catch (error) {
        setIsSubmitting(false);
        console.error(error);
      }
    },
    [api, fetchConfig]
  );

  useEffect(() => {
    if (config) {
      setTenantMenuConfiguration(config);
      setLoading(false);
    }
  }, [config]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText
            language={"en"}
            text='Edit Tenant Menu Configuration'
          />
        }
      />
      <div className='tenant-configuration-edit-page'>
        {loading ? (
          <CircularProgress />
        ) : tenantMenuConfiguration ? (
          <Card className='tenant-configuration-edit-form-card'>
            <CardContent>
              <NestedMenuDragAndDrop
                initialValues={tenantMenuConfiguration}
                onSubmitClick={handleSave}
                isSubmitting={isSubmitting}
                saveSuccess={saveSuccess}
              />
            </CardContent>
          </Card>
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
}
