import React, { useState, useEffect } from "react";
import { useAPI } from "@/frontend/components/APIProvider";
import {
  Outlet,
  useNavigate,
  useLocation,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PageLoader from "@/frontend/pages/PageLoader";
import OnboardingWelcome from "./OnboardingWelcome";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FormProvider from "@/frontend/components/hook-form/form-provider";
import BasicInfo from "./steps/BasicInfo";
import {
  getOnboardingSchema,
  getUserCurrentStep,
} from "@/frontend/zod-schema/onboarding";
import BusinessServiceSelection from "./steps/business/BusinessServiceSelection";
import AgentCustomization from "./steps/business/AgentCustomization";
import EssentialInfo from "./steps/business/EssentialInfo";
import BusinessPreview from "./steps/business/BusinessPreview";
import MarketplaceSetup from "./steps/marketplace/MarketplaceSetup";
import MarketplaceServiceSelection from "./steps/marketplace/MarketplaceServiceSelection";
import MarketplacePreview from "./steps/marketplace/MarketplacePreview";

const businessSteps = [
  "welcome",
  "basic-information",
  "essential-information",
  "service-selection",
  "agent-customization",
  "preview",
];

const markeplaceSteps = [
  "welcome",
  "basic-information",
  "service-selection",
  "marketplace-setup",
  "preview",
];

export default function OnboardingFlowPage() {
  const api = useAPI();
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [steps, setSteps] = useState([]);

  const currentStepIndex = steps.findIndex((step) =>
    location.pathname.includes(step)
  );

  const fetchOnboardingStatus = async () => {
    try {
      const response = await api.getOnboardingStatus();
      setOnboardingStatus(response);
    } catch (e) {
      console.error("Error fetching onboarding status:", e);
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm({
    resolver: zodResolver(getOnboardingSchema(steps[currentStepIndex])),
    defaultValues: {
      ...onboardingStatus,
      marketplace_specialist: {
        name: "Alexandra Koss",
        image_id: "",
        email: "",
        info: "Alexandra is an AI solutions strategist with a passion for automation. She specializes in designing intelligent AI workflows and ensuring seamless integrations with existing tools.",
        user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        setup_datetime_in_utc_timezone: null,
      },
      demo_request: {
        first_name:
          onboardingStatus?.demo_request?.first_name ||
          onboardingStatus?.first_name ||
          "",
        last_name:
          onboardingStatus?.demo_request?.last_name ||
          onboardingStatus?.last_name ||
          "",
        company_name:
          onboardingStatus?.demo_request?.company_name ||
          onboardingStatus?.company_name ||
          "",
        industry:
          onboardingStatus?.demo_request?.industry ||
          onboardingStatus?.industry ||
          "",
        user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });

  const { handleSubmit, reset, getValues } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const payload = { ...getValues(), ...data, status: "in_progress" };
    if (currentStepIndex >= steps.length - 1) {
      payload.status = "completed";
    }

    try {
      setIsSubmitting(true);
      await api.saveOnboardingStatus(payload);
      setIsSubmitting(false);
      fetchOnboardingStatus();
      handleNext();
    } catch (e) {
      setIsSubmitting(false);
      console.error("Error saving onboarding status:", e);
    }
  });

  const handleNext = () => {
    if (currentStepIndex < steps.length - 1) {
      navigate(`/onboarding/${steps[currentStepIndex + 1]}`);
    }
  };

  const handlePrev = () => {
    if (currentStepIndex > 0) {
      navigate(`/onboarding/${steps[currentStepIndex - 1]}`);
    }
  };

  useEffect(() => {
    if (!loading && onboardingStatus) {
      reset(onboardingStatus);
      const correctStep = getUserCurrentStep(onboardingStatus);
      if (!location.pathname.includes(correctStep) && currentStepIndex === -1) {
        navigate(`/onboarding/${correctStep}`, { replace: true });
      }
      if (onboardingStatus.onboarding_type === "Business") {
        setSteps(businessSteps);
      }
      if (onboardingStatus.onboarding_type === "Marketplace") {
        setSteps(markeplaceSteps);
      }
    }
  }, [
    currentStepIndex,
    loading,
    location.pathname,
    navigate,
    onboardingStatus,
    reset,
  ]);

  useEffect(() => {
    fetchOnboardingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !onboardingStatus) {
    return <PageLoader />;
  }

  return (
    <div className='bg-background'>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Routes>
          <Route index path='' element={<Navigate to='welcome' />} />
          <Route
            path='welcome/*'
            element={<OnboardingWelcome isSubmitting={isSubmitting} />}
          />
          <Route
            path='basic-information/*'
            element={
              <BasicInfo
                isSubmitting={isSubmitting}
                handlePrev={handlePrev}
                steps={steps}
                currentStep={currentStepIndex}
              />
            }
          />
          <Route
            path='essential-information/*'
            element={
              <EssentialInfo
                isSubmitting={isSubmitting}
                handlePrev={handlePrev}
                steps={steps}
                currentStep={currentStepIndex}
              />
            }
          />
          <Route
            path='service-selection/*'
            element={
              onboardingStatus.onboarding_type === "Business" ? (
                <BusinessServiceSelection
                  isSubmitting={isSubmitting}
                  handlePrev={handlePrev}
                  steps={steps}
                  currentStep={currentStepIndex}
                />
              ) : (
                <MarketplaceServiceSelection
                  handlePrev={handlePrev}
                  steps={steps}
                  currentStep={currentStepIndex}
                  fetchOnboardingStatus={fetchOnboardingStatus}
                  onboardingStatus={onboardingStatus}
                />
              )
            }
          />
          <Route
            path='agent-customization/*'
            element={
              <AgentCustomization
                handlePrev={handlePrev}
                steps={steps}
                currentStep={currentStepIndex}
                fetchOnboardingStatus={fetchOnboardingStatus}
              />
            }
          />
          <Route
            path='marketplace-setup/*'
            element={
              <MarketplaceSetup
                isSubmitting={isSubmitting}
                steps={steps}
                currentStep={currentStepIndex}
              />
            }
          />
          <Route
            path='preview/*'
            element={
              onboardingStatus.onboarding_type === "Business" ? (
                <BusinessPreview
                  isSubmitting={isSubmitting}
                  steps={steps}
                  currentStep={currentStepIndex}
                />
              ) : (
                <MarketplacePreview
                  isSubmitting={isSubmitting}
                  steps={steps}
                  currentStep={currentStepIndex}
                  handlePrev={handlePrev}
                />
              )
            }
          />
        </Routes>
        <Outlet />
      </FormProvider>
    </div>
  );
}
