import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function Auth0ConfigurationForm() {
  return (
    <FormSection
      title={"Auth0 Integration"}
      subtitle={"Configuration for the Auth0 API Integration for this tenant"}
      collapsable={true}
    >
      <FormSubFieldRoot field={"auth0"}>
        <FormInput
          type={"text"}
          label={"Client ID"}
          fieldName={"client_id"}
          placeholder={
            "The client ID used for the Auth0 management API connection (note:! This is not the client_id of the frontend)"
          }
        />

        <FormInput
          type={"text"}
          label={"Client Secret"}
          fieldName={"client_secret"}
          placeholder={
            "The client secret used for the Auth0 management API connection (note:! This is not the client_secret of the frontend)"
          }
        />

        <FormInput
          type={"text"}
          label={"API URL"}
          fieldName={"api_url"}
          placeholder={
            "The URL that this tenant's frontend is using to connect to the tenant's backend API server. This is used to verify the audience of the access token."
          }
        />

        <FormInput
          type={"json"}
          label={"Key"}
          fieldName={"key"}
          placeholder={
            "This should be the JSON Web Key Set (JWKS) of the Auth0 tenant. This is used to verify the access token. Should be in JSON format."
          }
          multiline
          rows={8}
        />

        <FormInput
          type={"text"}
          label={"Domain"}
          fieldName={"domain"}
          placeholder={
            "The domain of the Auth0 tenant. This is used to verify the access token. If the tenant uses a custom Auth0 domain, this should be the custom domain."
          }
        />

        <FormInput
          type={"text"}
          label={"Management API Domain"}
          fieldName={"management_api_domain"}
          placeholder={"The domain used to connect to the Auth0 management API"}
        />
      </FormSubFieldRoot>
    </FormSection>
  );
}
