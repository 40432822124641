import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalCustomDataSchemaSchema = null;
let globalCustomFormSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getCustomDataSchemaSchema() {
    if (globalCustomDataSchemaSchema) {
      return globalCustomDataSchemaSchema;
    }
    const response = await axiosWithETAGCache.get(`/custom_data_schema/schema`);
    globalCustomDataSchemaSchema = response.data;
    return response.data;
  },
  async getCustomDataSchemas(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/custom_data_schema`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewCustomDataSchema(newCustomDataSchemaData) {
    const response = await axios.post(`/custom_data_schema`, newCustomDataSchemaData);

    return response.data;
  },
  async getCustomDataSchema(customDataSchemaId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/custom_data_schema/${customDataSchemaId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveCustomDataSchema(customDataSchema) {
    const response = await axios.put(
      `/custom_data_schema/${customDataSchema.id}`,
      customDataSchema
    );
    return response.data;
  },
  async deleteCustomDataSchema(customDataSchemaId) {
    const response = await axios.delete(`/custom_data_schema/${customDataSchemaId}`);

    return response.data;
  },
  async getCustomDataObjects(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/custom_data_object`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewCustomDataObject(customDataObject) {
    const response = await axios.post(`/custom_data_object`, customDataObject);

    return response.data;
  },
  async getCustomDataObject(customDataObjectId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/custom_data_object/${customDataObjectId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveCustomDataObject(customDataObject) {
    const response = await axios.put(
      `/custom_data_object/${customDataObject.id}`,
      customDataObject
    );
    return response.data;
  },
  async deleteCustomDataObject(customDataObjectId) {
    const response = await axios.delete(
      `/custom_data_object/${customDataObjectId}`
    );

    return response.data;
  },
  async executeCustomAction(customDataObjectId, actionName) {
    const response = await axios.post(
      `/custom_data_object/${customDataObjectId}/${actionName}`
    );

    return response.data;
  },
  async getCustomFormSchema() {
    if (globalCustomFormSchema) {
      return globalCustomFormSchema;
    }
    const response = await axiosWithETAGCache.get(`/custom_form/schema`);
    globalCustomFormSchema = response.data;
    return response.data;
  },
  async getCustomForms(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/custom_form`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewCustomForm(newCustomFormData) {
    const response = await axios.post(`/custom_form`, newCustomFormData);

    return response.data;
  },
  async getCustomForm(customFormId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/custom_form/${customFormId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveCustomForm(customForm) {
    const response = await axios.put(
      `/custom_form/${customForm.id}`,
      customForm
    );
    return response.data;
  },
  async deleteCustomForm(customFormId) {
    const response = await axios.delete(`/custom_form/${customFormId}`);

    return response.data;
  },
  async submitCustomForm(customFormId, formData) {
    const response = await axios.post(
      `/custom_form/${customFormId}/submit`,
      formData
    );
    return response.data;
  },

  async createCustomDataObjectsWithCsvUpload(file, customDataSchemaId, onUploadProgress, ignoreUnknownColumns = true) {
    const formData = new FormData();
    formData.append(file.name, file);
    formData.append('ignore_unknown_fields', ignoreUnknownColumns.toString());

    let handle = null;

    const options = {
      onUploadProgress: function (progressEvent) {
        const proportionCompleted = progressEvent.loaded / progressEvent.total;
        if (onUploadProgress) {
          onUploadProgress(proportionCompleted * 0.2);
        }

        if (progressEvent.loaded === progressEvent.total) {
          if (onUploadProgress) {
            onUploadProgress(0.2);
          }

          let progress = 0.2;
          // Now start an interval to update the progress bar until the file finishes getting processed.
          handle = setInterval(async () => {
            progress += (1.0 - progress) * 0.01;
            if (onUploadProgress) {
              onUploadProgress(progress);
            }
          }, 250);
        }
      },
      onDownloadProgress: function (progressEvent) {
        if (progressEvent.loaded === progressEvent.total) {
          if (handle) {
            clearInterval(handle);
          }
          if (onUploadProgress) {
            onUploadProgress(1.0);
          }
        }
      },
    };

    const response = await axios.post('/custom_data_schema/'+ customDataSchemaId +'/upload', formData, options);
    if (onUploadProgress) {
      onUploadProgress(1.0);
    }
    return response.data;
  },
};

export default api;
