import "@grapesjs/studio-sdk/style";
import React, {useCallback, useEffect} from "react";
import {useAPI, useUser} from "../../../frontend/components/APIProvider";

export default function DocumentTemplateTemplateTab({documentTemplateId}) {
  const api = useAPI();
  const user = useUser();
  const grapesJsUrl = process.env.REACT_APP_GRAPESJS_IFRAME
  const grapesJsOrigin = new URL(grapesJsUrl).origin
  const selfOrigin = window.location.origin;

  const handleMessage = useCallback(async (event) => {
    const iframe = document.getElementById('grapes_iframe');

    // Verify the origin is your iframe domain
    if (event.origin === grapesJsOrigin) {
      if (event.data.type === 'childToParentSave') {
        const documentTemplate = await api.getDocumentTemplate(documentTemplateId);
        const newDocumentTemplate = {
          ...documentTemplate,
          editor_state: event.data.data.editor_state,
          pages: []
        }

        // We have to merge the page data returned by the iframe with the existing data,
        // matching based on page_id
        for (const page of event.data.data.pages) {
          const existingPage = documentTemplate.pages.find(p => p.page_id === page.page_id);
          if (existingPage) {
            newDocumentTemplate.pages.push({
              ...existingPage,
              ...page
            });
          } else {
            newDocumentTemplate.pages.push(page);
          }
        }

        await api.saveDocumentTemplate(documentTemplateId, newDocumentTemplate);
      } else if (event.data.type === 'childToParentLoad') {
        const documentTemplate = await api.getDocumentTemplate(documentTemplateId);
        const dataToSend = {
          type: 'parentToChildLoadResponse',
          data: {
            id: documentTemplateId,
            project: documentTemplate.editor_state ?? null,
          }
        };
        iframe.contentWindow.postMessage(dataToSend, grapesJsOrigin);
      }
    }
  }, [documentTemplateId, api]);

  const sendMessage = useCallback(async () => {
    const iframe = document.getElementById('grapes_iframe');

    try {
      // Await the document template
      const template = await api.getDocumentTemplate(documentTemplateId);

      const dataToSend = {
        type: 'parentToChild',
        data: template.editor_state
      };

      iframe.contentWindow.postMessage(dataToSend, grapesJsOrigin);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, [documentTemplateId, api]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    const iframe = document.getElementById('grapes_iframe');

    if (iframe.contentWindow) {
      // Iframe already loaded, sending message
      sendMessage();
    }

    // Also attach load listener for safety
    iframe.addEventListener('load', () => {
      // Iframe load event triggered
      sendMessage();
    });

    // Cleanup
    return () => {
      iframe.removeEventListener('load', sendMessage);
      window.removeEventListener('message', handleMessage);
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
      <iframe style={{width: '100%',height: '100%', border: 'none'}}
              title={`grapes_iframe`}
              id={`grapes_iframe`}
              src={grapesJsUrl+
                `?documentTemplateId=`+ documentTemplateId +
                `&origin=` + selfOrigin +
                `&userSub=` + user.sub}
      />
  );
}