import HomePage from "@/frontend/pages/HomePage";
import SystemHealthPage from "../pages/SystemHealthPage";
import AboutSystemPage from "../pages/AboutSystemPage";
import TenantConfigurationsListPage from "../tenant_configuration/TenantConfigurationListPage";
import NewTenantConfigurationsPage from "../tenant_configuration/NewTenantConfigurationPage";
import TenantConfigurationsEditPage from "../tenant_configuration/TenantConfigurationEditPage";
import ConversationsListPage from "../../conversations/frontend/ConversationsListPage";
import ConversationViewPage from "../../conversations/frontend/ConversationViewPage";
import BusinessInfoPage from "@/business_info/frontend/BusinessInfoPage";
import AIConfigurationPage from "@/ai_configuration/frontend/AIConfigurationPage";
import SchedulingRulesPage from "@/scheduling/frontend/scheduling_rules/SchedulingRulesPage";
import ChatWithBotPage from "../../web_chat/frontend/ChatWithBotPage";
import VoiceConfigurationPage from "../../voice/frontend/VoiceConfigurationPage";
import WA2ChatConfigurationPage from "../../wa_2chat/frontend/WA2ChatConfigurationPage";
import BookingsListPage from "@/scheduling/frontend/bookings/BookingsListPage";
import NewBookingPage from "@/scheduling/frontend/bookings/NewBookingPage";
import EditBookingPage from "@/scheduling/frontend/bookings/EditBookingPage";
import RunSyntheticUserPage from "@/synthetic_user/frontend/RunSyntheticUserPage";
import CalendarListPage from "@/scheduling/frontend/calendars/CalendarListPage";
import NewCalendarPage from "@/scheduling/frontend/calendars/NewCalendarPage";
import EditCalendarPage from "@/scheduling/frontend/calendars/EditCalendarPage";
import WebChatConfigurationPage from "../../web_chat/frontend/WebChatConfigurationPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import SubscriptionPage from "../../billing/frontend/SubscriptionPage";
import ImportedDocumentListPage from "../../knowledge_base/frontend/imported_document/ImportedDocumentListPage";
import ImportedDocumentEditPage from "../../knowledge_base/frontend/imported_document/ImportedDocumentEditPage";
import NewImportedDocumentPage from "../../knowledge_base/frontend/imported_document/NewDocumentPage";
import KnowledgeChunkListPage from "../../knowledge_base/frontend/knowledge_chunk/KnowledgeChunkListPage";
import KnowledgeChunkViewPage from "../../knowledge_base/frontend/knowledge_chunk/KnowledgeChunkViewPage";
import BulkDocumentUploadPage from "../../knowledge_base/frontend/imported_document/BulkDocumentUploadPage";
import DefaultOutboundRulesPage from "../../outbound/frontend/DefaultOutboundRulesPage";
import DocumentTemplateListPage from "../../document_generator/frontend/document_template/DocumentTemplateListPage";
import DocumentTemplateEditPage from "../../document_generator/frontend/document_template/DocumentTemplateEditPage";
import ImportedWebpageListPage from "../../knowledge_base/frontend/imported_webpage/ImportedWebpageListPage";
import ImportedWebpageEditPage from "../../knowledge_base/frontend/imported_webpage/ImportedWebpageEditPage";
import ImportWebpagePage from "../../knowledge_base/frontend/imported_webpage/ImportWebpagePage";
import GeneratedDocumentListPage from "../../document_generator/frontend/generated_document/GeneratedDocumentListPage";
import GeneratedDocumentEditPage from "../../document_generator/frontend/generated_document/GeneratedDocumentEditPage";
import IntakeStartPage from "../../intake/frontend/IntakeStartPage";
import IntakeListPage from "../../intake/frontend/intakes/IntakeListPage";
import IntakeEditorPage from "../../intake/frontend/intakes/IntakeEditorPage";
import FilledIntakeListPage from "../../intake/frontend/filled_intakes/FilledIntakeListPage";
import ViewFilledIntakePage from "../../intake/frontend/filled_intakes/ViewFilledIntakePage";
import DefaultIntakeConfigurationPage from "../../intake/frontend/default_intake_configuration/DefaultIntakeConfigurationPage";
import SmartChainListPage from "../../smart_chains/frontend/smart_chain/SmartChainListPage";
import SmartChainEditorPage from "../../smart_chains/frontend/smart_chain/SmartChainEditPage";
import NewSmartChainBindingPage from "../../smart_chains/frontend/smart_chain_binding/NewSmartChainBindingPage";
import SmartChainBindingListPage from "../../smart_chains/frontend/smart_chain_binding/SmartChainBindingListPage";
import CustomDataSchemaListPage from "../../custom_data/frontend/custom_data_schema/CustomDataSchemaListPage";
import NewCustomDataSchemaPage from "../../custom_data/frontend/custom_data_schema/NewCustomDataSchemaPage";
import CustomDataSchemaEditPage from "../../custom_data/frontend/custom_data_schema/CustomDataSchemaEditPage";
import CustomDataObjectListPage from "../../custom_data/frontend/custom_data_object/CustomDataObjectListPage";
import CustomDataObjectUploadPage from "../../custom_data/frontend/custom_data_object/CustomDataObjectUploadPage";
import NewCustomDataObjectPage from "../../custom_data/frontend/custom_data_object/NewCustomDataObjectPage";
import CustomDataObjectEditPage from "../../custom_data/frontend/custom_data_object/CustomDataObjectEditPage";
import CustomFormListPage from "../../custom_data/frontend/custom_form/CustomFormListPage";
import NewCustomFormPage from "../../custom_data/frontend/custom_form/NewCustomFormPage";
import CustomFormEditPage from "../../custom_data/frontend/custom_form/CustomFormEditPage";
import UseCustomFormPage from "../../custom_data/frontend/custom_form/UseCustomFormPage";
import ImpersonatePage from "../pages/ImpersonatePage";
import LoginPage from "@/frontend/auth/LoginPage";
import SignupPage from "@/frontend/auth/SignupPage";
import OnboardingFlowPage from "@/onboarding/frontend/onboarding-flow/OnboardingFlowPage";
import EmailConfigurationPage from "../../email_channel/frontend/EmailConfigurationPage";
import AnalysisListPage from "../../knowledge_base/frontend/analysis/AnalysisListPage";
import AnalysisViewPage from "../../knowledge_base/frontend/analysis/AnalysisViewPage";
import PerformAnalysisPage from "../../knowledge_base/frontend/analysis/PerformAnalysisPage";
import TenantMenuConfigurationEditPage from "../tenant_settings/TenantMenuConfigurationEditPage";
import TenantIntroductoryConfigurationEditPage from "../tenant_settings/TenantIntroductoryConfigurationEditPage";

// Define the pages without lazy loading
const pages = {
  HomePage,
  SystemHealthPage,
  AboutSystemPage,
  TenantConfigurationsListPage,
  NewTenantConfigurationsPage,
  TenantConfigurationsEditPage,
  ConversationsListPage,
  ConversationViewPage,
  BusinessInfoPage,
  AIConfigurationPage,
  SchedulingRulesPage,
  ChatWithBotPage,
  VoiceConfigurationPage,
  WA2ChatConfigurationPage,
  BookingsListPage,
  NewBookingPage,
  EditBookingPage,
  RunSyntheticUserPage,
  PrivacyPolicyPage,
  TermsAndConditionsPage,
  SubscriptionPage,
  ImpersonatePage,
  ImportedDocumentListPage,
  ImportedDocumentEditPage,
  NewImportedDocumentPage,
  KnowledgeChunkListPage,
  KnowledgeChunkViewPage,
  BulkDocumentUploadPage,
  DefaultOutboundRulesPage,
  DocumentTemplateListPage,
  DocumentTemplateEditPage,
  ImportedWebpageListPage,
  ImportedWebpageEditPage,
  ImportWebpagePage,
  GeneratedDocumentListPage,
  GeneratedDocumentEditPage,
  IntakeStartPage,
  IntakeListPage,
  IntakeEditorPage,
  FilledIntakeListPage,
  ViewFilledIntakePage,
  DefaultIntakeConfigurationPage,
  SmartChainListPage,
  SmartChainEditorPage,
  NewSmartChainBindingPage,
  SmartChainBindingListPage,
  CustomDataSchemaListPage,
  NewCustomDataSchemaPage,
  CustomDataSchemaEditPage,
  CustomDataObjectListPage,
  CustomDataObjectUploadPage,
  NewCustomDataObjectPage,
  CustomDataObjectEditPage,
  CustomFormListPage,
  NewCustomFormPage,
  CustomFormEditPage,
  UseCustomFormPage,
  CalendarListPage,
  NewCalendarPage,
  EditCalendarPage,
  WebChatConfigurationPage,
  LoginPage,
  SignupPage,
  OnboardingFlowPage,
  EmailConfigurationPage,
  AnalysisListPage,
  AnalysisViewPage: AnalysisViewPage,
  PerformAnalysisPage,
  TenantMenuConfigurationEditPage,
  TenantIntroductoryConfigurationEditPage,
};

export default pages;
