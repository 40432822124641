import React, { useState } from "react";
import TopNav from "../../components/TopNav";
import Back from "../../components/Back";
import Next from "../../components/Next";
import { useAPI } from "@/frontend/components/APIProvider";
import {
  useNavigate,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import AgentIdentity from "./AgentIdentity";
import AgentVisual from "./AgentVisual";
import { useFormContext } from "react-hook-form";

export default function AgentCustomization({
  steps,
  currentStep,
  handlePrev,
  fetchOnboardingStatus,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getValues } = useFormContext();
  const api = useAPI();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkVisualRoute = location.pathname.includes("visual");

  const checkIdentityRoute = location.pathname.includes("identity");

  const handleNext = async () => {
    const values = getValues();
    try {
      setIsSubmitting(true);
      await api.saveOnboardingStatus(values);
      setIsSubmitting(false);
      fetchOnboardingStatus();
      navigate(`/onboarding/${steps[currentStep]}/visual`);
    } catch (e) {
      setIsSubmitting(false);
      console.error("Error saving onboarding status:", e);
    }
  };

  const handleBack = () => {
    navigate(`/onboarding/${steps[currentStep]}/identity`);
  };

  const renderButton = () => {
    if (checkVisualRoute) {
      return <Next isSubmitting={isSubmitting} />;
    } else if (checkIdentityRoute) {
      return (
        <Next isSubmitting={isSubmitting} onClick={handleNext} type='button' />
      );
    }
  };

  return (
    <div className='flex flex-col'>
      <TopNav steps={steps} currentStep={currentStep} />
      <Back handlePrev={checkVisualRoute ? handleBack : handlePrev} />
      <Routes>
        <Route index path='' element={<Navigate to='identity' />} />
        <Route path='identity/*' element={<AgentIdentity />} />
        <Route path='visual/*' element={<AgentVisual />} />
      </Routes>

      {renderButton()}
    </div>
  );
}
