import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getAIConfiguration() {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/ai_configuration`, {
            params: queryParams
        });

        return response.data;
    },
    async saveAIConfiguration(aiConfiguration) {
        const response = await axios.put(`/ai_configuration`, aiConfiguration);
        return response.data;
    },
}

export default api;