import * as React from "react";
import { TableView } from "../../frontend/elements/TableView";
import { useAPI } from "../../frontend/components/APIProvider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function ConversationsTable() {
  const api = useAPI();
  const navigate = useNavigate();

  const handleConversationRowClicked = useCallback(
    (conversation) => {
      navigate(`/conversations/${conversation.id}`);
    },
    [navigate]
  );

  const fetchConversations = useCallback(
    async (tableQuery) => {
      if (!tableQuery.filterModel["event_count"]) {
        tableQuery.filterModel["event_count"] = {
          filterType: "number",
          type: "greaterThan",
          filter: 1,
        };
      }
      if (!tableQuery.sortModel[0]) {
        tableQuery.sortModel = [
          {
            colId: "most_recent_event_time",
            sort: "desc",
          },
        ];
      }
      return await api.getConversations(tableQuery);
    },
    [api]
  );

  return (
    <TableView
      schemaAPI={api.getConversationSchema}
      fetchAPI={fetchConversations}
      onRowClicked={handleConversationRowClicked}
    />
  );
}
