import React, {useEffect, useState, useCallback, useMemo} from 'react';
import "./VoiceConfigurationPage.scss";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {TextField} from "@mui/material";
import Radio from "@mui/material/Radio";
import {useAPI} from "../../frontend/components/APIProvider";
import CircularProgress from "@mui/material/CircularProgress";
import {formatPhoneNumberHumanReadable} from "../../frontend/utils/phone";
import _ from "lodash";
import CountrySelectorForAvailablePhoneNumbers from "./CountrySelectorForAvailablePhoneNumbers";
import PhoneNumberTypeSelector from "./PhoneNumberTypeSelector";
import "./AvailablePhoneNumberSelector.scss";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export const AvailablePhoneNumberSelector = ({onChange}) => {
    const [availablePhoneNumbers, setAvailablePhoneNumbers] = useState([]);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
    const [country, setSelectedCountry] = useState("US");
    const [phoneNumberType, setPhoneNumberType] = useState("local");

    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const api = useAPI();

    const fetchAvailablePhoneNumbers = useCallback((search, country, phoneNumberType) => {
        if (search.length < 2 && search.length !== 0) {
            return;
        }

        setIsLoading(true);
        api.getAvailablePhoneNumbers(search, country, phoneNumberType).then((data) => {
            setIsLoading(false);
            const phone_numbers = data.available_phone_numbers;

            // Sort the phone numbers so they are nicer to display to the user
            phone_numbers.sort((a, b) => {
                return a.localeCompare(b);
            });

            setAvailablePhoneNumbers(phone_numbers);
        }, (error) => {
            setIsLoading(false);
            console.log(error);
        });
    }, [api]);

    const fetchAvailablePhoneNumbersDebounced = useMemo(() => _.debounce(fetchAvailablePhoneNumbers, 500), [fetchAvailablePhoneNumbers]);

    useEffect(() => {
        fetchAvailablePhoneNumbersDebounced(searchText, country, phoneNumberType);
    }, [fetchAvailablePhoneNumbersDebounced, searchText, country, phoneNumberType]);

    const handleClickedPhoneNumber = useCallback((phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
        onChange(phoneNumber);
    }, [onChange]);

    const handleSearchTextChange = useCallback((event) => {
        let newText = event.target.value;
        // Remove anything that isn't a number
        newText = newText.replace(/[^0-9]/g, '');

        setSearchText(newText);
        // fetchAvailablePhoneNumbersDebounced(newText);
    }, [fetchAvailablePhoneNumbersDebounced]);

    const handleCountryChanged = useCallback((newCountry) => {
        setSelectedCountry(newCountry);
        // fetchAvailablePhoneNumbersDebounced(searchText);
    }, [fetchAvailablePhoneNumbersDebounced, searchText]);

    const handlePhoneNumberTypeChanged = useCallback((newPhoneNumberType) => {
        setPhoneNumberType(newPhoneNumberType);
        // fetchAvailablePhoneNumbersDebounced(searchText);
    }, [fetchAvailablePhoneNumbersDebounced, searchText]);

    return (
        <div className={"available-phone-number-selector"}>
            <p><LocalTranslatedText language={"en"} text="Please select or search for the phone number you would like to use" /></p>

            <div className={"field-box"}>
                <div className={"field-box-column"}>
                    <CountrySelectorForAvailablePhoneNumbers
                        onChange={handleCountryChanged}
                        value={country}
                    />

                    <PhoneNumberTypeSelector
                        value={phoneNumberType}
                        onChange={handlePhoneNumberTypeChanged}
                    />

                    <TextField
                        fullWidth
                        id="phone-number-search"
                        label={<LocalTranslatedText language={"en"} text="Search for a phone number" />}
                        type="search"
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearchTextChange}
                    />
                </div>
                <div className={"field-loader-column"}>
                    {
                        isLoading ? <CircularProgress /> : null
                    }
                </div>
            </div>

            <List dense sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {availablePhoneNumbers.map((phoneNumber) => {
                    const labelId = `phone-number-selector-${phoneNumber}`;
                    return (
                        <ListItem
                            key={phoneNumber}
                            secondaryAction={
                                <Radio
                                    edge="end"
                                    checked={selectedPhoneNumber === phoneNumber}
                                    onChange={() => handleClickedPhoneNumber(phoneNumber)}
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            }
                            onClick={() => handleClickedPhoneNumber(phoneNumber)}
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemText id={labelId} primary={formatPhoneNumberHumanReadable(phoneNumber)}/>
                            </ListItemButton>
                        </ListItem>
                    )
                })
                }
                {
                    availablePhoneNumbers.length === 0 ?
                        <ListItem>
                            <ListItemText primary={<LocalTranslatedText language={"en"} text="No Phone Numbers Found..." />}/>
                        </ListItem>
                    : null
                }
            </List>
        </div>
    );
};
