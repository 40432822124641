import React from "react";

export function GoogleCalendar(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      {...props}
    >
      <path
        d='M14.237 4.26322L9.97386 3.78955L4.76331 4.26322L4.28955 9.0001L4.76322 13.737L9.5001 14.3291L14.237 13.737L14.7107 8.88175L14.237 4.26322Z'
        fill='white'
      />
      <path
        d='M6.70668 11.6121C6.35262 11.3729 6.10746 11.0236 5.97363 10.5617L6.79551 10.223C6.87012 10.5073 7.00035 10.7275 7.18629 10.8838C7.37106 11.0401 7.59606 11.1171 7.85895 11.1171C8.12778 11.1171 8.35872 11.0354 8.55168 10.8719C8.74464 10.7085 8.84184 10.5001 8.84184 10.2479C8.84184 9.98976 8.73996 9.77889 8.53629 9.61554C8.33262 9.45219 8.07684 9.37038 7.77129 9.37038H7.29645V8.55687H7.72269C7.98558 8.55687 8.20707 8.48586 8.38707 8.34375C8.56707 8.20164 8.65707 8.00742 8.65707 7.75992C8.65707 7.53969 8.57652 7.36437 8.41551 7.23297C8.2545 7.10157 8.05074 7.03524 7.80324 7.03524C7.56168 7.03524 7.3698 7.09923 7.22769 7.22829C7.08558 7.35735 6.98253 7.51602 6.91746 7.70313L6.10395 7.36446C6.21168 7.05891 6.4095 6.78891 6.69957 6.55563C6.98973 6.32235 7.36035 6.20508 7.81035 6.20508C8.14308 6.20508 8.44269 6.26907 8.70801 6.39813C8.97324 6.52719 9.18168 6.70602 9.33207 6.93336C9.48246 7.16187 9.55707 7.41774 9.55707 7.70187C9.55707 7.99203 9.48723 8.2371 9.34746 8.43843C9.20769 8.63976 9.03597 8.79366 8.8323 8.90148V8.94999C9.10113 9.06249 9.32019 9.23421 9.49308 9.46515C9.6648 9.69609 9.7512 9.97203 9.7512 10.2941C9.7512 10.6162 9.66948 10.904 9.50604 11.1562C9.3426 11.4085 9.11643 11.6074 8.82987 11.7519C8.54214 11.8963 8.21886 11.9698 7.86003 11.9698C7.44441 11.9709 7.06074 11.8513 6.70668 11.6121Z'
        fill='#1A73E8'
      />
      <path
        d='M11.7502 7.53408L10.8525 8.18658L10.4014 7.50213L12.0202 6.33447H12.6407V11.8422H11.7502V7.53408Z'
        fill='#1A73E8'
      />
      <path
        d='M14.2369 18L18.5 13.7369L16.3684 12.7896L14.2369 13.7369L13.2896 15.8684L14.2369 18Z'
        fill='#EA4335'
      />
      <path
        d='M3.81592 15.8684L4.76326 17.9999H14.2369V13.7368H4.76326L3.81592 15.8684Z'
        fill='#34A853'
      />
      <path
        d='M1.92101 0C1.13594 0 0.5 0.63594 0.5 1.42101V13.7368L2.63156 14.6841L4.76312 13.7368V4.26312H14.2368L15.1841 2.13156L14.2369 0H1.92101Z'
        fill='#4285F4'
      />
      <path
        d='M0.5 13.7368V16.5789C0.5 17.3641 1.13594 17.9999 1.92101 17.9999H4.76312V13.7368H0.5Z'
        fill='#188038'
      />
      <path
        d='M14.2368 4.26326V13.7369H18.4999V4.26326L16.3684 3.31592L14.2368 4.26326Z'
        fill='#FBBC04'
      />
      <path
        d='M18.4999 4.26312V1.42101C18.4999 0.63585 17.864 0 17.0789 0H14.2368V4.26312H18.4999Z'
        fill='#1967D2'
      />
    </svg>
  );
}
