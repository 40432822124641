import React from "react";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NamedIcon } from "../elements/NamedIcon";

export default function LeftMenuEntry({ item, onClick }) {
  return (
    <ListItem>
      <NavLink
        to={item.link}
        onClick={onClick}
        className={({ isActive, isPending }) => {
          return isActive ? "active" : isPending ? "pending" : "";
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <NamedIcon icon={item.icon} />
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
}
