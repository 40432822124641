import React from "react";
import HFTextField from "@/frontend/components/hook-form/hf-text-field";
import industries from "@/backend/data/industries.json";
import HFSelect from "@/frontend/components/hook-form/hf-select";
import { convertToLabelValuePair } from "@/frontend/lib/utils";
import HFTextarea from "@/frontend/components/hook-form/hf-textarea";
import HFCalendarTime from "@/frontend/components/hook-form/hf-calendar-time";
import CustomDialog from "@/frontend/components/widgets/CustomDialog";

export default function DemoForm({ open, onClose, onSubmit, isSubmitting }) {
  return (
    <CustomDialog
      title='Book a demo about customization'
      open={open}
      onClose={onClose}
      width='4xl'
      actionBtnText='Book Demo'
      actionBtnLoading={isSubmitting}
      onActionBtnClick={onSubmit}
    >
      <div className='grid grid-cols-12 gap-4 w-full'>
        <div className='col-span-12 lg:col-span-6 my-10'>
          <div className='flex flex-col gap-4 w-full'>
            <div className='w-full flex items-center gap-3'>
              <div className='w-full'>
                <HFTextField
                  label='First Name *'
                  fieldName='demo_request.first_name'
                  placeholder='Enter your first name . . .'
                />
              </div>
              <div className='w-full'>
                <HFTextField
                  label='Last Name *'
                  fieldName='demo_request.last_name'
                  placeholder='Enter your last name . . .'
                />
              </div>
            </div>
            <div className='w-full'>
              <HFTextField
                label='Company Name *'
                fieldName='demo_request.company_name'
                placeholder='ACME Inc'
              />
            </div>
            <div className='w-full'>
              <HFTextField
                label='Email Address *'
                fieldName='demo_request.email'
                placeholder='email@example.com'
              />
            </div>
            <div className='w-full'>
              <HFSelect
                label='Industry *'
                fieldName='demo_request.industry'
                placeholder='Select your industry'
                options={convertToLabelValuePair(industries.industries)}
              />
            </div>
            <div className='w-full'>
              <HFTextarea
                label='How would you like your Agent to be customized?'
                fieldName='demo_request.agent_goal'
                placeholder='Enter your customization requirements . . .'
                rows={4}
              />
            </div>
          </div>
        </div>
        <div className='col-span-12 lg:col-span-6 my-10'>
          <div className='w-full h-full flex justify-center items-center'>
            <HFCalendarTime fieldName='demo_request.demo_datetime_in_utc_timezone' />
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}
