import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../../frontend/components/APIProvider";
import globalTranslationManager from "@/translation/frontend/components/translation_manager";
import "./ChatWithAgentPopupWidget.scss";
import { ChatWithBotWidget } from "@/web_chat/frontend/ChatWithBotWidget";
import { Alert } from "@mui/material";

export default function ChatWithAgentPopupWidget() {
  const api = useAPI();
  const [conversation, setConversation] = useState(null);
  const [webChatConfig, setWebChatConfig] = useState(null);
  const [error, setError] = useState(null);

  const resetConversation = useCallback(async () => {
    try {
      const config = await api.getWebChatConfiguration();
      setWebChatConfig(config);
      const conversationPayload = {
        client_token: config.client_token,
        communication_channel: "web_chat",
        initiate_conversation: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language_id: globalTranslationManager.getLocalLanguage(),
      };
      const conversation = await api.startWebChatConversation(
        conversationPayload
      );
      setError(null);
      setConversation(conversation);
      localStorage.setItem("web_chat_conversation_id", conversation._id);
    } catch (err) {
      setError(err);
    }
  }, [api]);

  useEffect(() => {
    if (!conversation) {
      // Check if there is a conversation id in local storage. If so, try to load that conversation
      const conversationId = localStorage.getItem("web_chat_conversation_id");
      if (conversationId) {
        api.getConversation_noToastOnError(conversationId).then(
          (conversation) => {
            setConversation(conversation);
            setError(null);
          },
          (error) => {
            resetConversation();
          }
        );
      } else {
        resetConversation();
      }
    }
  }, [conversation, api, resetConversation]);

  useEffect(() => {
    api.getWebChatConfiguration().then((config) => {
      setWebChatConfig(config);
    });
  }, [api]);

  return (
    <div className={"chat-with-agent-popup-widget"}>
      <ChatWithBotWidget
        conversation={conversation}
        webChatConfiguration={webChatConfig}
        showResetButton={true}
        onResetClicked={resetConversation}
        showInternalEvents={true}
      />
      {error ? <Alert severity='error'>{error.message}</Alert> : null}
    </div>
  );
}
