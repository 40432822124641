import React from "react";
import RobotSpeak from "@/frontend/assets/img/robot-speak-1.webp";

export default function AgentContainer({
  agentVisual,
  watchAgentVisual,
  watchAgentName,
}) {
  return (
    <div className='col-span-12 lg:col-span-4'>
      <div className='w-full flex flex-col gap-4 h-full justify-center items-center border border-slate-300 bg-slate-50 shadow-container rounded-md p-8'>
        <img
          src={
            agentVisual?.id === watchAgentVisual
              ? agentVisual?.content
              : RobotSpeak
          }
          alt={agentVisual?.content ? "Agent" : "Robot Speak"}
          height={146}
          width={146}
          loading='lazy'
          className='w-36 h-36 rounded-full object-cover shrink-0 shadow-container'
        />
        <p className='text-base font-semibold'>{watchAgentName ?? ""}</p>
      </div>
    </div>
  );
}
