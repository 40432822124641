import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomDataSchemaTable } from "./CustomDataSchemaTable";
import "./CustomDataSchemaListPage.scss";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";

export default function CustomDataSchemaListPage() {
  const navigate = useNavigate();

  const handleNewCustomDataSchemaClicked = useCallback(async () => {
    navigate(`/custom_data_schema/new`);
  }, [navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Custom Data Schemas"}
        subtitle={"Add and manage your custom data schemas here"}
      >
        <WhiteButton onClick={handleNewCustomDataSchemaClicked}>
          Create New Schema
        </WhiteButton>
      </PageHeader>
      <div className={"custom-data-schema-list-page"}>
        <div className={"custom-data-schema-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <CustomDataSchemaTable />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
