import "./DocumentTemplateEditPage.scss";
import "@grapesjs/studio-sdk/style";
import Box from '@mui/material/Box';
import DocumentTemplateSettingsTab from "./DocumentTemplateSettingsTab";
import React, {useCallback, useEffect} from "react";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {useParams} from "react-router-dom";
import DocumentTemplatePageSettingsTab from "./DocumentTemplatePageSettingsTab";
import DocumentTemplateTemplateTab from "./DocumentTemplateTemplateTab";

export default function DocumentTemplateEditPage() {
  const params = useParams();
  const documentTemplateId = params.documentTemplateId;
  const [tab, setTab] = React.useState('template');

  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, [setTab]);

  return (
    <PageWrapperWithMenu padding={false}>
      <div className={"document-template-edit-page"}>
        <div className={`document-template-edit-page-tab ${tab} ${tab === 'template' ? 'visible' : 'hidden'}`}>
          <DocumentTemplateTemplateTab
            documentTemplateId={documentTemplateId}
          />
        </div>
        <div className={`document-template-edit-page-tab ${tab} ${tab === 'page_settings' ? 'visible' : 'hidden'}`}>
          {
            tab === "page_settings" ?
              <DocumentTemplatePageSettingsTab
                documentTemplateId={documentTemplateId}
              />
              : null
          }
        </div>
        <div className={`document-template-edit-page-tab ${tab} ${tab === 'settings' ? 'visible' : 'hidden'}`}>
          {
            tab === "settings" ?
              <DocumentTemplateSettingsTab
                documentTemplateId={documentTemplateId}
              />
              : null
          }
        </div>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Template" value={"template"}/>
            <Tab label="Page Settings" value={"page_settings"}/>
            <Tab label="Settings" value={"settings"}/>
          </Tabs>
        </Box>
      </div>
    </PageWrapperWithMenu>
  );
}