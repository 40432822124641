import Avatar from "@mui/material/Avatar";
import React from "react";
import "./ConversationAvatar.scss";
import FaceIcon from '@mui/icons-material/Face';
import MemoryIcon from '@mui/icons-material/Memory';
import Icon from '@mui/material/Icon';
import {Person} from "@mui/icons-material";

export function ConversationAvatar({avatarUrl, role, moduleId, circleColor, iconColor}) {
    let altText = null;
    let icon = null;

    const circleStyle = {
        backgroundColor: circleColor,
    };

    const iconStyle = {
        color: iconColor,
        fill: iconColor,
    };

    if (role === "user") {
        altText = "User";
        icon = <Person sx={iconStyle} />
    }
    else if (role === "assistant") {
        altText = "AI Bot";

        if (moduleId !== "user_interactor") {
            icon = <MemoryIcon sx={iconStyle} />;
        } else {
            if (avatarUrl) {
                if (avatarUrl.startsWith("http")) {
                    icon = <img alt={altText} src={avatarUrl}/>;
                }
                else if (avatarUrl.startsWith("icon://")) {
                    icon = <Icon sx={iconStyle}>{avatarUrl.substring(7)}</Icon>;
                }
            } else {
                icon = <FaceIcon sx={iconStyle}/>;
            }
        }
    }

    return <Avatar
        alt={altText}
        className={"conversation-avatar"}
        style={circleStyle}
    >
        {icon}
    </Avatar>;
}
