import React from "react";
import { TextInput } from "./TextInput";

export function URLInput({label, fieldName, size, required}) {
  return (
    <TextInput
      fieldName={fieldName}
      label={label}
      example={"https://www.mydomainname.com/"}
      size={size}
      required={required}
    />
  );
}

export default URLInput;
