import React from "react";
import TopNav from "../../components/TopNav";
import Back from "../../components/Back";
import { useNavigate } from "react-router-dom";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import Specialist from "@/frontend/assets/img/specialist.jpg";
import HFCalendarTime from "@/frontend/components/hook-form/hf-calendar-time";
import Next from "../../components/Next";

export default function MarketplaceSetup({ steps, currentStep, isSubmitting }) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/onboarding/${steps[currentStep - 1]}/tools`);
  };

  return (
    <div className='flex flex-col'>
      <TopNav steps={steps} currentStep={currentStep} />
      <Back handlePrev={handleBack} />
      <div className='flex flex-col gap-10 justify-center items-center'>
        <h4 className='text-center text-gray-900 text-3xl font-bold'>
          <LocalTranslatedText text="You're paired with our specialist to set up your Marketplace" />
        </h4>
        <div className='w-full lg:w-1/2 px-8 mx-auto flex flex-col gap-6'>
          <div className='grid grid-cols-12 gap-8'>
            <div className='col-span-12 md:col-span-6 w-full'>
              <div className='w-full p-8 flex flex-col gap-8 bg-slate-50 rounded-md border border-slate-300'>
                <div className='w-full h-[242px] min-w-full min-h-[242px] rounded-md bg-slate-200'>
                  <img
                    src={Specialist}
                    alt='Specialist'
                    className='object-cover w-full h-full rounded-md'
                    loading='lazy'
                  />
                </div>
                <div className='flex flex-col gap-5'>
                  <h4 className='text-primary text-2xl font-semibold leading-normal'>
                    <LocalTranslatedText text='Alexandra Koss' />
                  </h4>
                  <p className='text-base text-muted-foreground'>
                    <LocalTranslatedText text='Alexandra is an AI solutions strategist with a passion for automation. She specializes in designing intelligent AI workflows and ensuring seamless integrations with existing tools.' />
                  </p>
                </div>
              </div>
            </div>
            <div className='col-span-12 md:col-span-6 w-full'>
              <div className='w-full h-full flex justify-center items-center border border-slate-300 rounded-md p-8'>
                <HFCalendarTime
                  fieldName='marketplace_specialist.setup_datetime_in_utc_timezone'
                  className='border-none'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Next isSubmitting={isSubmitting} />
    </div>
  );
}
