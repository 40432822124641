import Diversity3Icon from '@mui/icons-material/Diversity3';
import * as React from 'react';
import VoiceSettingSlider from "./VoiceSettingSlider";


export default function SimilarityBoostSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Similarity Boost"
      description="The amount to which the model will attempt to make the voice more similar to the reference examples. Higher mimics the underlying reference more but can result in garbled speech, so finding an optimal balance is encouraged."
      id="similarity-boost-slider"
      min={0}
      max={1.0}
      step={0.05}
      icon={<Diversity3Icon />}
      marks={[
        { value: 0, label: '0' },
        { value: 0.25, label: '0.25' },
        { value: 0.5, label: '0.5' },
        { value: 0.75, label: '0.75' },
        { value: 1, label: '1' },
      ]}
      onChange={onChange}
      value={value}
    />
  );
}
