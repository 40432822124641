import { z } from "zod";

export const OnboardingSchema = z.object({
  onboarding_type: z
    .string()
    .min(1, "You must select the purpose of your AI Agent"),
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  industry: z.string().min(1, "Industry is required"),
  company_name: z.string().min(1, "Company name is required"),
  company_size: z.string().min(1, "Company size is required"),
  company_website_url: z
    .string()
    .url({ message: "Please enter a valid website URL" }),
  demo_datetime_in_user_timezone: z
    .string()
    .min(1, "Demo date and time is required"),
  agent_goal: z.string().min(1, "Agent goal is required"),
});

export const getOnboardingSchema = (step) => {
  switch (step) {
    case "welcome":
      return OnboardingSchema.pick({
        onboarding_type: true,
      });
    case "basic-informations":
      return OnboardingSchema.pick({
        first_name: true,
        last_name: true,
        industry: true,
        company_name: true,
        company_size: true,
      });
    case "essential-information":
      return OnboardingSchema.pick({
        company_website_url: true,
      });
    default:
      return OnboardingSchema.pick({
        onboarding_type: true,
      });
  }
};

export const getUserCurrentStep = (onboarding) => {
  if (!onboarding?.onboarding_type) {
    return "welcome";
  } else {
    switch (onboarding.onboarding_type) {
      case "Business":
        if (
          !onboarding?.first_name ||
          !onboarding?.last_name ||
          !onboarding?.company_name ||
          !onboarding?.industry ||
          !onboarding?.company_size
        ) {
          return "basic-information";
        } else if (!onboarding?.company_website_url) {
          return "essential-information";
        } else if (!onboarding?.agent_service) {
          return "service-selection";
        } else if (
          !onboarding?.agent_configuration?.agent_name ||
          !onboarding?.agent_configuration?.personality ||
          !onboarding?.agent_configuration?.voice_tone ||
          !onboarding?.agent_configuration?.voice_type
        ) {
          return "agent-customization/identity";
        } else if (
          !onboarding?.agent_configuration?.hair_color ||
          !onboarding?.agent_configuration?.hair_length ||
          !onboarding?.agent_configuration?.hair_style ||
          !onboarding?.agent_configuration?.skin_color ||
          !onboarding?.agent_configuration?.eyes_color ||
          !onboarding?.agent_configuration?.clothing_style ||
          !onboarding?.agent_configuration?.clothing_color
        ) {
          return "agent-customization/visual";
        }
        return "preview";
      case "Marketplace":
        if (
          !onboarding?.first_name ||
          !onboarding?.last_name ||
          !onboarding?.company_name ||
          !onboarding?.industry ||
          !onboarding?.company_size
        ) {
          return "basic-information";
        } else if (
          !onboarding?.agent_marketplace?.length ||
          !onboarding?.workflow_tools?.length
        ) {
          return "service-selection";
        } else if (
          !onboarding?.marketplace_specialist?.datetime_in_utc_timezone
        ) {
          return "marketplace-setup";
        }
        return "preview";
      default:
        return "welcome";
    }
  }
};
