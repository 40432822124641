import * as React from 'react';
import BalanceIcon from '@mui/icons-material/Balance';
import VoiceSettingSlider from "./VoiceSettingSlider";
import SpeedIcon from '@mui/icons-material/Speed';


export default function BackgroundFadeInTimeSlider({onChange, value}) {
  return (
    <VoiceSettingSlider
      title="Background Fade In Time"
      description="The number of seconds to spend fading in the background volume."
      id="background-fade-out-time-slider"
      min={1.0}
      max={30.0}
      step={1.0}
      icon={<SpeedIcon />}
      marks={[
        { value: 1.0, label: '1.0' },
        { value: 5.0, label: '5.0' },
        { value: 10.0, label: '10.0' },
        { value: 15.0, label: '15.0' },
        { value: 20.0, label: '20.0' },
        { value: 25.0, label: '25.0' },
        { value: 30.0, label: '30.0' },
      ]}
      onChange={onChange}
      value={value}
    />
  );
}

