import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton} from "@mui/material";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useCallback} from "react";


export default function RenameSmartChainStepButton({onNameChanged, currentName}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleNameChangeSubmitted = useCallback((event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const name = formJson.step_name;
        onNameChanged(name);
        handleClose();
    }, [onNameChanged, handleClose]);

    return (
        <React.Fragment>
            <IconButton edge="end" aria-label="rename" title={"Rename"} onClick={handleClickOpen}>
                <DriveFileRenameOutlineIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleNameChangeSubmitted
                }}
            >
                <DialogTitle>Rename</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type in a new name for this smart chain step.
                        <br/>
                        <strong>Warning!</strong>
                        This will immediately cause any down-stream chain steps that reference
                        outputs from this step to break. Please make sure you fix those downstream templates.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="smart-chain-step-name"
                        name="step_name"
                        label="Step Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue={currentName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Rename</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}