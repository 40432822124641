import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./HookForm.module.scss";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  Chip,
} from "@mui/material";

export default function RHFTagsInputField({
  name,
  type,
  label,
  helperText,
  options,
  placeholder,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <div className={styles.formGroup}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error} {...props}>
            <Autocomplete
              {...field}
              multiple
              id="tags-outlined"
              freeSolo
              options={options ?? []}
              onChange={(event, value) => field.onChange(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  variant="outlined"
                  name={name}
                />
              )}
            />
            {(!!error || helperText) && (
              <FormHelperText error={!!error} className={styles.helperText}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </div>
  );
}
