import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainFrontendApp from './app/MainFrontendApp';
import "./components/AGGridChartLicense";
import {FrontendConfigurationProvider} from "./components/FrontendConfigurationProvider";
// import reportWebVitals from './reportWebVitals';

document.title = "";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // TODO: Removing strict mode is required for react-beautiful-dnd to work correctly.
  // <React.StrictMode>
    <FrontendConfigurationProvider showBootScreen={true}>
        <MainFrontendApp />
    </FrontendConfigurationProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
