import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "./PublishSmartChainDialog.scss";
import {useCallback, useMemo} from "react";
import _ from "lodash";
import {randomString} from "../../../frontend/utils/random";
import CodeEditor from "../../../frontend/elements/CodeEditor";

export default function ExportSmartChainDialog({open, handleClose, smartChain}) {
  const modifiedSmartChainText = useMemo(() => {
    return JSON.stringify({
      tenant_id: 'default',
      user_id: null,
      data_source: "built_in",
      id: smartChain.chain_name,
      _id: smartChain.chain_name,
      ...(
        _.omit(
          smartChain,
          [
            "id",
            "_id",
            "tenant_id",
            "user_id",
            "steps",
            "actual_input_schema",
            "table_combined_filter_values",
            "created_at_date",
            "data_source",
          ]
        )
      ),
      steps: smartChain?.steps?.map((step) => {
        return {
          step_id: randomString(24),
          tenant_id: 'default',
          user_id: null,
          ...(
            _.omit(
              step,
              [
                "user_id",
                "step_id",
                "tenant_id",
                "available_input_schema",
                "actual_input_schema",
                "step_output_schema",
                "cumulative_output_schema"
              ]
            )
          )
        };
      })
    }, null, 2);
  }, [smartChain]);

  const handleCopyClicked = useCallback(() => {
    navigator.clipboard.writeText(modifiedSmartChainText);
  }, [modifiedSmartChainText]);


  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Export Smart Chain"}
        </DialogTitle>
        <DialogContent>
            <CodeEditor
              height={"70vh"}
              width={"70vw"}
              defaultLanguage="json"
              value={modifiedSmartChainText}
              readOnly={true}
              theme={"vs-dark"}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyClicked} color={"info"} autoFocus>Copy</Button>
          <Button onClick={handleClose} color={"success"} autoFocus>Exit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
