import React, {useState} from 'react';
import {SpinnerButton} from "../../frontend/elements/SpinnerButton";
import {AvailablePhoneNumberSelector} from "./AvailablePhoneNumberSelector";
import "./PhoneNumberPurchaseFlow.scss";
import {useAPI} from "../../frontend/components/APIProvider";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


export const PhoneNumberPurchaseFlow = ({onVoiceConfigurationChanged}) => {
    const api = useAPI();
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

    const handlePhoneNumberChanged = (phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
    }

    const handlePhoneNumberPurchaseClicked = () => {
        return api.saveVoiceConfiguration({
            phone_number_configuration_mode: "purchase",
            bot_number: selectedPhoneNumber,
        }).then(() => {
            onVoiceConfigurationChanged({
                phone_number_configuration_mode: "purchase",
                bot_number: selectedPhoneNumber,
                status: 'ready'
            })
        })
    }

    return ( <div>
        <AvailablePhoneNumberSelector
            onChange={handlePhoneNumberChanged}
        />
        <SpinnerButton
            color={'primary'}
            variant={'contained'}
            onClick={handlePhoneNumberPurchaseClicked}
            disabled={!selectedPhoneNumber}
        >
            <LocalTranslatedText language={"en"} text="Purchase Phone Number" />
        </SpinnerButton>
    </div>
    );
};
