import React, { useState } from "react";
import { cn } from "@/frontend/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/frontend/components/ui/popover";
import { Button } from "@/frontend/components/ui/button";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { ChevronsUpDown, Check } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/frontend/components/ui/command";

export function Select({
  options,
  className,
  defaultValue,
  onValueChange,
  ...props
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='transparent'
          role='combobox'
          aria-expanded={open}
          className={cn("w-full justify-between", className)}
        >
          {defaultValue ? (
            options.find((option) => option.value === defaultValue)?.label
          ) : (
            <span className='text-muted-foreground'>
              {props?.placeholder ? (
                <LocalTranslatedText text={props?.placeholder} />
              ) : (
                <LocalTranslatedText language='en' text='Select . . .' />
              )}
            </span>
          )}
          <ChevronsUpDown className='ml-2 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandInput placeholder='Search . . .' />
          <CommandEmpty>
            <LocalTranslatedText language='en' text='No results found.' />
          </CommandEmpty>
          <CommandList>
            <CommandGroup>
              {options?.map((option) => (
                <CommandItem
                  key={option?.value}
                  value={option?.value}
                  onSelect={(currentValue) => {
                    onValueChange(currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      option?.value === defaultValue
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {option?.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
