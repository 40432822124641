import React from "react";
import { ScrollArea } from "@/frontend/components/ui/scroll-area";
import { Input } from "@/frontend/components/ui/input";
import { Button } from "@/frontend/components/ui/button";
import { Send } from "lucide-react";

export default function AgentChat({ agentName, agentVisual }) {
  return (
    <div className='w-full h-[50vh] border border-slate-300 shadow-container rounded-md p-4 flex flex-col'>
      <ScrollArea className='flex-1 overflow-y-auto p-4 scrollbar-hide'>
        <div className='flex flex-col gap-6'>
          <div className='flex items-start gap-4'>
            <img
              src={agentVisual?.content}
              alt={agentName}
              className='w-11 h-11 rounded-full'
            />
            <div className='py-3 px-5 bg-slate-100 rounded-tr-md rounded-br-md rounded-bl-md w-full md:w-2/3 text-wrap'>
              <p className='text-base leading-6 text-[#637381]'>
                Hi there! I&apos;m Ella from Prospera Upscale. We help
                businesses optimize operations and drive growth. I&apos;d love
                to learn more about your business—what&apos;s your biggest
                challenge right now? Many companies struggle with scaling,
                workflow efficiency, or finding new revenue streams. Let&apos;s
                chat and see if we can help—no pressure!
              </p>
            </div>
          </div>
          <div className='flex flex-col items-end'>
            <div className='bg-slate-500 w-full md:w-2/3 p-3 rounded-tl-md rounded-tr-md rounded-bl-md text-wrap ml-auto'>
              <p className='text-base leading-6 text-white'>
                Hello, Ella! Thank you! Nice to meet you.
              </p>
            </div>
          </div>
        </div>
      </ScrollArea>

      <div className=' p-3 flex items-center gap-3'>
        <Input
          className='flex-1 border-none bg-slate-100 focus:ring-transparent'
          placeholder='Type something here...'
          autoFocus
        />
        <Button
          variant='icon'
          className='border-sm bg-slate-950 hover:opacity-80'
          type='button'
        >
          <Send className='text-white' />
        </Button>
      </div>
    </div>
  );
}
