import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getOnboardingChecklist() {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(`/onboarding`, {
      params: queryParams,
    });

    return response.data;
  },

  async getOnboardingStatus() {
    const response = await axiosWithETAGCache.get(`/onboarding_status`);
    return response.data;
  },

  async saveOnboardingStatus(onboardingStatus) {
    const response = await axios.put(`/onboarding_status`, onboardingStatus);
    return response.data;
  },

  async uploadOnboardingStatusDocument(file, onSetUploadProgress) {
    const formData = new FormData();
    formData.append(file.name, file);

    const response = await axios.post(
      `/onboarding_status/upload_document`,
      formData,
      {
        onUploadProgress: (event) => {
          const progress = Math.round((100 * event.loaded) / event.total);
          onSetUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progress < 100 ? progress : 99,
          }));
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    onSetUploadProgress((prevProgress) => ({
      ...prevProgress,
      [file.name]: 100,
    }));

    return response.data;
  },
};

export default api;
