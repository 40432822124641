import * as React from 'react';
import {useCallback} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


let globalCurrentlyPlayingAudioClip = null;


export default function WaitingNoiseSelector({value, onChange}) {
    const handleChange = useCallback((event) => {
        const newValue = event.target.value;
        onChange(newValue);

        // Play an audio clip sourced from /voice_samples/:voiceId:.mp3
        if (globalCurrentlyPlayingAudioClip) {
            globalCurrentlyPlayingAudioClip.pause();
        }

        if (newValue) {
            globalCurrentlyPlayingAudioClip = new Audio(`/audio/${newValue}.mp3`);
            globalCurrentlyPlayingAudioClip.play();
        }

    }, [onChange]);

  return (
    <FormControl>
      <RadioGroup
        value={value}
        name="waiting_noise"
        onChange={handleChange}
      >
        <FormControlLabel value="" control={<Radio />} label={<LocalTranslatedText language={"en"} text={"None"} />} />
        <FormControlLabel value="waiting_boop" control={<Radio />} label={<LocalTranslatedText language={"en"} text={"Thinking Boop"}/>} />
        <FormControlLabel value="waiting_typing" control={<Radio />} label=<LocalTranslatedText language={"en"} text={"Typing"} /> />
      </RadioGroup>
    </FormControl>
  );
}