import React, { useState } from "react";
import RichEditor from "../elements/rich-editor/RichEditor";
import { Box, Button, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";

export default function TenantIntroductoryConfigurationForm({
  initialValues,
  onSubmit,
  isSubmitting,
  saveSuccess,
}) {
  const [value, setValue] = useState(initialValues?.introductory_text || "");

  const handleChange = (value) => {
    setValue(value);
  };

  const handleSubmit = () => {
    const newValues = {
      ...initialValues,
      introductory_text: value,
    };

    onSubmit(newValues);
  };

  return (
    <Box display='flex' flexDirection='column' gap={4}>
      <RichEditor
        value={value}
        onChange={handleChange}
        id='tenant-introductory-configuration'
        placeholder='Enter introductory text here...'
        rows={15}
      />
      <span>
        <Button
          type='button'
          variant='contained'
          color='primary'
          disabled={isSubmitting}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={20} />
            ) : saveSuccess ? (
              <CheckCircleIcon />
            ) : (
              <SaveIcon />
            )
          }
          onClick={handleSubmit}
        >
          {saveSuccess ? "Saved!" : "Save Introductory Text"}
        </Button>
      </span>
    </Box>
  );
}
