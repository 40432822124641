import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function convertToLabelValuePair(data) {
  return data.map((item) => ({
    label: item,
    value: item,
  }));
}
