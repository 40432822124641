import React from "react";

export function Zoom(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_157479_2888'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_157479_2888)'>
        <path
          d='M-0.450195 -0.449951H18.4498V18.45H-0.450195V-0.449951Z'
          fill='white'
        />
      </g>
      <mask
        id='mask1_157479_2888'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          d='M8.99973 17.28C13.5726 17.28 17.2797 13.5729 17.2797 8.99997C17.2797 4.42705 13.5726 0.719971 8.99973 0.719971C4.42681 0.719971 0.719727 4.42705 0.719727 8.99997C0.719727 13.5729 4.42681 17.28 8.99973 17.28Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_157479_2888)'>
        <path
          d='M0.269531 0.27002H17.7295V17.73H0.269531V0.27002Z'
          fill='#4A8CFF'
        />
      </g>
      <mask
        id='mask2_157479_2888'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='3'
        y='6'
        width='12'
        height='6'
      >
        <path
          d='M11.5206 7.92534L13.8606 6.21534C14.0649 6.04884 14.2206 6.08754 14.2206 6.39534V11.6099C14.2206 11.9555 14.0262 11.9159 13.8606 11.7899L11.5206 10.0799V7.92534ZM3.60059 6.40794V10.2959C3.60273 10.718 3.7724 11.122 4.0723 11.419C4.37221 11.7161 4.77779 11.8819 5.19989 11.8799H10.8699C10.9466 11.8802 11.0203 11.85 11.0748 11.796C11.1292 11.742 11.1601 11.6686 11.1606 11.5919V7.70394C11.1584 7.28184 10.9888 6.87787 10.6889 6.58084C10.389 6.28381 9.98338 6.11802 9.56129 6.11994H3.89129C3.81458 6.1197 3.74091 6.14988 3.68642 6.20386C3.63193 6.25785 3.60106 6.33123 3.60059 6.40794Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_157479_2888)'>
        <path
          d='M3.15039 5.66992H14.6704V12.3299H3.15039V5.66992Z'
          fill='white'
        />
      </g>
    </svg>
  );
}
