import React from "react";
import { cn } from "@/frontend/lib/utils";
import { Button } from "@/frontend/components/ui/button";
import RobotSpeak from "@/frontend/assets/img/robot-speak-1.webp";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function AgentServiceButton({
  service,
  watchAgentService,
  setValue,
  setAgentService,
}) {
  return (
    <Button
      variant='outline'
      className={cn(
        "h-14 w-full border-slate-300 hover:border-primary hover:border-2 justify-start shadow-container mb-4",
        watchAgentService === service?.service_name &&
          "border-2 border-primary bg-indigo-50"
      )}
      type='button'
      onClick={() => {
        setValue("agent_service", service?.service_name);
        setAgentService(service);
      }}
    >
      <img
        src={RobotSpeak}
        alt='Robot Speak'
        height={25}
        width={25}
        loading='lazy'
      />
      <p className='text-wrap'>
        <LocalTranslatedText text={service?.service_name} />
      </p>
    </Button>
  );
}
