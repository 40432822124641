import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CustomDataObjectForm from "../custom_data_object/CustomDataObjectForm";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function UseCustomFormPage(props) {
  const api = useAPI();
  const navigate = useNavigate();
  const params = useParams();
  const [customForm, setCustomForm] = React.useState(null);

  useEffect(() => {
    api.getCustomForm(params.customFormId).then((data) => {
      setCustomForm(data);
    });
  }, [params.customFormId, api]);

  const handleSubmitCustomForm = useCallback(
    async (customFormData) => {
      customFormData.custom_form_id = customForm.id;

      return await api.submitCustomForm(params.customFormId, customFormData);
    },
    [api, navigate, customForm, params.customFormId]
  );

  if (!customForm) {
    return (
      <PageWrapperWithMenu>
        <PageHeader title={`${customForm?.title ?? ''}`} />
        <div className='use-custom-form-page'>
          <Card className='use-custom-form-card'>
            <CardContent>
              <CircularProgress />
            </CardContent>
          </Card>
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`${customForm?.title ?? ''}`} />
      <div className='use-custom-form-page'>
        <Card className='use-custom-form-card'>
          <CardContent>
            <CustomDataObjectForm
              customDataSchema={customForm}
              initialCustomDataObject={{}}
              onCustomDataObjectSave={handleSubmitCustomForm}
              saveButtonText={`Submit`}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
