import React from "react";

export function Dropbox(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M4.5 1.125L0 4.09176L4.5 7.01491L8.99996 4.09176L4.5 1.125ZM13.5 1.125L8.99996 4.09176L13.5 7.01491L17.9999 4.09176L13.5 1.125ZM0 9.98164L4.5 12.9484L8.99996 9.98164L4.5 7.01491L0 9.98164ZM13.5 7.01491L8.99996 9.98164L13.5 12.9484L17.9999 9.98164L13.5 7.01491ZM4.5 13.9083L8.99996 16.875L13.5 13.9083L8.99996 10.9851L4.5 13.9083Z'
        fill='#0062FF'
      />
    </svg>
  );
}
