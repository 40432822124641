import React, { useContext, useEffect, useState } from "react";
import { api } from "./api";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";
const FrontendConfigurationContext = React.createContext(null);

/**
 * The frontend configuration provider is used to fetch the main configuration for the frontend depending
 * on what tenant is loaded.
 */
export const FrontendConfigurationProvider = ({ children, showBootScreen }) => {
  const [frontendConfiguration, setFrontendConfiguration] = useState();

  const fetchConfiguration = async () => {
    try {
      const frontendFeatures = await api.frontendConfiguration();
      setFrontendConfiguration(frontendFeatures);
    } catch (e) {
      console.error("Error fetching configuration:", e);
    }
  };

  useEffect(() => {
    if (!frontendConfiguration) {
      fetchConfiguration();
    }
  }, [frontendConfiguration]);

  if (!frontendConfiguration) {
    if (showBootScreen) {
      return <ApplicationBootingPage
        description={"Getting the frontend configuration ..."}
      />;
    } else {
      return null;
    }
  }

  return (
    <FrontendConfigurationContext.Provider value={frontendConfiguration}>
      {children}
    </FrontendConfigurationContext.Provider>
  );
};

export const useFrontendConfiguration = useContext.bind(
  null,
  FrontendConfigurationContext
);
