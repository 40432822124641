import { useFormContext } from "react-hook-form";
import { cn } from "@/frontend/lib/utils";
import {
  FormItem,
  FormField,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/frontend/components/ui/form";
import { Checkbox } from "@/frontend/components/ui/checkbox";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";

export default function HFCheckbox({ fieldName, label, center, ...rest }) {
  const { control } = useFormContext();

  return (
    <FormField
      name={fieldName}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          <FormControl>
            <div
              className={cn(
                "flex flex-row gap-1.5",
                center ? "items-center" : "items-start"
              )}
            >
              <Checkbox
                id={fieldName}
                checked={field.value}
                onCheckedChange={field.onChange}
                className={cn(error && "border-danger")}
                {...rest}
              />
              <FormLabel htmlFor={fieldName} className='cursor-pointer'>
                <LocalTranslatedText text={label} />
              </FormLabel>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
