import { arrayMove } from "@dnd-kit/sortable";

export const moveItemInNestedArray = (array, sourceId, destinationId) => {
  let sourceItem = null;
  let sourceParent = null;

  // Find the item and its parent
  const findItemAndParent = (items, parent = null) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === sourceId) {
        sourceItem = items[i];
        sourceParent = parent;
        return;
      }
      if (items[i].children) {
        findItemAndParent(items[i].children, items[i]);
      }
    }
  };

  findItemAndParent(array);

  // If the item is found and has a parent, remove it from the parent's children
  if (sourceParent) {
    sourceParent.children = sourceParent.children.filter(
      (child) => child.id !== sourceId
    );
  } else {
    // If the item has no parent, it must be a top-level item, so remove it from the root
    array = array.filter((item) => item.id !== sourceId);
  }

  // Insert item at the new location
  const insertItem = (items, parentId) => {
    return items.map((item) => {
      if (item.id === parentId) {
        return { ...item, children: [...item.children, sourceItem] };
      }
      if (item.children) {
        item.children = insertItem(item.children, parentId);
      }
      return item;
    });
  };

  return insertItem(array, destinationId);
};

export const reorderItemsInNestedArray = (array, sourceId, destinationId) => {
  const reorder = (items) => {
    const indexFrom = items.findIndex((item) => item.id === sourceId);
    const indexTo = items.findIndex((item) => item.id === destinationId);

    if (indexFrom !== -1 && indexTo !== -1) {
      return arrayMove(items, indexFrom, indexTo);
    }

    return items.map((item) => ({
      ...item,
      children: reorder(item.children),
    }));
  };

  return reorder(array);
};

export const getParentId = (items, itemId) => {
  for (let item of items) {
    if (item.children.some((child) => child.id === itemId)) return item.id;
    let found = getParentId(item.children, itemId);
    if (found) return found;
  }
  return null;
};

export function extractLinks(menuItems) {
  const result = [];

  function traverse(items) {
    for (const item of items) {
      if (item.link && item.link.trim() !== "") {
        result.push(item.link);
      }

      if (Array.isArray(item.children) && item.children.length > 0) {
        traverse(item.children);
      }
    }
  }

  traverse(menuItems);
  return result;
}
