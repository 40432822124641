import React, { useCallback, useState, useMemo } from "react";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import "./CustomDataSchemaForm.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import SchemaEditor from "../../../frontend/elements/schema-editor/SchemaEditor";
import IconSelector from "../../../frontend/elements/form/inputs/IconSelector";
import FormArraySection from "../../../frontend/elements/form/FormArraySection";
import SmartChainBindingSelector from "../../../smart_chains/frontend/smart_chain_binding/SmartChainBindingSelector";
import {Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useIsAdmin} from "../../../frontend/components/IsAdminProvider";

export default function CustomDataSchemaForm({
  initialCustomDataSchema,
  onCustomDataSchemaSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const api = useAPI();
  const isAdmin = useIsAdmin();
  const [customDataSchema, setCustomDataSchema] = useState(initialCustomDataSchema);
  const [schemaData, setSchemaData] = useState(
    initialCustomDataSchema?.json_schema || {}
  );
  const [userId, setUserId] = useState(initialCustomDataSchema?.user_id ?? null);
  const [enableOutboundRules, setEnableOutboundRules] = useState(
      Boolean(initialCustomDataSchema?.outbound_managed_datetime_field)
  );
  const [selectedDatetimeField, setSelectedDatetimeField] = useState(
    initialCustomDataSchema?.outbound_managed_datetime_field || ""
  );
  const [selectedPhonenumberField, setSelectedPhonenumberField] = useState(
    initialCustomDataSchema?.outbound_managed_phonenumber_field || ""
  );
  const [selectedEmailField, setSelectedEmailField] = useState(
    initialCustomDataSchema?.outbound_managed_email_field || ""
  );

  const handleCustomDataSchemaSaved = useCallback(
    (newCustomDataSchemaData) => {
      if (onCustomDataSchemaSave) {
        const payload = {
          ...newCustomDataSchemaData,
          json_schema: schemaData,
          user_id: userId,
          outbound_managed_datetime_field: enableOutboundRules ? selectedDatetimeField : null,
          outbound_managed_phonenumber_field: enableOutboundRules ? selectedPhonenumberField : null,
          outbound_managed_email_field: enableOutboundRules ? selectedEmailField : null,
        };
        return onCustomDataSchemaSave(payload);
      }
    },
    [onCustomDataSchemaSave, schemaData, userId, enableOutboundRules, selectedDatetimeField,
      selectedPhonenumberField, selectedEmailField]
  );

  const handleChangeApplyToAllUsers = useCallback(async (evt) => {
    if (evt.target.checked) {
      setUserId(null);
    } else {
      const userDetails = await api.getUserDetails();
      setUserId(userDetails.user_id);
    }
  }, [api]);

  const handleChangeOutboundRules = useCallback((evt) => {
    setEnableOutboundRules(evt.target.checked);
  }, []);

  const handleCustomDataSchemaChange = useCallback(
    (newCustomDataSchemaData) => {
      setCustomDataSchema(newCustomDataSchemaData);
    },
    []
  );

  const handleSchemaEditorChange = useCallback((schemaData) => {
    setSchemaData(schemaData);
  }, []);

  // Find all datetime, email, and phone fields in the schema
  const { datetimeFields, emailFields, phonenumberFields } = useMemo(() => {
    const datetimeFields = [];
    const emailFields = [];
    const phonenumberFields = [];
    
    // Helper function to recursively find fields of specific types
    const findFieldsByType = (schema, path = "") => {
      if (!schema || typeof schema !== "object") return;
      
      if (schema.properties) {
        // Process each property in the schema
        Object.entries(schema.properties).forEach(([key, value]) => {
          const currentPath = path ? `${path}.${key}` : key;
          
          // Check field types based on form_input_type
          if (value.type === "string") {
            if (value.form_input_type === "datetime") {
              datetimeFields.push({ key: currentPath, value });
            } else if (value.form_input_type === "email") {
              emailFields.push({ key: currentPath, value });
            } else if (value.form_input_type === "phone") {
              phonenumberFields.push({ key: currentPath, value });
            }
          }
          
          // Recursively check nested objects
          if (value.type === "object") {
            findFieldsByType(value, currentPath);
          }
          
          // Check items in arrays
          if (value.type === "array" && value.items && value.items.type === "object") {
            findFieldsByType(value.items, `${currentPath}[]`);
          }
        });
      }
    };
    
    findFieldsByType(schemaData);
    return { datetimeFields, emailFields, phonenumberFields };
  }, [schemaData]);

  // Ensure we have a valid selection when datetime fields are available
  React.useEffect(() => {
    if (enableOutboundRules && datetimeFields.length > 0 && !selectedDatetimeField) {
      // If no field is selected but fields are available, select the first one
      setSelectedDatetimeField(datetimeFields[0].key);
    }
  }, [enableOutboundRules, datetimeFields, selectedDatetimeField]);

  return (
    <div className={"custom-data-schema-form"}>
      <FormRoot
        initialValue={initialCustomDataSchema}
        saveButtonText={saveButtonText ?? "Save Custom Data Schema"}
        onSave={handleCustomDataSchemaSaved}
        onChange={handleCustomDataSchemaChange}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                <LocalTranslatedText language={"en"} text='Delete' />
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"General"}
          subtitle={"Metadata that about this custom data schema"}
        >
          <FormInput
            type={"text"}
            label={"Machine Name"}
            fieldName={"machine_name"}
            placeholder={
              "The machine name/id for the schema. This must be an all" +
              " lower-case string with only alphanumerics, underscores and hyphens" +
              " (url compatible)."
            }
            size='small'
          />

          <FormInput
            type={"text"}
            label={"Title"}
            fieldName={"title"}
            size='small'
          />

          <FormInput
            type={"text"}
            label={"Description"}
            fieldName={"description"}
            rows={4}
            multiline={true}
            size='small'
          />
        </FormSection>

        <SchemaEditor
          defaultLayout='schema'
          mode='full'
          initialSchemaData={schemaData}
          onChange={handleSchemaEditorChange}
          options={{
            padding: false,
            boxShadow: false,
            metadataComponent: false,
            margin: false,
          }}
        />

        <FormSection title={"Options"}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FormInput
              type={"checkbox"}
              fieldName={"show_in_left_menu"}
              label={"Show in Left Menu"}
              size='small'
            />
            <IconSelector fieldName={"left_menu_icon"} label={"Left Menu Icon"} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <FormControlLabel
              control={
                <Checkbox
                  checked={enableOutboundRules}
                  onChange={handleChangeOutboundRules}
                />
              }
              label="Link default outbound rules to this schema"
            />
            
            <div style={{ marginLeft: '30px', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              This will enable automatic scheduling based on the selected fields and the default
              scheduling rules defined at <a
                href="/default_outbound_rules"
                target="_blank" 
                rel="noopener noreferrer"
                style={{ marginLeft: '5px', color: '#1976d2', textDecoration: 'none' }}
              >Follow Up Rules</a>
            </div>
            
            {enableOutboundRules && datetimeFields.length > 0 && (
              <FormControl size="small" style={{ width: '300px', marginLeft: '30px' }}>
                <InputLabel>Select Datetime Field</InputLabel>
                <Select
                  value={selectedDatetimeField}
                  onChange={(e) => setSelectedDatetimeField(e.target.value)}
                  label="Select Datetime Field"
                >
                  {datetimeFields.map((field) => (
                    <MenuItem key={field.key} value={field.key}>
                      {field.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            
            {enableOutboundRules && datetimeFields.length === 0 && (
              <div style={{ color: 'rgba(211, 47, 47, 0.8)', marginLeft: '30px' }}>
                No datetime fields found in schema. Add a field with type "String" and Form Input Type "Datetime".
              </div>
            )}

            {enableOutboundRules && (
              <FormControl size="small" style={{ width: '300px', marginLeft: '30px' }}>
                <InputLabel>Select Phonenumber Field</InputLabel>
                <Select
                  value={selectedPhonenumberField}
                  onChange={(e) => setSelectedPhonenumberField(e.target.value)}
                  label="Select Phonenumber Field"
                >
                  {phonenumberFields.map((field) => (
                    <MenuItem key={field.key} value={field.key}>
                      {field.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {enableOutboundRules && (
              <FormControl size="small" style={{ width: '300px', marginLeft: '30px' }}>
                <InputLabel>Select Email Field</InputLabel>
                <Select
                  value={selectedEmailField}
                  onChange={(e) => setSelectedEmailField(e.target.value)}
                  label="Select Email Field"
                >
                  {emailFields.map((field) => (
                    <MenuItem key={field.key} value={field.key}>
                      {field.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            
            {enableOutboundRules && (
              <FormControl size="small" style={{ width: '300px', marginLeft: '30px' }}>
                <InputLabel>Select Smart Chain Binding</InputLabel>
                <div className="smart-chain-binding-wrapper">
                  <SmartChainBindingSelector
                    id={"outbound-smart-chain-binding-selector"}
                    label={""}
                    fieldName={"outbound_managed_smartchain"}
                  />
                </div>
              </FormControl>
            )}
          </div>
        </FormSection>
        
        <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.25)', margin: '0' }}></div>

        <FormSection title={"Custom Actions"}>
          <FormArraySection
            fieldName={"custom_actions"}
            defaultItemValue={{}}
            arrayFormItemComponent={({ item, index }) => (
              <>
                <FormSection title={`Custom Action ${index + 1}`}>
                  <FormInput
                    type={"text"}
                    label={"Action Name"}
                    fieldName={"action_name"}
                    size='small'
                  />

                  <FormInput
                    type={"text"}
                    label={"Title"}
                    fieldName={"title"}
                    size='small'
                  />

                  <SmartChainBindingSelector
                      id={"actions-smart-chain-binding-selector"}
                      label={"Smart chain binding"}
                      fieldName={"smart_chain_binding_name"}
                  />
                </FormSection>
              </>
            )}
          />
        </FormSection>

        <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.25)', margin: '0', height: '0px' }}></div>

        {
          isAdmin ?
            <FormSection title={"Owner"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customDataSchema.user_id === null}
                    onChange={handleChangeApplyToAllUsers}
                  />
                }
                label="Apply to all users"
              />
            </FormSection>
            : null
        }
      </FormRoot>
    </div>
  );
}
