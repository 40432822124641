import {Alert, IconButton} from "@mui/material";
import React, {useCallback, useMemo} from "react";
import moment from "moment";
import "./ConversationHistory.scss";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import FilePreview from "../../file_uploads/frontend/FilePreview";
import IndeterminateLoadingBar from "../../frontend/elements/IndeterminateLoadingBar";
import {ConversationAvatar} from "./ConversationAvatar";
import {useFrontendConfiguration} from "../../frontend/components/FrontendConfigurationProvider";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {titleCaseFieldName} from "./ConversationUtilities";


export function ConversationHistoryEvent({
                                           event,
                                           eventIndex,
                                           lastEvent,
                                           isLast,
                                           webChatConfiguration,
                                         }) {
  const config = useFrontendConfiguration();
  const [isShowingInfoPopover, setIsShowingInfoPopover] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const generatedDocumentUrl = `${config.frontend.backend_api_url}generated_document/${event?.metadata?.generated_document_id}/rendered`;

  const handleGeneratedDocumentClick = React.useCallback(
    (e) => {
      // Open the file in a new screen
      window.open(generatedDocumentUrl, "_blank");
      e.preventDefault();
    },
    [generatedDocumentUrl]
  );

  const isDateDifferentThenLast = useMemo(() => {
    if (lastEvent) {
      return moment(event.created_at).format("MMM Do") !== moment(lastEvent.created_at).format("MMM Do");
    }
    return true;
  }, [event.created_at, lastEvent]);

  const handleClickShowInfoPopover = useCallback((event) => {
    setIsShowingInfoPopover(true);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setIsShowingInfoPopover(false);
    setAnchorEl(null);
  }, []);

  let isDateDifferentThenLastClass = isDateDifferentThenLast ? "date-different" : "";

  const dateHeader = useMemo(() => {
    if (!isDateDifferentThenLast) return null;
    
    const eventDate = moment(event.created_at);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    
    if (eventDate.isSame(today, 'day')) {
      return <LocalTranslatedText language={"en"} text="Today" />;
    } else if (eventDate.isSame(yesterday, 'day')) {
      return <LocalTranslatedText language={"en"} text="Yesterday" />;
    } else if (eventDate.isAfter(moment().subtract(7, 'days'))) {
      // For day names, we'll use moment's localized day name
      return <LocalTranslatedText language={"en"} text={eventDate.format('dddd')} />;
    }
    return <LocalTranslatedText 
      language={"en"} 
      text={eventDate.format('MMM DD')} 
    />;
  }, [event.created_at, isDateDifferentThenLast]);

  return (
    <>
      {isDateDifferentThenLast && (
        <div className="date-separator" style={{borderColor: webChatConfiguration?.event_date_font_color}}>
          <div className="date-header" style={{
            backgroundColor: webChatConfiguration?.widget_background_color,
            color: webChatConfiguration?.event_date_font_color
          }}>{dateHeader}</div>
        </div>
      )}
      <div
        className={`event ${event.role} ${event.action_id} ${event.module_id} ${isDateDifferentThenLastClass}`}
      >
        {event.metadata?.generated_document_id ? (
          <div className={"event-file"}>
            <a
              href={generatedDocumentUrl}
              target={"_blank"}
              rel='noreferrer'
              onClick={handleGeneratedDocumentClick}
            >
              <FilePreview
                fileId={event.metadata?.file_id}
                fileName={`${event.metadata?.generated_document_title}.pdf`}
              />
            </a>
          </div>
        ) : null}
        <div className={"event-inner"}>
          {event.role === "assistant" ? (
            <div className={"event-role-bot"}>
              <ConversationAvatar
                avatarUrl={webChatConfiguration?.avatar_url}
                role={event.role}
                moduleId={event.module_id}
                circleColor={webChatConfiguration?.role_icon_color}
                iconColor={webChatConfiguration?.widget_background_color}
              />
            </div>
          ) : null}
          <div className={"header-alert-combo-widget"}>
            <div className={`event-header ${event.role}`} style={{color: webChatConfiguration?.event_date_font_color}}>
              {
                event.role === "assistant" ? (
                  <span className={"role-name"} style={{color: webChatConfiguration?.role_name_font_color}}>
                    {
                      event.module_id === "user_interactor" ? (
                            <LocalTranslatedText language={"en"} text='Agent'/>
                      ) : (
                            <LocalTranslatedText language={"en"} text='Tool'/>
                      )
                    }
                    </span>
                ) : null
              }
              {
                event.role === "user" ?
                  <IconButton aria-label="Detailed Info" onClick={handleClickShowInfoPopover} size={"small"}>
                    <InfoOutlinedIcon sx={{
                      fontSize: 16,
                      fill: webChatConfiguration?.event_date_font_color,
                    }}/>
                  </IconButton>
                  : null
              }
              {!event.created_at ? (
                <LocalTranslatedText language={"en"} text='Invalid Date'/>
              ) : (
                moment.utc(event.created_at).local().format(
                  isDateDifferentThenLast ? "MMM Do, h:mm a" : "h:mm a"
                )
              )}
              {
                event.role === "assistant" ?
                  <IconButton aria-label="Detailed Info" onClick={handleClickShowInfoPopover} size={"small"}>
                    <InfoOutlinedIcon
                      sx={{
                        fontSize: 16,
                        fill: webChatConfiguration?.event_date_font_color,
                    }}
                    />
                  </IconButton>
                  : null
              }
              {
                event.role === "user" ? (
                  <span className={"role-name"} style={{color: webChatConfiguration?.role_name_font_color}}>
                  <LocalTranslatedText language={"en"} text={event.name || 'User'}/>
                    </span>
                ) : null
              }
            </div>

            <Alert
              variant='filled'
              severity={event.role === "user" ? "info" : "success"}
              icon={null}
              style={{
                  backgroundColor: event.role === "user" ?
                    webChatConfiguration?.user_message_background_color :
                    (
                      event.action_id === "send_message" ?
                      webChatConfiguration?.assistant_message_background_color :
                      webChatConfiguration?.tool_message_background_color
                    )
                }}
            >
              <div className={"event-text"} style={{
                color: event.role === "user" ?
                  webChatConfiguration?.user_message_font_color :
                    (
                      event.action_id === "send_message" ?
                      webChatConfiguration?.assistant_message_font_color :
                      webChatConfiguration?.tool_message_font_color
                    )
              }}>
                <LocalTranslatedText
                  text={String(event.content).trim()}
                  showOptionToViewOriginalLanguage={true}
                />
              </div>
            </Alert>
          </div>
          {event.role === "user" ? (
            <div className={"event-role-user"}>
              <ConversationAvatar
                avatarUrl={webChatConfiguration?.avatar_url}
                role={event.role}
                moduleId={event.module_id}
                circleColor={webChatConfiguration?.role_icon_color}
                iconColor={webChatConfiguration?.widget_background_color}
              />
            </div>
          ) : null}
        </div>
        {event.metadata?.show_loading_bar && isLast ? (
          <div className={"event-loading-bar"}>
            <IndeterminateLoadingBar
              halfLifeSeconds={event.metadata?.loading_bar_halflife}
            />
          </div>
        ) : null}
        <Popover
          open={isShowingInfoPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 1 }}>Event ID: {event?.id}</Typography>
          <Typography sx={{ p: 1 }}>Date: {moment.utc(event.created_at).local().format("MMM Do, h:mm:ss a")}</Typography>
          {
            event.module_id ?
            <Typography sx={{ p: 1 }}>Module ID: {event?.module_id}</Typography>
              : null
          }
          {
            event.action_id ?
              <Typography sx={{ p: 1 }}>Action ID: {event?.action_id}</Typography>
              : null
          }
          {
            event.name ?
              <Typography sx={{ p: 1 }}>Name: {event?.name}</Typography>
              : null
          }
          {
            event?.action_parameters && Object.keys(event?.action_parameters).length > 0 ?
              Object.keys(event?.action_parameters).map((key) => (
                <Typography sx={{ p: 1, whiteSpace: 'pre' }} key={key}>
                  {titleCaseFieldName(key)}:&nbsp;
                  {
                    JSON.stringify(event?.action_parameters[key], null, 2)
                      .replace(/^"/, "")
                      .replace(/"$/, "")
                  }
                </Typography>
              ))
            : null
          }
          {
            event?.metadata && Object.keys(event?.metadata).length > 0 ?
              Object.keys(event?.metadata).map((key) => (
                <Typography sx={{ p: 1, whiteSpace: 'pre' }} key={key}>
                  {titleCaseFieldName(key)}:&nbsp;
                  {
                    JSON.stringify(event?.metadata[key], null, 2)
                      .replace(/^"/, "")
                      .replace(/"$/, "")
                  }
                </Typography>
              ))
            : null
          }

        </Popover>
      </div>
    </>
  );
}

export function ConversationHistory({
                                      events,
                                      onlyShowMessages,
                                      conversation,
                                      webChatConfiguration,
                                    }) {
  let filteredEvents = events;
  if (onlyShowMessages) {
    filteredEvents = filteredEvents.filter((event) => {
      return event.module_id === "user_interactor" || event.role === "user";
    });
  }

  return (
    <div className={"prospera-labs-agent-platform-chat-widget-conversation-history"}>
      {filteredEvents.map((event, eventIndex) => (
        <ConversationHistoryEvent
          key={event._id ?? event.id ?? eventIndex}
          event={event}
          eventIndex={eventIndex}
          lastEvent={eventIndex > 0 ? filteredEvents[eventIndex - 1] : null}
          isLast={eventIndex === filteredEvents.length - 1}
          webChatConfiguration={webChatConfiguration}
        />
      ))}
    </div>
  );
}
