import "./BookingsListPage.scss";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";
import { BookingsTable } from "./BookingsTable";
import { PageHeader } from "@/frontend/elements/PageHeader";
import { PageWrapperWithMenu } from "@/frontend/elements/PageWrapperWithMenu";
import { WhiteButton } from "@/frontend/elements/WhiteButton";
import { useNavigate } from "react-router-dom";

export default function BookingsListPage() {
  const navigate = useNavigate();

  const handleBookingClicked = useCallback(
    (booking) => {
      navigate(`/bookings/${booking.id}`);
    },
    [navigate]
  );

  const handleNewBookingClicked = useCallback(async () => {
    navigate(`/bookings/new`);
  }, [navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={"Bookings"}
        subtitle={
          "View and manage all your bookings in one centralized location."
        }
      >
        <WhiteButton onClick={handleNewBookingClicked}>
          Add New Booking
        </WhiteButton>
      </PageHeader>
      <div className='booking-list-page'>
        <Paper className={"main-page-contents"}>
          <BookingsTable onBookingClicked={handleBookingClicked} />
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
