import React from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "@/frontend/lib/utils";
import {
  FormItem,
  FormField,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/frontend/components/ui/form";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { Textarea } from "@/frontend/components/ui/textarea";

export default function HFTextarea({ label, fieldName, className, ...rest }) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          {label && (
            <FormLabel>
              <LocalTranslatedText text={label} />
            </FormLabel>
          )}
          <FormControl>
            <Textarea
              {...field}
              onChange={field.onChange}
              className={cn(
                error &&
                  "border-danger focus:shadow-error focus:ring-ring-error",
                className
              )}
              value={field.value}
              placeholder={rest?.placeholder}
              {...rest}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
