import {IconButton, InputAdornment, TextField} from "@mui/material";
import React, {useCallback} from "react";
import "./ChatMessageInputBox.scss";
import SendIcon from '@mui/icons-material/Send';
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";

export const ChatMessageInputBox = ({ onSendMessage }) => {
    const [message, setMessage] = React.useState("");

    const handleSendMessage = useCallback(() => {
        if (onSendMessage && (message.trim())) {
            onSendMessage(message);
            setMessage("");
        }
    }, [message, onSendMessage]);

    const handleInputChange = useCallback((event) => {
        setMessage(event.target.value);
    }, []);

    const handleKeyDown = useCallback((event) => {
        if (event.key === "Enter") {
            // Check if ctrl or shift is being held, and if so, allow the enter key to go through normally
            if (event.ctrlKey || event.shiftKey) {
                setMessage(message + "\n");
            }
            else {
                event.preventDefault();
                handleSendMessage();
            }
        }
    }, [message, onSendMessage, setMessage]);

    const handleSendClick = useCallback(() => {
        handleSendMessage();
    }, [message, onSendMessage]);

    return <FormControl variant="outlined" className={"prospera-chat-message-input-box"}>
        <OutlinedInput
          id="message-input"
          type={'text'}
          multiline={true}
          autosize={"true"}
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={"Type your message here..."}
          endAdornment={
              <InputAdornment position="end">
                  <IconButton
                    aria-label={"Send Message"}
                    onClick={handleSendClick}
                    color={message ? "info" : "default"}
                    edge="end"
                  >
                      <SendIcon />
                  </IconButton>
              </InputAdornment>
          }
        />
    </FormControl>
}