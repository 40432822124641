import React from "react";
import { Divider, Drawer, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./CustomDrawer.scss";

export default function CustomDrawer({ open, onClose, children, title }) {
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDrawer-paper": { width: "50vw" } }}
      TransitionComponent={Slide}
      classes={
        {
          paper: "custom-drawer-paper",
        }
      }
    >
      {children}
    </Drawer>
  );
}
