import axios from "axios";

const api = {
    async getAvailablePhoneNumbers(search, country, phoneNumberType) {
        const params = {
            search: search,
            country: country,
            phoneNumberType: phoneNumberType
        }

        const response = await axios.get(`/voice/available_phone_numbers`, {params: params});
        return response.data;
    },
    async getVoiceConfiguration() {
        const response = await axios.get(`/voice/configuration`);
        return response.data;
    },
    async saveVoiceConfiguration(changes) {
        const response = await axios.put(`/voice/configuration`, changes);
        return response.data;
    },
    async getAvailableVoices(changes) {
        const response = await axios.get(`/voice/available_voices`, changes);
        return response.data;
    },
}

export default api;