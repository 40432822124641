import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ArticleIcon from "@mui/icons-material/Article";
import "./CustomDataObjectUploadPage.scss";
import {useParams} from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CustomDataObjectUploadPage(props) {
  const api = useAPI();
  const params = useParams();

  const [customDataSchema, setCustomDataSchema] = useState(null);

  const [filesBeingUploaded, setFilesBeingUploaded] = useState({});
  const queueRef = useRef([]);
  const isUploadingRef = useRef(false);

  // Add state for upload results
  const [uploadResults, setUploadResults] = useState({});

  // Add state for ignore unknown columns checkbox
  const [ignoreUnknownColumns, setIgnoreUnknownColumns] = useState(true);

  useEffect(() => {
    if (!customDataSchema) {
      api
        .getCustomDataSchema(params?.customDataSchemaId)
        .then((customDataSchema) => {
          setCustomDataSchema(customDataSchema);
        });
    }
  }, [
    customDataSchema,
    params?.customDataSchemaId,
    api,
  ]);

  const processQueue = useCallback(async () => {
    if (isUploadingRef.current || queueRef.current.length === 0) return;

    isUploadingRef.current = true;
    while (queueRef.current.length > 0) {
      const fileToUpload = queueRef.current.shift();
      const { file, onUploadProgress } = fileToUpload;

      try {
        // Create FormData to include both the file and the ignoreUnknownColumns parameter
        const formData = new FormData();
        formData.append('csvFile', file); // Use a consistent key name
        formData.append('ignore_unknown_fields', ignoreUnknownColumns.toString());
        const result = await api.createCustomDataObjectsWithCsvUpload(
          file, // Pass the file directly, not the FormData
          params?.customDataSchemaId,
          onUploadProgress,
          ignoreUnknownColumns // Pass the ignore flag as a separate parameter
        );
        setUploadResults(prev => ({
          ...prev,
          [file.name]: result
        }));
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
        setUploadResults(prev => ({
          ...prev,
          [file.name]: { error: error.response?.data?.error }
        }));
      }
    }
    isUploadingRef.current = false;
  }, [api, params?.customDataSchemaId, ignoreUnknownColumns]);

  const handleFileUploadButtonPressed = useCallback(() => {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");

    fileSelector.onchange = async (evt) => {
      const files = evt.target.files;

      for (let file of files) {
        const onUploadProgress = (progress) => {
          setFilesBeingUploaded((prevState) => ({
            ...prevState,
            [file.name]: {
              ...prevState[file.name],
              progress: progress,
            },
          }));
        };

        setFilesBeingUploaded((prevState) => ({
          ...prevState,
          [file.name]: {
            file,
            progress: 0,
          },
        }));

        queueRef.current.push({ file, onUploadProgress });
      }
      processQueue();
    };

    fileSelector.click();
  }, [processQueue]);

  useEffect(() => {
    if (!isUploadingRef.current && queueRef.current.length > 0) {
      processQueue();
    }
  }, [filesBeingUploaded, processQueue]);

  const renderSchemaFields = useCallback((properties, onlyRequired = false, parentKey = '') => {
    const fields = Object.entries(properties).flatMap(([key, value]) => {
      const fullPath = parentKey ? `${parentKey}.${key}` : key;
      if (value.type === 'object' && value.properties) {
        return renderSchemaFields(value.properties, onlyRequired, fullPath);
      }
      return value.type !== 'object' && (!onlyRequired || value.required === true) ? fullPath : [];
    });

    return parentKey ? fields : fields.join(', ');
  }, []);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`Custom Data .csv Upload Page`} />
      <div className='custom-data-object-upload-page'>
        <Card className='document-edit-form-card'>
          <CardContent>
            <div className='custom-data-object-upload-page-content'>
              <div className={"custom-data-object-form"}>
                <p>You may upload a .csv file with data to be inserted into the custom data object store.</p>
                <p>Columns must be separated by a ,(comma), and rows must be terminated with a linebreak.</p>
                <p>The 1st row of the .csv file must contain the column names.<br/>
                  In order to match with machine-friendly names, column names in the csv header are automatically lower-cased,
                  and spaces are replaced with _ (underscores).</p>
                <p>In order to match the custom data schema, the following <strong>required</strong> columns are expected as a minimum:</p>
                {customDataSchema?.json_schema?.properties ? (
                  <div style={{ fontFamily: 'monospace', backgroundColor: '#f0f0d0', padding: '8px' }}>
                    {renderSchemaFields(customDataSchema.json_schema.properties, true)}
                  </div>
                ) : (
                  <span>Loading schema properties...</span>
                )}
                <br/><br/>
                <p>..and these are <strong>all columns</strong> available to be provided:</p>
                {customDataSchema?.json_schema?.properties ? (
                  <div style={{ fontFamily: 'monospace', backgroundColor: '#f0f0d0', padding: '8px' }}>
                    {renderSchemaFields(customDataSchema.json_schema.properties, false)}
                  </div>
                ) : (
                  <span>Loading schema properties...</span>
                )}
                
                {/* Add checkbox for ignoring unknown columns */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ignoreUnknownColumns}
                      onChange={(e) => setIgnoreUnknownColumns(e.target.checked)}
                      name="ignoreUnknown"
                      color="primary"
                    />
                  }
                  label="Ignore columns that are not present in the custom schema"
                />
              </div>

              <div
                className='upload-button-box'
                onClick={handleFileUploadButtonPressed}
                role='button'
                tabIndex={0}
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFileUploadButtonPressed(e);
                  }}
                >
                  Upload .csv file
                </Button>
              </div>

              <List dense={false}>
                {Object.keys(filesBeingUploaded).map((fileName) => {
                  const progress = filesBeingUploaded[fileName].progress * 100;
                  return (
                      <div>
                    <ListItem key={fileName}>
                      <ListItemAvatar>
                        <Avatar>
                          <ArticleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={fileName}
                        secondary={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <LinearProgress
                              variant='determinate'
                              value={progress}
                              style={{ flex: 1, marginRight: "8px" }}
                            />
                            <span>{`${Math.round(progress)}%`}</span>
                          </div>
                        }
                      />
                    </ListItem>
                    <ListItem key={`${fileName}-result`}>
                      {Math.round(progress) === 100 && (
                        <ListItemText>
                          {uploadResults[fileName]?.error 
                            ? `Error: ${uploadResults[fileName].error}`
                            : `Successfully uploaded ${uploadResults[fileName]?.recordCount || 0} records`
                          }
                        </ListItemText>
                      )}
                    </ListItem>
                        </div>
                  );
                })}
              </List>
            </div>
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
