import axios from "axios";
import SSEStreamer from "../../frontend/components/SSEStreamer";

const api = {
  async getEmailConfiguration() {
    const response = await axios.get(`/email/configuration`);
    return response.data;
  },

  async saveEmailConfiguration(newConfig) {
    const response = await axios.patch(`/email/configuration`, newConfig);
    return response.data;
  },
};

export default api;
