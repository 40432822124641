import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback } from "react";
import { Card, CardContent } from "@mui/material";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CustomDataSchemaForm from "./CustomDataSchemaForm";
import { useNavigate } from "react-router-dom";

export default function NewCustomDataSchemaPage(props) {
  const api = useAPI();
  const navigate = useNavigate();
  const handleCreateCustomDataSchema = useCallback(
    async (customDataSchemaData) => {
      return await api
        .createNewCustomDataSchema(customDataSchemaData)
        .then((newSchema) => {
          navigate(`/custom_data_schema/${newSchema.id}`);
        });
    },
    [api, navigate]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`New Data Schema`} />
      <div className='custom-data-schema-edit-page'>
        <Card className='custom-data-schema-edit-form-card'>
          <CardContent>
            <CustomDataSchemaForm
              initialCustomDataSchema={{
                json_schema: { type: "object", properties: {} },
                name: "",
                description: "",
                schema_type: "object",
                is_array: false,
                machine_name: "",
                title: "",
                show_in_left_menu: false,
                left_menu_icon: "",
                custom_actions: []
              }}
              onCustomDataSchemaSave={handleCreateCustomDataSchema}
              saveButtonText={"Create New Custom Data Schema"}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
