import CircularProgress from "@mui/material/CircularProgress";
import { PageWrapperWithoutMenu } from "../elements/PageWrapperWithoutMenu";
import { ElementVisibilityDelayer } from "../components/ElementVisibilityDelayer";
import React from "react";
import "./ApplicationBootingPage.scss";

let globalBootupCountdown = 90;

export const ApplicationBootingPage = ({description}) => {
  const [countdown, setCountdown] = React.useState(globalBootupCountdown);

  React.useEffect(() => {
    const interval = setInterval(() => {
      globalBootupCountdown -= 1;
      setCountdown(globalBootupCountdown);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <PageWrapperWithoutMenu>
      <ElementVisibilityDelayer delayMs={500}>
        <div className={"application-booting-page"}>
          {globalBootupCountdown <= 1 ? (
            <p>
              There may be a problem with the server boot sequence. Please
              contact support.
            </p>
          ) : (
            <div className={"application-booting-text"}>
              <p>Application is booting up ...</p>
              <p>Please wait</p>
              <br/>
              {
                description ? <p>{description}</p> : null
              }
              {globalBootupCountdown <= 80 ? (
                <>
                  <br />
                  <p>This may take up to {countdown} more seconds ...</p>
                </>
              ) : null}
              <CircularProgress />
            </div>
          )}
        </div>
      </ElementVisibilityDelayer>
    </PageWrapperWithoutMenu>
  );
};

export default ApplicationBootingPage;
