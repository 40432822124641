import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiIconSelector from "../components/widgets/MuiIconSelector";
import Autocomplete from "@mui/material/Autocomplete";

function MenuItemForm({
  menuItem,
  listeners,
  onRemoveMenuItem,
  index,
  setItems,
  allMenuLinks,
}) {
  const handleChange = (field, value) => {
    setItems((prevItems) => {
      const updateNestedItems = (items) =>
        items.map((item) => {
          if (item.id === menuItem.id) {
            return { ...item, [field]: value };
          }
          if (item.children.length > 0) {
            return { ...item, children: updateNestedItems(item.children) };
          }
          return item;
        });

      return updateNestedItems(prevItems);
    });
  };

  return (
    <Accordion elevation={0} sx={{ border: "1px solid #e0e0e0" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1-content'
        id='panel1-header'
      >
        <Box display='flex' alignItems='center'>
          <IconButton
            aria-label='drag'
            size='small'
            sx={{
              cursor: "grab",
            }}
            {...listeners}
          >
            <DragIndicatorIcon />
          </IconButton>
          <Typography component='span' fontWeight={600}>
            {menuItem.title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Title'
              value={menuItem.title}
              onChange={(e) => handleChange("title", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              options={allMenuLinks}
              getOptionLabel={(option) => option}
              value={
                allMenuLinks.find((link) => link === menuItem.link) || null
              }
              onChange={(event, newValue) => {
                handleChange("link", newValue ? newValue : "");
              }}
              renderInput={(params) => <TextField {...params} label='Link' />}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiIconSelector
              fieldName='icon'
              label='Icon'
              value={menuItem.icon}
              onChange={(field, value) => handleChange(field, value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuItem.is_hidden}
                  onChange={(e) => handleChange("is_hidden", e.target.checked)}
                />
              }
              label='Hide this menu item from your users'
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuItem.is_admin}
                  onChange={(e) => handleChange("is_admin", e.target.checked)}
                />
              }
              label='Hide if not admin'
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='outlined'
              color='error'
              onClick={() => onRemoveMenuItem(index)}
              startIcon={<DeleteIcon />}
            >
              Remove Menu Item
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(MenuItemForm);
