import React, { Fragment } from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NamedIcon } from "../elements/NamedIcon";

export default function LeftMenuSection({
  title,
  icon,
  children,
  isOpen,
  toggleSection,
}) {
  return (
    <Fragment>
      <ListItemButton onClick={toggleSection}>
        <ListItemIcon>
          <NamedIcon icon={icon} />
        </ListItemIcon>
        <ListItemText primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
}
