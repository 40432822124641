import Button from "@mui/material/Button";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import "./SpinnerButton.scss";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ensureMinimumPromiseResolveTime } from "../utils/time";

export function SpinnerButton(props) {
  const [isSpinnerShowing, setIsSpinnerShowing] = useState(false);
  const [isCheckShowing, setIsShowingCheck] = useState(false);
  const [isCrossShowing, setIsCrossShowing] = useState(false);
  const spinnerColor = props.spinnerColor || "primary";

  const handleOnClick = (evt) => {
    if (props.onClick) {
      if (isSpinnerShowing) {
        // Don't allow clicking the button again if it's already showing a spinner,
        // this prevents double-clicking.
        return;
      }

      setIsSpinnerShowing(true);

      try {
        let promise = props.onClick(evt);
        if (!promise) {
          setIsSpinnerShowing(false);
          return;
        } else {
          promise = ensureMinimumPromiseResolveTime(promise, 500);
        }

        return promise
          .then(
            (result) => {
              setIsSpinnerShowing(false);
              setIsShowingCheck(true);
              setTimeout(() => {
                setIsShowingCheck(false);
              }, 1000);
              return result;
            },
            (err) => {
              setIsSpinnerShowing(false);
              setIsCrossShowing(true);
              setTimeout(() => {
                setIsCrossShowing(false);
              }, 1000);
              return err;
            }
          )
          .catch((err) => {
            setIsSpinnerShowing(false);
            setIsCrossShowing(true);
            setTimeout(() => {
              setIsCrossShowing(false);
            }, 1000);
            return err;
          });
      } catch (err) {
        setIsSpinnerShowing(false);
        setIsCrossShowing(true);
        setTimeout(() => {
          setIsCrossShowing(false);
        }, 1000);
        throw err;
      }
    }
  };

  const className = `${props.className || ""} spinner-button`.trim();

  const newProps = {
    ...props,
    className: className,
    onClick: handleOnClick,
    startIcon: isSpinnerShowing ? null : props.startIcon,
    endIcon: isSpinnerShowing ? null : props.endIcon,
  };

  let textWrapperClassName = "button-text-wrapper";
  if (!isSpinnerShowing && !isCheckShowing && !isCrossShowing) {
    textWrapperClassName += " visible-button-text";
  } else {
    textWrapperClassName += " hidden-button-text";
  }

  return (
    <Button {...newProps}>
      <div className={"spinner-wrapper"}>
        {isSpinnerShowing ? (
          <CircularProgress size={24} color={spinnerColor} />
        ) : null}
      </div>
      <div className={"icon-wrapper"}>
        {!isSpinnerShowing && isCheckShowing ? <CheckIcon /> : null}
        {!isSpinnerShowing && !isCheckShowing && isCrossShowing ? (
          <CloseIcon />
        ) : null}
      </div>
      <div className={textWrapperClassName}>{props.children}</div>
    </Button>
  );
}
