import React, { useState } from "react";
import { useAPI } from "@/frontend/components/APIProvider";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { useFormContext } from "react-hook-form";
import workflowTools from "@/backend/data/workflow_tools.json";
import { ScrollArea } from "@/frontend/components/ui/scroll-area";
import { cn } from "@/frontend/lib/utils";
import { CheckMark } from "@/frontend/icons/CheckMark";
import { getIcon } from "./tool-icon";
import HFTextarea from "@/frontend/components/hook-form/hf-textarea";
import { LoadingButton } from "@/frontend/components/ui/loading-button";

export default function MarketplaceTools({
  fetchOnboardingStatus,
  onboardingStatus,
}) {
  const api = useAPI();
  const { watch, setValue, getValues } = useFormContext();
  const selectedTools = watch("workflow_tools", []);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleTools = (tool) => {
    const updatedTools = selectedTools.includes(tool)
      ? selectedTools.filter((name) => name !== tool)
      : [...selectedTools, tool];

    setValue("workflow_tools", updatedTools, { shouldValidate: true });
  };

  const onSubmitSuggestion = async () => {
    const values = getValues();
    try {
      setIsSubmitting(true);
      await api.saveOnboardingStatus(values);
      fetchOnboardingStatus();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      console.error("Error saving onboarding status:", e);
    }
  };

  return (
    <div className='flex flex-col gap-10 justify-center items-center relative'>
      <h4 className='text-center text-gray-900 text-3xl font-bold'>
        <LocalTranslatedText text='Select workflow tools needed for your AI Agents Marketplace' />
      </h4>
      <div className='w-full lg:w-1/2 px-8 mx-auto flex flex-col gap-6'>
        <ScrollArea className='h-[600px] w-full'>
          <div className='grid grid-cols-12 gap-6'>
            {workflowTools.workflow_tools.map((tool) => {
              const isSelected = selectedTools.includes(tool);
              return (
                <div className='col-span-12 md:col-span-4 w-full' key={tool}>
                  <button
                    type='button'
                    className={cn(
                      "w-full flex shadow-container gap-3 items-center p-4 border rounded-sm",
                      isSelected
                        ? "border-primary border-2 bg-indigo-50"
                        : "border-slate-300 hover:border-primary"
                    )}
                    onClick={() => toggleTools(tool)}
                  >
                    {isSelected ? (
                      <span className='w-4 h-4 bg-primary rounded-xs flex justify-center items-center'>
                        <CheckMark className='text-white w-3 h-3' />
                      </span>
                    ) : (
                      <span className='w-4 h-4 bg-slate-200 rounded-xs shadow-inner'></span>
                    )}
                    <div className='flex items-center gap-1.5'>
                      {getIcon(tool)}
                      <h4 className='text-base font-semibold leading-6'>
                        {tool}
                      </h4>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </ScrollArea>
        <div className='w-full lg:absolute lg:top-20 lg:right-4 lg:w-[350px] border border-slate-300 rounded-sm p-6 bg-white shadow-container'>
          <div className='flex flex-col gap-4 w-full'>
            {!onboardingStatus?.wishlist_tool ? (
              <>
                <div>
                  <h4 className='text-gray-950 text-md font-bold'>
                    <LocalTranslatedText text='Suggest a Tool' />
                  </h4>
                  <p className='text-muted-foreground text-sm'>
                    <LocalTranslatedText text='Missing a tool? Suggest us what you wish to see!' />
                  </p>
                </div>
                <div className='w-full'>
                  <HFTextarea
                    fieldName='wishlist_tool'
                    placeholder="Enter the tool's name"
                    rows={4}
                    className='text-sm'
                  />
                </div>
                <span>
                  <LoadingButton
                    className='bg-black hover:bg-black/80'
                    type='button'
                    onClick={onSubmitSuggestion}
                    loading={isSubmitting}
                  >
                    <LocalTranslatedText text='Submit Request' />
                  </LoadingButton>
                </span>
              </>
            ) : (
              <div className='flex flex-col gap-4'>
                <h4 className='text-gray-950 text-md font-bold'>
                  <LocalTranslatedText text='Suggestion Submitted!' />
                </h4>
                <p className='text-muted-foreground text-sm'>
                  <LocalTranslatedText text="Thank you for your input! We've received your suggestion and will review it soon. Your feedback helps us improve and expand our AI offerings!" />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
