import React from "react";
import { Logo } from "@/frontend/icons/Logo";
import { XIcon } from "lucide-react";
import { Button } from "@/frontend/components/ui/button";
import { Progress } from "@/frontend/components/ui/progress";
import { cn } from "@/frontend/lib/utils";

export default function TopNav({ steps, currentStep }) {
  const totalSteps = steps.length - 1;
  const stepCount = totalSteps - 1;

  const progressPercentage =
    currentStep > 1 ? Math.round(((currentStep - 1) / stepCount) * 100) : 0;

  return (
    <div className='px-8 lg:px-[100px] py-[40px] border-b border-slate-200'>
      <div className='flex flex-col gap-10'>
        <div className='flex items-center justify-between'>
          <Logo />
          <Button
            size='icon'
            variant='outline'
            type='button'
            className='rounded-sm'
          >
            <XIcon className='w-6 h-6 shrink-0' />
          </Button>
        </div>
        <div className='relative w-full flex flex-col gap-6'>
          <Progress value={progressPercentage} className='h-2' />
          <div className='hidden md:flex justify-between'>
            {steps.slice(1).map((step, index) => (
              <div key={index} className='flex justify-between text-xs'>
                <span
                  className={cn(
                    "text-sm font-medium text-slate-500 leading-5 capitalize",
                    index + 1 <= currentStep ? "text-primary" : "text-gray-600"
                  )}
                >
                  {step.replace("-", " ")}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
