import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Robotspeak from "@/frontend/assets/img/robot-speak-1.webp";
import { useAPI } from "@/frontend/components/APIProvider";
import { ScrollArea } from "@/frontend/components/ui/scroll-area";
import { LoaderCircle } from "lucide-react";
import LocalTranslatedText from "@/translation/frontend/components/LocalTranslatedText";
import { cn } from "@/frontend/lib/utils";
import HFTextField from "@/frontend/components/hook-form/hf-text-field";
import HFTextarea from "@/frontend/components/hook-form/hf-textarea";
import { LoadingButton } from "@/frontend/components/ui/loading-button";

export default function MarketplaceServices({
  fetchOnboardingStatus,
  onboardingStatus,
}) {
  const api = useAPI();
  const { watch, setValue, getValues } = useFormContext();
  const selectedServices = watch("agent_marketplace", []);
  const [loadAgentServices, setLoadAgentServices] = useState(true);
  const [agentServices, setAgentServices] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchAgentServices = async () => {
    try {
      const response = await api.getAgentServices({});
      setAgentServices(response);
    } catch (e) {
      console.error("Error fetching agent services:", e);
    } finally {
      setLoadAgentServices(false);
    }
  };

  const toggleService = (serviceName) => {
    const updatedServices = selectedServices.includes(serviceName)
      ? selectedServices.filter((name) => name !== serviceName)
      : [...selectedServices, serviceName];

    setValue("agent_marketplace", updatedServices, { shouldValidate: true });
  };

  const onSubmitSuggestion = async () => {
    const values = getValues();
    try {
      setIsSubmitting(true);
      await api.saveOnboardingStatus(values);
      fetchOnboardingStatus();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      console.error("Error saving onboarding status:", e);
    }
  };

  useEffect(() => {
    fetchAgentServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex flex-col gap-10 justify-center items-center relative'>
      <h4 className='text-center text-gray-900 text-3xl font-bold'>
        <LocalTranslatedText text='I want my AI Agents Marketplace to include...' />
      </h4>
      <div className='w-full lg:w-1/2 px-8 mx-auto flex flex-col gap-6'>
        <ScrollArea className='h-[600px] w-full'>
          <div className='grid grid-cols-12 gap-6'>
            {loadAgentServices ? (
              <LoaderCircle className='animate-spin-slow' />
            ) : agentServices.length > 0 ? (
              agentServices.map((service) => {
                const isSelected = selectedServices.includes(
                  service.service_name
                );
                return (
                  <div
                    className='col-span-12 md:col-span-4 w-full'
                    key={service.service_name}
                  >
                    <button
                      type='button'
                      onClick={() => toggleService(service.service_name)}
                      className={cn(
                        "w-full flex shadow-container flex-col gap-6 justify-center items-center p-6 border rounded-sm min-h-[250px] h-full",
                        isSelected
                          ? "border-primary border-2 bg-indigo-50"
                          : "border-slate-300 hover:border-primary hover:border-2"
                      )}
                    >
                      <img
                        src={Robotspeak}
                        alt='Robot speak'
                        className='w-[74px] h-[74px] shrink-0'
                      />
                      <div className='flex flex-col gap-2'>
                        <h4 className='text-center text-gray-900 text-lg font-bold'>
                          <LocalTranslatedText text={service?.service_name} />
                        </h4>
                        <p className='text-muted-foreground text-base text-center flex-grow'>
                          <LocalTranslatedText
                            text={service?.service_description}
                          />
                        </p>
                      </div>
                    </button>
                  </div>
                );
              })
            ) : null}
          </div>
        </ScrollArea>
        <div className='w-full lg:absolute lg:top-20 lg:right-4 lg:w-[350px] border border-slate-300 rounded-sm p-6 bg-white shadow-container'>
          <div className='flex flex-col gap-4 w-full'>
            {!onboardingStatus?.suggested_agent_service?.name ? (
              <>
                <div>
                  <h4 className='text-gray-950 text-md font-bold'>
                    <LocalTranslatedText text='Suggest AI Agent' />
                  </h4>
                  <p className='text-muted-foreground text-sm'>
                    <LocalTranslatedText text='Missing an AI Agent? Suggest us what you wish to see!' />
                  </p>
                </div>
                <div className='w-full'>
                  <HFTextField
                    fieldName='suggested_agent_service.name'
                    placeholder='Receptionist'
                    className='text-sm'
                  />
                </div>
                <div className='w-full'>
                  <HFTextarea
                    fieldName='suggested_agent_service.description'
                    placeholder='Give a brief description of how this agent service will work . . .'
                    rows={4}
                    className='text-sm'
                  />
                </div>
                <span>
                  <LoadingButton
                    className='bg-black hover:bg-black/80'
                    type='button'
                    onClick={onSubmitSuggestion}
                    loading={isSubmitting}
                  >
                    <LocalTranslatedText text='Submit Request' />
                  </LoadingButton>
                </span>
              </>
            ) : (
              <div className='flex flex-col gap-4'>
                <h4 className='text-gray-950 text-md font-bold'>
                  <LocalTranslatedText text='Suggestion Submitted!' />
                </h4>
                <p className='text-muted-foreground text-sm'>
                  <LocalTranslatedText text="Thank you for your input! We've received your suggestion and will review it soon. Your feedback helps us improve and expand our AI offerings!" />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
