import React, { useContext } from "react";

const LeftSideMenuStateContext = React.createContext(null);
export const LeftSideMenuStateProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  const leftMenuStateInterface = {
    isExpanded,
    setIsExpanded,
  };

  return (
    <LeftSideMenuStateContext.Provider value={leftMenuStateInterface}>
      {children}
    </LeftSideMenuStateContext.Provider>
  );
};
export const useLeftMenuState = useContext.bind(null, LeftSideMenuStateContext);
